import React, { useContext, useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../../../components/Button'
import { IoIosAdd } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Modal from './Modal'
import Table from '../../../../components/Table'
import { BiTrash } from 'react-icons/bi'
import Confirmation from '../../../../components/Confirmation/Confirmation'
import { deleteManners, getMannersList, } from '../../../../actions/directoryBasicEducation'
import { CandidatesContext } from '../../../../utils/context'
import { getVaccineExclusionsModal } from '../../../../actions/medCenter/medCard'


export default function Inoculations() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { student } = useContext(CandidatesContext)

  const { vaccinModal } = useSelector(state => state.directoryMedCenter)
  console.log('vaccinModal::: ', vaccinModal);

  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(false);

  useEffect(() => {
    dispatch(getVaccineExclusionsModal(student.mid))
  }, [])

  const openModal = (data) => () => {
    setSelectedItem(data || {})
  }
  const selected = (data) => () => {
    // setDiagnosisComplaint(data)
    // closeComplain()
  }

  const closeModal = () => setSelectedItem(null)

  const goHome = () => navigate('/')

  const acceptConfirmation = () => {
    closeModal();
    dispatch(deleteManners(confirmation.id));
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  const renderLi = (data) => {
    const { id, name } = data
    return (
      <tr >
        {/* <td onClick={openModal(data)}>{name}</td> */}
        <td onClick={selected(data)}>{name}</td>
        <td></td>
        <td></td>
        <td></td>
        <td><i className={styles.trash} onClick={() => setConfirmation({ id: id, name: name })}><BiTrash /></i></td>
      </tr>
    )
  }

  return (
    <div className={styles.wrapper} style={{ width: '780px', height: '320px', margin: '0 auto' }}>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
          acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      {selectedItem &&
        <Modal
          closeModal={closeModal}
          data={selectedItem}
          height={'430px'}
          // addMode={(!selectedItem?.name && selectedItem !== null)}
          addMode={!selectedItem.name}
          student={student}
        />
      }
      <h1>Прививки</h1>

      <Table className={styles.tables}>
        <thead>
          <tr>
            <th>Дата</th>
            <th>Наименование прививки</th>
            <th>Вакцина</th>
            <th>Серия</th>
            <th></th>
          </tr>
        </thead>
        <tbody>

          {vaccinModal?.map(renderLi)}

        </tbody>
      </Table>

      <div style={{ display: 'flex', flexDirection: "row" }}>
        <Button
          style={{ padding: '.5rem 1.5rem', margin: '12px' }}
          onClick={openModal()}
        >
          <IoIosAdd size={30} />
          Добавить
        </Button>
      </div>
    </div>
  )
}
