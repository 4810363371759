import React from 'react'
import styles from './styles.module.css'
import cn from 'classnames'

export default function Table ({children, className, maxHeight = '45vh', ...props}) {
  
  const classNames = cn([styles.table, className])

  return (
    <div className={classNames} >
        <table style={{maxHeight: maxHeight}}>
            {children}
        </table>
    </div>
  )
}
