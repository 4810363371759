import React, { useEffect, useState } from 'react'
import Modal from 'react-responsive-modal'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { variableStuffModalModes } from '../../assets/const'
import userPhoto from '../../assets/images/user_photo2.png'
import cn from 'classnames'
import { Main, Admission, Education, Qualification, Award, Punish, Experience, Lessons, Events, Roles, Documents, Attestation } from './ModalTabs'
import { StudentsContext } from '../../utils/context'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { createStudentMain, getStudentMain, updateStudentMain } from '../../actions/students/main'
import { createStudentOtherData, getStudentOtherData, updateStudentOtherData } from '../../actions/students/other'


const ACTION_TYPES = ['physical_training', 'severity', 'lang', 'grades', 'family', 'penalty', 'privilege', 'ege', 'dl', 'public_load', 'achievs']

const COMPONENTS = [
  { name: 'main', Component: Main },
  { name: 'admission', Component: Admission },
  { name: 'education', Component: Education },
  { name: 'qualification', Component: Qualification },
  { name: 'award', Component: Award },
  { name: 'punish', Component: Punish },
  { name: 'experience', Component: Experience },
  { name: 'lesson', Component: Lessons },
  { name: 'event', Component: Events },
  { name: 'role', Component: Roles },
  { name: 'documents', Component: Documents },
  { name: 'attestation', Component: Attestation },
]

export default function ModalComponent({ studentId, isOpen, closeModal, addMode }) {

  const dispatch = useDispatch()
  const { data } = useSelector(state => state.students)

  const [mode, setMode] = useState('main')

  useEffect(() => {
    if (!isOpen || addMode) return;
    dispatch(getStudentMain(studentId))
    ACTION_TYPES.forEach(type => dispatch(getStudentOtherData(type, studentId)))
  }, [isOpen])

  const submitForm = (entity) => ({ mid, ...values }) => {
    if (!Object.keys(values).length) return;
    if (entity === 'main') {
      mid ? dispatch(updateStudentMain(mid, values)) : dispatch(createStudentMain(values))
    } else {
      mid ? dispatch(updateStudentOtherData(entity, mid, values)) : dispatch(createStudentOtherData(entity, values))
    }
  }

  const mainForm = useFormik({
    initialValues: data.main || {},
    enableReinitialize: true,
    onSubmit: submitForm('main')
  })

  const physicalTrainingForm = useFormik({
    initialValues: data.physical_training || {},
    enableReinitialize: true,
    onSubmit: submitForm('physical_training')
  })

  const severityForm = useFormik({
    initialValues: data.severity || {},
    enableReinitialize: true,
    onSubmit: submitForm('severity')
  })

  const langForm = useFormik({
    initialValues: data.lang || {},
    enableReinitialize: true,
    onSubmit: submitForm('lang')
  })

  const gradesForm = useFormik({
    initialValues: data.grades || {},
    enableReinitialize: true,
    onSubmit: submitForm('grades')
  })

  const familyForm = useFormik({
    initialValues: data.family || {},
    enableReinitialize: true,
    onSubmit: submitForm('family')
  })

  const penaltyForm = useFormik({
    initialValues: data.penalty || {},
    enableReinitialize: true,
    onSubmit: submitForm('penalty')
  })

  const privilegeForm = useFormik({
    initialValues: data.privilege || {},
    enableReinitialize: true,
    onSubmit: submitForm('privilege')
  })

  const egeForm = useFormik({
    initialValues: data.ege || {},
    enableReinitialize: true,
    onSubmit: submitForm('ege')
  })

  const dlForm = useFormik({
    initialValues: data.dl || {},
    enableReinitialize: true,
    onSubmit: submitForm('dl')
  })

  const publicLoadForm = useFormik({
    initialValues: data.public_load || {},
    enableReinitialize: true,
    onSubmit: submitForm('public_load')
  })

  const achievsForm = useFormik({
    initialValues: data.achievs || {},
    enableReinitialize: true,
    onSubmit: submitForm('achievs')
  })

  const renderLi = ({ name, value, icon }) => {
    const selected = value === mode
    const classNames = cn(styles.modalMenuItem, { [styles.selectedMenuItem]: selected })
    return (
      <li onClick={() => setMode(value)} className={classNames}>
        {icon()}
        {name}
      </li>
    )
  }

  const renderCompoonent = ({ name, Component }) => <Component active={name === mode} />
  const forms = {
    mainForm,
    physicalTrainingForm,
    severityForm,
    langForm,
    gradesForm,
    familyForm,
    penaltyForm,
    privilegeForm,
    egeForm,
    dlForm,
    publicLoadForm,
    achievsForm
  }

  const saveClickHandler = () => {
    Object.values(forms).forEach(form => form.submitForm())
  }

  // Object.keys(forms).forEach(key => console.log(key, forms[key].values))

  return (
    <StudentsContext.Provider value={forms}>
      <Modal
        open={isOpen}
        classNames={{ modal: styles.modalMain }}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        showCloseIcon={false}
        animationDuration={1}
      >
        <div className={styles.modalContent}>
          <div className={styles.modalMenu}>
            <img src={userPhoto} alt='o_O' />
            <ul>{variableStuffModalModes.map(renderLi)}</ul>
          </div>
          <div className={styles.modalMain}>{COMPONENTS.map(renderCompoonent)}</div>
          <div className={styles.modalFooter}>
            <Button mode='bordered'>Пароль</Button>
            <div className={styles.modalFooterRightBlock}>
              <div>
                <div>Создал: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
                <div>Изменил: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
              </div>
              <Button mode='white' onClick={saveClickHandler}>Сохранить</Button>
              <Button mode='white' onClick={closeModal}>Закрыть</Button>
            </div>
          </div>
        </div>
      </Modal>
    </StudentsContext.Provider>
  )
}
