import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { IoIosAdd } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Modal from './Modal'
import Table from '../../components/Table'
import { BiTrash } from 'react-icons/bi'
import Confirmation from '../../components/Confirmation/Confirmation'
import { deleteTypesOfDisease, getTypesOfDisease } from '../../actions/medCenter/typesOfDisease'
import { getHolidays } from '../../actions/holidays'
import SelectPal from '../../components/Select copy'
import { deleteStagesStudy, getStagesStudy } from '../../actions/stagesStudy'


export default function StagesStudy() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { stageStudyList } = useSelector(state => state.stages_study)

  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(false);

  useEffect(() => {
    dispatch(getStagesStudy())
  }, [selectedItem])

  const openModal = (data) => () => {
    setSelectedItem(data)
  }

  const closeModal = () => setSelectedItem(null)

  const goHome = () => navigate('/')

  const acceptConfirmation = () => {
    closeModal();
    dispatch(deleteStagesStudy(confirmation.id));
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  const renderLi = (data) => {
    const { f_studysteps, name } = data
    return (
      <tr >
        <td onClick={openModal(data)}>{name}</td>
        <td><i className={styles.trash} onClick={() => setConfirmation({ id: f_studysteps, name })}><BiTrash /></i></td>
      </tr>
    )
  }

  return (
    <div className={styles.wrapper} style={{ width: '700px', margin: '0 auto' }}>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
          acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      {selectedItem &&
        <Modal
          closeModal={closeModal}
          data={selectedItem}
          height={'430px'}
          // addMode={(!selectedItem?.name && selectedItem !== null)}
          addMode={selectedItem == 'add'}
        />
      }
      <h1>Ступени обучения</h1>

      <Table className={styles.tableDis}>
        <thead>
          <tr>
            <th>Название</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {stageStudyList?.map(renderLi)}
        </tbody>
      </Table>

      <div style={{ display: 'flex', flexDirection: "row" }}>
        <Button
          style={{ padding: '.5rem 1.5rem', margin: '12px' }}
          onClick={openModal('add')}
        >
          <IoIosAdd size={30} />
          Добавить
        </Button>
      </div>
    </div>
  )
}
