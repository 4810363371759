import { BACKEND_URL_PREFIX, itemsOnPage } from "../assets/const";
import { createNotification } from "../components/Notifications/Notifications";
import { checkAndUpdateToken } from "./auth";
import { buildDefaultHeaders } from "./helper";
import { SET_CURRENT_PERIOD, SET_PERIODS_COUNT, SET_PERIODS_LIST, SET_PERIODS_LOADING, SET_PERIODS_PAGE } from "./types";


export const setPage = (pageNumber) => ({type: SET_PERIODS_PAGE, payload: pageNumber })

export const getPeriodsList = ({year, shift}) => async (dispatch, getState) => {
  
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_PERIODS_LOADING, payload: true });
    const { pageNumber } = getState().periods
    const limit = itemsOnPage
    const offset = itemsOnPage * pageNumber
    const urlParams = new URLSearchParams({limit, offset}).toString();
    const response = await fetch(`${BACKEND_URL_PREFIX}/period/${year}/${shift}`, {
    // ?${urlParams}
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {

      const {cnt: count, periods} = await response.json();
      const pageCount = Math.ceil(count / itemsOnPage)

      dispatch({ type: SET_PERIODS_LIST, payload: periods });
      dispatch({ type: SET_PERIODS_COUNT, payload: {pageCount, count} });

    }
  }
  dispatch({ type: SET_PERIODS_LOADING, payload: false });
};

export const getCurrentPeriod = (periodId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_PERIODS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/period/${periodId}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_CURRENT_PERIOD, payload });
    }
  }
  dispatch({ type: SET_PERIODS_LOADING, payload: false });
};

export const deletePeriod = ( data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_PERIODS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/period/${data.lid}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const {list, pageNumber} = getState().periods
      list.length < 2 && dispatch(setPage(pageNumber - 1)) 
      await dispatch(getPeriodsList(data.search));
    }
  }
  dispatch({ type: SET_PERIODS_LOADING, payload: false });
};

export const updatePeriod = (lid, data, search) => async (dispatch, getState) => {
  console.log('data::: ', data);
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_PERIODS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/period/${lid}`, {
      method: "PUT",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      // await dispatch(getPeriodsList({year:data.s_year_id, shift:data.outstatus}));
      await dispatch(getPeriodsList({year:search.year, shift: search.shift} ));

    }
  }
  dispatch({ type: SET_PERIODS_LOADING, payload: false });
};

export const createPeriod = (data, search) => async (dispatch, getState) => {
  console.log('data::: ', data);
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_PERIODS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/period`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getPeriodsList({year:search.year, shift: search.shift} ));
    }
  }
  dispatch({ type: SET_PERIODS_LOADING, payload: false });
};


export const copyPeriod = (data) => async (dispatch, getState) => {
  const {from_year, from_shift, to_year} = data
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_PERIODS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/period/copy/${from_year}/${from_shift}/${to_year}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
    }
  }
  dispatch({ type: SET_PERIODS_LOADING, payload: false });
};
