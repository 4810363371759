import { api } from "../App";
import { createNotification } from "../components/Notifications/Notifications";
import { SET_ADMISSION_DISCIPLINES, SET_ADMISSION_DISCIPLINES_FOR_SELECT, SET_ADMISSION_BY_ID, SET_ADMISSIONS_LIST, SET_ADMISSIONS_LOADING } from "./types";




export const getAdmissionsList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_ADMISSIONS_LOADING, payload: true });
    const { data } = await api.get('/enroll_comitet');
    dispatch({ type: SET_ADMISSIONS_LIST, payload: data });
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_ADMISSIONS_LOADING, payload: false });
  }
}



export const createAdmissionComitet = (data) => async (dispatch, getState) => {
  let success
  try {
    // dispatch({ type: SET_ACHIEV_POINTS_LOADING, payload: true });
    await api.post('/enroll_comitet', data);
    await dispatch(getAdmissionsList())
    // await dispatch(getAdmissionById(data.id))
    success = true
    createNotification('success', "Комиссия успешно создана");
  } catch ({ response }) {
    const errorMessage = response.data.error || 'Что-то пошло не так'
    createNotification('error', errorMessage);
  } finally {
    // dispatch({ type: SET_ACHIEV_POINTS_LOADING, payload: false });
    return success
  }
}
export const updateAdmissionComitet = ({ id, ...data }) => async (dispatch, getState) => {
  let success
  try {
    // dispatch({ type: SET_ACHIEV_POINTS_LOADING, payload: true });
    await api.put(`/enroll_comitet/${id}`, data);
    await dispatch(getAdmissionsList())
    success = true
  } catch ({ response }) {
    const errorMessage = response.data.error || 'Что-то пошло не так'
    createNotification('error', errorMessage);
  } finally {
    // dispatch({ type: SET_ACHIEV_POINTS_LOADING, payload: false });
    return success
  }
}

export const deleteAdmissionComitet = (id) => async (dispatch, getState) => {
  try {
    // dispatch({ type: SET_ACHIEV_POINTS_LOADING, payload: true });
    await api.delete(`/enroll_comitet/${id}`);
    await dispatch(getAdmissionsList())
  } catch (ERR) {
  } finally {
    // dispatch({ type: SET_ACHIEV_POINTS_LOADING, payload: false });
  }
}


export const getAdmissionById = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_ADMISSIONS_LOADING, payload: true });
    const { data } = await api.get(`/enroll_comitet/${id}`);
    console.log('data::: ', data);
    dispatch({ type: SET_ADMISSION_BY_ID, payload: data });
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_ADMISSIONS_LOADING, payload: false });
  }
}

// ЧЛЕНЫ КОМИССИИ
export const createAdmissionComitetMember = ({ id, ...data }) => async (dispatch, getState) => {
  let success
  try {
    // dispatch({ type: SET_ACHIEV_POINTS_LOADING, payload: true });
    await api.post(`/enroll_comitet/${id}/members`, data);
    await dispatch(getAdmissionById(id))
    success = true
  } catch ({ response }) {
    const errorMessage = response.data.error || 'Что-то пошло не так'
    createNotification('error', errorMessage);
  } finally {
    // dispatch({ type: SET_ACHIEV_POINTS_LOADING, payload: false });
    return success
  }
}

export const deleteAdmissionComitetMember = ({ id, mid }) => async (dispatch, getState) => {
  console.log('id, mid::: ', id, mid);
  try {
    // dispatch({ type: SET_ACHIEV_POINTS_LOADING, payload: true });
    await api.delete(`/enroll_comitet/${id}/members/${mid}`);
    await dispatch(getAdmissionById(id))
  } catch (ERR) {
  } finally {
    // dispatch({ type: SET_ACHIEV_POINTS_LOADING, payload: false });
  }
}


// Получение дисциплин для тестирования
export const getAdmissionDisciplines = (data) => async (dispatch, getState) => {
  const urlParams = new URLSearchParams(data).toString();
  try {
    dispatch({ type: SET_ADMISSIONS_LOADING, payload: true });
    const { data } = await api.get(`/enroll_comitet/disciplines?${urlParams}`);
    dispatch({ type: SET_ADMISSION_DISCIPLINES, payload: data });
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_ADMISSIONS_LOADING, payload: false });
  }
}

export const createAdmissionDisciplines = (data) => async (dispatch, getState) => {
  console.log('createAdmissionDisciplines cdata::: ', data);
  let success
  try {
    // dispatch({ type: SET_ACHIEV_POINTS_LOADING, payload: true });
    await api.post(`/enroll_comitet/disciplines`, data);
    // await dispatch(getAdmissionDisciplines(data))
    success = true
  } catch ({ response }) {
    const errorMessage = response.data.error || 'Что-то пошло не так'
    createNotification('error', errorMessage);
  } finally {
    // dispatch({ type: SET_ACHIEV_POINTS_LOADING, payload: false });
    return success
  }
}