import React, { useEffect, useState } from 'react'
import styles from './index.module.css'
import { Input } from '../../../../components/ModalInputs'
import Button from '../../../../components/Button'
import { createStudyPlanDOFile, getStudyPlanDOFiles } from '../../../../actions/studyPlansDO'
import { createNotification } from '../../../../components/Notifications/Notifications'
import { useDispatch, useSelector } from 'react-redux'
import { getSubjectsExtraCurEdu } from '../../../../actions/directoryExtraCurEducation'
import { getParallels } from '../../../../actions/common'
import { getSubjectsExtraEdu } from '../../../../actions/directoryExtraEducation'
import { api } from '../../../../App'
import { BACKEND_URL_PREFIX } from '../../../../assets/const'


const Index = ({ values, setFieldValue, handleChange, image, setImage }) => {

    const dispatch = useDispatch()
    const { files } = useSelector(state => state.studyPlansDO)
    console.log('files::: ', files);


    const [file, setFile] = useState(null);
    console.log('file::: ', file);

    useEffect(() => {
        values?.xp_key && dispatch(getStudyPlanDOFiles(values.xp_key))
    }, [values])


    useEffect(() => {
        if (!files) setImage(null);
        if (!files) return;
        fetchPhoto(files[0]?.file_name, values?.xp_key)
    }, [files])

    const handleAddFile = () => {
        if (file) {
            dispatch(createStudyPlanDOFile(file, values.xp_key));
        } else {
            createNotification('error', 'Выберите файл');
        }
        setFile('');
    }

    const handlePick = () => {
        document.getElementById('filePicker').click()
    }

    const handleChangeFile = (e) => {
        setFile(Object.values(e.target.files)[0])
        setImage(Object.values(e.target.files)[0])
    }

    const fetchPhoto = async (file_name, id) => { // file_name ИЗ-ЗА МАССИВА С ФОТО ОТ СЕРВЕРА
        const { data } = id && await api.get(`/xp_study_plans/${id}/${file_name}`, { responseType: 'blob' })
        console.log('data::: ', data);
        // const url = URL.createObjectURL(data)
        // dispatch({ type: GET_NUTRITION_PERIOD_FILE, payload: url })
        setImage(data)
    }

    useEffect(() => {
        file && handleAddFile()
    }, [file])

    useEffect(() => {
        // dispatch(getSubjectsExtraCurEdu({ status: "Идет" })) /// TODO что за запрос... Астрономия и тд
        dispatch(getSubjectsExtraEdu({ status: "Идет" })) // предметы ДО
    }, [])

    return (
        <div className={styles.tabWrapper}>
            <div className={styles.inputsRow}>
                <Input
                    error={values.name === '' ? 'Обязательное поле' : ''}
                    label='Название'
                    name='name'
                    value={values.name}
                    maxLength={50}
                    onChange={handleChange} />
            </div>

            <div className={styles.inputsColumn}>
                <Input
                    // error={values.edupart === '' ? 'Обязательное поле' : ''}
                    label='Ступень образования'
                    name='description'
                    maxLength={25}
                    value={values.description}
                    onChange={handleChange} />

            </div>
            <br />
            <div className={styles.buttons_wrap}>
                <div className={styles.buttons}>
                    <>
                        <Button onClick={handlePick}>{image ? 'Изменить фото' : 'Загрузить фото'}</Button>
                        <input
                            className={styles.hidden}
                            type='file'
                            onChange={(e) => handleChangeFile(e)}
                            accept="image/*"
                            id='filePicker'
                        />
                    </>

                </div>
            </div>
            <div>
                {!!image &&
                    <img
                        src={URL.createObjectURL(image)}
                        alt="0_o"
                        height={350}
                        width={450}
                    />
                }
            </div>

        </div>

    )
}

export default Index