import React, { useState } from 'react'
import Modal from 'react-responsive-modal'
import { useDispatch } from 'react-redux'
import styles from './styles.module.css'
import { useFormik } from 'formik'
import { Checkbox, Input, Select } from '../../components/ModalInputs'
import * as Yup from 'yup'
import ModalFooter from '../../components/ModalFooter/ModalFooter'
import { createManners, updateManners } from '../../actions/directoryBasicEducation'

const validationSchema = Yup.object({
  name: Yup.string().required('обязательное поле').max(30, 'Должно быть не более 30 символов'),

})

export default function ModalComponent({ data, closeModal, height, addMode }) {

  const dispatch = useDispatch()

  const handleCreate = (values) => {
    dispatch(createManners(values))
    closeModal()
  }

  const handleUpdate = (values) => {
    dirty && dispatch(updateManners(values))
    closeModal()
  }

  const {
    values,
    errors,
    touched,
    dirty, // проверяет есть ли изменения
    submitCount,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: data,
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: addMode ? handleCreate : handleUpdate
  })

  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]


  return (
    <Modal
      open={!!data}
      onClose={() => false}
      showCloseIcon={false}
      height={height}
      classNames={{
        // overlay: styles.overlay,
        modal: styles.custom
      }}
    >
      <>
        <div className={styles.modalLocal} >
          <div className={styles.tabWrapper} >

            <div className={styles.inputsRow}>

              <Input
                label='Дата'
                type='date'
                name='visitdate'
              // value={values.visitdate}
              // value={values.visitdate}
              // onChange={handleChange}
              />
              <Select
                label='Класс'
                name='visitorder'
              // value={values?.visitorder}
              // onChange={handleChange}
              // options={typeOfAppeal}
              >
                <option value=''>Выбрать</option>
                {/* {typeOfAppeal?.map(renderOption)} */}
              </Select>
            </div>
            <div className={styles.inputsRow}>
              <Input
                label='Замечания'
                name='name'
                value={values?.name}
                error={error('name')}
                onChange={handleChange}
                style={{ width: '600px' }}
              />

            </div>
            <div className={styles.inputsRow}>

              <Select
                label='Предмет'
                name='visitorder'
              // value={values?.visitorder}
              // onChange={handleChange}
              // options={typeOfAppeal}
              >
                <option value=''>Выбрать</option>
                {/* {typeOfAppeal?.map(renderOption)} */}
              </Select>
              <Select
                label='Проверяющий'
                name='visitorder'
              // value={values?.visitorder}
              // onChange={handleChange}
              // options={typeOfAppeal}
              >
                <option value=''>Выбрать</option>
                {/* {typeOfAppeal?.map(renderOption)} */}
              </Select>
            </div>

            <div className={styles.inputsRow}>
              <Checkbox label="Выполнено" />
              <Input
                label='Дата'
                type='date'
                name='visitdate'
              // value={values.visitdate}
              // value={values.visitdate}
              // onChange={handleChange}
              />
            </div>
            <div className={styles.inputsRow}>

              <Select
                label='Выполняющий'
                name='visitorder'
              // value={values?.visitorder}
              // onChange={handleChange}
              // options={typeOfAppeal}
              >
                <option value=''>Выбрать</option>
                {/* {typeOfAppeal?.map(renderOption)} */}
              </Select>

            </div>
          </div>
        </div>

        <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
      </>
    </Modal>

  )
}
