import { BACKEND_URL_PREFIX } from "../assets/const";
import { checkAndUpdateToken } from "./auth";
import { GET_CLASS_PARALLEL_LIST, GET_DAILY_ROUTINE_LIST, GET_DAYS_LIST } from "./types";
import { buildDefaultHeaders } from "./helper";
import { createNotification } from "../components/Notifications/Notifications";

export const getClassParallelList = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_periods`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      dispatch({ type: GET_CLASS_PARALLEL_LIST, payload: data });
    }
  }
};

export const createCopyDailyRoutine = (xp_key) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_periods_copy/${xp_key}`, {
      method: "POST",
      // body: JSON.stringify([data]),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getClassParallelList());
    } else createNotification('error', "Не удалось создать запись");
  }
};

export const deleteDailyRoutine = (xp_key) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    // вначале удалить параллели, а затем сам распорядок
    const responseParallels = await fetch(`${BACKEND_URL_PREFIX}/xp_periods/parallels/${xp_key}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_periods/${xp_key}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    const result = await response.json();
    if (response.ok) {
      await dispatch(getClassParallelList());
      createNotification('success', 'Успешно');
    } else createNotification('error', result.error);
  }
};

//ДНИ ДЛЯ 1-Й ВКЛАДКИ В МОДАЛКЕ НА ВЫБРАННЫЙ РАСПОРЯДОК

// export const getDays = (xp_key) => async (dispatch, getState) => {
//   await dispatch(checkAndUpdateToken());
//   if (getState().auth.token) {
//     const response = await fetch(`${BACKEND_URL_PREFIX}/xp_periods_days/${xp_key}`, {
//       method: "GET",
//       ...buildDefaultHeaders(getState),
//     });
//     if (response.ok) {
//       const data = await response.json();
//       dispatch({ type: GET_DAYS_LIST, payload: data });
//     }
//   }
// };
export const createDailyRoutine = ({ xp_key, parallels, week_days: day, ...data }) => async (dispatch, getState) => {
  console.log('data::: ', data);
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_periods`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    const result = await response.json();
    console.log('result::: ', result);
    const responseDay = await fetch(`${BACKEND_URL_PREFIX}/xp_periods_days/${result.xp_key}`, {
      method: "POST",
      body: JSON.stringify({ days: day }),
      ...buildDefaultHeaders(getState),
    });
    const responseParallels = await fetch(`${BACKEND_URL_PREFIX}/xp_periods/parallels/${result.xp_key}`, {
      method: "POST",
      body: JSON.stringify(parallels),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getClassParallelList());
    } else createNotification('error', "Не удалось создать запись");
  }
};
export const updateDailyRoutine = ({ xp_key, parallels, week_days: day, ...data }) => async (dispatch, getState) => {
  console.log('data::: ', data);
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_periods/${xp_key}`, {
      method: "PUT",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    const responseDay = await fetch(`${BACKEND_URL_PREFIX}/xp_periods_days/${xp_key}`, {
      method: "PUT",
      body: JSON.stringify({ days: day }),
      ...buildDefaultHeaders(getState),
    });
    const responseParallels = await fetch(`${BACKEND_URL_PREFIX}/xp_periods/parallels/${xp_key}`, {
      method: "PUT",
      body: JSON.stringify(parallels),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getClassParallelList());
    } else createNotification('error', "Не удалось создать запись");
  }
};
////////////////ДНИ ДЛЯ 1-Й ВКЛАДКИ В МОДАЛКЕ НА ВЫБРАННЫЙ РАСПОРЯДОК



//СОСТАВ вторая вкладка
export const getDailyRoutineList = (xp_key) => async (dispatch, getState) => {
  console.log('xp_key::: ', xp_key);
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_periods_daily/${xp_key}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      dispatch({ type: GET_DAILY_ROUTINE_LIST, payload: data });
    }
  }
};

//СОСТАВ вторая вкладка в МОДАЛке предусмотрена возможность сохранять много обьектов
export const createDailyRoutineConsists = ({ xp_key, ...data }) => async (dispatch, getState) => {

  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_periods_daily/${xp_key}`, {
      method: "POST",
      body: JSON.stringify([data]),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getDailyRoutineList(xp_key));
    } else createNotification('error', "Не удалось создать запись");
  }
};


export const updateDailyRoutineConsists = ({ xp_key, xp_period, ...data }) => async (dispatch, getState) => {
  // xp_key == выбранное мероприятие внутри СОСТАВА
  // xp_period == выбранный распорядок верх
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_periods_daily/${xp_key}`, {
      // const response = await fetch(`${BACKEND_URL_PREFIX}/xp_periods_daily/${daily_key}`, {
      method: "PUT",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getDailyRoutineList(xp_period));
      // await dispatch(getDailyRoutineList(xp_key));
    } else createNotification('error', "Не удалось создать запись");
  }
};

export const deleteDailyRoutineConsists = ({ xp_key, xp_period, ...data }) => async (dispatch, getState) => {
  // xp_key == выбранное мероприятие внутри СОСТАВА
  // xp_period == выбранный распорядок верх
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_periods_daily/${xp_key}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getDailyRoutineList(xp_period));
      createNotification('success', 'Успешно');
    } else createNotification('error', "Не удалось создать запись");
  }
};
//СОСТАВ вторая вкладка в МОДАЛке предусмотрена возможность сохранять много обьектов

