import React, { useEffect, useState } from 'react'
import styles from './index.module.css'
import { Input } from '../../../components/ModalInputs'
import Table from '../../../components/Table'
import Button from '../../../components/Button'
import { IoIosAdd, IoIosArrowRoundUp, IoIosArrowUp } from 'react-icons/io'
import Confirmation from '../../../components/Confirmation/Confirmation'
import { BiTrash } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'react-responsive-modal'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import { createAdmissionComitetMember, deleteAdmissionComitet, deleteAdmissionComitetMember } from '../../../actions/admissions'



const Index = ({ data, values, setFieldValue, handleChange, addMode }) => {
    console.log(' data, values::: ', data, values);

    const dispatch = useDispatch()

    // const { personnelList } = useSelector((state) => state.personnel)
    const [confirmation, setConfirmation] = useState(false);
    const [openPersonal, setOpenPersonal] = useState(false);
    const [selectedPerson, setSelectedPerson] = useState([]);
    const [teachersList, setTeachersList] = useState([]);
    console.log('selectedPerson::: ', selectedPerson);

    const { teachers } = useSelector(state => state.common)


    const acceptConfirmation = () => {
        dispatch(deleteAdmissionComitetMember({ mid: confirmation.id, id: data.id }));
        setConfirmation(false);
    };

    const rejectConfirmation = () => {
        setConfirmation(false);
    };

    const openModalPersonal = () => {
        // из teachers убрал повторы
        const withoutDuplicat = teachers?.teachers &&
            Array.from(new Set(teachers?.teachers.map(item => item.mid)))
                .map(mid => teachers?.teachers.find(item => item.mid === mid));
        //уникальные учителя с учетом присут-х в комиссии
        const uniqueTeachers = withoutDuplicat.filter(el =>
            !values.members.find(tea =>
                tea.mid === el.mid) && el)

        setTeachersList(uniqueTeachers)
        setOpenPersonal(true)
    }
    const closeModalPersonal = () => {
        setOpenPersonal(false)
    }

    const handleSubmitDisciplines = () => {
        dispatch(createAdmissionComitetMember({ id: values.id, mid: selectedPerson.mid }))
        closeModalPersonal()
    }


    return (
        <>
            <div>
                {confirmation && (
                    <Confirmation
                        top="30px"
                        left="40px"
                        title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
                        acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
                        rejectConfirmation={rejectConfirmation}
                    />
                )}

                {/* МОДАЛКА С ВЫБОРОМ НОВОГО ЧЛЕНА КОМИССИИ */}
                {openPersonal &&
                    < Modal
                        open={openPersonal}
                        onClose={() => false}
                        showCloseIcon={false}
                        classNames={{
                            // overlay: styles.overlay,
                            modal: styles.custom
                        }}
                        center
                    >
                        <div className={styles.modalLocal} >
                            <div className={styles.tabWrapperModal} >
                                <Table className={styles.tablePersonal}>
                                    <thead>
                                        <tr>
                                            <th>ФИО</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {teachers && teachersList.map(el =>
                                            <tr key={el.mid} className={selectedPerson.mid == el.mid ? styles.person : ''}>
                                                <td onClick={() => setSelectedPerson(el)} style={{ width: '550px' }} >{el.calc_fio}</td>
                                                {/* <td><i className={styles.trash} onClick={() => setConfirmation({ id: el.mid, name: el.fio })}><BiTrash /></i></td> */}
                                            </tr>)}
                                    </tbody>
                                </Table>

                            </div>
                        </div>
                        <ModalFooter handleSubmit={handleSubmitDisciplines} closeModal={closeModalPersonal} />
                    </Modal>
                }
                {/* МОДАЛКА С ВЫБОРОМ НОВОГО ЧЛЕНА КОМИССИИ */}

                <Table className={styles.tables}>
                    <thead>
                        <tr>
                            <th>ФИО</th>
                        </tr>
                    </thead>
                    <tbody>
                        {values?.members[0]?.mid !== null && values?.members?.map(el =>
                            <tr key={el.mid} >
                                <td style={{ width: '550px' }} >{el.fio}</td>
                                <td><i className={styles.trash} onClick={() => setConfirmation({ id: el.mid, name: el.fio })}><BiTrash /></i></td>
                            </tr>)}
                    </tbody>
                </Table>
                <div className={styles.inputsRow}>
                    <Button
                        style={{ padding: '.5rem 1.5rem', margin: '12px' }}
                        onClick={openModalPersonal}
                    >
                        <IoIosAdd size={30} />
                        Добавить
                    </Button>

                </div>
            </div >

        </>
    )
}

export default Index