import React from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { add, remove, change, print, copy } from '../../assets/svg/footers'
import { useState, useEffect } from 'react'
import Modal from './Modal'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getGroups, setPage } from '../../actions/groups'
import Loader from '../../components/Loader'
import Table from '../../components/Table'
import { deleteGroup, getLanguages, getSchoolYears, getSchools, getShifts, getSpecializations, getStudyForms, getStudyModes, getStudyProfiles, getStudySteps, getSubjects, getTeachersList } from '../../actions/common'
import { BiTrash } from 'react-icons/bi'
import Confirmation from '../../components/Confirmation/Confirmation'
import { Pagination } from '../../components/Pagination'
import { getStagesStudy } from '../../actions/stagesStudy'


export default function Classing() {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { groupsEnd, loading, pageCount, count, pageNumber } = useSelector(state => state.groups)

  const [classId, setClassId] = useState(null);
  const [confirmation, setConfirmation] = useState(false);


  useEffect(() => {
    dispatch(getTeachersList())
    dispatch(getSchoolYears())
    dispatch(getSpecializations())
    dispatch(getLanguages())
    dispatch(getStudyProfiles())
    dispatch(getStudyModes())
    dispatch(getStudyForms())
    dispatch(getShifts())
    // !stageStudyList && dispatch(getStagesStudy())
    dispatch(getGroups())
  }, [pageNumber])


  const acceptConfirmation = () => {
    closeModal();
    dispatch(deleteGroup(confirmation.id));
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  const openModal = (classId) => () => setClassId(classId)

  const closeModal = () => {
    setClassId(null)

  }

  const goHome = () => navigate('/')

  const pageClick = ({ selected }) => dispatch(setPage(selected))

  const renderRow = (data) => {
    const { calc_classroom_teacher, calc_graduation_year, calc_listeners, calc_study_period, calc_training_profile, gid, name, year } = data
    return (
      <tr>
        <td onClick={openModal(gid)}>{name}</td>
        <td onClick={openModal(gid)}>{year}</td>
        <td onClick={openModal(gid)}>{calc_listeners}</td>
        <td onClick={openModal(gid)}>{calc_graduation_year}</td>
        <td onClick={openModal(gid)}>{calc_training_profile}</td>
        <td onClick={openModal(gid)}>{calc_study_period}</td>
        <td onClick={openModal(gid)}>{calc_classroom_teacher}</td>
        <td><i className={styles.trash} onClick={() => setConfirmation({ id: gid, name: name })}><BiTrash /></i></td>
      </tr>
    )
  }

  return (
    <div className={styles.wrapper}>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title="Удалить данную запись?"
          confirmation={confirmation?.name}
          acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
          rejectConfirmation={rejectConfirmation}
        />
      )}

      <Modal
        classId={classId}
        closeModal={closeModal}
        classing
        infoCreateChange={groupsEnd?.find(group => group.gid === classId)}
      />

      <h1>Распределение по классам</h1>

      {loading
        ? <Loader />
        : groupsEnd?.length &&
        <Pagination
          pageCount={pageCount}
          pageNumber={pageNumber}
          onPageChange={pageClick}
          loading={loading}
        >
          <Table style={{ marginTop: '2rem', backgroundColor: 'white', maxHeight: '60vh' }}>
            <thead>
              <tr>
                <th>Название</th>
                <th>Год</th>
                <th>Количество слушателей</th>
                <th>Год выпуска</th>
                <th>Профиль обучения</th>
                <th>Тип уч. периода</th>
                <th>Классный руководитель</th>
                <th>Удалить</th>
              </tr>
            </thead>
            <tbody>
              {groupsEnd?.map(renderRow)}
            </tbody>
          </Table>
        </Pagination>
      }
      <footer className={styles.footer}>
        <div>
          <Button className={styles.footerButton} onClick={openModal('add')} mode='bordered'>{add()}Добавить</Button>
          <Button className={styles.footerButton} style={{ padding: '.5rem 0' }}>{copy()}Копировать</Button>
        </div>
        <Button mode='white' onClick={goHome}>Закрыть</Button>
      </footer>
    </div>
  )
}
