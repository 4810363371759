import * as types from "../actions/types";



const initialState = {
  testingScheduleList: [],
  loading: false
};


export default function testing_schedule(state = initialState, action) {
  switch (action.type) {
    case types.SET_TESTING_SCHEDULE_LIST:
      return {
        ...state,
        testingScheduleList: action.payload,
      };
    // case types.SET_TERMS_PAGE:
    //   return {
    //     ...state,
    //     pageNumber: action.payload
    //   };
    // case types.SET_TERMS_LIST:
    //   return {
    //     ...state,
    //     list: action.payload,
    //   };
    // // case types.SET_TERMS_LIST:
    // //   return {
    // //     ...state,
    // //     termForDelete: action.payload,
    // //   };
    // case types.SET_CURRENT_TERM:
    //   return {
    //     ...state,
    //     currentTerm: action.payload
    //   };
    case types.SET_TERMS_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}