import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Select } from '../../../components/ModalInputs'
import styles from '../styles.module.scss'
import Table from '../../../components/Table'
import { getScheduleSettingsStudyPeriod, getScheduleSettingsTeachersSubjects, getWorkloadTeachersClass } from '../../../actions/scheduleSettings'
import Loader from '../../../components/Loader'


export default function WorkloadTeachersClass() {

  const dispatch = useDispatch()
  const { scheduleSettingsTeachersSubList, loading, dayOfWeek, studyPeriod, workloadList } = useSelector(state => state.schedule_settings)
  const { schoolYears } = useSelector(state => state.common)
  const [selected, setSelected] = useState([]);
  const [panelOpen, setPanelOpen] = useState({ gid: null, cid: null });
  const [currPeriod, setCurrPeriod] = useState({ trmid: 0, year: 0 })
  const [currTeacher, setCurrTeacher] = useState('')



  useEffect(() => {
    dispatch(getScheduleSettingsStudyPeriod())
    dispatch(getScheduleSettingsTeachersSubjects())
  }, [])


  useEffect(() => {
    (currTeacher && currPeriod) &&
      dispatch(getWorkloadTeachersClass({ currPeriod, currTeacher })) // 3 params
  }, [currPeriod, currTeacher])

  const selectedCell = (data) => () => {
    const res = { "rid": data[0], "cid": data[1] }
    // dispatch(updateTeachersLesson(res))
  }

  const renderLi = (data) => {
    if (data) return (
      <tr key={data[0]}>
        <td className={styles.fio}>{data[1]}</td>
        {data[3]?.map((el, i) =>
          <td
            key={el[0]}
            onClick={selectedCell([dayOfWeek?.[i].rid, data[0]])}
            style={{ backgroundColor: el[1] === 1 && '#3689FF' }}

          ></td>)}
      </tr >
    )
  }



  return (
    loading ? <Loader /> :
      <div className={styles.inputsWrapper} style={{ flexDirection: 'column' }}>
        <div className={styles.selectWrapper}>
          <Select
            label='Учебный период'
            value={[currPeriod.trmid, currPeriod.year]}
            onChange={({ target: { value } }) => {
              const forReq = value.split(',')
              setCurrPeriod({ trmid: forReq[0], year: forReq[1] })
            }}
            name='trmid'
          >
            {studyPeriod && studyPeriod.map(({ period, trmid, year }) => <option key={trmid} value={[trmid, year]} >{period}</option>)}
          </Select>
          <Select
            label='Преподаватель'
            value={currTeacher}
            onChange={({ target: { name, value } }) => setCurrTeacher(value)}
            name='mid'
          >
            {scheduleSettingsTeachersSubList && scheduleSettingsTeachersSubList?.map((el) => <option key={el[0]} value={el[0]}>{el[1]}</option>)}
          </Select>
        </div>
        <Table style={{ marginTop: '2rem', backgroundColor: 'white', position: 'relative', height: '70vh' }}>
          {workloadList?.length ?
            <>
              <thead className={styles.headSchSet}>
                <tr>
                  <th className={styles.fio2}></th>
                  {dayOfWeek?.map(el => <th>{el.name_day}</th>)}
                </tr>
              </thead>
              <tbody>

                workloadList.map(renderLi)

              </tbody>
            </>
            : currTeacher && <h1>По выбранным параметрам нет данных</h1>}
        </Table>

      </div>
  )
}
