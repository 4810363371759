import { api } from "../App";
import { createNotification } from "../components/Notifications/Notifications";
import { SET_TESTING_SCHEDULE_LIST } from "./types";




export const getTestingScheduleList = (xp_key, payload) => async (dispatch, getState) => {
  try {
    const urlParams = new URLSearchParams(payload).toString();
    const data = await api.get(`/test_schedule/${xp_key}?${urlParams}`);
    dispatch({ type: SET_TESTING_SCHEDULE_LIST, payload: data.data });
  } catch (ERR) {
    createNotification('error', ERR.response.data.error)
  } finally {
  }
}

// export const createTypicalComment = (data) => async (dispatch, getState) => {
//   let success
//   try {
//     await api.post('/typical_comment', data);
//     await dispatch(getTestingScheduleList())
//     success = true
//   } catch (ERR) {
//   } finally {
//     return success
//   }
// }

// export const updateTypicalComment = ({ commentid, ...data }) => async (dispatch, getState) => {
//   let success
//   try {
//     await api.put(`/typical_comment/${commentid}`, data);
//     await dispatch(getTestingScheduleList())
//     success = true
//   } catch (ERR) {
//   } finally {
//     return success
//   }
// }

export const deleteTestingSchedule = (xp_key, exam_date_from, id) => async (dispatch, getState) => {
  console.log('id::: ', id);
  try {
    // await api.delete(`/applicant_exam_schedule/${id}`);
    await dispatch(getTestingScheduleList(xp_key, { exam_date_from: exam_date_from }))
  } catch (ERR) {
    createNotification('error', ERR.response.data.error)
  } finally {
  }
}