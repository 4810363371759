import React, { useContext, useEffect, useState } from 'react'
import styles from '../styles.module.css'
import ClassbookTable from '../../../components/ClassbookTable'
import { useDispatch, useSelector } from 'react-redux'
import { getSchoolYears } from '../../../actions/common'
import { getLesson, getStudyPeriod, getScClass, getClassbook, getGradeValue, getLessonChart, getClassTopic } from '../../../actions/classbook'
import Loader from '../../../components/Loader'
import { getCurrentPeriodUtils, getCurrentYearUtils } from '../../../utils/classBook'
import TeacherInfo from '../TeacherInfo/TeacherInfo'
import ClassbookChart from '../ClassbookChart/ClassbookChart'
import { ClassbookContext } from '../../../utils/context'
import Table from '../../../components/Table'
import { getStudents } from '../../../actions/students/list'


export default function GeneralInfo() {

    const dispatch = useDispatch()
    const { formData } = useContext(ClassbookContext)
    const { students_list, expelled_students } = useSelector(state => state.students)

    useEffect(() => {
        const { year, period } = formData
        dispatch(getStudents())
    }, [])

    const handleSearch = () => {
        dispatch(getClassbook(formData));
        dispatch(getLessonChart(formData));
    }

    const handleClassTopic = (year, sheid) => {
        dispatch(getClassTopic(year, sheid));
    }

    const renderLi = (data) => {
        const { fio, document_issue_date, sex, snils, login } = data
        return (
            <tr>
                <td>{fio}</td>
                <td>{document_issue_date}</td>
                <td>{sex}</td>
                <td>{snils}</td>
                <td>{login}</td>
            </tr>
        )
    }
    return (
        <Table className={styles.tables}>
            <thead>
                <tr>
                    <th>Фио</th>
                    <th>Дата рождения</th>
                    <th>Пол</th>
                    <th>Удостоверение</th>
                    <th>Email</th>

                    <th></th>
                </tr>
            </thead>
            <tbody>
                {students_list?.map(renderLi)}
            </tbody>
        </Table>

        // loading
        //     ? <Loader />
        //     : !classbook
        //         ? <div className={styles.noData}>Нет данных</div>
        //         : <>
        //             {class_teacher_fio && teacher_fio &&
        //                 <TeacherInfo
        //                     class_teacher_fio={class_teacher_fio}
        //                     teacher_fio={teacher_fio} />}

        //             <div className={styles.wrapperClassbook}>
        //                 <ClassbookTable
        //                     classbook={classbook}
        //                     formData={formData}
        //                     lesson_chart={lesson_chart}
        //                     class_topic={class_topic}
        //                     handleClassTopic={handleClassTopic} />

        //                 <>
        //                     {lesson_chart?.length > 0 ? <ClassbookChart handleClassTopic={handleClassTopic} data={lesson_chart} formData={formData} />
        //                         : <div className={styles.noData}>Нет данных</div>}
        //                 </>
        //             </div>
        //         </>



    )
}
