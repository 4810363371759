import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Checkbox, Input } from '../../../components/ModalInputs'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import { createReportGroup, getReportGroup, updateReportGroup } from '../../../actions/reportGroups'
import Li from '../../../components/Li'
import Loader from '../../../components/Loader'


export default function Main ({closeModal, data, addMode}) {
  
  const dispatch = useDispatch()  

  const {list: reports} = useSelector(state => state.reports)
  const {data: details, loading} = useSelector(state => state.report_groups)

  const [selectedIds, setSelectedIds] = useState([])

  const uniqueReports = [...new Map(reports.map(item => [item.xp_rpt_id, item])).values()];

  useEffect(() => {
    if (addMode) return;
    dispatch(getReportGroup(data.id))
  }, [data])
  
  useEffect(() => {
    if (addMode || !details) return setSelectedIds([]);
    setSelectedIds(details.filter(d => d.used).map(d => d.xp_rpt_id));
  }, [details])
  

  const handleCreateReportGroup = async (values) => {
    await dispatch(createReportGroup(values, selectedIds))
    closeModal()
  }

  const handleUpdateReportGroup = async (values) => {
    const {id, ...payload} = values
    await dispatch(updateReportGroup(id, payload, selectedIds))
    closeModal()
  }

  const {
    values, 
    errors,
    touched,
    submitCount,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: addMode ? {name: ''} : data,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: addMode ? handleCreateReportGroup : handleUpdateReportGroup
  })

  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

  const onReportClick = (reportId) => () => {
    const newIds = selectedIds.includes(reportId) ? selectedIds.filter(id => id !== reportId) : [...selectedIds, reportId]
    setSelectedIds(newIds)
  }

  const renderLi = ({name, xp_rpt_id}) => {
    const checked = selectedIds.includes(xp_rpt_id)
    return (
      <Li onClick={onReportClick(xp_rpt_id)}>
        <span>{name}</span>
        <Checkbox checked={checked} />
      </Li>
    )
  }

  return (
    <>
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          <div className={styles.inputsRow}>
            <Input
              label='Название'
              name='name'
              value={values.name}
              error={error('name')}
              onChange={handleChange}
              style={{width: '300px'}}
            />
          </div>
          {loading && <Loader />}
          {!loading &&
            <div>
              <p>Отчеты</p>
              <ul>{uniqueReports?.map(renderLi)}</ul>
            </div>
          }
        </div>
      </div>
      <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
    </>
  )
}
