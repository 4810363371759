import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getExamSchedule, createExamSchedule, updateExamSchedule, deleteExamSchedule } from '../../actions/exams/examSchedule'
import Li from '../../components/Li'
import Button from '../../components/Button'
import Modal from 'react-responsive-modal'
import { useFormik } from 'formik'
import { Input, Select, Textarea } from '../../components/ModalInputs'
import Confirmation from '../../components/Confirmation/Confirmation'
import { BiTrash } from 'react-icons/bi'
import * as Yup from 'yup'
import { getCourses, getExamTypes, getSchoolYears } from '../../actions/common'
import Table from '../../components/Table'
import moment from 'moment'
import { getDateFormatted } from '../../utils/smallHelpers'


const validationSchema = Yup.object().shape({
    edate: Yup.date().required('Это поле обязательно для заполнения'),
    cid: Yup.string().required('Это поле обязательно для заполнения'),
});

const Schedule = ({ogeMode}) => {
  
  const parallel = ogeMode ? 9 : 11

  const dispatch = useDispatch()
  const { schedule, loading } = useSelector(state => state.exams)
  const { courses, examTypes, schoolYears } = useSelector(state => state.common)

  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(null)
  const [year, setYear] = useState(null)
  const [params, setParams] = useState(null)


  useEffect(() => {
    dispatch(getCourses())
    dispatch(getExamTypes())
    dispatch(getSchoolYears())
  }, [])
  
  useEffect(() => {
    if (!schoolYears.length) return;
    const { xp_key } = schoolYears[0]
    setYear(xp_key)
  }, [schoolYears])
  
  useEffect(() => {
    if (!year) return;
    const { begdate, enddate } = schoolYears.find(({xp_key}) => xp_key == year)
    const params = {date_from: begdate, date_to: enddate, parallel}
    setParams(params)
    dispatch(getExamSchedule(params))
  }, [year])
  
  const deleteItem = (id) => (e) => {
    e.stopPropagation()
    setConfirmation(id)
  }

  const openModal = (data) => () => setSelectedItem(data || {})

  const closeModal = () => setSelectedItem(null)

  const renderLi = (item) => {
    const name = courses?.find(({cid}) => cid === item.cid)?.title
    const date = moment(item.edate).format('DD.MM.YYYY')
    return (
      <tr onClick={openModal(item)}>
        <td>{date}</td>
        <td>{name}</td>
        <td></td>
        <td>
          <BiTrash size={24} color='red' title='Удалить' onClick={deleteItem(item.eid)}/>
        </td>
      </tr>
    )
  }
  
  const acceptConfirmation = () => {
    dispatch(deleteExamSchedule(confirmation, params))
    setConfirmation(null)
  }
  
  const rejectConfirmation = () => {
    setConfirmation(null)
  }

  return (
    <div className={styles.wrapper}>
      
      <h1>Формирование расписания {ogeMode ? 'ОГЭ' : 'ЕГЭ'}</h1>
      
      <Select
        value={year}
        onChange={(e) => setYear(e.target.value)}
      >
        {schoolYears?.map(({xp_key, name}) => <option key={xp_key} value={xp_key}>{name}</option>)}
      </Select>
      <Table>
        <thead>
          <tr>
            <th>Дата</th>
            <th>Предмет</th>
            <th>Количество</th>
          </tr>
        </thead>
        <tbody>
          {schedule?.map(renderLi)}
        </tbody>
      </Table>
      <Button onClick={openModal()}>Добавить</Button>
      <ModalComponent
        open={!!selectedItem}
        closeModal={closeModal}
        data={selectedItem}
        addMode={!selectedItem?.eid}
        courses={courses}
        examTypes={examTypes}
        params={params}
      />
      {confirmation && (
        <Confirmation
            top="30px"
            left="40px"
            title="Уверены что хотите удалить запись?"
            acceptConfirmation={acceptConfirmation}
            rejectConfirmation={rejectConfirmation}
        />
      )}
    </div>
  )
}


const ModalComponent = ({open, closeModal, data, addMode, courses, examTypes, params}) => {
  
  const dispatch = useDispatch()

  useEffect(() => {
    if (!open) return;
    const edate = getDateFormatted(data?.edate)
    setValues(addMode ? {} : {...data, edate})
  }, [open])
  

  const createItem = async (values) => {
    const success = await dispatch(createExamSchedule(values, params))
    success && closeModal()
  }
  
  const updateItem = async ({eid, ...values}) => {
    const success = await dispatch(updateExamSchedule(eid, values, params))
    success && closeModal()
  }

  const {
    values,
    touched,
    errors,
    submitCount,
    handleBlur,
    handleSubmit,
    handleChange,
    setValues,
  } = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validationSchema,
    onSubmit: addMode ? createItem : updateItem
  })


  const renderOption = ({cid, title, type, ex_id}) => <option key={cid || ex_id} value={cid || ex_id}>{title || type}</option>

  const error = fieldName => (touched[fieldName] || submitCount) && errors[fieldName]

  return (
    <Modal
      open={open}
      onClose={closeModal}
      classNames={{modal: styles.modal}}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      showCloseIcon={false}
      animationDuration={2}
      center
    >
      {console.log('values.edate::: ', values.edate)}
      <div className={styles.modalMain}>
        <h2>{addMode ? 'Добавление записи' : 'Редактирование записи'}</h2>
        <Input
          label='Дата'
          name='edate'
          value={values.edate}
          onChange={handleChange}
          onBlur={handleBlur}
          error={error('edate')}
          type='date'
          />
        <Input
          label='ППЭ'
          name='ppe'
          value={values.ppe}
          onChange={handleChange}
          onBlur={handleBlur}
          />
        <Select
          label='Предмет'
          name='cid'
          value={values.cid}
          onChange={handleChange}
          onBlur={handleBlur}
          error={error('cid')}
        >
          <option value=''>Не выбран</option>
          {courses?.map(renderOption)}
        </Select>
        <Select
          label='Тип экзамена'
          name='type_id'
          value={values.type_id}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          <option value=''>Не выбран</option>
          {examTypes?.map(renderOption)}
        </Select>
      </div>
      <div className={styles.modalFooter}>
          <div>
              <div></div>
              <div></div>
          </div>
          <Button mode='white' type="submit" onClick={handleSubmit}>Сохранить</Button>
          <Button mode='white' onClick={closeModal}>Закрыть</Button>
      </div>
    </Modal>
  )
}


export default Schedule