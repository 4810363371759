import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { IoIosAdd } from 'react-icons/io'
import { thrash } from '../../assets/svg/other'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Modal from './Modal/Modal'
import Table from '../../components/Table'
import { getFormOfEducation, deleteFormOfEducation } from '../../actions/directory'
import { BiTrash } from 'react-icons/bi'
import Confirmation from '../../components/Confirmation/Confirmation'
import { getCanteenStaffList, deleteCanteenStaff } from '../../actions/directoryCanteen'


export default function PeriodNutrition() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { canteenStaffList, canteenStaff, image, } = useSelector(state => state.directoryCanteen)


  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(false);

  useEffect(() => {
    dispatch(getCanteenStaffList())
  }, [])


  const openModal = (data) => () => {
    console.log('data::: ', data);

    setSelectedItem(data || {})
  }

  const closeModal = () => setSelectedItem(null)

  const goHome = () => navigate('/')


  const acceptConfirmation = () => {
    closeModal();
    dispatch(deleteCanteenStaff(confirmation.id));
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  const renderLi = (data) => {
    const { name, id, post } = data
    return (
      <tr >
        <td onClick={openModal(data)}>{name}</td>
        <td onClick={openModal(data)}>{post}</td>
        <td><i className={styles.trash} onClick={() => setConfirmation({ id, name })}><BiTrash /></i></td>
      </tr>
    )
  }

  return (
    <div className={styles.wrapper} style={{ width: '700px', margin: '0 auto' }}>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
          acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      {selectedItem &&
        <Modal
          closeModal={closeModal}
          data={selectedItem}
        />
      }
      <h1>Сотрудники столовой</h1>

      <Table className={styles.tables}>
        <thead>
          <tr>
            <th>Имя</th>
            <th>Должность</th>
            <th></th>
          </tr>
        </thead>
        <tbody>

          {canteenStaffList?.map(renderLi)}

        </tbody>
      </Table>

      <div style={{ display: 'flex', flexDirection: "row" }}>
        <Button
          style={{ padding: '.5rem 1.5rem', margin: '12px' }}
          onClick={openModal()}
        >
          <IoIosAdd size={30} />
          Добавить
        </Button>
      </div>
    </div>
  )
}
