import { BACKEND_URL_PREFIX } from "../assets/const";
import { checkAndUpdateToken } from "./auth";
import { SET_DIRECTORY_OBJECTS_UMB, SET_DIRECTORY_OBJECTS_UMB_LOAD, SET_TFO_BASE_OBJ_LIST, SET_TFO_TYPES_LIST, SET_EQUIPMENTS_LIST, SET_EQUIPMENTS_BY_ROOM_LIST, SET_DIRECTORY_MODAL_LIST } from "./types";
import { buildDefaultHeaders } from "./helper";
import { createNotification } from "../components/Notifications/Notifications";

export const getDirectoryObjectsUMBList = (data) => async (dispatch, getState) => {
  console.log('data::: ', data);
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const urlParams = new URLSearchParams(data).toString();
    dispatch({ type: SET_DIRECTORY_OBJECTS_UMB_LOAD, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/tfo/object_umb?${urlParams}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      dispatch({ type: SET_DIRECTORY_OBJECTS_UMB, payload: data });
    }
  }
  dispatch({ type: SET_DIRECTORY_OBJECTS_UMB_LOAD, payload: false });
};
export const getDirectoryForModalList = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const urlParams = new URLSearchParams(data).toString();
    dispatch({ type: SET_DIRECTORY_OBJECTS_UMB_LOAD, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/tfo`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      dispatch({ type: SET_DIRECTORY_MODAL_LIST, payload: data });
    }
  }
  dispatch({ type: SET_DIRECTORY_OBJECTS_UMB_LOAD, payload: false });
};
export const getModalElement = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const urlParams = new URLSearchParams(data).toString();
    dispatch({ type: SET_DIRECTORY_OBJECTS_UMB_LOAD, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/tfo/${data.tfoid}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      dispatch({ type: SET_DIRECTORY_MODAL_LIST, payload: data });
    }
  }
  dispatch({ type: SET_DIRECTORY_OBJECTS_UMB_LOAD, payload: false });
};

export const editObjectUMB = (data) => async (dispatch, getState) => {
  try {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      dispatch({ type: SET_DIRECTORY_OBJECTS_UMB_LOAD, payload: true });
      const response = await fetch(`${BACKEND_URL_PREFIX}/tfo/${data.tfoid}`, {
        method: "PUT",
        ...buildDefaultHeaders(getState),
        body: JSON.stringify(data)
      });
      if (response.ok) {
        createNotification('success', 'Успешно');
        dispatch(getDirectoryObjectsUMBList())
        dispatch(getDirectoryForModalList())
      }
    }
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_DIRECTORY_OBJECTS_UMB_LOAD, payload: false });
  }
};

export const addObjectUMB = (data) => async (dispatch, getState) => {
  try {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      dispatch({ type: SET_DIRECTORY_OBJECTS_UMB_LOAD, payload: true });
      const response = await fetch(`${BACKEND_URL_PREFIX}/tfo`, {
        method: "POST",
        ...buildDefaultHeaders(getState),
        body: JSON.stringify(data)
      });
      if (response.ok) {
        createNotification('success', 'Успешно');
        dispatch(getDirectoryObjectsUMBList())
        dispatch(getDirectoryForModalList())
      }
    }
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_DIRECTORY_OBJECTS_UMB_LOAD, payload: false });
  }
};

export const deleteObjectUMB = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_DIRECTORY_OBJECTS_UMB_LOAD, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/tfo/${id}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      dispatch(getDirectoryObjectsUMBList());
    }
  }
  dispatch({ type: SET_DIRECTORY_OBJECTS_UMB_LOAD, payload: false });
};



export const getTFOBaseObjList = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/tfo_bases`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const data = await response.json();
      dispatch({ type: SET_TFO_BASE_OBJ_LIST, payload: data });
    }
  }
};

// export const getEquipmentsList = (id) => async (dispatch, getState) => {
//   await dispatch(checkAndUpdateToken());
//   if (getState().auth.token) {
//     const response = await fetch(`${BACKEND_URL_PREFIX}/equipments/${id}`, {
//       method: "GET",
//       ...buildDefaultHeaders(getState),
//     });
//     if (response.ok) {
//       const data = await response.json();
//       dispatch({ type: SET_EQUIPMENTS_LIST, payload: data});
//     }
//   }
// };

// export const getEquipmentsByRoom = (id) => async (dispatch, getState) => {
//   await dispatch(checkAndUpdateToken());
//   if (getState().auth.token) {
//     const response = await fetch(`${BACKEND_URL_PREFIX}/equipments/room/${id}`, {
//       method: "GET",
//       ...buildDefaultHeaders(getState),
//     });
//     if (response.ok) {
//       const data = await response.json();
//       dispatch({ type: SET_EQUIPMENTS_BY_ROOM_LIST, payload: data});
//     }
//   }
// };

// export const setEquipmentsForRoom = (data = [], id) => async (dispatch, getState) => {
//   try {
//     await dispatch(checkAndUpdateToken());
//     if (getState().auth.token) {
//       // dispatch({ type: SET_ROOMS_LOAD, payload: true });
//       const response = await fetch(`${BACKEND_URL_PREFIX}/rooms_equipments`, {
//         method: "POST",
//         ...buildDefaultHeaders(getState),
//         body: JSON.stringify(data)
//       });
//       if (response.ok) {
//         createNotification('success', 'Успешно');
//         dispatch(getEquipmentsByRoom(id));
//       } else {
//         createNotification('error', 'Ошибка')
//       }
//     }
//   } catch (ERR) {
//   } finally {
//     // dispatch({ type: SET_ROOMS_LOAD, payload: false });
//   }
// };

// export const deleteEquipment = (id, idAquip) => async (dispatch, getState) => {
//   await dispatch(checkAndUpdateToken());
//   if (getState().auth.token) {
//     const response = await fetch(`${BACKEND_URL_PREFIX}/rooms_equipment/${id}/${idAquip}`, {
//       method: "DELETE",
//       ...buildDefaultHeaders(getState),
//     });
//     if (response.ok) {
//       createNotification('success', 'Успешно');
//         dispatch(getEquipmentsByRoom(id));
//         dispatch(getEquipmentsList(id));
//     }
//   }
// };