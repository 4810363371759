import * as types from "../actions/types";



const initialState = {
  list: [],
  loading: false
};


export default function events_list(state = initialState, action) {
  switch (action.type) {

    case types.SET_EVENTS_LIST:
      return {
        ...state,
        eventsList: action.payload,
      };

    // case types.SET_EVENT_LEVELS_LOADING:
    //   return {
    //     ...state,
    //     loading: action.payload,
    //   };

    default:
      return state;
  }
}