import React from 'react'
import { Form, Table, Footer } from './Components'
import Title from '../../components/Title/index'
import styles from './styles.module.scss'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'


const SchedulingDO = () => {


  const navigate = useNavigate()

  const goHome = () => navigate('/')


  return (
    <div className={styles.wrapper}>
      <Title title="Составление расписания ДО" />
      <Form />
      <Table />
      <Footer goHome={goHome} />
    </div>
  )
}

export default SchedulingDO