import React, { useEffect, useState } from 'react'
import styles from './index.module.css'
import Title from '../../components/Title'
import Table from '../../components/Table'
import Empty from '../../components/Empty'
import { Pagination } from '../../components/Pagination'
import { useDispatch, useSelector } from 'react-redux'
import { deleteStudyPlans, getStudyPlans, createStudyPlan, createCopyStudyPlan, setPage } from '../../actions/studyPlans'
import { BiTrash } from 'react-icons/bi'
import moment from 'moment'
import Confirmation from '../../components/Confirmation/Confirmation'
import Modal from './Modal'
import Button from '../../components/Button'
import { getStudyPlanDOCoursesList, getStudyPlanDOById, getStudyPlansDO, createStudyPlanDO, editStudyPlanDO, deleteStudyPlansDO } from '../../actions/studyPlansDO'
import { useFormik } from 'formik'

const Index = () => {

  const dispatch = useDispatch()
  const { list, pageCount, pageNumber, loading } = useSelector(state => state.studyPlans)
  const { listDO, coursesListDO } = useSelector(state => state.studyPlansDO)

  const [confirmation, setConfirmation] = useState(false);
  const [modalOpen, setModalOpen] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null)

  const [select, setSelect] = useState(null);

  const [image, setImage] = useState(null);

  const pageClick = ({ selected }) => dispatch(setPage(selected))

  const handleCreate = (values) => {
    closeModal()
    dispatch(createStudyPlanDO(values))
    // dispatch(createAdmissionComitet(removeUnnecessaryKey({ ...values }, ...keysToDelete)))
    // setCreated(values)
  }

  const handleUpdate = (values) => {
    dispatch(editStudyPlanDO(values))

    // dispatch(updateAdmissionComitet((removeUnnecessaryKey({ ...values }, ...keysToDelete))))
    closeModal()
    setSelectedItem(null)
    // setCreated(null)
  }


  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: selectedItem || {},      /// TODO как придет полный запрос можно поменять сейчас одинаковые {}
    // !!selectedItem?.id ? admissionById :
    enableReinitialize: true,
    validateOnChange: true,
    // validationSchema: validationSchema,
    onSubmit: !selectedItem?.xp_key ? handleCreate : handleUpdate
  })

  const acceptConfirmation = () => { // Подтвердить удаление
    dispatch(deleteStudyPlansDO(confirmation?.xp_key));
    closeModal();
    setConfirmation(false);
  };
  const rejectConfirmation = () => { // Отменить подтверждение
    setConfirmation(false);
  };

  // запрос по выбранному плану
  useEffect(() => {
    selectedItem?.xp_key && dispatch(getStudyPlanDOById(selectedItem.xp_key))
    // data && !data.id && resetForm()
  }, [selectedItem])

  useEffect(() => {
    dispatch(getStudyPlansDO())
  }, [])

  const openModal = (data) => () => { // Открыть модалку
    setImage(null)
    setModalOpen(data)
    setSelectedItem(data || {})
    data?.xp_key && dispatch(getStudyPlanDOCoursesList(data.xp_key))
  }

  const closeModal = () => { // Закрыть модалку
    setSelectedItem(null)
  }

  const handleConfirmation = (e, data) => {
    e.stopPropagation() // Отмена действия по умолчанию (открытие модалки)
    setConfirmation(data)
  }

  const handleSelect = (data) => {
    if (select?.xp_key === data.xp_key) {
      setSelect(null)
    } else {
      setSelect(data)
    }
  }

  useEffect(() => () => dispatch(setPage(0)), []);

  return (
    <div className={styles.wrapper}>
      <Title title="Учебные планы" />

      <div className={styles.buttons}>
        {select?.xp_key && <div className={styles.copy}><Button onClick={() => dispatch(createCopyStudyPlan(select?.xp_key))}>Создать копию</Button></div>}
        <Button
          style={{ backgroundColor: "#3689FF", fontSize: "16px" }}
          onClick={openModal()}
        >
          Добавить
        </Button>
      </div>

      {listDO ? listDO.length === 0 && <Empty /> : ''}
      {/* <Pagination /// номинально стоит тут - запрос не работает с ней
        pageCount={pageCount}
        pageNumber={pageNumber}
        onPageChange={pageClick}
        loading={loading}
      > */}
      {
        listDO ? listDO.length !== 0 &&
          <Table className={styles.tables}>
            <thead>
              <tr>
                <th style={{ width: '100px' }}>№ п/п</th>
                <th>Название</th>
                <th style={{ width: '100px' }}>Удалить</th>
              </tr>
            </thead>
            <tbody>

              {
                listDO.map((data, index) => (
                  <tr
                    key={index}
                    onClick={() => handleSelect(data)}
                    onDoubleClick={openModal(data)}
                    style={{ backgroundColor: select?.xp_key === data.xp_key ? '#3689FF' : '' }}
                  >
                    <td style={{ color: select?.xp_key === data.xp_key ? 'white' : '' }}>{index + 1}</td>
                    <td style={{ color: select?.xp_key === data.xp_key ? 'white' : '' }}>{data.name}</td>
                    <td onClick={(e) => handleConfirmation(e, data)}>
                      <i className={styles.trash}><BiTrash /></i>
                    </td>
                  </tr>
                ))
              }

            </tbody>
          </Table>
          : ''
      }
      {/* </Pagination> */}


      {
        selectedItem && (
          <div className={styles.modal}>
            <Modal
              closeModal={closeModal}
              data={selectedItem}  // что передавать это или из  запроса?
              height={'430px'}
              addMode={!selectedItem?.xp_key}
              values={values}
              setFieldValue={setFieldValue}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              image={image}
              setImage={setImage}
            />
          </div>
        )
      }

      {
        confirmation && (
          <Confirmation
            top="30px"
            left="40px"
            title="Удалить данную запись?"
            confirmation={confirmation?.name}
            acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
            rejectConfirmation={rejectConfirmation}
          />
        )
      }

    </div >
  )
}

export default Index