import {BACKEND_URL_PREFIX} from "../assets/const";
import {createNotification} from "../components/Notifications/Notifications";
import {checkAndUpdateToken} from "./auth";
import {buildDefaultHeaders} from "./helper";
import {
    GET_TRAJECTORIES_LIST, SET_TRAJECTORIES_LIST_LOADING,
    // SET_TRAJECTORIES_BY_ID
} from "./types";

export const getTrajectoriesList = () => async(dispatch, getState) => {
    dispatch({type: SET_TRAJECTORIES_LIST_LOADING, payload: true});
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/xp_trajectories`, {
            method: "GET",
            ...buildDefaultHeaders(getState)
        });
        if (response.ok) {
            const result = (await response.json());
            dispatch({type: GET_TRAJECTORIES_LIST, payload: result});
        } else {
            dispatch({type: GET_TRAJECTORIES_LIST, payload: []});
        }
    }
    dispatch({type: SET_TRAJECTORIES_LIST_LOADING, payload: false});
};

export const updateTrajectories = (params) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {

        const response = await fetch(`${BACKEND_URL_PREFIX}/xp_trajectories/${params.id}`, {
            method: "PUT",
            body: JSON.stringify(params),
            ...buildDefaultHeaders(getState)
        });
        if (response.ok) {
            await dispatch(getTrajectoriesList());
            createNotification('success', 'Успешно!');
        }
    };
}

export const createTrajectories = (data) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/xp_trajectories`, {
            method: "POST",
            body: JSON.stringify(data),
            ...buildDefaultHeaders(getState)
        });
        if (response.ok) {
            createNotification('success', 'Успешно');
            await dispatch(getTrajectoriesList());
        } else 
            createNotification('error', "Не удалось создать запись");
        }
    };

export const deleteTrajectories = (id) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/xp_trajectories/${id}`, {
            method: "DELETE",
            ...buildDefaultHeaders(getState)
        });
        const result = await response.json();

        if (response.ok) {
            createNotification('success', 'Успешно');
            await dispatch(getTrajectoriesList());
        } else 
            createNotification('error', result.error);
        }
    };


// export const getTrajectoriesList = (id) => async (dispatch, getState) => {
//   await dispatch(checkAndUpdateToken());
//   if (getState().auth.token) {
//     const response = await fetch(`${BACKEND_URL_PREFIX}/track_profile/${id} `, {
//       method: "GET",
//       ...buildDefaultHeaders(getState),
//     });
//     if (response.ok) {
//       const result = (await response.json());

//       dispatch({ type: SET_TRAJECTORIES_BY_ID, payload: result });
//     }
//   }
// };