import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../../components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Input, MultiSelect, Select } from '../../../components/ModalInputs'
import * as Yup from 'yup'
import { createTerm, updateTerm } from '../../../actions/terms'
import { getSchoolYears, getTermGradeTypes, getTermTypes } from '../../../actions/common'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import moment from 'moment'
import { getArrayWithDateFormatted } from '../../../utils/smallHelpers'


const validationSchema = Yup.object({
  title: Yup.string().required('обязательное поле').max(50, 'Должно быть не более 50 символов'),
  term_begin: Yup.date().required('обязательное поле'),
  term_end: Yup.date().required('обязательное поле').min(Yup.ref('term_begin'), 'дата окончания не может быть раньше даты начала'),
  year: Yup.string().required('обязательное поле'),
  term_type_id: Yup.string().required('обязательное поле'),
})


export default function Main({ closeModal, data, addMode, setFilterYear, setSearch }) {

  const dispatch = useDispatch()
  const { loading } = useSelector(state => state.terms)
  const { schoolYears, termTypes, termGradeTypes } = useSelector(state => state.common)

  useEffect(() => {
    !schoolYears && dispatch(getSchoolYears())
    !termTypes && dispatch(getTermTypes())
    !termGradeTypes && dispatch(getTermGradeTypes())
    setFieldValue('term_begin', moment().format('YYYY-MM-DD'))
    setFieldValue('term_end', moment().format('YYYY-MM-DD'))

  }, [])

  const handleCreateTerm = (values) => {
    dispatch(createTerm(values))
    closeModal()
    setSearch(values.year)
  }

  const handleUpdateTerm = (values) => {
    const { trmid, ...payload } = values
    dispatch(updateTerm(trmid, payload))
    setSearch(values.year)
    closeModal()
  }

  const {
    values,
    errors,
    touched,
    submitCount,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue
  } = useFormik({
    initialValues: addMode ? {} : data,
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: addMode ? handleCreateTerm : handleUpdateTerm
  })
  console.log('values::: ', values);
  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

  const renderOptions = ({ name, xp_key, term_type_id, term_type_name }) => <option value={term_type_id || xp_key}>{term_type_name || name}</option>

  const getMultiOptions = ({ title, trmtid }) => ({ label: title, value: trmtid })

  const getTermGradeValues = () => {
    const options = termGradeTypes?.filter(({ trmtid }) => values.term_grade_types?.includes(trmtid)) || []
    return options.map(getMultiOptions)
  }

  const setTermGradeValues = (fieldName) => (grades) => {
    const newValue = grades.map(({ value }) => value).join(',')
    setFieldValue(fieldName, newValue)
  }

  return (
    <>
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          <div className={styles.inputsRow}>
            <Input
              style={{ width: '300px' }}
              label='Название'
              name='title'
              value={values.title}
              error={error('title')}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className={styles.inputsRow}>
            <Input
              label='Период (начало)'
              name='term_begin'
              type='date'
              value={moment(values.term_begin).format('YYYY-MM-DD')}
              // value={values.term_begin}
              error={error('term_begin')}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Input
              label='Период (конец)'
              name='term_end'
              type='date'
              value={moment(values.term_end).format('YYYY-MM-DD')}
              error={error('term_end')}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className={styles.inputsRow}>
            <Select
              style={{ width: '300px' }}
              label='Учебный год'
              name='year'
              value={values.year}
              error={error('year')}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value=''>Выбрать</option>
              {schoolYears?.map(renderOptions)}
            </Select>
          </div>
          <div className={styles.inputsRow}>
            <Select
              style={{ width: '300px' }}
              label='Тип'
              name='term_type_id'
              value={values.term_type_id}
              error={error('term_type_id')}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value=''>Выбрать</option>
              {termTypes?.map(renderOptions)}
            </Select>
          </div>
          <div className={styles.inputsRow}>
            <MultiSelect
              label='Тип оценок'
              value={getTermGradeValues()}
              options={termGradeTypes?.map(getMultiOptions)}
              onChange={setTermGradeValues('term_grade_types')}
              onBlur={handleBlur}
            >
              {termTypes?.map(renderOptions)}
            </MultiSelect>
          </div>
        </div>
      </div>
      <ModalFooter style={{ padding: '1rem' }} handleSubmit={handleSubmit} closeModal={closeModal} />
    </>
  )
}
