import React from 'react'
import styles from './styles.module.css'
import Select from 'react-select/creatable';
import cn from 'classnames'


export default function CreatableSelect({ label, error, ...props }) {

  const inputClassObject = cn(
    styles.errorAboveCreatableSelect,
    { [styles.error]: error }
  )
  return (
    <label className={styles.label}>
      <p>{label}</p>
      <div className={inputClassObject}>
        <Select
          className={styles.creatableSelect}
          noOptionsMessage={() => 'Нет опций'}
          placeholder="Новый элемент"
          formatCreateLabel={userInput => `Создать ${userInput}`}
          {...props}
        />
        {!!error && <p className={styles.errorText}>{error}</p>}
      </div>

    </label>
  )
}
