import React from 'react'
import { Input, Select, Quill, Checkbox } from '../../../components/ModalInputs'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import styles from '../styles.module.scss'
import Button from '../../../components/Button'


const STASUSES = [
  {name: 'Действующий', value: 0},
  {name: 'Архивный', value: 1},
  {name: 'Тестовый', value: 2}
]

const TYPES = [
  {name: 'Все', value: 0},
  {name: 'Выбор', value: 1},
  {name: 'Верно/Неверно', value: 2},
  {name: 'Примеры', value: 3},
  {name: 'Задача', value: 4},
  {name: 'Задача с решением', value: 5},
  {name: 'Заполнение пропусков', value: 6},
  {name: 'Перевод в контексте', value: 7},
  {name: 'Перестановка слов', value: 8},
  {name: 'Перестановка предложений', value: 9},
  {name: 'Классификация', value: 10},
  {name: 'Вопросы к тесту', value: 11},
]


const Form = ({filters}) => {
  
  const { values, handleChange, handleSubmit } = filters 

  const {blocks, lessons, levels} = useSelector(state => state.enroll_questions)
  const { schoolYears } = useSelector(state => state.common)


  const renderOption = (item) => {
    const {name, value, xp_key, block, lid, year} = item
    const optionName = name || block || year
    const optionValue = value || xp_key || block || lid
    return <option key={optionValue} value={optionValue}>{optionName}</option>
  }
  
  return (
    <div className={styles.form}>
      <Select
        value={values.status}
        name="status"
        onChange={handleChange}
        label="Статус"
      >
        <option value=''>Все</option>
        {STASUSES.map(renderOption)}
      </Select>
      
      <Select
        value={values.school_year}
        name="school_year"
        onChange={handleChange}
        label="Год поступления"
      >
        <option value=''>Все</option>
        {schoolYears.map(renderOption)}
      </Select>

      <Select
        value={values.level}
        name="level"
        onChange={handleChange}
        label="Класс"
        >
        <option value=''>Все</option>
        {levels.map(renderOption)}
      </Select>

      <Select
        value={values.lesson}
        name="lesson"
        onChange={handleChange}
        label="Дисциплина"
      >
        <option value=''>Все</option>
        {lessons.map(renderOption)}
      </Select>

      <Select
        value={values.block}
        name="block"
        onChange={handleChange}
        label="Блок"
        >
        <option value=''>Все</option>
        {blocks.map(renderOption)}
      </Select>

      <Select
        value={values.type}
        name="type"
        onChange={handleChange}
        label="Тип"
      >
        <option value=''>Все</option>
        {TYPES.map(renderOption)}
      </Select>

      <Button onClick={handleSubmit}>Обновить</Button>

    </div>
  )
}

export default Form