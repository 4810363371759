import React, { useEffect, useRef, useState } from 'react'
import styles from './styles.module.css'
import Button from '../../../components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Checkbox, Input, Select } from '../../../components/ModalInputs'
import * as Yup from 'yup'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import { createPeriodNutrition, createPeriodNutritionFile, getCanteenStaff, getNutritionPeriod, getPeriodNutritionFile, getPeriodNutritionFiles, updateGroupNutrition, updatePeriodNutrition, updatePeriodNutritionFile } from '../../../actions/directoryCanteen'
import { getCanteenStaffList, createCanteenStaff, updateCanteenStaff, getCanteenStaffFile, createCanteenStaffFile } from '../../../actions/directoryCanteen'
import { createNotification } from '../../../components/Notifications/Notifications'
import { api } from '../../../App'
import Modal from 'react-responsive-modal'
import { GET_CANTEEN_STAFF_FILE } from '../../../actions/types'

const validationSchema = Yup.object({
  post: Yup.string().required('обязательное поле').max(30, 'Должно быть не более 30 символов'),
  name: Yup.string().required('обязательное поле').max(30, 'Должно быть не более 30 символов'),
})

export default function ModalComponent({ data, closeModal, }) {

  const dispatch = useDispatch()


  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);

  const addMode = !data.name

  const handleCreate = async (values) => {
    console.log('values::: ', values);
    dispatch(createCanteenStaff(values, image))
    closeModal()
  }

  const handleUpdate = async ({ id, ...values }) => {
    dispatch(updateCanteenStaff(id, values, image))
    closeModal()
  }

  const {
    values,
    errors,
    touched,
    submitCount,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: addMode ? { post: '', name: '' } : data,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: addMode ? handleCreate : handleUpdate
  })

  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

  const handlePick = () => {
    document.getElementById('filePicker').click()
  }
  const handleChangeFile = (e) => {
    setImage(Object.values(e.target.files)[0])
  }

  const fetchPhoto = async (id) => {
    const { data } = await api.get(`/cook_team/images/${id}`, { responseType: 'blob' })
    setImage(data)
  }

  useEffect(() => {
    if (data && !addMode) {
    fetchPhoto(values.id)
    }
  }, [data])

  useEffect(() => {
    if (data && !addMode) {
      dispatch(getCanteenStaffFile(data.id))
      // fetchPhoto(data.id)
    } 
  }, [data])

  return (
    <Modal
      open={!!data}
      onClose={() => false}
      showCloseIcon={false}
      center
      classNames={{
        // overlay: styles.overlay,
        modal: styles.custom
      }}
    >
      <>
        <div className={styles.modalLocal} >
          <div className={styles.tabWrapper} style={{ display: "flex", justifyContent: 'space-evenly', flexDirection: 'column' }}>

            <div className={styles.inputsRow}>
              <Input
                label='Должность'
                name='post'
                value={values.post}
                error={error('post')}
                onChange={handleChange}
                style={{ width: '300px' }}
              />

            </div>
            <div className={styles.inputsRow}>
              <Input
                label='ФИО'
                name='name'
                value={values.name}
                error={error('name')}
                onChange={handleChange}
                style={{ width: '300px' }}
              />
            </div>

            <div className={styles.buttons_wrap}>
              <div className={styles.buttons}>
                <>
                  <Button onClick={handlePick}>{image ? 'Изменить фото' : 'Загрузить фото'}</Button>
                  <input
                    className={styles.hidden}
                    type='file'
                    onChange={(e) => handleChangeFile(e)}
                    accept="image/*"
                    id='filePicker'
                  />
                </>

              </div>
            </div>
            <br />
            {!!image &&
              <img
                src={URL.createObjectURL(image)}
                alt="0_o"
                height={350}
                width={450}
              />
            }
          </div>
        </div>

        <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
      </>
    </Modal>
  )
}
