import React, { useEffect, useState } from 'react'
import Modal from 'react-responsive-modal'
import {useDispatch, useSelector} from 'react-redux'
import styles from './styles.module.css'
import {useFormik} from 'formik'
import {Input} from '../../components/ModalInputs'
import * as Yup from 'yup'
import ModalFooter from '../../components/ModalFooter/ModalFooter'
import {getExamCityItem, createExamCity, updateExamCity, deleteExamCityAddress} from '../../actions/exam_city'
import Table from '../../components/Table'
import { BiTrash } from 'react-icons/bi'
import Confirmation from '../../components/Confirmation/Confirmation'
import Button from '../../components/Button'
import ModalCity from './ModalCity'
import { IoIosAdd } from 'react-icons/io'
import { createNotification } from '../../components/Notifications/Notifications'

const validationSchema = Yup.object({
    name: Yup
        .string()
        .required('обязательное поле')
        .min(4, 'Должно быть не менее 4 символов')
        .max(30, 'Должно быть не более 255 символов'),
})

export default function ModalComponent({data, closeModal, height, addMode, setMode}) {

    const dispatch = useDispatch();
   
    const [selectedItem, setSelectedItem] = useState(null);
    const [confirmation, setConfirmation] = useState(false);

    const {item} = useSelector(state => state.exam_city);

    useEffect(() => {
        !addMode &&
      dispatch(getExamCityItem(data?.xp_key));
    }, [data?.xp_key])
    
    const acceptConfirmation = () => {
        closeModalCity();
        dispatch(deleteExamCityAddress(confirmation.id, item?.xp_key));
        setConfirmation(false);
    };
    const rejectConfirmation = () => {
        setConfirmation(false);
    };

    const handleCreate = (values) => {
        dispatch(createExamCity(values))
            closeModal();
    }

    const handleUpdate = (values) => {
        dispatch(updateExamCity(values))
        closeModal();
    }

    const openModalCity = (data) => () => {
        if (addMode && values?.name?.length > 3) {
            dispatch(createExamCity(values, addMode));
            setSelectedItem('add')    
        } else if (!addMode) {
            setSelectedItem(data);
        } else {
            createNotification('error', 'Заполните Название города')
        }
    }

    const closeModalCity = () => setSelectedItem(null)


    const renderLi = (item) => {
        const {id, address} = item;
        return (
            <tr >
                <td onClick={openModalCity(item)}>{address}</td>
                <td width={'1.5rem'}>
                    <i className={styles.trash} onClick={() => setConfirmation({id, name: address})}>
                        <BiTrash/>
                    </i>
                </td>
            </tr>
        )
    }

    const {
        values,
        errors,
        touched,
        submitCount,
        handleChange,
        handleSubmit
    } = useFormik({
        initialValues: addMode
            ? {}
            : item,
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: addMode
            ? handleCreate
            : handleUpdate
    })

    const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

    return (<>
                {confirmation && (<Confirmation
                    top="30px"
                    left="40px"
                    title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
                    acceptConfirmation={() => acceptConfirmation(confirmation?.id)}
                    rejectConfirmation={rejectConfirmation}/>)}
                {selectedItem && <ModalCity
                    closeModal={closeModalCity}
                    data={selectedItem}
                    dataId={item?.xp_key}
                    height={'430px'}
                    addMode={selectedItem == 'add'}/>}
            <Modal
                open={!!item}
                onClose={() => closeModal()}
                showCloseIcon={false}
                height={height}
                center
                closeOnOverlayClick={true}
                closeOnEsc={true}
                
                classNames={{
                    modal: styles.custom
                }}>
                <> <div className={styles.modalLocal}>
                    <div className={styles.tabWrapper}>

                        <div className={styles.inputsColumn}>
                            <Input
                                label='Название'
                                name='name'
                                value={values.name}
                                error={error('name')}
                                onChange={handleChange}
                                style={{
                                    width: '100%',
                                }}/>
                        </div>

                        <div className={styles.inputsColumn}>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Город</th>
                                            <th>Удалить</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {item?.address?.map(renderLi)}
                                    </tbody>
                                </Table>
                       
                      <div style={{display: 'flex', flexDirection: "row"}}>
                        <Button
                            style={{padding: '.5rem 1.5rem', margin: '12px'}}
                            onClick={openModalCity('add')}><IoIosAdd size={30}/>Добавить
                        </Button>
                    </div>
                        </div>

                    </div>
                </div>

                <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal}/>
                </>
            </Modal>
        </>
    )
}
