import React from 'react'
import Modal from 'react-responsive-modal'
import { useDispatch, useSelector } from 'react-redux'
import styles from './styles.module.css'
import { useFormik } from 'formik'
import { Checkbox, Input, Select } from '../../components/ModalInputs'
import * as Yup from 'yup'
import ModalFooter from '../../components/ModalFooter/ModalFooter'
import { createJournalControl, updateJournalControl } from '../../actions/journalControl'
import moment from 'moment'

const validationSchema = Yup.object({
  // name: Yup.string().required('обязательное поле').max(30, 'Должно быть не более 30 символов'),
})

export default function ModalComponent({ data, closeModal, height, addMode, valuesFilters }) {
  console.log('valuesFilters::: ', valuesFilters);

  const dispatch = useDispatch()
  const { periods, groups } = useSelector(state => state.journal_control)
  const { courses, classes } = useSelector(state => state.common)
  console.log('classes::: ', classes);


  const handleCreate = async ({ subject, ...values }) => {
    // await dispatch(createJournalControl({ ...values, trmid: valuesFilters.trmid, gid: valuesFilters.gid }))
    await dispatch(createJournalControl({ ...values, trmid: 14, gid: 7, year: 13 })) /// заглушки
    closeModal()
  }

  const handleUpdate = async ({ jmid, subject, ...values }) => {
    console.log('handleCreatehandleCreate values::: ', values);

    await dispatch(updateJournalControl(jmid, { ...values, trmid: valuesFilters.trmid, gid: valuesFilters.gid }))
    closeModal()
  }
  console.log('data::: ', data);
  const {
    values,
    errors,
    touched,
    dirty,
    submitCount,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: data,
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema,
    onSubmit: addMode ? handleCreate : handleUpdate
  })

  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

  const renderOptions = ({ name, xp_key, gid, f, title, cid }) => <option value={gid || xp_key || +cid}>{name || f || title}</option>

  console.log(data)

  return (
    <Modal
      open={!!data}
      onClose={() => false}
      showCloseIcon={false}
      height={height}
      classNames={{
        // overlay: styles.overlay,
        modal: styles.custom
      }}
      center
    >
      <>
        <div className={styles.modalLocal} >
          <div className={styles.tabWrapper} >

            <div className={styles.inputsRow}>
              <Input
                label='Дата'
                type='date'
                name='date'
                value={values.date}
                onChange={handleChange}
                disabled={!addMode && moment(values.date).isBefore(moment())}
              />
              <Select
                label='Класс'
                name='group'
                value={values?.group}
                onChange={handleChange}
                disabled={!addMode && moment(values.date).isBefore(moment())}
              >
                <option value=''>{values?.group}</option>
                {groups?.map(renderOptions)}
              </Select>
            </div>
            <div className={styles.inputsRow}>
              <Input
                label='Замечания'
                name='checktext'
                value={values.checktext}
                error={error('checktext')}
                onChange={handleChange}
                style={{ width: '600px' }}
                disabled={!addMode && moment(values.date).isBefore(moment())}
              />

            </div>
            <div className={styles.inputsRow}>

              <Select
                label='Предмет'
                name='cid'
                value={values?.cid}
                onChange={handleChange}
              // disabled={moment(values.date).isBefore(moment())}
              >
                <option value=''>{values?.cid !== null ? values?.cid : "Не выбран"}</option>
                {courses?.map(renderOptions)}
              </Select>

              <Select
                label='Проверяющий'
                name='xp_f_get_mid_fio'
                value={values?.xp_f_get_mid_fio}
                onChange={handleChange}
                disabled
              >
                <option value=''>{values?.xp_f_get_mid_fio !== null ? values?.xp_f_get_mid_fio : "Не выбран"}</option>
              </Select>
            </div>

            <div className={styles.inputsRow}>
              <Checkbox label="Выполнено" />
              {!addMode &&
                <Input
                  label='Дата выполнения'
                  type='date'
                  name='visitdate'
                  // value={values.visitdate}
                  // value={values.visitdate}
                  // onChange={handleChange}
                  disabled
                />}

            </div>
            <div className={styles.inputsRow}>
              {!addMode &&
                <Select
                  label='Выполняющий'
                  name='visitorder'
                  // value={values?.visitorder}
                  // onChange={handleChange}
                  // options={typeOfAppeal}
                  disabled
                >
                  <option value=''>Выбрать</option>
                  {/* {typeOfAppeal?.map(renderOption)} */}
                </Select>
              }
            </div>
          </div>
        </div>

        <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
      </>
    </Modal >

  )
}
