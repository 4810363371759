import { api } from "../App";
import { SET_STAGES_STUDY_LIST } from "./types";




export const getStagesStudy = () => async (dispatch, getState) => {
  try {
    const { data } = await api.get('/study_steps');
    dispatch({ type: SET_STAGES_STUDY_LIST, payload: data });
  } catch (ERR) {
  } finally {
  }
}

export const createStagesStudy = (data) => async (dispatch, getState) => {
  let success
  try {
    await api.post('/study_steps', data);
    await dispatch(getStagesStudy())
    success = true
  } catch (ERR) {
  } finally {
    return success
  }
}

export const updateStagesStudy = ({ f_studysteps, ...data }) => async (dispatch, getState) => {
  let success
  try {
    await api.put(`/study_steps/${f_studysteps}`, data);
    await dispatch(getStagesStudy())
    success = true
  } catch (ERR) {
  } finally {
    return success
  }
}

export const deleteStagesStudy = (id) => async (dispatch, getState) => {
  try {
    await api.delete(`/study_steps/${id}`);
    await dispatch(getStagesStudy())
  } catch (ERR) {
  } finally {
  }
}