import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { IoIosAdd } from 'react-icons/io'
import { thrash } from '../../assets/svg/other'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Modal from './Modal'
import Table from '../../components/Table'
import { getGradeOptions, deleteGradeOptions } from '../../actions/directory'
import { BiTrash } from 'react-icons/bi'
import Confirmation from '../../components/Confirmation/Confirmation'


export default function GradesOptions() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { gradesOptions } = useSelector(state => state.directory)
  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(false);

  useEffect(() => {
    dispatch(getGradeOptions())
  }, [])


  const openModal = (data) => () => {
    setSelectedItem(data || {})
  }

  const closeModal = () => setSelectedItem(null)

  const goHome = () => navigate('/')

  const thrashClickHandler = (valid) => (e) => {
    e.stopPropagation()
    dispatch(deleteGradeOptions(valid))
  }

  const acceptConfirmation = () => {
    closeModal();
    dispatch(deleteGradeOptions(confirmation.id));
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  const renderLi = (data) => {
    const { text, value, valid } = data
    return (
      <tr >
        <td onClick={openModal(data)}>{value}</td>
        <td onClick={openModal(data)}>{text}</td>
        {/* <td onClick={()=> setConfirmation({id: valid})}>{thrash()}</td> */}
        <td><i className={styles.trash} onClick={() => setConfirmation({ id: valid, name: value })}><BiTrash /></i></td>

      </tr>
    )
  }

  return (
    <div className={styles.wrapper} style={{ width: '700px', margin: '0 auto' }}>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
          // confirmation={confirmation?.name} 
          acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      <Modal
        closeModal={closeModal}
        data={selectedItem}
      // height={'630px'}
      />
      <h1>Варианты значений оценок</h1>
      <Table className={styles.tables}>
        <thead>
          <tr>
            <th>Значение</th>
            <th>Примечание</th>
            <th></th>
          </tr>
        </thead>
        <tbody>

          {gradesOptions?.map(renderLi)}

        </tbody>
      </Table>

      <div style={{ display: 'flex', flexDirection: "row" }}>
        <Button
          style={{ padding: '.5rem 1.5rem', margin: '12px' }}
          onClick={openModal()}
        >
          <IoIosAdd size={30} />
          Добавить
        </Button>
      </div>
    </div>
  )
}
