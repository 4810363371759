import React, { useState } from 'react'
import Table from '../../../components/Table'
import { BiTrash } from 'react-icons/bi'
import Confirmation from '../../../components/Confirmation/Confirmation'
import { useDispatch } from 'react-redux'
import { deleteEnrollQuestion } from '../../../actions/enrollQuestions'



const TableComponent = ({ data, selectItem, filters }) => {
  
  const dispatch = useDispatch()

  const [confirmation, setConfirmation] = useState(null)

  const deleteClickHandler = (id) => (e) => {
    e.stopPropagation()
    setConfirmation(id)
  }

  const renderRow = (item, index) => (
    <tr onClick={selectItem(item)}>
      <td>{item.school_year_name}</td>
      <td>{item.q_num}</td>
      <td>{item.discipline}</td>
      <td>{item.level}</td>
      <td>{item.block}</td>
      <td>{item.weight}</td>
      <td>{item.question_type}</td>
      <td>{item.q_name}</td>
      <td><BiTrash onClick={deleteClickHandler(item.id)} color='red' size={24}/></td>
    </tr>
  )

  const acceptConfirmation = () => {
    dispatch(deleteEnrollQuestion(confirmation, filters))
    setConfirmation(null)
  }

  return (
    <>
      {confirmation &&
        <Confirmation
          title='Вы уверены что хотите удалить вопрос?'
          acceptConfirmation={acceptConfirmation}
          rejectConfirmation={() => setConfirmation(null)}
        />
      }
      <Table>
        <thead>
          <tr>
            <td>Год поступления</td>
            <td>№</td>
            <td>Дисциплина</td>
            <td>Класс</td>
            <td>Блок</td>
            <td>Сложность</td>
            <td>Тип</td>
            <td>Вопрос</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {data?.map(renderRow)}
        </tbody>
      </Table>
    </>
  )
}

export default TableComponent