import React, { useEffect } from 'react'
import styles from './index.module.css'
import Modal from 'react-responsive-modal'
import ModalFooter from '../../../../../components/ModalFooter/ModalFooter'
import { Input, Select } from '../../../../../components/ModalInputs'
import { useDispatch, useSelector } from 'react-redux'
import { getStudyTrackProfile } from '../../../../../actions/studyPlans'
import { PARALLELS } from '../../../../../assets/const'
import { addStudyPlanVDCourse, editStudyPlanVDCourse } from '../../../../../actions/studyPlansVD'



const Index = ({ compoundModalOpen, setCompoundModalOpen, closeModal, addMode, out_tracks }) => {

    const dispatch = useDispatch()

    const { extraCurEduList } = useSelector(state => state.directoryExtraCurEducation)

    useEffect(() => {
        dispatch(getStudyTrackProfile());
    }, [])

    const renderOptions = ({ name, cid, xp_key, year }) => <option value={xp_key || year} key={xp_key || year}>{name || year}</option>

    const handleChange = (e) => {
        setCompoundModalOpen({ ...compoundModalOpen, [e.target.name]: Number(e.target.value) })
    }

    const handleSubmit = () => {
        if (addMode) {
            dispatch(addStudyPlanVDCourse({ out_tracks, ...compoundModalOpen }))
            closeModal()
        } else {
            dispatch(editStudyPlanVDCourse(compoundModalOpen))
            closeModal()
        }
    }

    return (
        <Modal
            open={!!compoundModalOpen}
            onClose={closeModal}
            closeOnOverlayClick={true}
            closeOnEsc={true}
            showCloseIcon={true}
            animationDuration={1}
            classNames={{
                modal: styles.modalMain
            }}
        >

            <div className={styles.modalContent}>
                <h1 className={styles.modalHeader}>{!addMode ? 'Изменить' : 'Создать'}</h1>
                <div className={styles.modalMenu}>
                    <div className={styles.inputsRow}>
                        <Select
                            // error={compoundModalOpen?.cid === '' ? 'Обязательное поле' : ''}
                            label='Предмет'
                            name='out_courses'
                            value={compoundModalOpen?.out_courses}
                            onChange={e => handleChange({ target: { name: 'out_courses', value: Number(e.target.value) } })}>
                            {[{ xp_key: '', name: 'Выбрать' }, ...extraCurEduList]?.map(renderOptions)}
                        </Select>
                    </div>

                    <div className={styles.inputsRow}>
                        <Select
                            // error={compoundModalOpen?.level === '' ? 'Обязательное поле' : ''}
                            label='Паралель'
                            name='level'
                            value={compoundModalOpen?.level}
                            onChange={e => handleChange({ target: { name: 'level', value: Number(e.target.value) } })}>
                            {[{ values: '', name: 'Выбрать' }, ...PARALLELS]?.map(renderOptions)}
                        </Select>
                    </div>

                    <div className={styles.tabWrapper}>
                        <div className={styles.inputsRow}>
                            <Input
                                type='number'
                                // error={compoundModalOpen.hours === 0 ? 'Обязательное поле' : ''}
                                label='Часы в год'
                                maxLength={9}
                                onKeyPress={e => !/[0-9]/.test(e.key) && e.preventDefault()}
                                name='hours'
                                value={compoundModalOpen?.hours}
                                onChange={e => handleChange(e)} />
                        </div>
                        <div className={styles.inputsRow}>
                            <Input
                                type='number'
                                // error={compoundModalOpen.hoursweek === 0 ? 'Обязательное поле' : ''}
                                label='Часы в неделю'
                                maxLength={9}
                                onKeyPress={e => !/[0-9]/.test(e.key) && e.preventDefault()}
                                name='hoursweek'
                                value={compoundModalOpen?.hoursweek}
                                onChange={e => handleChange(e)} />
                        </div>


                    </div>

                </div>
            </div>

            <ModalFooter isShowInfo={true} closeModal={closeModal} handleSubmit={handleSubmit}
            />
        </Modal>
    )
}

export default Index