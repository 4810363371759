import * as types from "../actions/types";

const initialState = {
  list: [],
  loading: false,
  item: {},
  years: [],
  kurses: [],
  trajectories_list: [],
  interviews_list: [],
  interviewers_list: [],
  interviewers_list_all: []
};

export default function exam_board(state = initialState, action) {
  switch (action.type) {

    case types.GET_EXAM_BOARD_LIST:
      return {
        ...state,
        list: action.payload
      };
      case types.SET_EXAM_BOARD_LIST_LOADING:
        return {
          ...state,
          loading: action.payload
        };
      case types.SET_EXAM_BOARD_BY_ID:
        return {
          ...state,
          item: action.payload
        };
      
      
      case types.GET_EXAM_BOARD_YEARS:
        return {
          ...state,
          years: action.payload
        };
      case types.GET_EXAM_BOARD_KURSES:
        return {
          ...state,
          kurses: action.payload
        };

      case types.SET_EXAM_BOARD_TRAJECTORIES_BY_ID:
        return {
          ...state,
          trajectories_list: action.payload
        };
      case types.SET_EXAM_BOARD_INTERVIEWS_BY_ID:
        return {
          ...state,
          interviews_list: action.payload
        };
      case types.SET_EXAM_BOARD_INTERVIEWERS_BY_ID:
        return {
          ...state,
          interviewers_list: action.payload
        };
      case types.SET_EXAM_BOARD_INTERVIEWERS_ALL:
        return {
          ...state,
          interviewers_list_all: action.payload
        };
    default:
      return state;
  }
}