import React, { useContext, useEffect } from 'react'
import styles from './styles.module.css'
import { Input, Select } from '../../../components/ModalInputs'
import { admissionYears } from '../../../assets/const'
import { useDispatch, useSelector } from 'react-redux'
import { ClassingContext } from '../../../utils/context'
import { createGroups, getGroupInfo, updateGroups } from '../../../actions/groups'
import { useFormik } from 'formik'
import Button from '../../../components/Button'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import { createNotification } from '../../../components/Notifications/Notifications'
import { getStudySteps } from '../../../actions/common'

export default function Main() {

  const dispatch = useDispatch()
  const { classId, closeModal, infoCreateChange } = useContext(ClassingContext)
  const { groupInfo, modalLoading: loading } = useSelector(state => state.groups)
  const {
    schoolYears,
    specializations,
    languages,
    studyProfiles,
    studyModes,
    studyForms,
    studySteps,
    shifts = [{ sid: '1', name: 1 }, { sid: '2', name: 2 }],
    teachers
  } = useSelector(state => state.common)

  useEffect(() => {
    if (classId && classId !== 'add') {
      dispatch(getGroupInfo(classId))
    } else {
      dispatch({ type: 'SET_GROUP_INFO', payload: null })
    }
    dispatch(getStudySteps())

  }, [classId])

  const handleCreateClass = () => {
    if (values.name) {
      // closeModal()
      dispatch(createGroups(values))
    } else {
      createNotification('error', 'Заполните все обязательные поля')
    }
    closeModal()
  }

  const handleUpdateClass = () => {
    if (values.name) {
      // closeModal()
      dispatch(updateGroups(classId, values))
    } else {
      createNotification('error', 'Заполните все обязательные поля')
    }
    closeModal()
  }
  const {
    values,
    errors,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: { owner_gid: 0, ...groupInfo } ||
    {
      name: "test123",
      rota: "",
      vzvod: '',
      kurs: '',
      class_teacher: null,
      class_teacher2: null,
      class_teacher3: null,
      class_teacher4: null,
      year_graduation: null,
      year: '0',
      liter: '',
      owner_gid: 0, // разделение класса на подгруппы
    },
    enableReinitialize: true,
    onSubmit: classId == 'add' ? handleCreateClass : handleUpdateClass
  })
  console.log('values::: ', values);
  return (
    <>
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          <div className={styles.inputsRow}>
            <Input label='Название' value={values.name} onChange={handleChange} name='name' />
            <Select label='Год обучения' style={{ width: '200px' }} value={values.year} onChange={handleChange} name='year'>
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]?.map((el) => <option value={el}>{el}</option>)}
            </Select>
            <Input label='Литера' value={values.liter} onChange={handleChange} name='liter' />
          </div>
          <div className={styles.inputsRow}>
            <Input label='Рота' value={values.rota} onChange={handleChange} name='rota' />
            <Input label='Взвод' value={values.vzvod} onChange={handleChange} name='vzvod' />
            <Input label='Курс' value={values.kurs} onChange={handleChange} name='kurs' />
          </div>
          <div className={styles.inputsRow}>
            <Select label='Классный руководитель' value={values.class_teacher} onChange={handleChange} name='class_teacher'>
              {teachers?.teachers?.map(({ calc_fio, mid }) => <option value={mid}>{calc_fio}</option>)}
            </Select>
          </div>
          <div className={styles.inputsRow} style={{ display: 'block' }}>
            <Select label='Воспитатели' style={{ width: '315px' }} value={values.class_teacher2} onChange={handleChange} name='class_teacher2'>
              {teachers?.teachers?.map(({ calc_fio, mid }) => <option value={mid}>{calc_fio}</option>)}
            </Select>
            <Select style={{ width: '315px' }} value={values.class_teacher3} onChange={handleChange} name='class_teacher3'>
              {teachers?.teachers?.map(({ calc_fio, mid }) => <option value={mid}>{calc_fio}</option>)}
            </Select>
            <Select style={{ width: '315px' }} value={values.class_teacher4} onChange={handleChange} name='class_teacher4'>
              {teachers?.teachers?.map(({ calc_fio, mid }) => <option value={mid}>{calc_fio}</option>)}
            </Select>
          </div>
          <div className={styles.inputsRow} style={{ marginTop: '2rem' }}>
            <Select label='Выпущен в учебном году' style={{ width: '200px' }} value={values.yeargraduation} onChange={handleChange} name='yeargraduation'>
              {values.yeargraduation == 0 && <option value={values.yeargraduation}>Не выбран</option>}
              {schoolYears?.map(({ xp_key, name }) => <option value={xp_key}>{name}</option>)}
            </Select>
            <Select label='Специализация' style={{ width: '200px' }} value={values.specialization} onChange={handleChange} name='specialization'>
              {specializations?.map((value) => <option>{value}</option>)}
            </Select>

            <Select label='Язык обучения' style={{ width: '200px' }}>
              {languages?.map((value) => <option>{value}</option>)}
            </Select>
          </div>
          <div className={styles.inputsRow}>
            <Select label='Профиль' style={{ width: '200px' }} value={values.track_profile_id} onChange={handleChange} name='track_profile_id'>
              {studyProfiles?.map(({ trp_id, name }) => <option value={trp_id}>{name}</option>)}
            </Select>
            <Select label='Режим обучения' style={{ width: '200px' }} >
              {/* value={values.yeargraduation} onChange={handleChange} name='track_profile_id' */}
              {studyModes?.map((value) => <option>{value}</option>)}
            </Select>
            <Select label='Форма обучения' style={{ width: '200px' }} value={values.f_studyform} onChange={handleChange} name='f_studyform'>
              {studyForms?.map(({ f_studyform, name }) => <option value={f_studyform}>{name}</option>)}
            </Select>
          </div>
          <div className={styles.inputsRow}>
            <Select label='Смена' style={{ width: '200px' }} value={values.shift} onChange={handleChange} name='shift'>
              {shifts?.map(({ sid, name }) => <option value={sid}>{name}</option>)}
            </Select>
            <Select label='Степень обучения' style={{ width: '200px' }} value={values.f_studysteps} onChange={handleChange} name='f_studysteps'>
              {studySteps?.map(({ f_studysteps, name }) => <option value={f_studysteps}>{name}</option>)}
            </Select>
          </div>
        </div>
      </div>
      <ModalFooter closeModal={closeModal} handleSubmit={handleSubmit} infoCreateChange={infoCreateChange} />
    </>

  )
}
