import React from 'react'
import Modal from 'react-responsive-modal'
import {useDispatch} from 'react-redux'
import styles from './styles.module.css'
import {useFormik} from 'formik'
import {Textarea} from '../../components/ModalInputs'
import * as Yup from 'yup'
import ModalFooter from '../../components/ModalFooter/ModalFooter'
import {createExamCityAddress, updateExamCityAddress} from '../../actions/exam_city'

const validationSchema = Yup.object({
    address: Yup
        .string()
        .required('обязательное поле')
        .min(4, 'Должно быть не менее 4 символов')
        .max(30, 'Должно быть не более 255 символов')
})

export default function ModalComponent({data, closeModal, height, addMode, dataId}) {

    const dispatch = useDispatch();

    const handleCreate = (values) => {
        dispatch(createExamCityAddress(dataId, values))
        closeModal();
    }

    const handleUpdate = (values) => {
        dispatch(updateExamCityAddress(dataId, values))
        closeModal();
    }

    const {
        values,
        errors,
        touched,
        submitCount,
        handleChange,
        handleSubmit
    } = useFormik({
        initialValues: addMode
            ? {}
            : data,
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: addMode
            ? handleCreate
            : handleUpdate
    })

    const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

    return (
        <Modal
            open={!!data}
            onClose={() => closeModal()}
            showCloseIcon={false}
            height={height}
            center
            closeOnOverlayClick={true}
            closeOnEsc={true}
            classNames={{modal: styles.custom}}>
            <> 
                <div className={styles.modalLocal}>
                    <div className={styles.tabWrapper}>

                        <div className={styles.inputsColumn}>
                            <Textarea
                                label='Адрес'
                                name='address'
                                value={values.address}
                                error={error('address')}
                                onChange={handleChange}
                                style={{width: '100%', height: '200px', resize: 'none'}}/>
                        </div>
                    </div>
                </div>

            <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal}/>
            </>
        </Modal>

    )
}
