import React from 'react'
import styles from './styles.module.css'
import Button from '../../../components/Button'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import { Checkbox } from '../../../components/ModalInputs'
import Table from '../../../components/Table'
import { updateSubjectToStudyYear } from '../../../actions/subjectsByYearStudy'


// const validationSchema = Yup.object({
//   name: Yup.string().required('обязательное поле'),
//   begdate: Yup.string().required('обязательное поле'),
//   enddate: Yup.string().required('обязательное поле'),
// })



export default function Main({ closeModal, data, addMode, selectedItem }) {

  const dispatch = useDispatch()

  const {
    values,
    setFieldValue,
  } = useFormik({
    initialValues: addMode ? data : data,
    enableReinitialize: true,
  })

  const handleChange2 = (value) => {
    setFieldValue('value', value.label)
  }
  const handleChange1 = (data) => {
    // 1/11 не ясно как оно должно работать - удаления как такого нет - level  решает где в годе будет предмет 
    const forRequestAdd = { ...data, profile: -1, prev_cid: 0, level: selectedItem.id }
    const forRequestDelete = { ...data, profile: -1, prev_cid: 0, level: 0 }
    if (values.find(el => el.xp_key === data.xp_key && el.level === selectedItem.id)) {
      dispatch(updateSubjectToStudyYear(forRequestDelete))
    } else dispatch(updateSubjectToStudyYear(forRequestAdd))
  }

  return (
    <>
      <div className={styles.modalMain} >
        <Table style={{ marginTop: '2rem', backgroundColor: 'white', width: '500px', margin: "50px auto" }}>
          <thead>
            <tr>
              <th colSpan={2}>Составление учебного плана</th>
            </tr>
            <tr>
              <th colSpan={2} style={{ textAlign: 'left' }}>{selectedItem?.name}</th>
            </tr>
          </thead>
          <tbody>

            {values.map((el) => {
              const equal = selectedItem?.id === el.level
              return (

                <tr key={el.cid} >
                  <td style={{ textAlign: 'left', paddingLeft: '15px' }}>{el.course_name}</td>
                  <td>
                    <Checkbox onChange={() => handleChange1(el)} checked={equal} />
                  </td>
                </tr>
              )
            }
            )}

          </tbody>
        </Table>
      </div >
      {/* <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} /> */}
      <div className={styles.modalExitButton}>

        <Button mode='bordered' onClick={() => closeModal(true)}>Закрыть</Button>
      </div>

    </>
  )
}
