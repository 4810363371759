import * as types from "../actions/types";



const initialState = {
  info: null,
  schedule: null,
  report: null,
  year: null,
  loading: false,
};


export default function exams (state = initialState, action) {
  switch (action.type) {
    case types.SET_EXAM_INFO:
      return {
        ...state,
        info: action.payload
      };
    case types.SET_EXAM_SCHEDULE:
      return {
        ...state,
        schedule: action.payload
      };
    case types.SET_EXAM_REPORT:
      return {
        ...state,
        report: action.payload
      };
    case types.SET_EXAM_YEAR:
      return {
        ...state,
        year: action.payload
      };
    case types.SET_EXAM_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}