import React from 'react'
import styles from './styles.module.css'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import { Input } from '../../../components/ModalInputs'
import * as Yup from 'yup'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'

import { createDischarge, updateDischarge, } from '../../../actions/medCenter/discharge'

const validationSchema = Yup.object({
  discharge: Yup.string().required('обязательное поле').max(50, 'Должно быть не более 50 символов'),

})


export default function Main({ closeModal, data, addMode }) {

  const dispatch = useDispatch()

  const handleCreate = (values) => {
    dispatch(createDischarge(values))
    closeModal()
  }

  const handleUpdate = (values) => {
    dispatch(updateDischarge(values))
    closeModal()
  }

  const {
    values,
    errors,
    touched,
    submitCount,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: addMode ? {} : data,
    enableReinitialize: true,
    // validateOnChange: true,
    // validationSchema: validationSchema,
    onSubmit: addMode ? handleCreate : handleUpdate
  })

  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

  return (
    <>
      <div className={styles.modalMain} >
        <div className={styles.tabWrapper} >

          <div className={styles.inputsRow}>
            <Input
              label='Название'
              name='discharge'
              value={values.discharge}
              error={error('discharge')}
              onChange={handleChange}
              style={{ width: '600px' }}
            />
          </div>

        </div>
      </div>

      <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
    </>
  )
}
