import React, { useEffect, useState } from 'react';
import Modal from 'react-responsive-modal';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.css';
import { useFormik } from 'formik';
import { Checkbox, Input, Select } from '../../components/ModalInputs';
import * as Yup from 'yup';
import ModalFooter from '../../components/ModalFooter/ModalFooter';
import {
  getExamBoardItem,
  createExamBoard,
  updateExamBoard,
  getKurses,
  getExamBoardItemTrajectories,
  getExamBoardItemInterviews,
  getExamBoardItemInterviewers,
  editExamBoardItemInterviewersRole
} from '../../actions/exam_board';
import Confirmation from '../../components/Confirmation/Confirmation';
import Empty from '../../components/Empty';
import ModalInterviewers from './ModalInterviewers';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Table from '../../components/Table';

const defaultStreams = [
  { name: 'Основной', value: 0 },
  { name: 'Донабор', value: 1 },
];

const validationSchema = Yup.object({
    name: Yup.string()
    .required('Обязательное поле')
    .min(4, 'Должно быть не менее 4 символов')
    .max(30, 'Должно быть не более 30 символов'),
});

export default function ModalComponent({ data, closeModal, height, addMode, setFilters }) {
  const dispatch = useDispatch();
  const { item, years, kurses, trajectories_list, interviews_list, interviewers_list } = useSelector((state) => state.exam_board);

  const [selectedItem, setSelectedItem] = useState(null);
  const [confirmation, setConfirmation] = useState(false);
  const [interviewers_listLocal, setinterviewers_listLocal] = useState([]);
  const [edit, setEdit] = useState({});

  useEffect(() => {
    setinterviewers_listLocal(interviewers_list);
  }, [interviewers_list]);

  const formik = useFormik({
    initialValues: addMode
      ? { 
        name: '', 
        school_year: years.find(el => el.actual === true)?.xp_key, 
        stream: 0, 
        kurs: 0 }
      : item || {},
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema,
    onSubmit: (values) => {
      if (addMode) {
        dispatch(createExamBoard(values));
    } else {
        dispatch(updateExamBoard(values)); 
    }
        setFilters({ school_year: values.school_year, stream: values.stream, kurs: values.kurs });
        closeModal();
    },
  });

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, submitCount } = formik;
  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName];

  useEffect(() => {
    if (!addMode && data?.id) {
      dispatch(getExamBoardItem(data.id));
      dispatch(getExamBoardItemTrajectories(data.id));
      dispatch(getExamBoardItemInterviews(data.id));
      dispatch(getExamBoardItemInterviewers(data.id));
    }
  }, [data?.id, dispatch, addMode]);

  useEffect(() => {
        values?.school_year && dispatch(getKurses(values.school_year));
  }, [values.school_year]);

  useEffect(() => {
    if (kurses.length === 0) {
        setFieldValue('kurs', 0);
    }
  }, [kurses])
  
  const openModalInterviewers = (data) => setSelectedItem(data);
  const closeModalInterviewers = () => setSelectedItem(null);
  const acceptConfirmation = () => {
    closeModalInterviewers();
    setConfirmation(false);
  };
  const rejectConfirmation = () => setConfirmation(false);


  const handleChangeInterviewer = (value, list, item) => {
    const newList = list.map(item => item.id == value ? ({...item, role: -1}) : ({...item, role: 0}));
    setinterviewers_listLocal(newList);
    setEdit({...item, role: -1});
    dispatch(editExamBoardItemInterviewersRole(data.id, {mid_old: list.find(el => el.role === -1)?.mid, mid_new: item.mid}));
  }

  return (
    <>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
          acceptConfirmation={acceptConfirmation}
          rejectConfirmation={rejectConfirmation}
        />
      )}

      {selectedItem && (
        <ModalInterviewers
          closeModal={closeModalInterviewers}
          data={selectedItem}
          dataId={item?.id}
          height={'430px'}
          addMode={selectedItem === 'add'}
        />
      )}

      <Modal
        open={!!item}
        onClose={closeModal}
        showCloseIcon={false}
        height={height}
        center
        closeOnOverlayClick={true}
        closeOnEsc={true}
        classNames={{ modal: styles.custom }}
      >
        <div className={styles.modalLocal}>
          <div className={styles.tabWrapper}>
            <div className={styles.inputsColumn}>
              <Input
                label="Название"
                name="name"
                value={values.name}
                error={error('name')}
                onChange={handleChange}
                style={{ width: '100%' }}
              />
            </div>

            <div className={styles.inputsRow}>
              <Select
                name="school_year"
                label="Год набора"
                value={values.school_year}
                onChange={(e) => setFieldValue('school_year', Number(e.target.value))}
              >
                {years.map((el) => (
                  <option key={el.xp_key} value={el.xp_key}>
                    {el.name}
                  </option>
                ))}
              </Select>

              <Select
                name="stream"
                label="Тип"
                value={values.stream}
                onChange={(e) => setFieldValue('stream', Number(e.target.value))}
              >
                {defaultStreams.map((el) => (
                  <option key={el.value} value={el.value}>
                    {el.name}
                  </option>
                ))}
              </Select>

              <Select
                name="kurs"
                label="Класс"
                value={values.kurs}
                onChange={(e) => setFieldValue('kurs', Number(e.target.value))}
              >
                {[{ kurs: 0, kursname: 'Все' }, ...kurses].map((el) => (
                  <option key={el.kurs} value={el.kurs}>
                    {el.kursname}
                  </option>
                ))}
              </Select>
            </div>

            {!addMode && <Tabs>
                <TabList className={styles.tabsBlock}>
                    <Tab className={styles.tab} selectedClassName={styles.activeTab}>Члены комиссии</Tab>
                    <Tab className={styles.tab} selectedClassName={styles.activeTab}>Собеседования</Tab>
                </TabList>

                <TabPanel>
                    <Table className={styles.table}>
                        <tr>
                            <th>Дисциплина</th>
                            <th>ФИО</th>
                            <th>Старший</th>
                        </tr>
                        {   interviewers_listLocal.length ?
                            interviewers_listLocal.map((el) => (
                                <tr key={el.id}>
                                    <td>{el.name}</td>
                                    <td onClick={() => openModalInterviewers(el)}>{el.fio}</td>
                                    <td className={styles.checkbox}><Checkbox checked={el.role} onChange={() => {handleChangeInterviewer(el.id, interviewers_listLocal, el)}}/></td>
                                </tr>
                        )) : <></>
                        }
                    </Table>
                    {trajectories_list.length ? <></> : <Empty/>}
                </TabPanel>

                <TabPanel> 
                    <Table className={styles.table}>
                    <tr>
                        <th>Время тестирования</th>
                        <th>Претендент</th>
                        <th>Билет</th>
                    </tr>
                    {   interviews_list.length ?
                        interviews_list.map((el) => (
                            <tr key={el.id}>
                                <td>{el.test_time}</td>
                                <td>{el.applicant}</td>
                                <td>{el.bilet}</td>
                            </tr>
                       )) : <></>
                    }
                    </Table>
                    {interviews_list.length ? <></> : <Empty/>}
                </TabPanel>
            </Tabs>}
          </div>
        </div>
        <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
      </Modal>
    </>
  );
}
