import * as types from "../actions/types";

const initialState = {
  list: [],
  loading: false
};

export default function user_dictionary(state = initialState, action) {
  switch (action.type) {

    case types.GET_USER_DICT_LIST:
      return {
        ...state,
        list: action.payload
      };
    case types.SET_USER_DICT_LIST_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}