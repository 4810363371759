import { BACKEND_URL_PREFIX } from "../assets/const";
import { createNotification } from "../components/Notifications/Notifications";
import { checkAndUpdateToken } from "./auth";
import { buildDefaultHeaders } from "./helper";
import { GET_COUNTRIES, GET_EDUCATIONAL_STATUSES, GET_FORMS_OF_EDUCATION, GET_GRADE_OPTIONS, GET_GRADE_WEIGHT, GET_TYPES_STUDY_SESSIONS } from "./types";


//ВСЕ СПРАВОЧНИКИ

// <---- ВЕСА ОЦЕНОК ---->
export const getGradeWeight = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/weight`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      dispatch({ type: GET_GRADE_WEIGHT, payload: result });
    }
  }

};

export const updateGradeWeight = (params, id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/weight/${id}`, {
      method: "PUT",
      body: JSON.stringify(params),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getGradeWeight());
      createNotification('success', 'Успешно');
    } else createNotification('error', 'Не удалось изменить запись');
  };
}

export const createGradeWeight = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/weight`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getGradeWeight());
    } else createNotification('error', "Не удалось создать запись");
  }
};
export const deleteGradeOption = (groupsId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    try {
      const response = await fetch(`${BACKEND_URL_PREFIX}/weight/${groupsId}`, {
        method: "DELETE",
        ...buildDefaultHeaders(getState),
      });
      const result = await response.json();

      if (response.ok) {
        createNotification('success', 'Успешно');
        await dispatch(getGradeWeight());
      }

    }
    catch (error) {
      createNotification('error', error.message);
    }
  }
};

// <----  ВАРИАНТЫ ОЦЕНОК ---->

export const getGradeOptions = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/grade_value`, { //TODO
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      dispatch({ type: GET_GRADE_OPTIONS, payload: result });
    }
  }

};

export const updateGradeOptions = (params) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/grade_value/${params.valid}`, {//TODO
      method: "PUT",
      body: JSON.stringify(params),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getGradeOptions());
    }
  };
}

export const createGradeOptions = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/grade_value`, {//TODO
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = await response.json();
      createNotification('success', 'Успешно');
      await dispatch(getGradeOptions());
    } else {
      const { error } = await response.json();
      createNotification('error', error);
    }
  }
};

export const deleteGradeOptions = (valid) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/grade_value/${valid}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    const result = await response.json();

    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getGradeOptions());
    } else createNotification('error', result.error);
  }
};


// <----  ТИПЫ УЧЕБНЫХ ЗАНЯТИЙ ---->

export const getTypesStudySessions = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/types_training`, { //TODO
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      dispatch({ type: GET_TYPES_STUDY_SESSIONS, payload: result });
    }
  }

};

export const updateTypesStudySessions = (params, id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/types_training/${params.typeid}`, {//TODO
      method: "PUT",
      body: JSON.stringify(params),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getTypesStudySessions());
      createNotification('success', 'Успешно');
    } else createNotification('error', 'Не удалось изменить запись');
  };
}

export const createTypesStudySessions = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/types_training`, {//TODO
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getTypesStudySessions());
      createNotification('success', 'Успешно');
    } else createNotification('error', "Не удалось создать запись");
  }
};

// <---- УЧЕБНЫЕ СТАТУСЫ ---->

export const getEducationalStatuses = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/status`, { //TODO
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      dispatch({ type: GET_EDUCATIONAL_STATUSES, payload: result });
    }
  }

};

export const updateEducationalStatuses = (params) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/status/${params.xp_key}`, {//TODO
      method: "PUT",
      body: JSON.stringify(params),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getEducationalStatuses());
      createNotification('success', 'Успешно');
    } else createNotification('error', "Не удалось создать запись");
  };
}

export const createEducationalStatuses = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/status`, {//TODO
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getEducationalStatuses());
    } else createNotification('error', "Не удалось создать запись");
  }
};

export const deleteEducationalStatuses = (groupsId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/status/${groupsId}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getEducationalStatuses());
      createNotification('success', 'Успешно');
    } else createNotification('error', "Не удалось удалить запись");
  }
};


// <---- СТРАНЫ ---->

export const getCountries = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/countries`, { //TODO
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      dispatch({ type: GET_COUNTRIES, payload: result });
    }
  }

};

// export const updateCountries= (params) => async (dispatch, getState) => {
// await dispatch(checkAndUpdateToken());
// if (getState().auth.token) {

//     const response = await fetch(`${BACKEND_URL_PREFIX}/status/${params.xp_key}`, {//TODO
//       method: "PUT",
//             body: JSON.stringify(params),
//             ...buildDefaultHeaders(getState),
//       });
//       if (response.ok) {      
//         await dispatch(getCountries());
//       }
// };
// }

// export const createCountries = (data) => async (dispatch, getState) => {
//   await dispatch(checkAndUpdateToken());
//   if (getState().auth.token) {
//     const response = await fetch(`${BACKEND_URL_PREFIX}/status`, {//TODO
//       method: "POST",
//       body: JSON.stringify(data),
//       ...buildDefaultHeaders(getState),
//     });
//     if (response.ok) {
//       createNotification('success', 'Успешно');
//       await dispatch(getCountries());
//     } else createNotification('error', "Не удалось создать запись");
//   }
// };

// export const deleteCountries = (groupsId) => async (dispatch, getState) => {
//   await dispatch(checkAndUpdateToken());
//   if (getState().auth.token) {
//     const response = await fetch(`${BACKEND_URL_PREFIX}/status/${groupsId}`, {
//       method: "DELETE",
//       ...buildDefaultHeaders(getState),
//     });
//     if (response.ok) {
//       createNotification('success', 'Успешно');
//       await dispatch(getCountries());
//     }
//   }
// };


// <---- ФОРМЫ ОБУЧЕНИЯ ---->

export const getFormOfEducation = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/study_forms`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());

      dispatch({ type: GET_FORMS_OF_EDUCATION, payload: result });
    }
  }

};

export const updateFormOfEducation = (params) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/study_forms/${params.f_studyform}`, {
      method: "PUT",
      body: JSON.stringify(params),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getFormOfEducation());
      createNotification('success', 'Успешно');
    } else createNotification('error', "Не удалось создать запись");
  };
}

export const createFormOfEducation = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/study_forms`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getFormOfEducation());
    } else createNotification('error', "Не удалось создать запись");
  }
};

export const deleteFormOfEducation = (groupsId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/study_forms/${groupsId}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getFormOfEducation());
      createNotification('success', 'Успешно');
    } else createNotification('error', "Не удалось удалить запись");
  }
};

