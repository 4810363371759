import { api } from "../App";
import { createNotification } from "../components/Notifications/Notifications";
import { SET_TYPICAL_COMMENT_LIST } from "./types";




export const getTypicalComment = () => async (dispatch, getState) => {
  try {
    const data = await api.get('/typical_comment');
    console.log('data::: ', data);
    dispatch({ type: SET_TYPICAL_COMMENT_LIST, payload: data.data });
  } catch (ERR) {
    createNotification('error', ERR.response.data.error)
  } finally {
  }
}

export const createTypicalComment = (data) => async (dispatch, getState) => {
  let success
  try {
    await api.post('/typical_comment', data);
    await dispatch(getTypicalComment())
    success = true
  } catch (ERR) {
  } finally {
    return success
  }
}

export const updateTypicalComment = ({ commentid, ...data }) => async (dispatch, getState) => {
  let success
  try {
    await api.put(`/typical_comment/${commentid}`, data);
    await dispatch(getTypicalComment())
    success = true
  } catch (ERR) {
  } finally {
    return success
  }
}

export const deleteTypicalComment = (id) => async (dispatch, getState) => {
  try {
    await api.delete(`/typical_comment/${id}`);
    await dispatch(getTypicalComment())
  } catch (ERR) {
  } finally {
  }
}