import React, { useEffect } from 'react'
import styles from './styles.module.scss'
import Button from '../../components/Button'
import { IoIosAdd, IoIosClose } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Modal from './Modal'
import { setPage, getScheduleVariantsList, deleteScheduleVariant } from '../../actions/scheduleVariants'
import Select from '../../components/Select'
import Table from '../../components/Table'
import { getSchoolYears } from '../../actions/common'
import Confirmation from '../../components/Confirmation/Confirmation'
import { BiTrash } from 'react-icons/bi';
import { Pagination } from '../../components/Pagination'


export default function ScheduleVariants() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { list, pageNumber, pageCount, loading } = useSelector(state => state.schedule_variants)


  const t = list && [...list]
  const highLow = t?.sort((a, b) => b.sh_var_id - a.sh_var_id)

  const [selectedItem, setSelectedItem] = useState(null)
  const { schoolYears } = useSelector(state => state.common)

  const [scYear, setScYear] = useState('15')
  const [variantForDelete, setVariantForDelete] = useState(null)

  useEffect(() => {
    dispatch(getSchoolYears())
  }, [])

  useEffect(() => () => dispatch(setPage(0)), [])

  useEffect(() => {
    if (!scYear) return;
    dispatch(getScheduleVariantsList(scYear))
  }, [pageNumber, scYear])

  const sortedYear = scYear !== 0 ? highLow : highLow?.filter(el => el.s_year_id == (schoolYears.find(el => el.xp_key == scYear)?.xp_key))

  const openModal = (data) => () => {
    setSelectedItem(data || {})
  }

  const closeModal = () => setSelectedItem(null)

  const goHome = () => navigate('/')

  const handleInputChange = (e) => { setScYear(e.target.value) }

  const renderLi = (data) => {
    const { sh_var_id, s_year_id, sh_var_name, sh_var_comments } = data
    return (
      <tr onClick={openModal(data)}>
        <td>{schoolYears.find((el) => el.xp_key === s_year_id)?.name}</td>
        <td>{sh_var_name}</td>
        <td>{sh_var_comments}</td>
        <td onClick={deleteClickHandler(sh_var_id)}><i className={styles.remove}><BiTrash /></i>
        </td>

      </tr>
    )
  }

  const renderOption = (option, index) => {
    const { xp_key, value, trmid, cid, gid, work_status, id, pmid, did, name, title, year, access, } = option
    const optionValue = xp_key || value || trmid || cid || gid || work_status || id || pmid || did
    const optionTitle = name || title || value || year || work_status || access
    return <option key={`${optionValue}-${index}`} value={optionValue}>{optionTitle}</option>
  }

  const deleteClickHandler = (scVarId) => (e) => {
    e.stopPropagation()
    setVariantForDelete(scVarId)
  }

  const acceptConfirm = () => {
    dispatch(deleteScheduleVariant(variantForDelete, scYear))
    setVariantForDelete(null)
  }

  const rejectConfirm = () => {
    setVariantForDelete(null)
  }

  const pageClick = ({ selected }) => dispatch(setPage(selected))

  return (
    <div className={styles.wrapper}>
      {variantForDelete &&
        <Confirmation
          title='Вы уверены что хотите удалить вариант расписания?'
          acceptConfirmation={acceptConfirm}
          rejectConfirmation={rejectConfirm}
        />
      }
      <Modal
        closeModal={closeModal}
        data={selectedItem}
        yearId={scYear}
      />
      <h1>Варианты расписания</h1>
      <Select
        title="Учебный год"
        name={"scYear"}
        options={[{ name: "Все", value: '0' }, ...schoolYears]}
        value={scYear}
        withoutArrowMode
        onChange={handleInputChange}
      />

      <Pagination
        pageCount={pageCount}
        pageNumber={pageNumber}
        onPageChange={pageClick}
        loading={loading}
      >
        <div style={{ marginTop: '2rem', backgroundColor: 'white' }}>
          <Table className={styles.table}>
            <thead>
              <tr>
                <th>Уч.год</th>
                <th>Вариант</th>
                <th>Комментарий</th>
                <th />
              </tr>
            </thead>
            <tbody>

              {sortedYear?.map(renderLi)}

            </tbody>
          </Table>
        </div>
      </Pagination>

      <Button
        style={{ padding: '.5rem 1.5rem' }}
        onClick={openModal()}
      >
        <IoIosAdd size={30} />
        Добавить вариант
      </Button>
    </div>
  )
}

