import React, { useEffect, useState } from 'react'
import styles from './index.module.css'
import { Input } from '../../../components/ModalInputs'
import SelectPal from '../../../components/Select copy'
import { streams, } from '../../../assets/const'
import { useSelector } from 'react-redux'
import { arrayForSelect } from '../../../utils/arrayForSelect'



const Index = ({ data, values, setFieldValue, handleChange, addMode, error }) => {

    const { teachers, schoolYears } = useSelector(state => state.common)

    // TODO переделать - при переходе падает перерисовка в селекте 
    const [arr, setArr] = useState([])

    const uniqueTeachers = teachers?.teachers && Array.from(new Set(teachers?.teachers?.map(item => item.mid)))?.map(mid => teachers?.teachers?.find(item => item.mid === mid));

    const [val, setVal] = useState([])

    useEffect(() => {
        // teachers && setArr(teachers?.teachers)
        teachers && setVal(arrayForSelect(uniqueTeachers, 'calc_fio', 'mid'))
        values && setArr(values)
    }, [values])

    // TODO переделать - при переходе падает перерисовка в селекте 

    const { chairman, deputy, deputy2, secretary, school_year, stream, phone, positivedecision, negativedecision, chairman_name, deputy_name } = arr

    return (
        !!teachers &&
        <>
            <SelectPal
                title='Год набора'
                valuesName="school_year"
                options={schoolYears && arrayForSelect(schoolYears, 'name', 'xp_key')}
                width='120px'
                value={data.year_name}
                setFieldValue={setFieldValue}
                disabled={addMode ? false : true}
            />
            <SelectPal
                valuesName="stream"
                options={streams}
                width='300px'
                setFieldValue={setFieldValue}
                value={streams.find(el => el.value == stream)?.name}
            // disabled={data.type === 1 ? false : true}
            />
            <SelectPal
                title="Председатель приёмной комиссии"
                valuesName="chairman"
                value={chairman}
                // options={arr && arrayForSelect(uniqueTeachers, 'calc_fio', 'mid')}
                options={val}
                setFieldValue={setFieldValue}
                width='300px'
            />
            < SelectPal
                title="Заместитель председателя приёмной комиссии"
                valuesName="deputy"
                value={deputy || deputy_name}
                options={val}
                width='300px'
                setFieldValue={setFieldValue}
            />
            < SelectPal
                title="Заместитель председателя приёмной комиссии"
                valuesName="deputy2"
                value={deputy2}
                options={val}
                width='300px'
                setFieldValue={setFieldValue}
            />
            < SelectPal
                title="Секретарь приёмной комиссии"
                valuesName="secretary"
                value={secretary}
                options={val}
                width='300px'
                setFieldValue={setFieldValue}
            />

            <div className={styles.inputsRow}>
                <Input
                    label='Телефон приёмной комиссии'
                    name='phone'
                    value={phone}
                    error={error('phone')}
                    onChange={handleChange}
                    style={{ width: '600px' }}
                />

            </div>
            <div className={styles.inputsRow}>
                <Input
                    label='Решение приемной комиссии в случае прохождения ВИ'
                    name='positivedecision'
                    value={positivedecision}
                    error={error('positivedecision')}
                    onChange={handleChange}
                    style={{ width: '600px' }}
                />

            </div>
            <div className={styles.inputsRow}>
                <Input
                    label='Решение приемной комиссии в случае не прохождения ВИ'
                    name='negativedecision'
                    value={negativedecision}
                    error={error('negativedecision')}
                    onChange={handleChange}
                    style={{ width: '600px' }}
                />


            </div>

        </>

    )
}

export default Index