import React, { useEffect, useState } from 'react'
import Modal from 'react-responsive-modal'
import { useDispatch, useSelector } from 'react-redux'
import styles from './styles.module.css'
import { useFormik } from 'formik'
import { Checkbox, CreatableSelect, Input, Select } from '../../components/ModalInputs'
import * as Yup from 'yup'
import ModalFooter from '../../components/ModalFooter/ModalFooter'
import { createManners, createSubgroup, getSubgroupGroupuser, getSubgroupTablesList, updateManners, updateSubgroup } from '../../actions/directoryBasicEducation'
import moment from 'moment'
import ModalHistory from '../../components/ModalHistory/ModalHistory'
import { getGroupHistory } from '../../actions/groups'
import Button from '../../components/Button'
import { History } from '../Classing/ModalTabs'
import { ClassingContext } from '../../utils/context'
import Table from '../../components/Table'
import { getLanguages, getShifts, getSpecializations, getStudyForms, getStudyModes, getStudyProfiles, getStudySteps } from '../../actions/common'
import { IoIosAdd } from 'react-icons/io'

const validationSchema = Yup.object({
  name: Yup.string().required('обязательное поле').max(30, 'Должно быть не более 30 символов'),

})

const PARALLELS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']

export default function ModalComponent({ data, closeModal, height, addMode, classGroup, filters, electiveCourse }) {
  console.log('addMode::: ', addMode);
  console.log('data::: ', data);

  /// TODO  не приходит с объектом gid

  const dispatch = useDispatch()
  const [main, setMain] = useState(true)
  const [activeTab, setActiveTab] = useState(1); // 1 - Main, 2 - Equipment

  const { groupHistory, modalLoading: loading } = useSelector(state => state.groups)
  const { specializations, languages, studyProfiles, studySteps, shifts, studyForms, studyModes } = useSelector(state => state.common)
  const { stageStudyList } = useSelector(state => state.stages_study)
  const { subgroup } = useSelector(state => state.directoryBasicEducation)
  const [userModal, setUserModal] = useState()
  const gid = classGroup && classGroup[0].gid

  const [parellel, setParellel] = useState(false)
  const [parallels, setParallels] = useState([])

  useEffect(() => {
    gid && dispatch(getGroupHistory(gid))
    dispatch(getSubgroupGroupuser({ cid: filters.cid, gid: data.gid }))
    dispatch(getSpecializations())
    dispatch(getLanguages())
    dispatch(getStudyProfiles())
    dispatch(getStudyModes())
    dispatch(getStudyForms())
    dispatch(getStudySteps())
    dispatch(getShifts())
  }, [gid])

  const handleCreate = (values) => {
    dispatch(createSubgroup({ ...values, owner_gid: filters.gid }))
    closeModal()
    dispatch(getSubgroupTablesList({ gid: filters.gid, xp_key: filters.xp_key, cid: filters.cid, }))
  }

  const handleUpdate = (values) => {
    dispatch(updateSubgroup({ ...values, owner_gid: filters.gid, gid: data.gid }))
    closeModal()
  }

  const { journalControlCourses } = useSelector(state => state.directoryBasicEducation)

  const {
    values,
    errors,
    touched,
    dirty, // проверяет есть ли изменения
    submitCount,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: subgroup,
    enableReinitialize: true,
    validateOnChange: true,
    // validationSchema: validationSchema,
    onSubmit: addMode ? handleCreate : handleUpdate
  })
  console.log('values::: ', values);
  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

  const renderOptions = ({ name, xp_key, gid, f, title, cid, f_studysteps, f_studyform }) => <option value={gid || xp_key || cid || f_studyform || f_studysteps}>{name || f || title}</option>

  const openUserModal = () => {
    setUserModal(true)
  }

  useEffect(() => {
    setActiveTab(1)
  }, [data])


  const handleTab = (tab) => {
    setActiveTab(tab);
  }
  const handleParallels = (e) => {
    if (parallels?.find(el => el === e.target.value))
      setParallels(parallels.filter(el => el !== e.target.value))
    else setParallels([...parallels, e.target.value])
  }

  return (
    <Modal
      open={!!data}
      onClose={() => false}
      showCloseIcon={false}
      height={height}
      classNames={{
        // overlay: styles.overlay,
        modal: styles.custom
      }}
      center
    >
      <>

        <div className={styles.modalMain}>
          <div className={styles.tabsHeader}>
            <div className={styles.title}>{data?.gid
              ? 'Редактирование'
              : 'Добавление'}</div>
            <div className={styles.tabs}>
              <Button mode={activeTab === 1 ? '' : 'white'} onClick={() => handleTab(1)}>Главная</Button>
              {data.gid && <Button mode={activeTab === 2 ? '' : 'white'} onClick={() => handleTab(2)}>Состав</Button>}
              <Button mode={activeTab === 3 ? '' : 'white'} onClick={() => handleTab(3)}>История</Button>
            </div>
            {/* {getTab(activeTab)} */}
          </div>
        </div>
        <div className={styles.modalLocal} >
          <div className={styles.tabWrapper} >
            {activeTab == 1 &&
              values &&
              <>
                <div className={styles.inputsRow}>
                  <Input
                    label='Название'
                    name='group_name'
                    value={values.group_name || values.name}
                    error={error('group_name')}
                    onChange={handleChange}
                    style={{ width: '600px' }}
                  />

                </div>
                <div className={styles.inputsRow}>
                  <Input
                    label='Короткое название'
                    name='shortname'
                    value={values.shortname}
                    // error={error('checktext')}
                    onChange={handleChange}
                    style={{ width: '600px' }}
                  />

                </div>
                <div className={styles.inputsRow}>


                  <div className={styles.inputsColumn} >

                    {/* // TODO сделать редактируемыми */}
                    <Select
                      label='Специализация'
                      name='specialization'
                      value={values?.specialization}
                      onChange={handleChange}
                      style={{ width: '300px' }}
                    >
                      {specializations.map(item => <option>{item}</option>)}
                      {/*                     
                    <option value=''>{values?.group}</option>
                    {specializations?.map(renderOptions)} */}
                    </Select>

                    {/* <CreatableSelect
                    label='Язык обучения'
                    name='language'
                    value={values?.language}
                    onChange={handleChange}
                    // options={languages}
                    style={{ width: '300px' }}
                  >
                    {languages.map(item => <option>{item}</option>)}
                  </CreatableSelect> */}
                    <Select
                      label='Язык обучения'
                      name='language'
                      value={languages.find(el => el == values?.language)}
                      onChange={handleChange}
                      // options={languages}
                      style={{ width: '300px' }}
                    >
                      {languages.map(item => <option>{item}</option>)}
                    </Select>

                    <Select
                      label='Режим обучения'
                      name='study_mode'
                      value={values?.study_mode}
                      onChange={handleChange}
                      options={studyModes}
                      style={{ width: '300px' }}
                    >
                      {studyModes.map(item => <option>{item}</option>)}
                      {/* <option value=''>{values?.group}</option>
                    {studyModes?.map(renderOptions)} */}
                    </Select>
                    {/* // TODO сделать редактируемыми */}


                    <Select
                      label='Смена'
                      name='shift'
                      value={values?.shift}
                      onChange={handleChange}
                      options={classGroup}
                      style={{ width: '300px' }}
                    >
                      <option value=''>{shifts?.group}</option> // TODO
                      {shifts?.map(renderOptions)}
                    </Select>
                    <Select
                      label='Профиль'
                      name='calc_training_profile'
                      value={values?.calc_training_profile}
                      onChange={handleChange}
                      options={studyProfiles}
                      style={{ width: '300px' }}
                    >
                      <option value=''>{values?.group}</option> // TODO
                      {studyProfiles?.map(renderOptions)}
                    </Select>
                    <Select
                      label='Форма обучения'
                      name='f_studyform'
                      value={values?.f_studyform}
                      onChange={handleChange}
                      options={studyForms}
                      style={{ width: '300px' }}
                    >
                      <option value=''>{values?.group}</option> // TODO
                      {studyForms?.map(renderOptions)}
                    </Select>
                    <Select
                      label='Ступень обучения'
                      name='f_studysteps'
                      value={values?.f_studysteps}
                      onChange={handleChange}
                      options={studySteps}
                      style={{ width: '300px' }}
                    >
                      <option value=''>{values?.group}</option> // TODO
                      {studySteps?.map(renderOptions)}
                    </Select>
                  </div>
                  <div className={styles.inputsColumn} >
                    {electiveCourse &&
                      <div className={styles.inputsColumnYears}>
                        <p>Года обучения</p>
                        {PARALLELS?.map(par => {
                          const che = parallels?.find(el => +par == +el)
                          return (
                            <Checkbox
                              label={par}
                              checked={!!che}
                              value={par}
                              onChange={(e) => handleParallels(e)}
                              name='parallels'
                            // disabled={disabledList2?.find(el => el == par)}
                            />
                          )
                        }
                        )}
                      </div>
                    }
                  </div>
                </div>
              </>
            }
            {activeTab === 2 &&

              <div>
                <Table className={styles.tables}>
                  <thead>
                    <tr>
                      <th>Ученица</th>
                      <th>Отчислить</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* 
          {console.log('tableList::: ', tableList)}
          {tableList && tableList.map(renderLi)} */}
                  </tbody>
                </Table>
                <div className={styles.inputsRow}>
                  <Button
                    style={{ padding: '.5rem 1.5rem', margin: '12px' }}
                    onClick={() => openUserModal()}
                  >
                    <IoIosAdd size={30} />
                    Зачислить
                  </Button>
                  <div style={{ width: '200px' }}> </div>
                  <Input
                    label='Дата'
                    name='date'
                    value={values.date}
                    onChange={handleChange}
                    type='date'
                  />
                </div>
              </div>
            }
            {activeTab === 3 &&
              <ClassingContext.Provider value={{ classId: data.gid, }}>
                <History subGroups />
              </ClassingContext.Provider>
            }
          </div>
        </div>

        <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
      </>
      {userModal &&
        <Modal
          open={userModal}
          onClose={() => setUserModal(false)}
          // showCloseIcon={false}
          height={height}
          classNames={{
            // overlay: styles.overlay,
            modal: styles.custom
          }}
          center>
          <div>
            <div className={styles.inputsRow}>
              <Input
                label='Название'
                name='group_name'
                value={values.group_name}
                error={error('group_name')}
                onChange={handleChange}
                style={{ width: '600px' }}
              />

            </div>
            <div className={styles.inputsRow}>
              <Input
                label='Короткое название'
                name='shortname'
                value={values.shortname}
                // error={error('checktext')}
                onChange={handleChange}
                style={{ width: '600px' }}
              />

            </div>
          </div>
          <ModalFooter handleSubmit={handleSubmit} closeModal={() => setUserModal(false)} />
        </Modal>
      }
    </Modal >

  )
}
