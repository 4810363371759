import React, { useEffect } from 'react'
import styles from './styles.module.scss'
import { Input, Select, Checkbox, } from '../../../components/ModalInputs'
// import { Input, } from '../../../components/Input'
import { admissionYears, cities, countries, genders, sex, sportGrade, streams, documenttype, docTypes, armyDistricts } from '../../../assets/const'
import { useContext } from 'react'
import { CandidatesContext, } from '../../../utils/context'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../components/Button'
import { useFormik } from 'formik'



const studentHealth = [
  { name: 'Первая', id: 1 },
  { name: 'Вторая', id: 2 },
  { name: 'Третья', id: 3 },
  { name: 'Специальная', id: 4 }
]

const studentBloodGroup = [
  { name: 'O (I)', id: 1 },
  { name: 'A (II)', id: 2 },
  { name: 'B (III)', id: 3 },
  { name: 'AB (IV)', id: 4 }
]
const studentFizGroup = [
  { name: 'I группа', id: 1 },
  { name: 'II группа', id: 2 },
  { name: 'III группа', id: 3 },
  { name: 'IV группа', id: 4 }
]
const studentRezus = [
  { name: 'Rh-', id: 1 },
  { name: 'Rh+', id: 2 },

]

export default function Main() {

  const dispatch = useDispatch()

  const { student, closeModal, studentData } = useContext(CandidatesContext)
  console.log('studentData::: ', studentData);
  const { schoolYears, countries, subjects, schools, familyStatuses, familyCompos } = useSelector(state => state.common)
 

  const renderOptions = ({ name, value, xp_key, subject, idschool, schoolname }) => (
    <option value={value || xp_key || idschool}>
      {name || subject || schoolname}
    </option>
  )
  const {
    values,
    errors,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: {},
    enableReinitialize: true,
    onSubmit: console.log
  })
  console.log('values::: ', values);
  return (
    student &&
    <>
      <div className={styles.modalMain}>

        <div className={styles.tabWrapper}>

          <div className={styles.inputsRow}>
            <Input
              label='ФИО'
              value={student?.fio}
              style={{ width: '400px' }}
              disabled
            />
          </div>


          <div className={styles.inputsRow}>
            <Input label='Номер' disabled />
            <Input
              label='Логин'
              name='login'
              value={student.login}
              // onChange={mainForm.handleChange}
              disabled
            />
          </div>
          <div className={styles.inputsRow}>
            <Input label='Литер' disabled />
            <Input
              label='Логин родителей'
              name='login_parent'
              value={student.login_parent}
              // onChange={mainForm.handleChange}
              disabled
            />
          </div>

          <div className={styles.inputsRow}>
            <Select
              label='Пол'
              value={student.sex}
              name='sex'
              // onChange={mainForm.handleChange}
              disabled
            >
              {sex.map(renderOptions)}
            </Select>
            <Input
              label='Дата рождения'
              type='date'
              value={student.birthdate}
              name='birthdate'
              // onChange={student.handleChange}
              disabled
            />
            <Checkbox label='Годен к строевой службе' disabled />
          </div>
          {/* ///  */}
          {/* ///  */}
          {/* ///  */}
          <div className={styles.inputsRow}>
            <Select
              label='Группа здоровья'
              value={values.healthGroup}
              name='healthGroup'
              onChange={handleChange}
            >
              {studentHealth.map(renderOptions)}
            </Select>

            <Select
              label='Группа крови'
              value={values.bloodGroup}
              name='bloodGroup'
              onChange={handleChange}
            >
              {studentBloodGroup.map(renderOptions)}
            </Select>
            <Select
              label='Резус-фактор'
              value={values.rezus}
              name='rezus'
              onChange={handleChange}
            >
              {studentRezus.map(renderOptions)}
            </Select>
          </div>
          <div className={styles.inputsRow}>
            <Input
              label='Индекс физического здоровья'
              type='number'
              value={values.fizZdor}
              name='fizZdor'
              onChange={handleChange} />
            <Input
              label='Уровень физической подг.'
              type='number'
              value={values.levelFizTraining}
              name='levelFizTraining'
              onChange={handleChange} />
          </div>

          {/* /// */}
          {/* ///  */}
          {/* ///  */}


          <div className={styles.inputsRow}>
            <Input style={{ width: '700px' }} label='Место рождения' disabled />
          </div>
          <div className={styles.inputsRow}>
            <Select
              style={{ width: '380px' }}
              value={student.idcountry}
              label='Страна'
              name='idcountry'
              // onChange={mainForm.handleChange}
              disabled
            >
              {countries?.map(renderOptions)}
            </Select>
            <Select
              style={{ width: '180px' }}
              value={student.citizen}
              label='Гражданство'
              name='citizen'
              // onChange={mainForm.handleChange}
              // {countries?.map(renderOptions)}
              disabled
            >
            </Select>
          </div>
          <div className={styles.inputsRow}>
            <Select
              label='Субъект РФ'
              value={student.subject}
              name='subject'
              // onChange={mainForm.handleChange}
              disabled
            >
              {subjects?.map(renderOptions)}
            </Select>

          </div>


          <div className={styles.inputsRow}>
            <Input label='Индекс' disabled />
            <Input style={{ width: '400px' }} label='Нас. пункт' disabled />

          </div>
          <div className={styles.inputsRow}>
            <Input style={{ width: '700px' }} label='Адрес проживания' disabled />
          </div>
          <div className={styles.inputsRow}>
            <Input label='Дом. телефон' disabled />
            <Input label='Моб. телефон' disabled />
            <Input label='IMEI' disabled />
          </div>
          <div className={styles.inputsRow}>
            <Input label='Оператор сотовой связи ' disabled />
            <Input label='Модель телефона' disabled />
            <Input label='E-mail' disabled />
          </div>

          <div className={styles.inputsRow}>
            <Input label='E-mail родителей' disabled />
            <Input label='Skype' disabled />
          </div>
          <h3>Регистрационные данные</h3>
          <div className={styles.inputsRow}>
            <Input label='Адрес регистрации' disabled />
          </div>
          <div className={styles.inputsRow}>
            <Select label='Тип документа' disabled>{docTypes.map(renderOptions)}</Select>
            <Input label='Серия' disabled />
            <Input label='Номер' disabled />
          </div>
          <div className={styles.inputsRow}>
            <Input label='Кем выдан' disabled />
            <Input
              label='Когда выдан'
              type='date'
              value={student.date_for_passport}
              name='date_for_passport'
              disabled
            // onChange={mainForm.handleChange}
            />
          </div>
          <h3>Прочее</h3>
          <div className={styles.inputsRow}>
            <Select label='Тип восприятия информации' disabled>{sex.map(renderOptions)}</Select>
          </div>
          <div className={styles.inputsRow}>
            <Input label='Примечание' disabled />
            <Input label='S/N ноутбука' disabled />
            <Input label='Инв № ноутбука' disabled />
          </div>
          <div className={styles.inputsRow}>
            <Input label='№ удостоверения учащегося' disabled />
            <Input label='RFID метка' disabled />
            <Input label='' disabled />
          </div>
          {/* /// */}
          {/* ///  */}
          {/* ///  */}
          <div className={styles.inputsRow}>
            <Select
              label='Физкультурная группа'
              value={values.fizCultGroup}
              name='fizCultGroup'
              onChange={handleChange}
            >
              {studentFizGroup.map(renderOptions)}
            </Select>
            <Input
              label='№ парты'
              type="number"
              value={values.numDesk}
              name='numDesk'
              onChange={handleChange}
            />
            <Input
              label='Рекомендации врача для учителя'
              value={values.recomendDocToTeacher}
              name='recomendDocToTeacher'
              onChange={handleChange}
            />
            {/* /// */}
            {/* ///  */}
            {/* ///  */}
            {console.log('values::: ', values)}
          </div>
        </div>

      </div>

      <div className={styles.modalFooter}>
        <div>
          <div>Создал: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
          <div>Изменил: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
        </div>
        <Button mode='white' onClick={closeModal}>Сохранить</Button>
        <Button mode='white' onClick={closeModal}>Закрыть</Button>
      </div>

    </>

  )
}
