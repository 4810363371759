import React from 'react'
import styles from '../styles.module.css'

export default function Summary({ active }) {
  return (
    <div >
      Summary
    </div>
  )
}
