import { BACKEND_URL_PREFIX, itemsOnPage } from "../assets/const";
import { createNotification } from "../components/Notifications/Notifications";
import { checkAndUpdateToken } from "./auth";
import { buildDefaultHeaders } from "./helper";
import {
  SET_SCHOOL_YEARS_LOADING,
  SET_SCHOOL_YEARS_LIST,
  SET_CURRENT_SCHOOL_YEAR,
  SET_SCHOOL_YEARS_COUNT,
  SET_SCHOOL_YEARS_PAGE
} from "./types";


export const setPage = (pageNumber) => ({ type: SET_SCHOOL_YEARS_PAGE, payload: pageNumber })

export const getSchoolYearsCount = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/school_year/count`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const { cnt: count } = await response.json();
      const pageCount = Math.ceil(count / itemsOnPage)
      const payload = { pageCount, count }
      dispatch({ type: SET_SCHOOL_YEARS_COUNT, payload });
    }
  }
};

export const getSchoolYearsList = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_SCHOOL_YEARS_LOADING, payload: true });
    await dispatch(getSchoolYearsCount())
    const { pageNumber } = getState().school_years
    const limit = itemsOnPage
    const offset = itemsOnPage * pageNumber
    const urlParams = new URLSearchParams({ limit, offset }).toString();
    const response = await fetch(`${BACKEND_URL_PREFIX}/school_year?${urlParams}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_SCHOOL_YEARS_LIST, payload: payload.school_year });
    }
  }
  dispatch({ type: SET_SCHOOL_YEARS_LOADING, payload: false });
};

export const getCurrentSchoolYear = (yearId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_SCHOOL_YEARS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/school_year/${yearId}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_CURRENT_SCHOOL_YEAR, payload });
    }
  }
  dispatch({ type: SET_SCHOOL_YEARS_LOADING, payload: false });
};

export const deleteSchoolYear = (yearId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_SCHOOL_YEARS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/school_year/${yearId}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    const result = await response.json();
    if (response.ok) {
      createNotification('success', 'Успешно');
      const { list, pageNumber } = getState().school_years
      list.length < 2 && dispatch(setPage(pageNumber - 1))
      await dispatch(getSchoolYearsList());
    } else createNotification('error', result.error);
  }
  dispatch({ type: SET_SCHOOL_YEARS_LOADING, payload: false });
};

export const updateSchoolYear = (yearId, data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_SCHOOL_YEARS_LOADING, payload: true });
    try {
      const response = await fetch(`${BACKEND_URL_PREFIX}/school_year/${yearId}`, {
        method: "PUT",
        body: JSON.stringify(data),
        ...buildDefaultHeaders(getState),
      });


      if (response.ok) {
        await dispatch(getSchoolYearsList());
        createNotification('success', 'Успешно обновлен учебный год');
      }
      const result = await response.json();
      createNotification('error', result.error[0].msg);
    } catch (ERR) {
      console.log(ERR)
      // createNotification('error', result.error);

    }
  }
  dispatch({ type: SET_SCHOOL_YEARS_LOADING, payload: false });
};

export const createSchoolYear = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_SCHOOL_YEARS_LOADING, payload: true });
    try {
      const response = await fetch(`${BACKEND_URL_PREFIX}/school_year`, {
        method: "POST",
        body: JSON.stringify(data),
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        await dispatch(getSchoolYearsList());
        createNotification('success', 'Успешно создан учебный год');
      }
      const result = await response.json();
      createNotification('error', result.error[0].msg);
    } catch (ERR) {
      console.log(ERR)
      // createNotification('error', 'Не удалось создать учебный год');
      //TODO отработка ошибки с пересечением по др годам LMSTWO-300
    }
  }
  dispatch({ type: SET_SCHOOL_YEARS_LOADING, payload: false });
};
