import React, { useEffect } from 'react'
import styles from './styles.module.css'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Modal from './Modal'
import Table from '../../components/Table'
import { BiTrash } from 'react-icons/bi'
import Confirmation from '../../components/Confirmation/Confirmation'
import { deleteManners, getSubgroupById, getSubgroupCoursesList, getSubgroupList, getSubgroupTablesList, } from '../../actions/directoryBasicEducation'
import { useFormik } from 'formik'
import { createAdmissionComitet, deleteAdmissionComitet, getAdmissionById, getAdmissionDisciplines, getAdmissionDisciplinesForSelect, getAdmissionsList, updateAdmissionComitet } from '../../actions/admissions'
import { streams, } from '../../assets/const'
import { getPeoples } from '../../actions/disciplineStructure'
import { getTeachersList } from '../../actions/common'
import { getPersonnelList } from '../../actions/personnel'
import { IoIosAdd } from 'react-icons/io'
import Button from '../../components/Button'
import { removeUnnecessaryKey } from '../../utils/smallHelpers'
import { getLessonAdmissions } from '../../actions/classbook'
import * as Yup from 'yup'


const validationSchema = Yup.object().shape({
  negativedecision: Yup.string().max(100, 'Должно быть не более 100 символов'),
  positivedecision: Yup.string().max(100, 'Должно быть не более 100 символов'),
  phone: Yup.number().typeError('Пожалуйста, введите число').nullable().max(99999999999, 'Должно быть не более 11 символов'),

})

export default function Admissions() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { tableList, subgroup, subgroupClasses, subgroupCourses } = useSelector(state => state.directoryBasicEducation)

  const { admissions_list, admissionById } = useSelector(state => state.admissions)

  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(false);
  /// не совпадают содержание и найминг 
  const [data, setData] = useState();
  const [create, setCreated] = useState(false)
  const [hasMounted, setHasMounted] = useState(false);

  const keysToDelete = ['chairman_name', 'deputy_name', 'deputy_name2', 'exams', 'members', 'secretary_name', 'secretary_name2']

  useEffect(() => {
    dispatch(getAdmissionsList())
    dispatch(getLessonAdmissions())
  }, [])
  useEffect(() => {
    if (!hasMounted) return;
  }, [admissions_list])

  useEffect(() => {
    create && admissions_list && setSelectedItem(admissions_list[admissions_list.length - 1]) // при создании переход в редактирование - криво агрится на список приходящий
  }, [admissions_list])

  useEffect(() => {
    selectedItem?.id && dispatch(getAdmissionById(selectedItem.id)) && dispatch(getAdmissionDisciplines({ school_year: selectedItem.year_id, stream: selectedItem.stream }))
    data && !data.id && resetForm()
    dispatch(getTeachersList())
    // dispatch(getPersonnelList('Работает'))

  }, [selectedItem])


  useEffect(() => {
    setHasMounted(true);
  }, []);
  useEffect(() => {

  }, []);

  useEffect(() => {
    if (!hasMounted) return;
    // Your effect code here
  }, [admissions_list]);

  const handleCreate = (values) => {
    // closeModal()
    dispatch(createAdmissionComitet(removeUnnecessaryKey({ ...values }, ...keysToDelete)))
    setCreated(values)
  }

  const handleUpdate = (values) => {
    dispatch(updateAdmissionComitet((removeUnnecessaryKey({ ...values }, ...keysToDelete))))
    closeModal()
    setSelectedItem(null)
    setCreated(null)
  }

  const {
    values,
    errors,
    touched,
    dirty, // проверяет есть ли изменения
    submitCount,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: !!selectedItem?.id ? admissionById :
      {}, // беру полную инфу на комиссию для все вкладок
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: !selectedItem?.id ? handleCreate : handleUpdate
  })

  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

  const openModal = (data) => () => {
    setSelectedItem(data || {})
  }

  const closeModal = () => {
    setSelectedItem(null)
  }

  const goHome = () => navigate('/')

  const acceptConfirmation = () => {
    closeModal();
    dispatch(deleteAdmissionComitet(confirmation.id));
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  const renderLi = (data) => {
    const { chairman, deputy, secretary, id, year_name, stream } = data
    const correctStream = streams.find(el => el.value == stream)?.name

    return (
      <tr key={id} >
        <td style={{ width: '120px' }} onClick={openModal(data)}>{year_name}</td>
        <td onClick={openModal(data)}>{correctStream}</td>
        <td onClick={openModal(data)}>{chairman}</td>
        <td onClick={openModal(data)}>{deputy}</td>
        <td onClick={openModal(data)}>{secretary}</td>
        <td><i className={styles.trash} onClick={() => setConfirmation({ id: id, name: chairman })}><BiTrash /></i></td>
      </tr>
    )
  }


  return (
    <div className={styles.wrapper} style={{ width: '1200px', margin: '0 auto' }}>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
          acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      {selectedItem &&
        <Modal
          closeModal={closeModal}
          data={selectedItem}
          height={'430px'}
          addMode={!selectedItem.id}
          values={values}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          error={error}
        // filters={values}
        // handleSubmit={handleSubmit}
        // handleCreate={handleCreate}
        />
      }
      <h1>Приемные комиссии</h1>


      <Table className={styles.tables}>
        <thead>
          <tr>
            <th>Год набора</th>
            <th>Набор</th>
            <th>Начальник</th>
            <th>Заместитель</th>
            <th>Секретарь</th>
            <th>Удалить</th>
          </tr>
        </thead>
        <tbody>
          {admissions_list && admissions_list.map(renderLi)}
        </tbody>
      </Table>
      {/* } */}

      <div style={{ display: 'flex', flexDirection: "row" }}>
        <Button
          style={{ padding: '.5rem 1.5rem', margin: '12px' }}
          onClick={openModal()}
        >
          <IoIosAdd size={30} />
          Добавить
        </Button>
      </div>
    </div>
  )
}
