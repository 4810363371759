import { api } from "../App";
import { objFilter } from "../utils/smallHelpers";
import {
  SET_ENROLL_QUESTIONS_LIST,
  SET_ENROLL_QUESTIONS_LOADING,
  SET_ENROLL_QUESTIONS_BLOCKS,
  SET_ENROLL_QUESTIONS_LESSONS,
  SET_ENROLL_QUESTIONS_LEVELS,
  SET_ENROLL_QUESTIONS_DATA
} from "./types";


export const getEnrollQuestions = (_params) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_ENROLL_QUESTIONS_LOADING, payload: true });
    const params = objFilter(_params)
    const { data } = await api.get('/enroll_questions', { params });
    dispatch({ type: SET_ENROLL_QUESTIONS_LIST, payload: data });
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_ENROLL_QUESTIONS_LOADING, payload: false });
  }
}

export const getEnrollQuestionData = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_ENROLL_QUESTIONS_LOADING, payload: true });
    const { data } = await api.get(`/enroll_questions/${id}`);
    dispatch({ type: SET_ENROLL_QUESTIONS_DATA, payload: data });
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_ENROLL_QUESTIONS_LOADING, payload: false });
  }
}

export const createEnrollQuestion = (data, filters) => async (dispatch, getState) => {
  let success
  try {
    dispatch({ type: SET_ENROLL_QUESTIONS_LOADING, payload: true });
    await api.post('/enroll_questions', data);
    await dispatch(getEnrollQuestions(filters))
    success = true
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_ENROLL_QUESTIONS_LOADING, payload: false });
    return success
  }
}

export const updateEnrollQuestion = (id, data, filters) => async (dispatch, getState) => {
  let success
  try {
    dispatch({ type: SET_ENROLL_QUESTIONS_LOADING, payload: true });
    await api.put(`/enroll_questions/${id}`, data);
    await dispatch(getEnrollQuestions(filters))
    success = true
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_ENROLL_QUESTIONS_LOADING, payload: false });
    return success
  }
}

export const deleteEnrollQuestion = (id, filters) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_ENROLL_QUESTIONS_LOADING, payload: true });
    await api.delete(`/enroll_questions/${id}`);
    await dispatch(getEnrollQuestions(filters))
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_ENROLL_QUESTIONS_LOADING, payload: false });
  }
}

export const getEnrollQuestionBlocks = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_ENROLL_QUESTIONS_LOADING, payload: true });
    const { data } = await api.get('/enroll_questions/blocks');
    dispatch({ type: SET_ENROLL_QUESTIONS_BLOCKS, payload: data });
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_ENROLL_QUESTIONS_LOADING, payload: false });
  }
}

export const getEnrollQuestionLessons = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_ENROLL_QUESTIONS_LOADING, payload: true });
    const { data } = await api.get('/enroll_questions/lessons');
    dispatch({ type: SET_ENROLL_QUESTIONS_LESSONS, payload: data });
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_ENROLL_QUESTIONS_LOADING, payload: false });
  }
}

export const getEnrollQuestionLevels = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_ENROLL_QUESTIONS_LOADING, payload: true });
    const { data } = await api.get('/enroll_questions/levels');
    dispatch({ type: SET_ENROLL_QUESTIONS_LEVELS, payload: data });
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_ENROLL_QUESTIONS_LOADING, payload: false });
  }
}
