import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCandidatesRate } from '../../../../actions/settings/candidates'
import { getTrajectoriesList } from '../../../../actions/trajectories'
import styles from './styles.module.scss'
import Table from '../../../../components/Table'
import Button from '../../../../components/Button'
import Select from 'react-select'

const Enrolment = ({ form }) => {

  const dispatch = useDispatch()
  const { rate } = useSelector(state => state.settings?.candidates)
  const { list } = useSelector(state => state.trajectories)

  useEffect(() => {
    dispatch(getCandidatesRate(form.values.year))
  }, [form.values.year])

  useEffect(() => {
    dispatch(getTrajectoriesList());
  }, [])
  
  const [traj, setTraj] = useState(null);

  const renderLi = (data) => {
    const { places, year, places_male, places_female } = data
    return (
      <tr >
        <td >{year}</td>
        <td >{places}</td>
        <td >{places_male}</td>
        <td >{places_female}</td>
      </tr>
    )
  }

    const handleAddTrajectories = () => {
      console.log(traj);
    }

  return (
    <div className={styles.wrapper} style={{ width: '800px', margin: '0 50px ' }}>

      <Table style={{ marginTop: '2rem', backgroundColor: 'white', }}>
        <thead>
          <tr>
            <th colSpan={4}>Количество мест для зачисления</th>
          </tr>
          <tr>
            <th>№ параллели</th>
            <th>кол-во мест</th>
            <th>(мальчики)</th>
            <th>(девочки)</th>
          </tr>
        </thead>
        <tbody>
          {rate?.map(renderLi)}
        </tbody>
      </Table>

      <div className={styles.add}>
        <Button mode='primary' onClick={() => handleAddTrajectories()}>Добавить направления для приёма</Button>
        <Select
        options={[...list?.map(el => ({ value: el.id, label: el.name }))]}
        placeholder={'Выберите направление'}
        value={traj}
        onChange={setTraj}
        styles={{
          control: (base, state) => ({
            ...base,
            borderColor: state.isFocused ? '#007BFF' : '#ced4da',
            boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : '',
            '&:hover': {
              borderColor: '#007BFF',
            },
          }),
          option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected ? '#007BFF' : 'white',
            color: state.isSelected ? 'white' : 'black',
          }),
        }}
        classNamePrefix="select"
        className={styles.select}
        isMulti={false}
        />  
      </div>
    </div>
  )
}

export default Enrolment