import * as types from "../actions/types";



const initialState = {
  pageNumber: 0,
  pageCount: 0,
  count: 0,
  list_loading: false,
  item: [],
  item_loading: false,
  track_profile: [],
  track_profile_loading: false
};


export default function studyPlansVD(state = initialState, action) {
  switch (action.type) {
    case types.SET_STUDY_PLANS_COUNT:
      return {
        ...state,
        ...action.payload,
      };
    case types.SET_STUDY_PLANS_PAGE:
      return {
        ...state,
        pageNumber: action.payload,
      };

    case types.SET_STUDY_PLANS_VD:
      return {
        ...state,
        listVD: action.payload,
      };
    case types.SET_STUDY_PLAN_VD_COURSES_LIST:
      return {
        ...state,
        coursesListVD: action.payload,
      };
    case types.SET_STUDY_PLAN_VD_FILES:
      return {
        ...state,
        files: action.payload,
      };

    case types.SET_STUDY_PLANS_LOADING:
      return {
        ...state,
        list_loading: action.payload,
      };


    case types.SET_STUDY_PLAN_LOADING:
      return {
        ...state,
        item_loading: action.payload,
      };


    default:
      return state;
  }
}