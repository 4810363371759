import axios from 'axios'
import { BACKEND_URL_PREFIX } from '../assets/const'
import { createNotification } from '../components/Notifications/Notifications'
import { checkAndUpdateToken, signOut } from '../actions/auth'


export const configureApi = store => {
  
  const instance = axios.create({ baseURL: BACKEND_URL_PREFIX })


  instance.interceptors.request.use(config => {
    store.dispatch(checkAndUpdateToken())
    const token = store.getState().auth.token;
    if (token) {
      config.headers.Authorization = `Basic ${btoa(`nnz:${store.getState().auth.token}`)}`;
    }
    return config;
  }, error => {
    return Promise.reject(error);
  });

  
  instance.interceptors.response.use(
    response => response,
    error => {
      if (error.response?.status === 401) {
        store.dispatch(signOut())
      } else if (error.response?.status === 503) {
        createNotification('error', `Сервис временно недоступен`)
      }
      return Promise.reject(error)
    }
  )

  return instance
}