import React, { useEffect, useState } from 'react'
import Modal from 'react-responsive-modal'
import { useDispatch, useSelector } from 'react-redux'
import styles from './styles.module.css'
import { useFormik } from 'formik'
import { Input, Select } from '../../components/ModalInputs'
import * as Yup from 'yup'
import ModalFooter from '../../components/ModalFooter/ModalFooter'
import { createSpecMedGroup, getAttachedStudents, getSpecMedGroupsTeachers, updateSpecMedGroup } from '../../actions/medCenter/specMedGroups'
import { getStudyPeriod } from '../../actions/classbook'
import Table from '../../components/Table'
import Button from '../../components/Button'
import EnrollModal from './EnrollModal/EnrollModal'
import Confirmation from '../../components/Confirmation/Confirmation'
import { BiCross, BiCrosshair, BiCut, BiExit, BiTrash } from 'react-icons/bi'

const validationSchema = Yup.object().shape({
  title: Yup.string().required('обязательное поле').max(30, 'Должно быть не более 30 символов'),
  teacher_mid: Yup.string().required('обязательное поле'),
  school_year: Yup.string().required('обязательное поле'),
  period: Yup.string().required('обязательное поле'),

})

export default function ModalComponent({ data, closeModal, height, addMode, width }) {
  console.log('addMode::: ', addMode);

  const dispatch = useDispatch()
  const { specMedGroupsTeacherList, attachedStudent } = useSelector(state => state.directoryMedCenter)
  const { schoolYears } = useSelector(state => state.common)
  const { studyPeriod } = useSelector(state => state.classbook)

  const [openEnrollModal, setOpenEnrollModal] = useState(false)
  const [selectedStudents, setSelectedStudents] = useState([])
  const [confirmation, setConfirmation] = useState(false);

  useEffect(() => {
    data.aid && dispatch(getAttachedStudents(data.aid))
  }, [])

  useEffect(() => {
    !addMode && attachedStudent?.students && setSelectedStudents(attachedStudent.students)
  }, [attachedStudent])


  const handleCreate = (forRequst) => {
    createSpecMedGroup(forRequst)
    closeModal()
  }

  const handleUpdate = (forRequst) => {
    updateSpecMedGroup(forRequst)
    closeModal()
  }

  const handle = (values) => {

    const forRequst = {
      title: values.title,
      teacher_mid: values.teacher_mid,
      school_year: values.school_year,
      period: values.period,
      people_specmedgroup: selectedStudents.map(el => el.mid)
    }
    console.log('values::: ', values);
    // if (!school_year_id || !parallel) return;

    addMode ? dispatch(createSpecMedGroup(forRequst)) : dispatch(updateSpecMedGroup(forRequst, values.aid))
    closeModal()

  }

  const handleChangeStudents = (student) => {
    if (typeof student === 'number') setSelectedStudents((prev) => prev.filter(el => el.mid !== student))
    else setSelectedStudents((prev) => !prev.includes(student.mid) ? [...prev, student] : prev.toSpliced(prev.indexOf(student), 1))
  }
  const {
    values,
    errors,
    touched,
    submitCount,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: addMode ? {
      title: "",
      teacher_mid: null,
      school_year: null,
      period: null,
      people_specmedgroup: []
    } : data,
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema,
    // onSubmit: addMode ? handleCreate : handleUpdate
    onSubmit: handle
  })
  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

  useEffect(() => {
    (data && values.school_year) && dispatch(getStudyPeriod(+values.school_year))
    dispatch(getSpecMedGroupsTeachers())
  }, [values.school_year])

  const renderLi = (data) => {
    const { student, mid, year_liter } = data
    return (
      <tr>
        <td>{student}</td>
        <td>{year_liter}</td>
        <td><i className={styles.trash} onClick={() => setConfirmation(data)}><BiExit /></i></td>

      </tr>
    )
  }

  const openEnroll = () => {
    setOpenEnrollModal(true)
  }
  const closeEnroll = () => {
    setOpenEnrollModal(false)
  }

  const acceptConfirmation = (mid) => {
    // closeModal();
    // dispatch(deleteSpecMedGroup(confirmation.mid));
    handleChangeStudents(mid)
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };



  return (
    <>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title={`Вы уверены, что хотите отчислить  ${confirmation?.student}?`}
          // confirmation={confirmation?.name} 
          acceptConfirmation={() => acceptConfirmation(confirmation?.mid)}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      <Modal
        open={!!data}
        // onClose={() => false}
        close={closeModal}
        showCloseIcon={false}
        animationDuration={1}
        center
        classNames={{
          // overlay: styles.overlay,
          modal: styles.custom
        }}
      >
        <div className={styles.modalLocal} >
          {openEnrollModal &&
            <EnrollModal
              closeModal={closeEnroll}
              data={openEnrollModal}
              height={'430px'}
              handleChangeStudents={handleChangeStudents}
              // selectedStudentsList={attachedStudent?.students}
              selectedStudents={selectedStudents}
            // addMode={(!selectedItem?.name && selectedItem !== null)}
            // addMode={selectedItem == 'add'}
            />
          }
          <div className={styles.tabWrapper} >

            <div className={styles.inputsRow}>
              <Input
                label='Название'
                name='title'
                value={values.title}
                error={error('title')}
                onChange={handleChange}
                style={{ width: '400px' }}
              />
            </div>
            <div className={styles.inputsRow}>
              <Select
                label='Преподаватель'
                name='teacher_mid'
                value={values.teacher_mid}
                onChange={handleChange}
                style={{ width: '300px' }}
                error={error('teacher_mid')}
              >
                {specMedGroupsTeacherList && [{ fio: 'Выберите преподавателя', mid: 0 }, ...specMedGroupsTeacherList]?.map(({ fio, mid }) => <option key={mid} value={mid}>{fio}</option>)}
              </Select>
            </div>
            <div className={styles.inputsRow}>
              <Select
                label='Учебный год'
                value={values.school_year}
                onChange={handleChange}
                name='school_year'
                error={error('school_year')}
                style={{ width: '300px' }}
              >
                {schoolYears && [{ name: 'Выберите год', xp_key: 0 }, ...schoolYears]?.map(({ name, xp_key }) => <option key={xp_key} value={xp_key}>{name}</option>)}
              </Select>
            </div>
            <div className={styles.inputsRow}>
              <Select
                label='Учебный период'
                value={values.period}
                onChange={handleChange}
                name='period'
                error={error('period')}
                style={{ width: '300px' }}
              >
                {studyPeriod && [{ title: 'Выберите период', trmid: 0 }, ...studyPeriod]?.map(({ title, trmid }) => <option key={trmid} value={trmid}>{title}</option>)}
              </Select>
            </div>
            <Button style={{ margin: '10px 0px 0px auto' }} onClick={openEnroll}>Зачислить</Button>
            <Table className={styles.tables}>
              <thead>
                <tr>
                  <th>Ученица</th>
                  <th>Класс</th>
                  <th>Отчислить</th>
                </tr>
              </thead>
              <tbody>

                {selectedStudents.map(renderLi)}

              </tbody>
            </Table>
          </div>
        </div>
        <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
      </Modal>
    </>

  )
}
