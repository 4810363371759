import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../../components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Input, MultiSelect, Select } from '../../../components/ModalInputs'
import { createSchoolYear, updateSchoolYear } from '../../../actions/schoolYears'
import * as Yup from 'yup'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import { getTermGradeTypes, getTermTypes } from '../../../actions/common'

const validationSchema = Yup.object({
  name: Yup.string().required('обязательное поле').max(30, 'Должно быть не более 30 символов'),
  begdate: Yup.string().required('обязательное поле'),
  enddate: Yup.string().required('обязательное поле'),
})


export default function Main ({closeModal, data, addMode}) {
  
  const dispatch = useDispatch()  

  const handleCreateYear = async (values) => {
    await dispatch(createSchoolYear(values))  
    closeModal()
  }

  const handleUpdateYear = async (values) => {
    const {xp_key, ...payload} = values
    await dispatch(updateSchoolYear(xp_key, payload))
    closeModal()
  }

  const {
    values, 
    errors,
    touched,
    submitCount,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit
  } = useFormik({
    initialValues: addMode ? {year_grade_types: '', name: '', begdate: '', enddate: ''} : data,
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: addMode ? handleCreateYear : handleUpdateYear
  })

  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

  const { termTypes, termGradeTypes } = useSelector(state => state.common)
  const getMultiOptions = ({title, trmtid}) => ({label: title, value: trmtid})
  const renderOptions = ({name, xp_key, term_type_id, term_type_name}) => <option value={term_type_id || xp_key}>{term_type_name || name}</option>

  const getTermGradeValues = () => {
    const options = termGradeTypes?.filter(({trmtid}) => values.year_grade_types?.includes(trmtid)) || []
    return options.map(getMultiOptions)
  }

  useEffect(() => {
    !termTypes && dispatch(getTermTypes())
    !termGradeTypes && dispatch(getTermGradeTypes())
  }, [])

  const setTermGradeValues = (fieldName) => (grades) => {
    const newValue = grades.map(({value}) => value).join(',')
    setFieldValue(fieldName, newValue)
  }

  return (
    <>
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          <div className={styles.inputsRow}>
            <Input
              label='Название'
              name='name'
              value={values.name}
              error={error('name')}
              onChange={handleChange}
              style={{width: '300px'}}
            />
          </div>
          <div className={styles.inputsRow}>
            <Input
              label='Период (начало)'
              name='begdate'
              type='date'
              error={error('begdate')}
              value={values.begdate}
              onChange={handleChange}
              />
            <Input
              label='Период (конец)'
              name='enddate'
              type='date'
              error={error('enddate')}
              value={values.enddate}
              onChange={handleChange}
            />
          </div>
          <div className={styles.inputsColumn}>
            <MultiSelect
              label='Тип оценок'
              value={getTermGradeValues()}
              options={termGradeTypes?.map(getMultiOptions)}
              onChange={setTermGradeValues('year_grade_types')}
            >
              {termTypes?.map(renderOptions)}
            </MultiSelect>   
          </div>
        </div>
      </div>
      <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
    </>
  )
}
