import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { IoIosAdd, IoIosCheckmark, IoIosCheckmarkCircle, IoIosEye, IoIosRefresh, } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Modal from './Modal'
import Table from '../../components/Table'
import { BiTrash } from 'react-icons/bi'
import Confirmation from '../../components/Confirmation/Confirmation'
import { deleteTypesOfDisease, getTypesOfDisease } from '../../actions/medCenter/typesOfDisease'
import { Input } from '../../components/ModalInputs'
import { useFormik } from 'formik'
import moment from 'moment'
import SelectPal from '../../components/Select copy'
import { arrayForSelect } from '../../utils/arrayForSelect'
import { streams, } from '../../assets/const'
import { getSchoolYears } from '../../actions/common'
import { deleteTestingSchedule, getTestingScheduleList } from '../../actions/testingSchedule'



export default function TestingSchedule() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { testingScheduleList } = useSelector(state => state.testing_schedule)
  console.log('testingScheduleList::: ', testingScheduleList);
  const { schoolYears } = useSelector(state => state.common)


  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(false);

  // const correctStream = streams.find(el => el.value == stream)?.name  // набор
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue
  } = useFormik({
    initialValues: {},
  })

  useEffect(() => {
    dispatch(getSchoolYears())
  }, [])



  const openModal = (data) => () => {
    setSelectedItem(data)
  }

  const refreshList = () => {
    const { xp_key, ...payload } = values
    dispatch(getTestingScheduleList(xp_key, payload))
  }

  const closeModal = () => setSelectedItem(null)

  const goHome = () => navigate('/')

  const acceptConfirmation = () => {
    closeModal();
    dispatch(deleteTestingSchedule(values.xp_key, values.exam_date_from, confirmation.id));
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  console.log('values::: ', values);

  const renderLi = (data) => {
    console.log('data::: ', data);
    const { applicant, child_class, commission, date, time, id } = data

    return (
      <tr >
        <td>{date}</td>
        <td>{time}</td>
        <td>{commission}</td>
        <td>{child_class}</td>
        <td>{applicant}</td>
        <td><i className={styles.trash} onClick={() => setConfirmation({ id: id, name: applicant })}><BiTrash /></i></td>
      </tr>
    )
  }

  return (
    <div className={styles.wrapper} style={{ width: '85vw', margin: '0 auto' }}>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
          acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      {selectedItem &&
        <Modal
          closeModal={closeModal}
          data={selectedItem}
          height={'430px'}
        />
      }
      <h1>Расписание тестирования</h1>
      <div className={styles.filters}>
        <div className={styles.filtersBlock}>
          <SelectPal
            title='Год набора'
            valuesName="xp_key"
            options={schoolYears && arrayForSelect(schoolYears, 'name', 'xp_key')}
            width='120px'
            value={values.year_name}
            setFieldValue={setFieldValue}
          />
          <SelectPal
            title='Набор'
            valuesName="stream"
            options={streams && arrayForSelect(streams, 'name', 'value')}
            style={{ width: '120px' }}
            value={values.stream}
            setFieldValue={setFieldValue}
          />
          <SelectPal
            title='Класс' // TODO что за классы??
            valuesName="class"
            // options={classes && arrayForSelect(classes, 'name', 'gid')}
            options={[]}
            style={{ width: '120px' }}
            setFieldValue={setFieldValue}
          />
          <SelectPal
            title='Комиссия'
            valuesName="сommission" // TODO correct key??
            // options={teachers && arrayForSelect(teachers?.teachers, 'calc_fio', 'mid')}
            options={[]}
            style={{ width: '120px' }}
            setFieldValue={setFieldValue}
          />
          <Input
            // error={values.periodfrom === '' ? 'Обязательное поле' : ''}
            type='date'
            label='с'
            name='exam_date_from'
            value={moment(values.exam_date_from).format('YYYY-MM-DD')}
            onChange={e => handleChange(e)}
          />
          <Input
            // error={values.periodtill === '' ? 'Обязательное поле' : ''}
            type='date'
            label='по'
            name='exam_date_to'
            value={moment(values.exam_date_to).format('YYYY-MM-DD')}
            onChange={e => handleChange(e)}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center' }}>
          <Button
            style={{ padding: '.5rem 1.5rem', margin: '12px' }}
            onClick={refreshList}
          >
            <IoIosRefresh size={30} style={{ marginRight: '10px' }} />
            Обновить
          </Button>
        </div>
      </div>

      <Table className={styles.tableDis}>
        <thead style={{ backgroundColor: '#d7e8ff', }}>
          <tr>
            <th>Дата</th>
            <th>Время</th>
            <th>Комиссия</th>
            <th>Класс</th>
            <th>Претендент</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {!!testingScheduleList && testingScheduleList.map(renderLi)}
        </tbody>
      </Table>
      <br />

      {/* 302 заявка = отлижили функционал на потом */}
      <div className={styles.filters}>
        <div className={styles.filtersBlock}>
          <Input
            // error={values.periodtill === '' ? 'Обязательное поле' : ''}
            type='date'
            label='Дата начала'
            name='date_start' // TODO key???
            value={moment(values.date_start).format('YYYY-MM-DD')}
            onChange={e => handleChange(e)}
          />
          <Input
            type='time'
            label='Начало'
            value={values.start}
            name='start'
            onChange={handleChange}
          />
          <Input
            type='number'
            label='Кол-во собеседований в день'
            value={values.interview}
            name='interview'
            onChange={handleChange}
          />
          <Input
            type='number'
            label='Длительность собеседования (мин)'
            value={values.howLong}
            name='howLong'
            onChange={handleChange}
          />
          <Button
            style={{ padding: '.5rem 1.5rem', margin: '12px' }}
            onClick={openModal('add')}
          >
            <IoIosEye size={30} style={{ marginRight: '10px' }} />
            Заполнить
          </Button>
        </div>
      </div>
      {/* 302 заявка = отлижили функционал на потом */}

      <div style={{ display: 'flex', flexDirection: "row" }}>
        {/* ЗАГРУЗКА ЧЕГО ТО.... */}
        <Button
          style={{ padding: '.5rem 1.5rem', margin: '12px' }}
          onClick={openModal('add')}
        >
          <IoIosCheckmarkCircle size={30} style={{ marginRight: '10px' }} />
          Результат
        </Button>
        {/* ЗАГРУЗКА ЧЕГО ТО.... */}
      </div>
    </div>
  )
}
