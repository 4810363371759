import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import Li from '../../components/Li'
import { IoIosAdd } from 'react-icons/io'
import { thrash } from '../../assets/svg/other'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/Loader'
import { useState } from 'react'
import Modal from './Modal'
import { Pagination } from '../../components/Pagination'
import { deleteSchoolYear, setPage, getSchoolYearsList } from '../../actions/schoolYears'
import Table from '../../components/Table'
import { BiTrash } from 'react-icons/bi'
import Confirmation from '../../components/Confirmation/Confirmation'
import moment from 'moment'


export default function SchoolYears() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { list, pageNumber, pageCount, loading } = useSelector(state => state.school_years)
  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(false);

  useEffect(() => {
    dispatch(getSchoolYearsList());
    dispatch(setPage(0));
  }, [])


  const openModal = (data) => () => {
    setSelectedItem(data || {})
  }

  const closeModal = () => setSelectedItem(null)

  const goHome = () => navigate('/')

  const acceptConfirmation = () => {
    closeModal();
    dispatch(deleteSchoolYear(confirmation.id));
    setConfirmation(false);
  };

  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  // const thrashClickHandler = (id) => (e) => {
  //   e.stopPropagation()
  //   dispatch(deleteSchoolYear(id))
  // }

  const renderLi = (data) => {
    const { name, xp_key, begdate, enddate } = data
    return (
      <tr>
        <td onClick={openModal(data)}>{name}</td>
        <td onClick={openModal(data)}>{moment(begdate).format('DD.MM.YYYY')}</td>
        <td onClick={openModal(data)}>{moment(enddate).format('DD.MM.YYYY')}</td>
        {/* <td onClick={thrashClickHandler(xp_key)}>{thrash()}</td> */}
        <td><i className={styles.trash} onClick={() => setConfirmation({ id: xp_key, name })}><BiTrash /></i></td>


      </tr>
    )
  }

  return (
    <div className={styles.wrapper}>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
          // confirmation={confirmation?.name} 
          acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      <Modal
        closeModal={closeModal}
        data={selectedItem}
      />
      <h1>Учебные года</h1>
      <div style={{ margin: '2rem 0', backgroundColor: 'white' }}>
        <Table>
          <thead>
            <tr>
              <th>Название</th>
              <th>Дата начала</th>
              <th>Дата конца</th>
            </tr>
          </thead>
          <tbody>

            {list?.map(renderLi)}

          </tbody>
        </Table>
      </div>

      <Button
        style={{ padding: '.5rem 1.5rem' }}
        onClick={openModal()}
      >
        <IoIosAdd size={30} />
        Добавить учебный год
      </Button>
    </div>
  )
}
