import React, { useEffect, useState } from 'react'
import { Main, CopyPeriod, Exceptions } from './ModalTabs'
import Modal from '../../components/Modal'

export default function ModalComponent({ data, closeModal, search }) {

  const [mode, setMode] = useState(data?.year ? 'copyPeriod' : 'main');
  useEffect(() => { setMode(data?.year ? 'copyPeriod' : 'main') }, [data])

  const renderTab = () => {
    switch (mode) {
      case 'main': return <Main closeModal={closeModal} data={data} addMode={!data?.lid} search={search} />
      case 'exceptions': return <Exceptions closeModal={closeModal} data={data} addMode={!data?.lid} search={search} />
      case 'copyPeriod': return <CopyPeriod closeModal={closeModal} data={data} addMode={!data?.lid} />
      default: return
    }
  }

  return (
    <Modal
      open={!!data}
    >
      {renderTab()}
    </Modal>
  )
}
