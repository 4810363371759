import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getAchievPoints, deleteAchievPoint, createAchievPoint, updateAchievPoint } from '../../actions/achievPoints'
import Button from '../../components/Button'
import Modal from 'react-responsive-modal'
import { useFormik } from 'formik'
import { Input, Select } from '../../components/ModalInputs'
import Confirmation from '../../components/Confirmation/Confirmation'
import { BiTrash } from 'react-icons/bi'
import { getAchievements } from '../../actions/achievements'
import { getEventLevels } from '../../actions/event_levels'
import Table from '../../components/Table'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  achpoints: Yup.number().required('обязательное поле'),
  rachid: Yup.string().required('обязательное поле'),
  rlevelid: Yup.string().required('обязательное поле'),
})

const AchievementsPoints = () => {
  
  const dispatch = useDispatch()

  const { list } = useSelector(state => state.achiev_points)
  const { list: achievements } = useSelector(state => state.achievements)
  const { list: event_levels } = useSelector(state => state.event_levels)


  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(null)

  const achievdict = achievements?.reduce((acc, cur) => ({...acc, [cur.id]: cur.name}), {})
  const eventdict = event_levels?.reduce((acc, cur) => ({...acc, [cur.news_level_id]: cur.news_level}), {})

  useEffect(() => {
    dispatch(getAchievements())
    dispatch(getEventLevels())
    dispatch(getAchievPoints())
  }, [])
  
  const deleteItem = (id) => (e) => {
    e.stopPropagation()
    setConfirmation(id)
  }

  const openModal = (data) => () => setSelectedItem(data || {})

  const closeModal = () => setSelectedItem(null)

  const renderRow = (item) => (
    <tr onClick={openModal(item)}>
      <td>{achievdict?.[item.rachid]}</td>
      <td>{eventdict?.[item.rlevelid]}</td>
      <td>{item.achpoints}</td>
      <td>
        <BiTrash size={24} color='red' title='Удалить' onClick={deleteItem(item.rrowid)}/>
      </td>
    </tr>
  )
  
  const acceptConfirmation = () => {
    dispatch(deleteAchievPoint(confirmation))
    setConfirmation(null)
  }
  
  const rejectConfirmation = () => {
    setConfirmation(null)
  }

  return (
    <div className={styles.wrapper}>
      <h1>Баллы за достижения</h1>
      <Table>
        {list?.map(renderRow)}
      </Table>
      <Button onClick={openModal()}>Добавить</Button>
      <ModalComponent
        open={!!selectedItem}
        closeModal={closeModal}
        data={selectedItem}
        addMode={!selectedItem?.rrowid}
        achievements={achievements}
        event_levels={event_levels}
      />
      {confirmation && (
        <Confirmation
            top="30px"
            left="40px"
            title="Уверены что хотите удалить запись?"
            acceptConfirmation={acceptConfirmation}
            rejectConfirmation={rejectConfirmation}
        />
      )}
    </div>
  )
}


const ModalComponent = ({open, closeModal, data, addMode, achievements, event_levels}) => {
  
  const dispatch = useDispatch()

  const [confirmation, setConfirmation] = useState(false)

  useEffect(() => {
    if (!open) return;
    setValues(addMode ? {} : data)
  }, [open])
  

  const createItem = async (values) => {
    const success = await dispatch(createAchievPoint(values))
    success && closeModal()
  }
  
  const updateItem = async ({rrowid, ...values}) => {
    const success = await dispatch(updateAchievPoint(rrowid, values))
    success && closeModal()
  }

  const {
    values,
    errors,
    touched,
    dirty,
    submitCount,
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
  } = useFormik({
    initialValues: addMode ? {} : data,
    enableReinitialize: true,
    validationSchema,
    onSubmit: addMode ? createItem : updateItem
  })

  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

  const renderOptions = ({name, id, news_level, news_level_id}) => <option key={id || news_level_id} value={id || news_level_id}>{name || news_level}</option>

  const handleCloseModal = () => {
    if (dirty) {
      setConfirmation(true)
    } else {
      closeModal()
    }
  }

  const acceptConfirmation = () => {
    closeModal()
    setConfirmation(false)
  }

  return (
    <Modal
      open={open}
      onClose={closeModal}
      classNames={{modal: styles.modal}}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      showCloseIcon={false}
      animationDuration={2}
      center
    >
      <div className={styles.modalMain}>
        <h2>{addMode ? 'Добавление записи' : 'Редактирование записи'}</h2>
        <Select
          label='Вид достижения'
          name='rachid'
          value={values.rachid}
          error={error('rachid')}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          <option value=''>Не выбран</option>
          {achievements?.map(renderOptions)}
        </Select>
        
        <Select
          label='Уровень'
          name='rlevelid'
          value={values.rlevelid}
          error={error('rlevelid')}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          <option value=''>Не выбран</option>
          {event_levels?.map(renderOptions)}
        </Select>
        
        <Input
          name='achpoints'
          label='Баллы'
          value={values.achpoints}
          error={error('achpoints')}
          onChange={handleChange}
          onBlur={handleBlur}
          type='number'
        />
      </div>
      <div className={styles.modalFooter}>
          <div>
              <div></div>
              <div></div>
          </div>
          <Button mode='white' type="submit" onClick={handleSubmit}>Сохранить</Button>
          <Button mode='white' onClick={handleCloseModal}>Закрыть</Button>
      </div>
      {confirmation &&
        <Confirmation
          title="Есть несохраненные данные. Уверены что хотите выйти?"
          acceptConfirmation={acceptConfirmation}
          rejectConfirmation={() => setConfirmation(false)}
        />
      }
    </Modal>
  )
}


export default AchievementsPoints