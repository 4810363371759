import { BACKEND_URL_PREFIX } from "../../assets/const";
import { createNotification } from "../../components/Notifications/Notifications";
import { checkAndUpdateToken } from "../auth";
import { buildDefaultHeaders } from "../helper";
import {
    GET_DIAGNOSES,
    GET_MES_VISITES,
    GET_LIST,
    GET_APPEALS,
    GET_VACCINE_EXCLUSIONS,
    GET_VACCINATION_MODAL
} from "../types";

export const getDiagnoses = (year) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const urlParams = new URLSearchParams(year).toString();
        const response = await fetch(`${BACKEND_URL_PREFIX}/diagnoses`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            const result = (await response.json());
            dispatch({ type: GET_DIAGNOSES, payload: result });
        }
    }

};

export const getMedVisites = (year) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const urlParams = new URLSearchParams(year).toString();
        const response = await fetch(`${BACKEND_URL_PREFIX}/med_visites`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            const result = (await response.json());
            dispatch({ type: GET_MES_VISITES, payload: result });
        }
    }

};

export const getList = (params) => async (dispatch, getState) => {
    console.log('params::: ', params);
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const urlParams = new URLSearchParams(params).toString();
        const response = await fetch(`${BACKEND_URL_PREFIX}/people/medcards?${urlParams}`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            const result = (await response.json());
            dispatch({ type: GET_LIST, payload: result });
        }
    }
};

export const getAppeals = (id) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/med_visites/recourse/${id}`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            const result = (await response.json());
            dispatch({ type: GET_APPEALS, payload: result });
        }
    }

};

export const createMedVisites = (data) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/med_visites`, {
            method: "POST",
            body: JSON.stringify(data),
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            await dispatch(getAppeals(data.mid));
            createNotification('success', 'Успешно');
        } else createNotification('error', "Не удалось создать запись");
    }
};
export const updateMedVisites = ({ mid, idmedvisit, ...data }) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/med_visites/${idmedvisit}`, {
            method: "PUT",
            body: JSON.stringify(data),
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            await dispatch(getAppeals(mid));
            createNotification('success', 'Успешно');
        } else createNotification('error', "Не удалось обновить запись");
    };
}

//Противопоказания
export const getVaccineExclusionsModal = (mid) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/vaccine_exclusions/contraindications/${mid}`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            const result = (await response.json());
            dispatch({ type: GET_VACCINE_EXCLUSIONS, payload: result });
        }
    }
};

export const createVaccineExclusionsModal = (data) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/vaccine_exclusions`, {
            method: "POST",
            body: JSON.stringify(data),
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            createNotification('success', 'Успешно');
            await dispatch(getVaccineExclusionsModal(data.mid));
        } else createNotification('error', "Не удалось создать запись");
    }
};
//ПРИВИВКА
export const getVaccinationModal = (mid) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/f_vaccination/inoculations/${mid}`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            const result = (await response.json());
            dispatch({ type: GET_VACCINATION_MODAL, payload: result });
        }
    }
};

export const createVaccinationModal = (data) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/f_vaccination`, {
            method: "POST",
            body: JSON.stringify(data),
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            createNotification('success', 'Успешно');
            await dispatch(getVaccinationModal(data.mid));
        } else createNotification('error', "Не удалось создать запись");
    }
};