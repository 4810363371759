import classNames from 'classnames'
import React from 'react'
import styles from './styles.module.css'
import FitnessIndicators from '../../FitnessIndicators'

export default function Experience({ active }) {
  return (
    <div className={classNames(styles.tabWrapper, { [styles.dislplayNone]: !active })}>
      <FitnessIndicators />
    </div>
  )
}
