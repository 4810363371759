import * as types from "../actions/types";



const initialState = {
  list: [],
  loading: false
};


export default function backup(state = initialState, action) {
  switch (action.type) {

    case types.SET_BACKUP_LIST:
      return {
        ...state,
        backupList: action.payload,
      };
    case types.SET_BACKUP_TIME:
      return {
        ...state,
        backupTime: action.payload,
      };

    case types.SET_EVENT_LEVELS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
}