import React from 'react'
import { useContext } from 'react'
import { CandidatesContext } from '../../../utils/context'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getCandidateAddresses } from '../../../actions/candidates'
import styles from './styles.module.scss'
import Button from '../../../components/Button'
import { Input, Select, Textarea } from '../../../components/ModalInputs'
import { useFormik } from 'formik'
import Table from '../../../components/Table'
import { getStudentFamily } from '../../../actions/students/family'


const list = [
  { name: 'Мама', typePar: 'мама' }
]
export default function Adresses() {

  const { student, closeModal, studentData } = useContext(CandidatesContext)
  console.log('studentData::: ', studentData);

  const dispatch = useDispatch()
  // const { address: data } = useSelector(state => state.candidates.data)
  const { data } = useSelector(state => state.students)
  console.log('family::: ', );

const studentFamily =data.family
console.log('studentFamily::: ', studentFamily);

  useEffect(() => {
    dispatch(getStudentFamily(student.mid))
  }, [])

  const {
    values,
    errors,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: studentData || {},
    enableReinitialize: true,
    onSubmit: console.log
  })

  console.log('values::: ', values);

  const { socialstatus, family_financial_sotuation } = !!studentData && studentData
  return (
    <>
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          <h5>Статус семьи</h5>
          <div className={styles.inputsColumn}>
            {/* TODO нет выпадашек */}
            <Select
              style={{ width: '350px' }}
              label='Социальный состав семьи'
              name='familySocial'
              value={values.familySocial}
              onChange={handleChange}
            >
              <option>Полные семьи</option>
            </Select>
            <Select
              style={{ width: '350px' }}
              label='Статус семьи'
              name='socialstatus'
              value={values.socialstatus}
              onChange={handleChange}
            >
              <option>{socialstatus}</option>
            </Select>
            <Select
              style={{ width: '350px' }}
              label='Материальное положение семьи'
              name='family_financial_sotuation'
              value={values.family_financial_sotuation}
              onChange={handleChange}
            >
              <option>{family_financial_sotuation}</option>
            </Select>
            {/* TODO нет выпадашек */}
          </div>
          <div className={styles.inputsRow} style={{ margin: '20px' }}>
            <Table style={{ marginTop: '1rem', maxHeight: 'none', }}>
              <thead style={{ backgroundColor: '#cce5ff', }}>
                <tr>
                  <th>ФИО</th>
                  <th>Тип родства</th>
                </tr>
              </thead>
              <tbody>
                {studentFamily && studentFamily.map((member) =>
                  <tr>
                    <td>{`${member.lastname} ${member.firstname} ${member.patronymic}`}</td>
                    <td>{member.relationship}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          <div className={styles.inputsRow}>
            <Input
              label='ФИО опекуна'
              name='guardian'
              value={values.guardian}
              onChange={handleChange}
            />
          </div>

          <div className={styles.inputsRow}></div>
          <Input
            label='Инвалиды'
            name='disabledPeople'
            value={values.disabledPeople}
            onChange={handleChange}
          />
        </div>
      </div >


      <div className={styles.modalFooter}>
        <div>
          <div>Создал: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
          <div>Изменил: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
        </div>
        <Button mode='white' onClick={closeModal}>Сохранить</Button>
        <Button mode='white' onClick={closeModal}>Закрыть</Button>
      </div>
    </>
  )
}
