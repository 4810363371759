import * as types from "../actions/types";

const initialState = {
  list: [],
  loading: false,
  item: {}
};

export default function exam_city(state = initialState, action) {
  switch (action.type) {

    case types.GET_EXAM_CITY_LIST:
      return {
        ...state,
        list: action.payload
      };
      case types.SET_EXAM_CITY_LIST_LOADING:
        return {
          ...state,
          loading: action.payload
        };
      case types.SET_EXAM_CITY_BY_ID:
        return {
          ...state,
          item: action.payload
        };
    default:
      return state;
  }
}