import React, { useState } from 'react'
import { Main } from './ModalTabs'
import Modal from '../../components/Modal'


export default function ModalComponent({ data, closeModal, height, setFilterYear, setSearch }) {

  const [mode, setMode] = useState('main')

  const renderTab = () => {
    switch (mode) {
      case 'main': return <Main closeModal={closeModal} data={data} addMode={!data?.trmid} height={height} setSearch={setSearch} />
      default: return
    }
  }

  return (
    <Modal
      open={!!data}
      menuItem={mode}
      setMenuItem={setMode}
      height={height}
    >
      {renderTab()}
    </Modal>
  )
}
