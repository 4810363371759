import { api } from "../App";
import { SET_EDUC_INSTIT_LIST, SET_EVENT_LEVELS_LIST, SET_EVENT_LEVELS_LOADING, SET_HOLIDAYS_LIST } from "./types";




export const getEducationalInstitutions = () => async (dispatch, getState) => {
  try {
    // dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: true });
    const { data } = await api.get('/schools');
    dispatch({ type: SET_EDUC_INSTIT_LIST, payload: data });
  } catch (ERR) {
  } finally {
    // dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: false });
  }
}

export const createEducationalInstitutions = (data) => async (dispatch, getState) => {
  let success
  try {
    dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: true });
    await api.post('/schools', data);
    await dispatch(getEducationalInstitutions())
    success = true
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: false });
    return success
  }
}

export const updateEducationalInstitutions = ({ idschool, ...data }) => async (dispatch, getState) => {
  console.log('data::: ', data);
  let success
  try {
    dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: true });
    await api.put(`/schools/${idschool}`, data);
    await dispatch(getEducationalInstitutions())
    success = true
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: false });
    return success
  }
}

export const deleteEducationalInstitutions = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: true });
    await api.delete(`/schools/${id}`);
    await dispatch(getEducationalInstitutions())
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: false });
  }
}