import React from 'react'
import Modal from 'react-responsive-modal'
import {useDispatch} from 'react-redux'
import styles from './styles.module.css'
import {useFormik} from 'formik'
import {Input} from '../../components/ModalInputs'
import * as Yup from 'yup'
import ModalFooter from '../../components/ModalFooter/ModalFooter'
import {createTrajectories, updateTrajectories} from '../../actions/trajectories'

const validationSchema = Yup.object({
    name: Yup
        .string()
        .required('обязательное поле')
        .min(4, 'Должно быть не менее 4 символов')
        .max(30, 'Должно быть не более 255 символов'),
    alias: Yup
        .string()
        .required('обязательное поле')
        .min(4, 'Должно быть не менее 4 символов')
        .max(255, 'Должно быть не более 255 символов')
})

export default function ModalComponent({data, closeModal, height, addMode}) {

    const dispatch = useDispatch();

    const handleCreate = (values) => {
        dispatch(createTrajectories(values))
        closeModal();
    }

    const handleUpdate = (values) => {
        dispatch(updateTrajectories(values))
        closeModal();
    }

    const {
        values,
        errors,
        touched,
        submitCount,
        handleChange,
        handleSubmit
    } = useFormik({
        initialValues: addMode
            ? {}
            : data,
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        onSubmit: addMode
            ? handleCreate
            : handleUpdate
    })

    const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

    return (
        <Modal
            open={!!data}
            onClose={() => closeModal()}
            showCloseIcon={false}
            height={height}
            center
            closeOnOverlayClick={true}
            closeOnEsc={true}
            classNames={{
                modal: styles.custom
            }}>
            <> <div className={styles.modalLocal}>
                <div className={styles.tabWrapper}>

                    <div className={styles.inputsColumn}>
                        <Input
                            label='Наименование'
                            name='name'
                            value={values.name}
                            error={error('name')}
                            onChange={handleChange}
                            style={{
                            width: '600px',
                        }}/>
                    </div>
                    <div className={styles.inputsColumn}>
                        <Input
                            label='Кор. наименование'
                            name='alias'
                            value={values.alias}
                            error={error('alias')}
                            onChange={handleChange}
                            style={{
                            width: '600px',
                        }}/>
                    </div>

                </div>
            </div>

            <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal}/>
        </>
    </Modal>

    )
}
