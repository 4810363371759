import React, { useEffect } from 'react'
import Modal from 'react-responsive-modal'
import { useDispatch, useSelector } from 'react-redux'
import styles from './styles.module.css'
import { useFormik } from 'formik'
import { Checkbox, Input, Select } from '../../../../components/ModalInputs'
import * as Yup from 'yup'
import ModalFooter from '../../../../components/ModalFooter/ModalFooter'
import { createVaccinationModal } from '../../../../actions/medCenter/medCard'
import { getTypesOfVaccinations, getVaccinations } from '../../../../actions/medCenter/vassinations'


const validationSchema = Yup.object({
  seria: Yup.string().required('обязательное поле').max(30, 'Должно быть не более 30 символов'),
})

export default function ModalComponent({ data, closeModal, height, addMode, student }) {

  const dispatch = useDispatch()
  const { vaccinations, typesVaccinations } = useSelector(state => state.directoryMedCenter)

  useEffect(() => {
    dispatch(getVaccinations())
    dispatch(getTypesOfVaccinations())
  }, [])

  const handleCreate = (values) => {
    dispatch(createVaccinationModal({mid:student.mid, doctor_mid: 67890, ...values}))
    closeModal()
  }

  const handleUpdate = (values) => {
    // dirty && dispatch(updateManners(values))
    closeModal()
  }

  const {
    values,
    errors,
    touched,
    dirty, // проверяет есть ли изменения
    submitCount,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: data,
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: addMode ? handleCreate : handleUpdate
  })
console.log('values::: ', values);
  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

  const renderOption = (option, index) => {
    const { xp_key, value, trmid, cid, gid, work_status, id, pmid, did, name, title, year, access, diagnosis, disease, iddischarge, discharge, c_vac_type } = option
    const optionValue = xp_key || value || trmid || cid || gid || work_status || id || pmid || did || disease || iddischarge || c_vac_type;
    const optionTitle = name || title || value || year || work_status || access || diagnosis || discharge;
    return <option key={`${optionValue}+${index}`} value={optionValue}>{optionTitle}</option>
  }

  return (
    <Modal
      open={!!data}
      onClose={() => false}
      showCloseIcon={false}
      height={height}
      classNames={{
        // overlay: styles.overlay,
        modal: styles.custom
      }}
    >
      <>
        <div className={styles.modalLocal} >
          <div className={styles.tabWrapper} >

            <div className={styles.modalLocal} >
              <div className={styles.tabWrapper} >
                <div className={styles.inputsRow}>
                  <Input
                    label='Дата прививки'
                    type='datetime-local'
                    name='vac_date'
                    value={values.vac_date}
                    onChange={handleChange}
                  />
                  <Checkbox
                    name='revaccination'
                    label='Ревакцинация'
                    checked={values.revaccination}
                    disabled
                  />


                </div>

                <div className={styles.column}>
                  <Input
                    label='Пациент'
                    value={student.fio}
                    style={{ width: '600px' }}
                    disabled
                  />



                  <Select
                    label='Вид прививки'
                    name='c_vaccine'
                    value={values?.c_vaccine}
                    onChange={handleChange}
                    style={{ width: '300px' }}
                    options={vaccinations}
                  >
                    <option value=''>Выбрать</option>
                    {vaccinations?.map(renderOption)}
                  </Select>
                  <Select
                    label='Вакцина'
                    name='c_vac_type'
                    value={values?.c_vac_type}
                    onChange={handleChange}
                    style={{ width: '300px' }}
                    options={typesVaccinations}
                  >
                    <option value=''>Выбрать</option>
                    {typesVaccinations?.map(renderOption)}
                  </Select>

                  <Input
                    label='Серия'
                    name='seria' 
                    value={values.seria}
                    error={error('seria')}
                    onChange={handleChange}
                    style={{ width: '200px' }}
                  />




                </div>
              </div>

            </div>

          </div>
        </div>

        <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
      </>
    </Modal>

  )
}
