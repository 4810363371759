import { useState } from 'react'
import { Participants, Parameters, Main, MembersEmployees, Responsible } from './ModalTabs'
import Modal from 'react-responsive-modal'
import { EventsListModalModes, } from '../../assets/const'
import styles from './styles.module.scss'
import cn from 'classnames'
import { EventListContext } from '../../utils/context'

export default function ModalComponent({ student, isOpen, closeModal, studentData }) {

    const [mode, setMode] = useState('main')

    const renderLi = ({ name, value, icon }) => {
        const selected = value === mode
        return (
            <li
                onClick={() => setMode(value)}
                className={cn(styles.modalMenuItem, {
                    [styles.selectedMenuItem]: selected
                })}
            >
                {icon()}
                {name}
            </li>
        )
    }

    const renderTab = () => {
        switch (mode) {
            case 'main': return <Main />
            case 'participants': return <Participants />
            case 'responsible': return <Responsible />
            case 'members_employees': return <MembersEmployees />
            case 'parameters': return <Parameters />
            default: return
        }
    }

    return (
        <EventListContext.Provider value={{ closeModal, }}>
            <Modal
                open={isOpen}
                classNames={{ modal: styles.modalMain }}
                closeOnOverlayClick={false}
                closeOnEsc={false}
                showCloseIcon={false}
                animationDuration={1}
                center
            >
                <div className={styles.modalContent}>
                    <div className={styles.modalMenu}>
                        <ul>{EventsListModalModes.map(renderLi)}</ul>
                    </div>
                    {renderTab()}
                </div>
            </Modal>
        </EventListContext.Provider>
    )
}