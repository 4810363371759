import { BACKEND_URL_PREFIX, itemsOnPage, recipientActionTypes } from "../assets/const";
import { createNotification } from "../components/Notifications/Notifications";
import { checkAndUpdateToken } from "./auth";
import { buildDefaultHeaders } from "./helper";
import { api } from "../App";

import {
    SET_STUDY_PLANS_VD,
    SET_STUDY_PLAN_VD_BY_ID,
    SET_STUDY_PLAN_VD_COURSES_LIST,
    SET_STUDY_PLAN_VD_FILES,
    SET_STUDY_PLAN_VD_LOADING,
    SET_STUDY_PLANS_PAGE,

} from "./types";

export const setPage = (pageNumber) => ({ type: SET_STUDY_PLANS_PAGE, payload: pageNumber })


export const getStudyPlansVD = (params) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());

    const { pageNumber } = getState().studyPlans
    const max_results = itemsOnPage
    const paginate_from = itemsOnPage * pageNumber
    let copyParams = {
        ...params,
        limit: max_results,
        offset: paginate_from
    }

    const urlParams = new URLSearchParams(copyParams).toString();

    if (getState().auth.token) {
        dispatch({ type: SET_STUDY_PLAN_VD_LOADING, payload: true });

        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/out_study_plans`, {
                method: "GET",
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                const data = await response.json();
                console.log('data::: ', data);

                dispatch({ type: SET_STUDY_PLANS_VD, payload: data });
                // dispatch({ type: SET_STUDY_PLANS_COUNT, payload: {pageCount, count} });
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            dispatch({ type: SET_STUDY_PLAN_VD_LOADING, payload: false });
        }
    }
};


export const getStudyPlansVDById = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: SET_STUDY_PLAN_VD_LOADING, payload: true });
        const { data } = await api.get(`/out_study_plans/${id}`);
        dispatch({ type: SET_STUDY_PLAN_VD_BY_ID, payload: data });
    } catch (ERR) {
    } finally {
        dispatch({ type: SET_STUDY_PLAN_VD_LOADING, payload: false });
    }
}

export const deleteStudyPlansVD = (id) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({ type: SET_STUDY_PLAN_VD_LOADING, payload: true });
        const response = await fetch(`${BACKEND_URL_PREFIX}/out_study_plans/${id}`, {
            method: "DELETE",
            ...buildDefaultHeaders(getState),
        });
        const result = await response.json();
        if (response.ok) {
            createNotification('success', 'Успешно');
            // const {list, pageNumber} = getState().plans
            // list.length < 2 && dispatch(setPage(pageNumber - 1))
            await dispatch(getStudyPlansVD());
        } else createNotification('error', result.error);
    }
    dispatch({ type: SET_STUDY_PLAN_VD_LOADING, payload: false });
};



export const editStudyPlanVD = (data) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({ type: SET_STUDY_PLAN_VD_LOADING, payload: true });
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/out_study_plans/${data.xp_key}`, {
                method: "PUT",
                body: JSON.stringify(data),
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                createNotification('success', 'Успешно');
            } else {
                const error = await response.json();
                createNotification('error', error.error);
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            await dispatch(getStudyPlansVD());
        }
    }
    dispatch({ type: SET_STUDY_PLAN_VD_LOADING, payload: false });
};

export const createStudyPlanVD = (data) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({ type: SET_STUDY_PLAN_VD_LOADING, payload: true });
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/out_study_plans`, {
                method: "POST",
                body: JSON.stringify(data),
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                createNotification('success', 'Успешно');
            } else {
                const error = await response.json();
                createNotification('error', error.error);
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            await dispatch(getStudyPlansVD());
        }
    }
    dispatch({ type: SET_STUDY_PLAN_VD_LOADING, payload: false });
};

// МО Список предметов
export const getStudyPlanVDCoursesList = (id) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({ type: SET_STUDY_PLAN_VD_LOADING, payload: true });
        const response = await fetch(`${BACKEND_URL_PREFIX}/out_study_plans/${id}/courses`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            const payload = await response.json();
            dispatch({ type: SET_STUDY_PLAN_VD_COURSES_LIST, payload });
        }
    }
    dispatch({ type: SET_STUDY_PLAN_VD_LOADING, payload: false });
};
export const addStudyPlanVDCourse = (data) => async (dispatch, getState) => {
    console.log('data::: ', data);
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({ type: SET_STUDY_PLAN_VD_LOADING, payload: true });
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/out_study_plans/courses`, {
                method: "POST",
                body: JSON.stringify(data),
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                createNotification('success', 'Успешно');
            } else {
                const error = await response.json();
                createNotification('error', error.error);
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            await dispatch(getStudyPlanVDCoursesList(data.out_tracks));
        }
    }
    dispatch({ type: SET_STUDY_PLAN_VD_LOADING, payload: false });
};

export const editStudyPlanVDCourse = ({ out_tracks, out_courses, xp_key, cid, name, ...data }) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({ type: SET_STUDY_PLAN_VD_LOADING, payload: true });
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/out_study_plans/${out_tracks}/courses/${out_courses}`, {
                method: "PUT",
                body: JSON.stringify(data),
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                createNotification('success', 'Успешно');
            } else {
                const error = await response.json();
                createNotification('error', error.error);
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            await dispatch(getStudyPlanVDCoursesList(out_tracks));
        }
    }
    dispatch({ type: SET_STUDY_PLAN_VD_LOADING, payload: false });
};
export const createStudyPlanVDFile = (data, id) => async (dispatch, getState) => {
    console.log('(data, id::: ', (data, id))
    await dispatch(checkAndUpdateToken());
    const fd = new FormData();
    fd.append("file", data);

    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/out_study_plans/${id}`, {
            method: "POST",
            body: fd,
            headers: {
                "Access-Control-Allow-Origin": "*",
                Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
            },
        });
        if (response.ok) {
            createNotification('success', 'Успешно');
            await dispatch(getStudyPlansVDById(id));
        }
    }
};


export const getStudyPlanVDFiles = (id) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {

        const response = await fetch(`${BACKEND_URL_PREFIX}/out_study_plans/${id}/file`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            const result = await response.json();
            console.log('result::: ', result);
            dispatch({ type: SET_STUDY_PLAN_VD_FILES, payload: result });
        }
        else dispatch({ type: SET_STUDY_PLAN_VD_FILES, payload: null });
    }
};

export const createCopyStudyPlanVD = (id) => async (dispatch, getState) => {
    // await dispatch(checkAndUpdateToken());
    // if (getState().auth.token) {

    //     const response = await fetch(`${BACKEND_URL_PREFIX}/out_study_plans/${id}`, {
    //         method: "GET",
    //         ...buildDefaultHeaders(getState),
    //     });
    //     if (response.ok) {
    //         const result = await response.json();
    //         dispatch({ type: GET_NUTRITION_PERIOD_FILES, payload: result });
    //     } else dispatch({ type: GET_NUTRITION_PERIOD_FILES, payload: null });
    // }
};