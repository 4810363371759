import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { IoIosAdd } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Modal from './Modal'
import Table from '../../components/Table'
import { BiTrash } from 'react-icons/bi'
import Confirmation from '../../components/Confirmation/Confirmation'
import { getJournalControlPeriods, getJournalControlGroups, getJournalControlData, getJournalControlCourses, deleteJournalControl } from '../../actions/journalControl'
import Select from '../../components/Select'
import { useFormik } from 'formik'
import { getSchoolYears } from '../../actions/common'


const initialValues = {
  course_type: 2,
  year: '',
  trmid: '',
  gid: '',
  cid: ''
}

export default function JournalControl() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { schoolYears } = useSelector(state => state.common)
  const { periods, groups, courses, data } = useSelector(state => state.journal_control)

  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(false);

  const { values, handleChange } = useFormik({ initialValues })

  useEffect(() => {
    dispatch(getSchoolYears())
  }, [])

  useEffect(() => {
    const { course_type, year } = values
    if (!year) return;
    const params = { course_type, year }
    dispatch(getJournalControlPeriods(params))
  }, [values.year])

  useEffect(() => {
    const { course_type, year, trmid } = values
    if (!trmid || !year) return;
    const params = { course_type, year, trmid }
    dispatch(getJournalControlGroups(params))
  }, [values.trmid])

  useEffect(() => {
    const { course_type, year, trmid, gid } = values
    if (!trmid || !gid || !year) return;
    const params = { course_type, trmid, gid, year }
    dispatch(getJournalControlCourses(params))
  }, [values.gid])

  useEffect(() => {
    const { course_type, year, trmid, gid, cid } = values
    if (!trmid || !gid || !cid || !year) return;
    const params = { course_type, trmid, gid, cid, year }
    dispatch(getJournalControlData(params))
  }, [values.cid])

  const goTo = (url) => () => navigate(url)

  const openModal = (data) => () => setSelectedItem(data || {})

  const closeModal = () => setSelectedItem(null)

  const acceptConfirmation = () => {
    const { course_type } = values
    dispatch(deleteJournalControl(confirmation?.id, course_type));
    setConfirmation(false);
    closeModal();
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  const deleteItemHandler = (data) => (e) => {
    e.stopPropagation()
    setConfirmation({ id: data.jmid, name: data.date })
  }

  const renderLi = (data) => {
    const { date, checktext, xp_f_get_mid_fio, subject, group } = data
    return (
      <tr onClick={openModal(data)}>
        <td>{date}</td>
        <td>{checktext}</td>
        <td>{xp_f_get_mid_fio}</td>
        <td>{subject}</td>
        <td>{group}</td>
        <td><i className={styles.trash} onClick={deleteItemHandler(data)}><BiTrash /></i></td>
      </tr>
    )
  }

  const addButtonDisabled = !values.trmid || !values.gid || !values.cid

  return (
    <div className={styles.wrapper} style={{ width: '1200px', margin: '0 auto' }}>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
          acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      {selectedItem &&
        <Modal
          closeModal={closeModal}
          data={selectedItem}
          height={'430px'}
          addMode={!selectedItem.jmid}
          filters={values}
        />
      }
      <h1>Контроль ведения классного журнала ВД</h1>

      <div className={styles.filtersBlock}>

        <Select
          title="Учебный год"
          name="year"
          options={[{ name: "Выбрать", xp_key: 0 }, ...schoolYears]}
          onChange={handleChange}
        />

        <Select
          title="Учебный период"
          name="trmid"
          value={values.trmid}
          options={[{ name: "Выбрать", value: '' }, ...periods]}
          onChange={handleChange}
        />

        <Select
          title="Группа"
          name="gid"
          value={values.gid}
          options={[{ name: "Выбрать", value: '' }, ...groups]}
          onChange={handleChange}
        />

        <Select
          title="Предмет"
          name="cid"
          value={values.cid}
          options={[{ name: "Выбрать", value: '' }, ...courses]}
          onChange={handleChange}
        />

      </div>

      <Table className={styles.tables}>
        <thead>
          <tr>
            <th>Месяц, число</th>
            <th>Замечания и предложения проверяющих</th>
            <th>Отметка о выполнении</th>
            <th>Предмет</th>
            <th>Класс</th>

            <th></th>
          </tr>
        </thead>
        <tbody>
          {data?.map(renderLi)}
        </tbody>
      </Table>

      <div style={{ display: 'flex', flexDirection: "row" }}>
        <Button
          style={{ padding: '.5rem 1.5rem', margin: '12px' }}
          disabled={addButtonDisabled}
          onClick={openModal()}
          title={addButtonDisabled ? "Необходимо выбрать фильтры" : "Добавить"}
        >
          <IoIosAdd size={30} />
          Добавить
        </Button>
      </div>
      <div className={styles.footer}>
        <Button mode='white' onClick={goTo('/classbook_vd')}>Классный журнал ВД</Button>
        <Button onClick={goTo('/')}>На главную</Button>
      </div>
    </div>
  )
}
