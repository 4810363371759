import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import Li from '../../components/Li'
import { IoIosClose, IoIosAdd } from 'react-icons/io'
import {thrash} from '../../assets/svg/other'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getCoursesList, deleteCourse, setPage } from '../../actions/courses'
import Loader from '../../components/Loader'
import { useState } from 'react'
import Modal from './modal'
import { Pagination } from '../../components/Pagination'
import Table from '../../components/Table'
import Confirmation from '../../components/Confirmation/Confirmation'
import { BiTrash } from 'react-icons/bi'


export default function Courses () {
  
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {pageNumber, pageCount, list, loading} = useSelector(state => state.courses)

  const [addMode, setAddMode] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [confirmation, setConfirmation] = useState(false);


  const thrashClickHandler = () => {
    dispatch(deleteCourse(confirmation?.id, pageNumber));
    setConfirmation(false);
  }

  const acceptConfirmation = () => {
    closeModal();
    thrashClickHandler();
  };

  const rejectConfirmation = () => {
    setConfirmation(false);
  };


  useEffect(() => () => dispatch(setPage(0)), []);

  useEffect(() => {
    dispatch(getCoursesList(pageNumber))
  }, [pageNumber])
  

  const openModal = (id) => () => {
    setSelectedId(id)
  }

  const closeModal = () => {
    setSelectedId(null)
    setAddMode(false)
  }
  
  const goHome = () => navigate('/')

  const statusList = [
    {name: 'Идет', value: '0'},
    {name: 'Закрыт', value: '2'},
  ]

  const renderRow = (data) => {
    const { title, alias, cid, status, xp_paired, needhomework, neednt_grades, skip_attestat, gia, ege  } = data
    return (
      <tr key={cid}>
        <td onClick={openModal(cid)} style={{textAlign: 'left'}}>{title}</td>
        <td onClick={openModal(cid)} style={{textAlign: 'left'}}>{alias}</td>
        <td onClick={openModal(cid)} style={{textAlign: 'left'}}>{statusList.find(item => item.value == status)?.name}</td>
        <td onClick={openModal(cid)}>{xp_paired == '1' ? 'Да' : 'Нет'}</td>
        <td onClick={openModal(cid)}>{needhomework == '1' ? 'Нет' : 'Да'}</td>
        <td onClick={openModal(cid)}>{neednt_grades == '1' ? 'Нет' : 'Да'}</td>
        <td onClick={openModal(cid)}>{skip_attestat == '1' ? 'Нет' : 'Да'}</td>
        <td onClick={openModal(cid)}>{gia == '1' ? 'Да' : 'Нет'}</td>
        <td onClick={openModal(cid)}>{ege == '1' ? 'Да' : 'Нет'}</td>
        <td><i className={styles.trash} onClick={() => setConfirmation({id: cid, title: title})}><BiTrash/></i></td>
      </tr>
    )
  }

  const pageClick = ({selected}) => dispatch(setPage(selected))

  return (
    <div className={styles.wrapper}>
      {
        confirmation?.id && (
          <Confirmation
            top="30px"
            left="40px"
            title={`Удалить ${confirmation.title}?`}
            // confirmation={confirmation.title} 
            acceptConfirmation={() => acceptConfirmation(confirmation)}
            rejectConfirmation={rejectConfirmation}
          />
        )
      }
        <Modal    
          closeModal={closeModal}
          data={selectedId}
          list={list?.courses}
          pageNumber={pageNumber}
          addMode={addMode}
          setAddMode={setAddMode}
          infoCreateChange={list?.courses?.find(course => course.cid === selectedId)}
          />
        <h1>Предметы</h1>
        <Pagination
          pageCount={pageCount}
          pageNumber={pageNumber}
          onPageChange={pageClick}
          loading={loading}
        >
        {loading
          ? <Loader/>
          : list?.courses &&
              <Table style={{marginTop: '2rem', backgroundColor: 'white'}}>
                <thead>
                        <tr>
                            <th>Название</th>
                            <td>Дисциплина</td>
                            <td>Статус</td>
                            <td>Парный урок</td>
                            <td>Без ДЗ</td>
                            <td>Без оценок</td>
                            <td>Аттестат</td>
                            <td>ОГЭ</td>
                            <td>ЕГЭ</td>
                            <td>Удалить</td>
                        </tr>
                    </thead>
                    <tbody>
                        {list?.courses
                          .map(renderRow)}
                    </tbody>
              </Table>
        }

        </Pagination>

        <Button onClick={openModal('add')}><IoIosAdd/>Добавить</Button>
 
        <footer className={styles.footer}>
            {/* <Button mode='white' onClick={() => setAddMode(true)}>Добавить</Button> */}
            <Button mode='white' onClick={goHome}>Закрыть</Button>
        </footer>
    </div>
  )
}