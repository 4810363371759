import { api } from "../App";
import { createNotification } from "../components/Notifications/Notifications";
import { SET_ACHIEV_POINTS_LIST, SET_ACHIEV_POINTS_LOADING } from "./types";




export const getAchievPoints = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_ACHIEV_POINTS_LOADING, payload: true });
    const { data } = await api.get('/achievements_points');
    dispatch({ type: SET_ACHIEV_POINTS_LIST, payload: data });
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_ACHIEV_POINTS_LOADING, payload: false });
  }
}

export const createAchievPoint = (data) => async (dispatch, getState) => {
  let success
  try {
    dispatch({ type: SET_ACHIEV_POINTS_LOADING, payload: true });
    await api.post('/achievements_points', data);
    await dispatch(getAchievPoints())
    success = true
  } catch ({response}) {
    const errorMessage = response.data.error || 'Что-то пошло не так'
    createNotification('error', errorMessage);
  } finally {
    dispatch({ type: SET_ACHIEV_POINTS_LOADING, payload: false });
    return success
  }
}

export const updateAchievPoint = (id, data) => async (dispatch, getState) => {
  let success
  try {
    console.log('response')
    dispatch({ type: SET_ACHIEV_POINTS_LOADING, payload: true });
    await api.put(`/achievements_points/${id}`, data);
    await dispatch(getAchievPoints())
    success = true
  } catch ({response}) {
    const errorMessage = response.data.error || 'Что-то пошло не так'
    createNotification('error', errorMessage);
  } finally {
    dispatch({ type: SET_ACHIEV_POINTS_LOADING, payload: false });
    return success
  }
}

export const deleteAchievPoint = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_ACHIEV_POINTS_LOADING, payload: true });
    await api.delete(`/achievements_points/${id}`);
    await dispatch(getAchievPoints())
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_ACHIEV_POINTS_LOADING, payload: false });
  }
}