import * as types from "../actions/types";



const initialState = {
  blocks: [],
  lessons: [],
  levels: [],
  list: null,
  data: null,
  loading: false
};


export default function enroll_questions (state = initialState, action) {
  switch (action.type) {
    
    case types.SET_ENROLL_QUESTIONS_BLOCKS:
      return {
        ...state,
        blocks: action.payload,
      };
    
    case types.SET_ENROLL_QUESTIONS_LESSONS:
      return {
        ...state,
        lessons: action.payload,
      };
    
    case types.SET_ENROLL_QUESTIONS_LEVELS:
      return {
        ...state,
        levels: action.payload,
      };
    
    case types.SET_ENROLL_QUESTIONS_LIST:
      return {
        ...state,
        list: action.payload,
      };
    
    case types.SET_ENROLL_QUESTIONS_DATA:
      return {
        ...state,
        data: action.payload,
      };
    
    case types.SET_ENROLL_QUESTIONS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
  
    default:
      return state;
  }
}