import React, { useEffect, useState } from 'react'
import styles from './index.module.css'
import Title from '../../components/Title'
import Table from '../../components/Table'
import Empty from '../../components/Empty'
import { Pagination } from '../../components/Pagination'
import { useDispatch, useSelector } from 'react-redux'
import { deleteStudyPlans, getStudyPlans, createCopyStudyPlan, setPage } from '../../actions/studyPlans'
import { BiTrash } from 'react-icons/bi'
import moment from 'moment'
import Confirmation from '../../components/Confirmation/Confirmation'
import Modal from './Modal'
import Button from '../../components/Button'
import { getStudyPlansVD } from '../../actions/studyPlansVD'
import { getClassParallelList, createCopyDailyRoutine, deleteDailyRoutine } from '../../actions/dailyRoutine'


const Index = () => {

  const dispatch = useDispatch()
  const { list, pageCount, pageNumber, loading } = useSelector(state => state.studyPlans)
  const { dailyRoutinClassList, } = useSelector(state => state.dailyRoutin)

  const [confirmation, setConfirmation] = useState(false);
  const [modalOpen, setModalOpen] = useState(null);
  console.log('modalOpen::: ', modalOpen);
  const [select, setSelect] = useState(null);

  const pageClick = ({ selected }) => dispatch(setPage(selected))

  const acceptConfirmation = () => { // Подтвердить удаление
    dispatch(deleteDailyRoutine(confirmation?.xp_key));
    closeModal();
    setConfirmation(false);
  };
  const rejectConfirmation = () => { // Отменить подтверждение
    setConfirmation(false);
  };

  useEffect(() => {
    dispatch(getStudyPlansVD())
    dispatch(getClassParallelList())

  }, [])

  const openModal = (data) => () => { // Открыть модалку
    console.log('data::: ', data);
    setModalOpen(data || {})
  }

  const closeModal = () => { // Закрыть модалку
    setModalOpen(null)
  }

  const handleConfirmation = (e, data) => {
    e.stopPropagation() // Отмена действия по умолчанию (открытие модалки)
    setConfirmation(data)
  }

  const handleSelect = (data) => {
    if (select?.xp_key === data.xp_key) {
      setSelect(null)
    } else {
      setSelect(data)
    }
  }

  useEffect(() => () => dispatch(setPage(0)), []);

  useEffect(() => {
    dispatch(getStudyPlans(pageNumber))
  }, [pageNumber])

  return (
    <div className={styles.wrapper}>
      <Title title="Распорядок дня" />

      {/* //TODO Копирование выполнить */}
      <div className={styles.buttons}>
        {select?.xp_key &&
          <div className={styles.copy}>
            <Button
              onClick={() => dispatch(createCopyDailyRoutine(select?.xp_key))}
            // onClick={() => console.log('selectselectselect: ', (select?.xp_key))}
            >
              Создать копию
            </Button>
          </div>}
        <Button
          style={{ backgroundColor: "#3689FF", fontSize: "16px" }}
          onClick={openModal({})}>Добавить</Button>
      </div>

      {dailyRoutinClassList ? dailyRoutinClassList.length === 0 && <Empty /> : ''}

      {dailyRoutinClassList ? dailyRoutinClassList.length !== 0 &&
        <Table className={styles.table}>
          <thead>
            <tr>
              <th style={{ width: '100px' }}>№ п/п</th>
              <th>Название</th>
              <th>Удалить</th>
            </tr>
          </thead>
          <tbody>

            {
              dailyRoutinClassList.map((data, index) => (
                <tr
                  key={index}
                  onClick={() => handleSelect(data)}
                  onDoubleClick={openModal(data)}
                  style={{ backgroundColor: select?.xp_key === data.xp_key ? '#3689FF' : '' }}
                >
                  <td style={{ color: select?.xp_key === data.xp_key ? 'white' : '' }}>{index + 1}</td>
                  <td style={{ color: select?.xp_key === data.xp_key ? 'white' : '' }}>{data.name}</td>


                  <td onClick={(e) => handleConfirmation(e, data)}>
                    <i className={styles.trash}><BiTrash /></i>
                  </td>
                </tr>
              ))
            }

          </tbody>
        </Table>
        : ''
      }

      {!!modalOpen && (
        <div className={styles.modal}>
          <Modal modalOpen={modalOpen} closeModal={closeModal} />
        </div>
      )}

      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title="Удалить данную запись?"
          confirmation={confirmation?.name}
          acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
          rejectConfirmation={rejectConfirmation}
        />
      )}

    </div>
  )
}

export default Index