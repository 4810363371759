import React from 'react';

import { useState } from 'react';
import { api } from '../App'
import Button from '../components/Button';
import { IoIosDownload } from 'react-icons/io';


const ZipDownloader = ({ url, path }) => {
    const [loading, setLoading] = useState(false);

    const downloadZipFile = async () => {
        setLoading(true);
        try {
            const { data } = await api.get(`/${path}/${url}`, { responseType: 'blob' })
            const blob = new Blob([data], { type: 'application/zip' });
            const urlObject = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = urlObject;
            link.download = `${url}.zip`;
            link.click();
            URL.revokeObjectURL(urlObject);
        } catch (error) {
            console.error('Ошибка при загрузке:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Button
            style={{ padding: '.5rem 1.5rem', margin: '12px' }}
            onClick={downloadZipFile}
            disabled={loading}
        >
            <IoIosDownload size={30} />
            {loading ? 'Загрузка...' : 'Получить ZIP'}
        </Button>
    );
};

export default ZipDownloader;