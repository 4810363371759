import React from 'react'
import { useContext } from 'react'
import { CandidatesContext } from '../../../utils/context'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getCandidateMedicineInfo } from '../../../actions/candidates'
import styles from './styles.module.scss'
import Button from '../../../components/Button'
import { Checkbox, Textarea } from '../../../components/ModalInputs'
import Table from '../../../components/Table'


export default function Anthropometry() {

  const { student, closeModal } = useContext(CandidatesContext)

  const dispatch = useDispatch()
  const { medicine_info } = useSelector(state => state.candidates.data)

  useEffect(() => {
    // dispatch(getCandidateMedicineInfo(student))
  }, [])

  return (
    <>
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          <div className={styles.inputsRow}>

            <Table>
              <thead>
                <tr>
                  <th>Параметры</th>
                  <th>Значение</th>
                  <th>Дата внесения</th>
                  <th>2024-2025</th>
                </tr>
              </thead>
            </Table>
          </div>
        </div>
      </div>

      <div className={styles.modalFooter}>
        <div>
          <div>Создал: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
          <div>Изменил: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
        </div>
        <Button mode='white' onClick={closeModal}>Сохранить</Button>
        <Button mode='white' onClick={closeModal}>Закрыть</Button>
      </div>
    </>
  )
}
