import React, { useState } from 'react'
import styles from './index.module.css'
import Table from '../../../../components/Table'
import { BiTrash } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import Confirmation from '../../../../components/Confirmation/Confirmation'
import Button from '../../../../components/Button'
import Modal from './Modal'


const Index = ({ data, closeModal, height, addMode, classGroup, values, setFieldValue, handleChange, handleSubmit }) => {
    console.log('Index Index Compound  data::: ', data);

    const dispatch = useDispatch();
    const { listVD, coursesListVD } = useSelector(state => state.studyPlansVD)


    const { extraEduList } = useSelector(state => state.directoryExtraEducation)
    const [compoundModalOpen, setCompoundModalOpen] = useState(null);



    const [confirmation, setConfirmation] = useState(false);
    const [modalOpen, setModalOpen] = useState(null);

    const acceptConfirmation = () => { // Подтвердить удаление
        // dispatch(deleteStudyPlansContent(confirmation));
        closeModal();
        setConfirmation(false);
    };
    const rejectConfirmation = () => { // Отменить подтверждение
        setConfirmation(false);
    };

    const openModal = (item) => { // Открыть модалку
        if (item
            ?.xp_key) {
            setCompoundModalOpen(item)
        } else {
            setCompoundModalOpen({})
        }
    }

    const closeSubModal = () => { // Закрыть модалку
        // setClassId(null)
        setCompoundModalOpen(null)
    }

    const handleConfirmation = (e, data) => {
        e.stopPropagation() // Отмена действия по умолчанию (открытие модалки)
        setConfirmation(data)
    }


    return (
        <div className={styles.tabWrapper}>
            {!!coursesListVD &&

                <Table className={styles.table}>
                    <thead>
                        <tr>
                            <th>№ п/п</th>
                            <th>Уч. план</th>
                            <th>Предмет</th>
                            <th>Часы в год</th>
                            <th>Часы в неделю</th>
                            <th>Параллель</th>
                            <th>Удалить</th>
                        </tr>
                    </thead>
                    {coursesListVD?.map((data, index) => (
                        <tbody>
                            <tr key={index} onClick={() => openModal(data)}>
                                <td>{index + 1}</td>
                                {/* TODO как сравнивать то??? */}
                                <td>{listVD.find(el => el.xp_key === data.out_courses)?.name}</td>
                                <td>{data.name}</td>
                                <td>{data.hours}</td>
                                <td>{data.hoursweek}</td>
                                <td>{data.level}</td>
                                <td onClick={(e) => handleConfirmation(e, data)}>
                                    <i className={styles.trash}><BiTrash /></i>
                                </td>
                            </tr>
                        </tbody>
                    ))
                    }

                </Table>
            }
            {compoundModalOpen && (
                <div className={styles.modal}>
                    <Modal
                        closeModal={closeSubModal}
                        compoundModalOpen={compoundModalOpen}
                        setCompoundModalOpen={setCompoundModalOpen}
                        addMode={!compoundModalOpen.xp_key}
                        out_tracks={data.xp_key}
                    /></div>
            )}

            <div className={styles.buttons}>
                <Button
                    style={{
                        backgroundColor: "#3689FF",
                        fontSize: "16px"
                    }}
                    onClick={() => openModal()}>Добавить</Button>
            </div>

            {confirmation && (<Confirmation
                top="30px"
                left="40px"
                title="Удалить данную запись?"
                confirmation={confirmation
                    ?.name}
                acceptConfirmation={() => acceptConfirmation(confirmation
                    ?.name)}
                rejectConfirmation={rejectConfirmation} />)}
        </div>
    )
}

export default Index