import * as types from "../actions/types";



const initialState = {
  list: [],
  loading: false
};


export default function educational_institutions (state = initialState, action) {
  switch (action.type) {
    
    case types.SET_EDUC_INSTIT_LIST:
      return {
        ...state,
        educInstitList: action.payload,
      };
    
    case types.SET_EVENT_LEVELS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
  
    default:
      return state;
  }
}