import React from 'react'
import Modal from 'react-responsive-modal'
import { useDispatch, useSelector } from 'react-redux'
import styles from './styles.module.css'
import { useFormik } from 'formik'
import { Checkbox, Input, Select, Textarea } from '../../components/ModalInputs'
import * as Yup from 'yup'
import ModalFooter from '../../components/ModalFooter/ModalFooter'
import { createJournalControl, updateJournalControl } from '../../actions/journalControl'

const validationSchema = Yup.object({
  // name: Yup.string().required('обязательное поле').max(30, 'Должно быть не более 30 символов'),
})

export default function ModalComponent({ data, closeModal, height, addMode, filters }) {

  const dispatch = useDispatch()

  const handleCreate = async(values) => {
    const { gid, cid, trmid, course_type } = filters
    const payload = { gid, cid, trmid, course_type, ...values }
    await dispatch(createJournalControl(payload))
    closeModal()
  }
  
  const handleUpdate = async ({jmid, group, subject, ...values}) => {
    const { gid, cid, trmid, course_type } = filters
    const payload = { gid, cid, trmid, course_type, ...values }
    await dispatch(updateJournalControl(jmid, payload))
    closeModal()
  }

  const {
    values,
    errors,
    touched,
    dirty,
    submitCount,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: data,
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema,
    onSubmit: addMode ? handleCreate : handleUpdate
  })

  const error = (fieldName) => (touched[fieldName] || !!submitCount) && errors[fieldName]

  const renderOptions = ({ name, xp_key, gid, f, title, cid }) => <option value={gid || xp_key || cid}>{name || f || title}</option>

  return (
    <Modal
      open={!!data}
      onClose={() => false}
      showCloseIcon={false}
      height={height}
      classNames={{
        modal: styles.custom
      }}
      center
    >
      <>
        <div className={styles.modalLocal} >
          <div className={styles.tabWrapper} >

            <div className={styles.inputsRow}>
              <Input
                label='Дата'
                type='date'
                name='date'
                value={values.date}
                onChange={handleChange}
              />
            </div>
            <div className={styles.inputsRow}>
              <Textarea
                label='Замечания'
                name='checktext'
                value={values.checktext}
                error={error('checktext')}
                onChange={handleChange}
                style={{ width: '600px' }}
              />

            </div>
            <div className={styles.inputsRow}>

              <Select
                label='Проверяющий'
                name='xp_f_get_mid_fio'
                value={values?.xp_f_get_mid_fio}
                onChange={handleChange}
                disabled
              >
                <option value=''>Выбрать</option>
              </Select>
            </div>

            <div className={styles.inputsRow}>
              <Checkbox label="Выполнено" />
              <Input
                label='Дата'
                type='date'
                name='visitdate'
                disabled
              />
            </div>
            <div className={styles.inputsRow}>

              <Select
                label='Выполняющий'
                name='visitorder'
                disabled
              >
                <option value=''>Выбрать</option>
              </Select>

            </div>
          </div>
        </div>

        <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
      </>
    </Modal>

  )
}
