import React, { useContext, useEffect, useState } from 'react'
import { Select } from '../../../components/ModalInputs'
import { useDispatch, useSelector } from 'react-redux'
import { getCourses, getDataStructure, getPeoples, getProfiles, getSchoolYears } from '../../../actions/disciplineStructure'
import styles from '../styles.module.scss'
import { DisciplineStructureContext } from '../../../utils/context'

const EDU_TYPES = [
  { name: 'Основное', value: 0 },
  { name: 'Дополнительное', value: 1 },
  { name: 'Внеурочная деятельность', value: 2 }
]

export default function Form() {

  const { form, initialValues } = useContext(DisciplineStructureContext)

  const { values, setFieldValue, setValues, handleChange } = form
  console.log('values::: ', values);
  const dispatch = useDispatch()
  const { schoolYears, peoples, courses, profiles } = useSelector(state => state.discipline_structure.filters)
  const data = useSelector(state => state.discipline_structure.data)

  useEffect(() => {
    dispatch(getSchoolYears())
    dispatch(getProfiles())
  }, [])

  useEffect(() => {
    if (!values?.educationType) return;
    if (!values?.year) return;
    // !!values && setValues(initialValues)
    const params = { course_type: values?.educationType }
    // dispatch(getPeoples(values?.year, params))
    dispatch(getDataStructure({ ...params, xp_key: values?.year }))
    // вставить запрос на образёвание
  }, [values?.educationType, values?.year])

  useEffect(() => {
    const params = { course_type: values?.educationType }
    dispatch(getCourses(values?.year, values?.teacher, params))
    dispatch(getDataStructure(params))
  }, [values?.educationType, values?.year])

  const renderOptions = (item) => {
    const { xp_key, trp_id, name, cid, mid, fio, title, teacher_mid, teacher } = item
    const optionName = name || title || fio || teacher
    const optionValue = xp_key || trp_id || cid || mid || teacher_mid
    return <option key={optionValue} value={optionValue}>{optionName}</option>
  }

  const handleSetEduType = (educationType) => () => {
    setValues({ ...initialValues, educationType })
  }

  return (
    <form className={styles.form}>

      <div className={styles.eduTypeBlock}>
        <p>Образование</p>
        {EDU_TYPES.map(({ name, value }) =>
          <label>
            <input
              type='radio'
              name='edu_type'
              checked={value == values?.educationType}
              onChange={handleSetEduType(value)}
            />
            {name}
          </label>
        )}
      </div>

      <div className={styles.filtersBlock}>
        <Select
          label='Учебный год'
          name='year'
          value={values?.year}
          style={{ backgroundColor: 'white' }}
          onChange={handleChange}
        >
          <option value={0}>Не выбран</option>
          {schoolYears?.map(renderOptions)}
        </Select>

        <Select
          label='Преподаватель'
          name='teacher'
          value={values?.teacher}
          style={{ backgroundColor: 'white' }}
          onChange={handleChange}
        >
          <option value={0}>Не выбран</option>
          {data?.map(renderOptions)}
        </Select>

        <Select
          label='Предмет'
          name='discipline'
          value={values?.discipline}
          style={{ backgroundColor: 'white' }}
          onChange={handleChange}
        >
          <option value={0}>Не выбран</option>
          {courses?.map(renderOptions)}
        </Select>

        <Select
          label='Профиль'
          name='profile'
          value={values?.profile}
          style={{ backgroundColor: 'white' }}
          onChange={handleChange}
        >
          <option value={0}>Не выбран</option>
          {profiles?.map(renderOptions)}
        </Select>
      </div>

    </form>
  )
}
