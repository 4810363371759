import { api } from "../../App";
import { SET_STUDENTS_DATA_MAIN, SET_STUDENTS_LOADING } from "../types";



export const getStudentMain = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_STUDENTS_LOADING, payload: true });
    const { data } = await api.get(`/student/${id}`)
    // const { data } = (await api.get(`/xp_personal_file/base/user/${id}`))
    // console.log('datas::: ', datas);

    dispatch({ type: SET_STUDENTS_DATA_MAIN, payload: data })
  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_STUDENTS_LOADING, payload: false });
  }
};

export const updateStudentMain = (id, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_STUDENTS_LOADING, payload: true });
    await api.put(`/student/${id}`, data)
  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_STUDENTS_LOADING, payload: false });
  }
};

export const createStudentMain = (data) => async (dispatch, getState) => {
  console.log('data::: ', data);
  try {
    dispatch({ type: SET_STUDENTS_LOADING, payload: true });
    // await api.post('/students', data)
    await api.post('/staff_roster', data)
  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_STUDENTS_LOADING, payload: false });
  }
};

export const deleteStudentMain = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_STUDENTS_LOADING, payload: true });
    await api.delete(`/student/${id}`)
  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_STUDENTS_LOADING, payload: false });
  }
};