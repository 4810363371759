import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Table from '../../components/Table'
import { getCountries } from '../../actions/directory'


export default function Countries() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { countries } = useSelector(state => state.directory)
  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(false);

  useEffect(() => {
    dispatch(getCountries())
  }, [])


  const openModal = (data) => () => {
    setSelectedItem(data || {})
  }

  const closeModal = () => setSelectedItem(null)

  const goHome = () => navigate('/')

  const renderLi = (data) => {
    const { idcountry, name } = data
    return (
      <tr >
        <td >{name}</td>
      </tr>
    )
  }

  return (
    <div className={styles.wrapper} style={{ width: '700px', margin: '0 auto' }}>

      <h1>Страны</h1>

      <Table style={{ marginTop: '2rem', backgroundColor: 'white' }}>
        <thead>
          <tr>
            <th>Название</th>
          </tr>
        </thead>
        <tbody>
          {countries?.map(renderLi)}
        </tbody>
      </Table>
    </div>
  )
}
