import { api } from "../App";
import { SET_SCHEDULING_DO_LIST, SET_SCHEDULING_LOADING, SET_SCHEDULING_DO_YEAR_LIST } from "./types";



export const getScheduleDO = (year) => async (dispatch, getState) => {
  try {

    dispatch({ type: SET_SCHEDULING_LOADING, payload: true });
    const response = await api.get(year ? `/schedule_variant_do/year/${year}` : `/schedule_variant_do`)
    dispatch({ type: SET_SCHEDULING_DO_LIST, payload: response.data })

  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_SCHEDULING_LOADING, payload: false });
  }
};

export const getScheduleDOByYear = (year) => async (dispatch, getState) => {
  try {

    dispatch({ type: SET_SCHEDULING_LOADING, payload: true });
    const response = await api.get(`/schedule_variant_do/year/${year}`)
    dispatch({ type: SET_SCHEDULING_DO_YEAR_LIST, payload: response.data })

  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_SCHEDULING_LOADING, payload: false });
  }
};