import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import Button from '../../../components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Checkbox, Input, Select } from '../../../components/ModalInputs'
import * as Yup from 'yup'
import { createPeriod, updatePeriod } from '../../../actions/periods'
import moment from 'moment'
import { getSchoolYears, getShifts } from '../../../actions/common'
import cn from 'classnames'
import Confirmation from '../../../components/Confirmation/Confirmation'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import Exceptions from './Exceptions/Exceptions'


const validationSchema = Yup.object({
  name: Yup.string().required('обязательное поле').max(256, 'Должно быть не более 256 символов'),
  s_year_id: Yup.string().required('обязательное поле'),
  dopstatus: Yup.string().required('обязательное поле'),
  short_name: Yup.string().required('обязательное поле').max(11, 'Должно быть не более 11 символов'),

  starttime: Yup.string().required('обязательное поле'),
  stoptime: Yup.string().required('обязательное поле'),
});

export default function Main({ closeModal, data, search, addMode }) {

  const dispatch = useDispatch()
  const { loading } = useSelector(state => state.terms);
  const { schoolYears, shifts } = useSelector(state => state.common);
  const [confirmation, setConfirmation] = useState(false);
  const [open, setOpen] = useState(false);

  const acceptConfirmation = () => {
    closeModal();
    setConfirmation(false);
  };

  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  useEffect(() => {
    !schoolYears && dispatch(getSchoolYears())
    !shifts && dispatch(getShifts())
  }, [])

  const getCorrectPayload = (values) => {
    let { starttime, stoptime } = values
    starttime = moment.duration(starttime).asMinutes()
    stoptime = moment.duration(stoptime).asMinutes()
    return { ...values, starttime, stoptime }
  }

  const handleCreatePeriod = async (values) => {
    const payload = getCorrectPayload(values)
    await dispatch(createPeriod(payload, search))
    closeModal()
  }

  const handleUpdatePeriod = async (values) => {
    let { lid, ...data } = values
    const payload = getCorrectPayload(data)
    await dispatch(updatePeriod(lid, payload, search))
    closeModal()
  }


  const {
    values,
    errors,
    touched,
    submitCount,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue
  } = useFormik({
    initialValues: addMode
      ? { s_year_id: search.year, starttime: '', stoptime: '', status: 0, dopstatus: 0, outstatus: 0, short_name: '', }
      : {
        ...data,
        s_year_id: search.year,
        lid: data.lid
      },
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: addMode ? handleCreatePeriod : handleUpdatePeriod
  })

  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

  const renderOptions = ({ name, xp_key, sid }) => <option value={sid || xp_key}>{name}</option>

  const checkboxClick = (e) => {
    const { name } = e.target
    const newValue = values[name] ? 0 : 1
    setFieldValue(name, newValue)
  }

  return (
    <>
      {
        confirmation && (
          <Confirmation
            top="30px"
            left="40px"
            title="Закрыть без сохранения?"
            confirmation={confirmation}
            acceptConfirmation={() => acceptConfirmation(confirmation)}
            rejectConfirmation={rejectConfirmation}
          />
        )
      }
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          <div className={styles.titleButton}>
            <Button onClick={() => setOpen(false)}>Период</Button>
            <Button onClick={() => setOpen(true)}>Исключения</Button>
          </div>
          {!open ?
            <>
              <div className={styles.inputsRow}>
                <Input
                  style={{ width: '750px' }}
                  label='Название'
                  name='name'
                  value={values.name}
                  error={error('name')}
                  onChange={handleChange}
                />
              </div>
              <div className={styles.inputsRow}>
                <Input
                  label='Начало'
                  name='starttime'
                  type='time'
                  value={values.starttime}
                  error={error('starttime')}
                  onChange={handleChange}
                />
                <Input
                  label='Окончание'
                  name='stoptime'
                  type='time'
                  value={values.stoptime}
                  error={error('stoptime')}
                  onChange={handleChange}
                />
              </div>
              <div className={cn(styles.inputsRow, styles.checkboxRow)}>
                <Checkbox
                  label='Учебный период'
                  name='status'
                  checked={values.status}
                  onChange={checkboxClick}
                />
                <Checkbox
                  label='Служебный'
                  name='is_additional'
                  checked={values.is_additional}
                  onChange={checkboxClick}
                  disabled={!values.status}
                />
                {console.log('values.status::: ', values.status)}
              </div>
              <div className={cn(styles.inputsRow, styles.checkboxRow)}>
                <Checkbox
                  label='Доп. образование'
                  name='dopstatus'
                  checked={values.dopstatus}
                  onChange={checkboxClick}
                />
                <Checkbox
                  label='Внеурочная деятельность'
                  name='outstatus'
                  checked={values.outstatus}
                  onChange={checkboxClick}
                />
              </div>
              <div className={styles.inputsRow}>
                <Input
                  label='Сокр. Название'
                  name='short_name'
                  value={values.short_name}
                  error={error('short_name')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className={styles.inputsRow}>
                <Select
                  label='Учебный год'
                  name='s_year_id'
                  value={values.s_year_id}
                  error={error('s_year_id')}
                  onChange={handleChange}
                >
                  {[{ name: 'Выбрать учебный год', xp_key: '' }, ...schoolYears]?.map(renderOptions)}
                </Select>
              </div>
              <div className={styles.inputsRow}>
                <Select
                  label='Смена'
                  name='shift'
                  value={values.shift}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {shifts?.map(renderOptions)}
                </Select>
              </div>
            </>
            : <Exceptions />
          }
        </div>
      </div>
      <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
      {/* <div className={styles.modalFooter}>
        <div>
            <div>Создал: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
            <div>Изменил: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
        </div>
        <Button mode='white' loading={loading} onClick={handleSubmit}>Сохранить</Button>
        <Button mode='white' onClick={() => setConfirmation(true)}>Закрыть</Button>
      </div> */}
    </>
  )
}
