import React, { useEffect, useState } from 'react'
import styles from './index.module.css'
import { Checkbox, Input } from '../../../../components/ModalInputs'
import moment from 'moment'
import { weekForSchedule } from '../../../../assets/const'

const PARALLELS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']


const Index = ({ values, setValues, days, setDays, parallels, setParallels, addMode }) => {

    const [parellel, setParellel] = useState(false)
    const [classes, setClasses] = useState(false)


    // БУДУТ ЛИ ВЫБРАННЫЕ ПАРАЛЛЕЛИ ИЗ ДРУГИХ КЛАССОВ??
    //   const disabledList2 = PARALLELS?.map(el => (values?.parallels?.find(par => +el == +par) ? null : el))
    // const disabledList2 = []


    const handleChange = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })

    }

    const handleCheck = (e) => {
        if (e.target.name === 'parellel') {
            setParellel(!parellel)
            setClasses(false)
        } else {
            setClasses(!classes)
            setParellel(false)
        }
    }

    const handleParallels = (e) => {
        if (parallels?.find(el => el === e.target.value))
            setParallels(parallels.filter(el => el !== e.target.value))
        else setParallels([...parallels, e.target.value])
    }

    const handleDays = (e) => {
        if (days.includes(+e.target.value))
            setDays(days.filter(el => +el !== +e.target.value))
        else setDays([...days, +e.target.value])
    }


    return (
        <div className={styles.tabWrapper}>
            <div className={styles.inputsRow}>
                <Input
                    error={values.name === '' ? 'Обязательное поле' : ''}
                    label='Название'
                    name='name'
                    value={values.name}
                    maxLength={50}
                    onChange={e => handleChange(e)} />
            </div>

            <div className={styles.checkbox}>
                <Checkbox
                    label='Для параллелей'
                    name='parellel'
                    checked={parellel}
                    onChange={e => handleCheck(e)}
                />
                <Checkbox
                    label='Для классов'
                    name='classes'
                    checked={classes}
                    onChange={e => handleCheck(e)}
                />
            </div>
            <div className={styles.inputsRowDaily}>
                <div className={styles.inputsColumn}>
                    {!classes && !parellel && <div style={{ width: '300px', height: '300px', }}></div>}
                    {parellel &&
                        <div style={{ width: '300px' }}  >
                            <p>Параллели</p>

                            {PARALLELS?.map(par => {
                                const che = parallels?.find(el => +par == +el)
                                return (
                                    <Checkbox
                                        label={par}
                                        checked={!!che}
                                        value={par}
                                        onChange={(e) => handleParallels(e)}
                                        name='parallels'
                                    // disabled={disabledList2?.find(el => el == par)}
                                    />
                                )
                            }
                            )}
                        </div>

                    }
                    {classes &&
                        <div style={{ width: '300px', height: '300px', border: '1px solid black' }}></div>
                    }
                </div>

                <div className={styles.inputsColumn}>
                    <p>Дни недели</p>

                    {weekForSchedule?.map(par => {
                        const { name, value } = par
                        const che = days.find(el => +value == +el)
                        return (
                            <Checkbox
                                label={name}
                                checked={che == 0 ? true : !!che}
                                value={value}
                                onChange={(e) => handleDays(e)}
                                name='week_days'
                            // disabled={disabledList2?.find(el => el == par)}
                            />
                        )
                    }
                    )}
                </div>
            </div>


        </div>
    )
}

export default Index