import React, { useEffect, useState } from 'react'
import styles from './index.module.css'
import { Input } from '../../../components/ModalInputs'
import { useDispatch, useSelector } from 'react-redux'
import Table from '../../../components/Table'
import Confirmation from '../../../components/Confirmation/Confirmation'
import Modal from 'react-responsive-modal'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import Button from '../../../components/Button'
import { IoIosAdd } from 'react-icons/io'
import { createAdmissionDisciplines } from '../../../actions/admissions'
import { BiTrash } from 'react-icons/bi'
import { removeUnnecessaryKey } from '../../../utils/smallHelpers'



const Index = ({ values, handleChange, setFieldValue, addMode }) => {

    const dispatch = useDispatch()


    const { admissionDisciplines, } = useSelector(state => state.admissions) // содержание как в exams нет сортировки
    console.log('admissionDisciplines::: ', admissionDisciplines);
    const { lessons, } = useSelector(state => state.classbook)

    const [openList, setOpenList] = useState(false)
    const [confirmation, setConfirmation] = useState(false);
    const [selectedPerson, setSelectedPerson] = useState([]);
    // {
    //     "kurs": 5,
    //     "name": "Иностранный язык ",
    //     "lid": 11,
    //     "id": 6,
    //     "question_count": 4,
    //     "school_year": 13,
    //     "stream": 0
    // }
    console.log('selectedPerson::: ', selectedPerson);
    const [openPersonal, setOpenPersonal] = useState(false);
    const [selectedClass, setSelectedClass] = useState([]);

    const keysToDelete = ['id', 'name']

    // const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

    const uniqueDisciplinesKurs = admissionDisciplines && Array.from(new Set(admissionDisciplines.map(item => item.kurs)))
        .map(kurs => admissionDisciplines.find(item => (item.kurs === kurs)).kurs);

    const acceptConfirmation = () => {
        // dispatch(deleteAdmissionComitet({ mid: confirmation.id, id: data.id }));
        setConfirmation(false);
    };

    const rejectConfirmation = () => {
        setConfirmation(false);
    };

    const openModalPersonal = () => {
        setOpenPersonal(true)
    }
    const openClassList = (kurs) => {
        setSelectedClass(kurs)
        setOpenList(!openList)
    }
    const closeModalPersonal = () => {
        setOpenPersonal(false)
    }

    const handleSubmitPersonal = () => {
        dispatch(createAdmissionDisciplines(removeUnnecessaryKey({ ...selectedPerson }, ...keysToDelete)))
        closeModalPersonal()
    }

    return (
        <>
            <div className={styles.modalLocal} >
                {confirmation && (
                    <Confirmation
                        top="30px"
                        left="40px"
                        title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
                        acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
                        rejectConfirmation={rejectConfirmation}
                    />
                )}
                <div className={styles.tabWrapperModal} >

                    {/* МОДАЛКА С ВЫБОРОМ  НОВОЙ ДИСЦ  */}
                    {openPersonal &&
                        < Modal
                            open={openPersonal}
                            onClose={() => false}
                            showCloseIcon={false}
                            classNames={{
                                // overlay: styles.overlay,
                                modal: styles.custom
                            }}
                            center
                        >
                            <div className={styles.modalLocal} >
                                <div className={styles.tabWrapperSubModal} >
                                    <Table >
                                        <thead>
                                            <tr>
                                                <th>Дисциплина</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {lessons && lessons.map(el =>
                                                <tr key={el.lid} className={selectedPerson.lid == el.lid ? styles.person : ''}>
                                                    <td onClick={() => setSelectedPerson(el)} style={{ width: '550px' }} >{el.name}</td>
                                                    {/* <td><i className={styles.trash} onClick={() => setConfirmation({ id: el.lid, name: el.name })}><BiTrash /></i></td> */}
                                                </tr>)}
                                        </tbody>
                                    </Table>

                                </div>
                            </div>
                            <ModalFooter handleSubmit={handleSubmitPersonal} closeModal={closeModalPersonal} />
                        </Modal>
                    }
                    {/* МОДАЛКА С ВЫБОРОМ НОВОЙ ДИСЦ */}
                    <Table className={styles.tablePersonal}>
                        <thead>
                            <tr>
                                <th style={{ width: '550px' }}>Дисциплина</th>
                                <th style={{ width: '250px' }}>Кол-во вопросов</th>
                            </tr>
                        </thead>
                        <tbody>
                            {admissionDisciplines && uniqueDisciplinesKurs.map(kurs =>
                                <>
                                    <td className={styles.class} colspan='3' onClick={() => openClassList(kurs)} >
                                        <p>
                                            {kurs} класс
                                        </p>
                                    </td>

                                    {openList && values && values.exams.map(el =>

                                        <>
                                            {/* пример {"examname": "Русский язык","sorting": 2} */}
                                            <tr key={el.sorting} >
                                                <td onClick={() => { }} style={{ width: '550px' }} >{el.examname}</td>
                                                <td onClick={() => { }} style={{ width: '250px' }} >{el.sorting}</td>
                                            </tr>
                                        </>

                                    )}
                                </>

                            )}

                        </tbody>
                        {/* <td><i className={styles.trash} onClick={() => setConfirmation({ id: el.mid, name: el.fio })}><BiTrash /></i></td> */}
                    </Table>
                    <div className={styles.inputsRow}>
                        <Button
                            style={{ padding: '.5rem 1.5rem', margin: '12px' }}
                            onClick={openModalPersonal}
                        >
                            <IoIosAdd size={30} />
                            Добавить
                        </Button>

                    </div>

                </div>
            </div>

        </>
    )
}

export default Index