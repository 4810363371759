import React, { useEffect } from 'react'
import Modal from 'react-responsive-modal'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import styles from '../styles.module.scss'
import { useFormik } from 'formik'
import { Input, Select, Quill, Checkbox } from '../../../components/ModalInputs'
import { useDispatch, useSelector } from 'react-redux'
import { createEnrollQuestion, getEnrollQuestionData, updateEnrollQuestion } from '../../../actions/enrollQuestions'
import { getSchoolYears } from '../../../actions/common'

const STASUSES = [
  {name: 'Действующий', value: 0},
  {name: 'Архивный', value: 1},
  {name: 'Тестовый', value: 2}
]

const TYPES = [
  {name: 'Все', value: 0},
  {name: 'Выбор', value: 1},
  {name: 'Верно/Неверно', value: 2},
  {name: 'Примеры', value: 3},
  {name: 'Задача', value: 4},
  {name: 'Задача с решением', value: 5},
  {name: 'Заполнение пропусков', value: 6},
  {name: 'Перевод в контексте', value: 7},
  {name: 'Перестановка слов', value: 8},
  {name: 'Перестановка предложений', value: 9},
  {name: 'Классификация', value: 10},
  {name: 'Вопросы к тесту', value: 11},
]

const initialValues = {
  question_type: 11,
  status: 2,
  level: 1,
  block: 1
}

const ModalComponent = ({addMode, open, closeModal, data, filters}) => {
  
  const modalTitle = addMode ? 'Добавление вопроса' : 'Редактирование вопроса'
  
  const dispatch = useDispatch()
  const {data: item, blocks, lessons, levels} = useSelector(state => state.enroll_questions)
  const { schoolYears } = useSelector(state => state.common)


  useEffect(() => {
    setValues(initialValues)
    !addMode && dispatch(getEnrollQuestionData(data.id))
    !schoolYears.length && dispatch(getSchoolYears())
  }, [open])

  useEffect(() => {
    item && !addMode && setValues(item)
  }, [item])
  
  const handleCreate = (values) => {
    dispatch(createEnrollQuestion(values, filters))
    closeModal()
  }
  
  const handleUpdate = ({id, ...values}) => {
    dispatch(updateEnrollQuestion(id, values, filters))
    closeModal()
  }

  const {
    values,
    setValues,
    handleChange,
    setFieldValue,
    handleSubmit
  } = useFormik({
    initialValues,
    onSubmit: addMode ? handleCreate : handleUpdate
  })

  const renderOption = (item) => {
    const {name, value, xp_key, block, lid, year} = item
    const optionName = name || block || year
    const optionValue = value || xp_key || block || lid
    return <option key={optionValue} value={optionValue}>{optionName}</option>
  }

  return (
    <Modal
      open={open}
      showCloseIcon={false}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      classNames={{modal: styles.modal}} 
      animationDuration={1}
    >
      
      <h2>{modalTitle}</h2>
      
      <div>

        <div>
          <Select
            value={values.status}
            name="status"
            onChange={handleChange}
            label="Статус"
          >
            {STASUSES.map(renderOption)}
          </Select>
          
          <Select
            value={values.school_year}
            name="school_year"
            onChange={handleChange}
            label="Год поступления"
          >
            <option value=''>Не выбрано</option>
            {schoolYears.map(renderOption)}
          </Select>

          <Input
            value={values.q_num}
            name="q_num"
            onChange={handleChange}
            type="number"
            label="№"
            style={{width: '50px'}}
            />

          <Select
            value={values.level}
            name="level"
            onChange={handleChange}
            label="Класс"
            >
            {levels.map(renderOption)}
          </Select>

          <Select
            value={values.block}
            name="block"
            onChange={handleChange}
            label="Блок"
            >
            {blocks.map(renderOption)}
          </Select>

          <Input
            value={values.weight}
            name="weight"
            onChange={handleChange}
            type="number"
            label="Сложность"
            style={{width: '50px'}}
          />

          <Select
            value={values.lesson}
            name="lesson"
            onChange={handleChange}
            label="Дисциплина"
          >
            <option value=''>Не выбрано</option>
            {lessons.map(renderOption)}
          </Select>

          <Select
            value={values.question_type}
            name="question_type"
            onChange={handleChange}
            label="Вид вопроса"
            disabled
          >
            {TYPES.map(renderOption)}
          </Select>

          <Input
            value={values.q_name}
            name="q_name"
            onChange={handleChange}
            label="Наименование"
          />

          <Checkbox
            label='Ввод решения'
          />

          <Checkbox
            label='Скрывать от претендента'
          />

        </div>

        <Quill
          value={values.question_text}
          onChange={val => setFieldValue('question_text', val)}
          className={styles.quill}
        />

      </div>

      <ModalFooter closeModal={closeModal} handleSubmit={handleSubmit}/>
    </Modal>
  )
}

export default ModalComponent