import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import Li from '../../components/Li'
import { IoIosClose } from 'react-icons/io'
import Button from '../../components/Button'
import Modal from 'react-responsive-modal'
import { useFormik } from 'formik'
import { Checkbox, Input } from '../../components/ModalInputs'
import Confirmation from '../../components/Confirmation/Confirmation'
import { createEventLevel, deleteEventLevel, getEventLevels, updateEventLevel } from '../../actions/event_levels'
import { BiTrash } from 'react-icons/bi'

const INITIAL_VALUES = {
  news_level: '',
  reporthse: 0
}

const EventLevels = ({ data, closeSubModal, setModalValue }) => {
  console.log('dEventLevelsEventLevelsEventLevelsata::: ', data);

  const dispatch = useDispatch()
  const { list, loading } = useSelector(state => state.event_levels)

  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(null)
  const [select, setSelect] = useState(null)

  useEffect(() => {
    dispatch(getEventLevels())
  }, [])

  const deleteItem = (id) => (e) => {
    e.stopPropagation()
    setConfirmation(id)
  }

  const openModal = (data) => () => setSelectedItem(data || {})

  const closeModal = () => setSelectedItem(null)

  const handleSelect = (item) => {
    if (data === 'eventList') {
      const isSelected = select?.news_level_id === item.news_level_id;
      setSelect(isSelected ? null : item);
      if (!isSelected) setModalValue({ news_level_id: item.news_level_id });
    }
  };

  const renderLi = (item) => (
    <Li
      onClick={() => handleSelect(item)}
      onDoubleClick={openModal(item)}
      style={{ backgroundColor: select?.news_level_id === item.news_level_id ? '#3689FF' : '' }}
    >
      <span>{item.news_level}</span>
      <BiTrash size={24} color='red' title='Удалить' onClick={deleteItem(item.news_level_id)} />
    </Li>
  )

  const acceptConfirmation = () => {
    dispatch(deleteEventLevel(confirmation))
    setConfirmation(null)
  }

  const rejectConfirmation = () => {
    setConfirmation(null)
  }

  return (
    <div className={styles.wrapper}>
      <h1>Уровни мероприятий</h1>
      <ul>{list?.map(renderLi)}</ul>
      <div className={styles.bottonsRow}>
        <Button onClick={openModal()}>Добавить</Button>
        {
          !!select?.news_level_id && <Button onClick={() => closeSubModal()}>Выбрать</Button>
        }
      </div>
      <ModalComponent
        open={!!selectedItem}
        closeModal={closeModal}
        data={selectedItem}
        addMode={!selectedItem?.news_level_id}
      />
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title="Уверены что хотите удалить запись?"
          acceptConfirmation={acceptConfirmation}
          rejectConfirmation={rejectConfirmation}
        />
      )}
    </div>
  )
}


const ModalComponent = ({ open, closeModal, data, addMode }) => {

  const dispatch = useDispatch()

  useEffect(() => {
    if (!open) return;
    setValues(addMode ? INITIAL_VALUES : data)
  }, [open])


  const createItem = async (values) => {
    const success = await dispatch(createEventLevel(values))
    success && closeModal()
  }

  const updateItem = async ({ news_level_id, ...values }) => {
    const success = await dispatch(updateEventLevel(news_level_id, values))
    success && closeModal()
  }

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    setValues,
    setFieldValue
  } = useFormik({
    initialValues: addMode ? {} : data,
    enableReinitialize: true,
    onSubmit: addMode ? createItem : updateItem
  })

  return (
    <Modal
      open={open}
      onClose={closeModal}
      classNames={{ modal: styles.modal }}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      showCloseIcon={false}
      animationDuration={2}
      center
    >
      <div className={styles.modalMain}>
        <h2>{addMode ? 'Добавление записи' : 'Редактирование записи'}</h2>
        <Input
          name='news_level'
          label='Название'
          value={values.news_level}
          onChange={handleChange}
        />
        <Checkbox
          label='Закрыт'
          checked={!!values.closed}
          onChange={() => setFieldValue('closed', Number(!values.closed))}
        />
      </div>
      <div className={styles.modalFooter}>
        <div>
          <div></div>
          <div></div>
        </div>
        <Button mode='white' type="submit" onClick={handleSubmit}>Сохранить</Button>
        <Button mode='white' onClick={closeModal}>Закрыть</Button>
      </div>
    </Modal>
  )
}


export default EventLevels