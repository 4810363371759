import React, { useContext, useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../../../components/Button'
import { IoIosAdd } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Modal from './Modal'
import Table from '../../../../components/Table'
import { BiTrash } from 'react-icons/bi'
import Confirmation from '../../../../components/Confirmation/Confirmation'
import { deleteManners } from '../../../../actions/directoryBasicEducation'
import { CandidatesContext } from '../../../../utils/context'
import { Select } from '../../../../components/ModalInputs'


const contraindicationsList = [
  { name: 'все', id: 3 },
  { name: 'действующие', id: 4 },
  // { name: 'Вакцинация не рекомендована', id: 5 }
]

export default function Contraindications({ diagnosisComplaintList, setDiagnosisComplaint, closeComplain }) {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { student } = useContext(CandidatesContext)

  const { vacExclusions } = useSelector(state => state.directoryMedCenter)

  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(false);
  const [all, setAll] = useState();

  useEffect(() => {
    // dispatch(getMannersList())
  }, [])

  const openModal = (data) => () => {
    setSelectedItem(data || {})
  }
  const selected = (data) => () => {
    setDiagnosisComplaint(data)
    closeComplain()
  }

  const closeModal = () => setSelectedItem(null)

  const handleChange = (e) => setAll(e.target.value)

  const goHome = () => navigate('/')

  const acceptConfirmation = () => {
    closeModal();
    dispatch(deleteManners(confirmation.id));
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  const renderLi = (data) => {
    const { id, vac_name, type_name, doctor, ex_date, to_date, description, } = data
    return (
      <tr >
        {/* <td onClick={openModal(data)}>{name}</td> */}
        <td onClick={selected(data)}>{type_name}</td>
        <td onClick={selected(data)}>{vac_name}</td>
        <td onClick={selected(data)}>{doctor}</td>
        <td onClick={selected(data)}>{ex_date}</td>
        <td onClick={selected(data)}>{to_date}</td>
        <td onClick={selected(data)}>{description}</td>

        <td><i className={styles.trash} onClick={() => setConfirmation({ id: id, name: type_name })}><BiTrash /></i></td>
      </tr>
    )
  }

  const renderOption = (option, index) => {
    const { xp_key, value, trmid, cid, gid, work_status, id, pmid, did, name, title, year, access, diagnosis, disease, iddischarge, discharge, c_vac_type } = option
    const optionValue = xp_key || value || trmid || cid || gid || work_status || id || pmid || did || disease || iddischarge || c_vac_type;
    const optionTitle = name || title || value || year || work_status || access || diagnosis || discharge;
    return <option key={`${optionValue}+${index}`} value={optionValue}>{optionTitle}</option>
  }
  return (
    <div className={styles.wrapper} style={{ width: '780px', height: '320px', margin: '0 auto' }}>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
          acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      {selectedItem &&
        <Modal
          closeModal={closeModal}
          data={selectedItem}
          height={'430px'}
          // addMode={(!selectedItem?.name && selectedItem !== null)}
          addMode={!selectedItem.name}
          student={student}
        />
      }
      <h1>Противопоказания</h1>

      <Table className={styles.tables}>
        <thead>
          <tr>
            <th>Тип прививки</th>
            <th>Вакцина</th>
            <th>Врач</th>
            <th>Выдано</th>
            <th>Действует по</th>
            <th>Основание</th>
            <th></th>
          </tr>
        </thead>
        <tbody>

          {vacExclusions?.map(renderLi)}

        </tbody>
      </Table>

      <div style={{ display: 'flex', flexDirection: "row" }}>
        <Select
          label=''
          name='ex_type' //TODO mockDAta
          value={all}
          onChange={(e) => handleChange(e)}
          style={{ width: '100px' }}
          options={contraindicationsList}
        >
          <option value=''>Выбрать</option>
          {contraindicationsList?.map(renderOption)}
        </Select>

        <Button
          style={{ padding: '.5rem 1.5rem', margin: '12px' }}
          onClick={openModal()}
        >
          <IoIosAdd size={30} />
          Добавить
        </Button>
      </div>
    </div>
  )
}
