import { api } from "../App";
import { SET_JOURNAL_CONTROL_COURSES, SET_JOURNAL_CONTROL_COURSES_ALL, SET_JOURNAL_CONTROL_DATA, SET_JOURNAL_CONTROL_GROUPS, SET_JOURNAL_CONTROL_LOADING, SET_JOURNAL_CONTROL_PERIODS, SET_PHYSICAL_GROUP_LIST, SET_PHYSICAL_GROUP_LOADING } from "./types";




export const getJournalControlPeriods = (params) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_JOURNAL_CONTROL_LOADING, payload: true });
    const { data } = await api.get('journal_control', { params });
    dispatch({ type: SET_JOURNAL_CONTROL_PERIODS, payload: data });
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_JOURNAL_CONTROL_LOADING, payload: false });
  }
}

export const getJournalControlGroups = (params) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_JOURNAL_CONTROL_LOADING, payload: true });
    const { data } = await api.get('journal_control', { params });
    dispatch({ type: SET_JOURNAL_CONTROL_GROUPS, payload: data });
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_JOURNAL_CONTROL_LOADING, payload: false });
  }
}


export const getJournalControlCourses = (params) => async (dispatch, getState) => {
  console.log('params::: ', params);
  try {
    dispatch({ type: SET_JOURNAL_CONTROL_LOADING, payload: true });
    const { data } = await api.get('journal_control', { params });
    dispatch({ type: SET_JOURNAL_CONTROL_COURSES, payload: data });
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_JOURNAL_CONTROL_LOADING, payload: false });
  }
}

export const getJournalControlCoursesAll = (params) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_JOURNAL_CONTROL_LOADING, payload: true });
    const { data } = await api.get('journal_control/courses');
    dispatch({ type: SET_JOURNAL_CONTROL_COURSES_ALL, payload: data });
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_JOURNAL_CONTROL_LOADING, payload: false });
  }
}

export const getJournalControlData = (getParams) => async (dispatch, getState) => {
  console.log('getParams::: ', getParams);
  try {
    dispatch({ type: SET_JOURNAL_CONTROL_LOADING, payload: true });
    const params = getParams || getState().journal_control.filters
    const { data } = await api.get('journal_control', { params });
    dispatch({ type: SET_JOURNAL_CONTROL_DATA, payload: data, params });
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_JOURNAL_CONTROL_LOADING, payload: false });
  }
}

export const createJournalControl = (data) => async (dispatch, getState) => {
  let success
  try {
    dispatch({ type: SET_JOURNAL_CONTROL_LOADING, payload: true });
    await api.post('journal_control', data);
    await dispatch(getJournalControlData())
    success = true
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_JOURNAL_CONTROL_LOADING, payload: false });
    return success
  }
}

export const updateJournalControl = (id, data) => async (dispatch, getState) => {
  let success
  try {
    dispatch({ type: SET_JOURNAL_CONTROL_LOADING, payload: true });
    await api.put(`journal_control/${id}`, data);
    await dispatch(getJournalControlData())
    success = true
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_JOURNAL_CONTROL_LOADING, payload: false });
    return success
  }
}



export const deleteJournalControl = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_JOURNAL_CONTROL_LOADING, payload: true });
    await api.delete(`journal_control/${id}`);
    await dispatch(getJournalControlData())
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_JOURNAL_CONTROL_LOADING, payload: false });
  }
}