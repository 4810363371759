import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getPhysicalGroups, deletePhysicalGroup, createPhysicalGroup, updatePhysicalGroup } from '../../actions/physicalGroup'
import Li from '../../components/Li'
import Button from '../../components/Button'
import Modal from 'react-responsive-modal'
import { useFormik } from 'formik'
import { Input } from '../../components/ModalInputs'
import Confirmation from '../../components/Confirmation/Confirmation'
import { BiTrash } from 'react-icons/bi'




const PhysicalGroup = () => {
  
  const dispatch = useDispatch()
  const { list, loading } = useSelector(state => state.physical_group)

  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(null)


  useEffect(() => {
    dispatch(getPhysicalGroups())
  }, [])
  
  const deleteItem = (id) => (e) => {
    e.stopPropagation()
    setConfirmation(id)
  }

  const openModal = (data) => () => setSelectedItem(data || {})

  const closeModal = () => setSelectedItem(null)

  const renderLi = (item) => (
    <Li onClick={openModal(item)}>
      {item.title}
      <BiTrash size={24} color='red' title='Удалить' onClick={deleteItem(item.pid)}/>
    </Li>
  )
  
  const acceptConfirmation = () => {
    dispatch(deletePhysicalGroup(confirmation))
    setConfirmation(null)
  }
  
  const rejectConfirmation = () => {
    setConfirmation(null)
  }

  return (
    <div className={styles.wrapper}>
      <h1>Физкультурные группы</h1>
      <ul>{list?.map(renderLi)}</ul>
      <Button onClick={openModal()}>Добавить</Button>
      <ModalComponent
        open={!!selectedItem}
        closeModal={closeModal}
        data={selectedItem}
        addMode={!selectedItem?.pid}
      />
      {confirmation && (
        <Confirmation
            top="30px"
            left="40px"
            title="Уверены что хотите удалить запись?"
            acceptConfirmation={acceptConfirmation}
            rejectConfirmation={rejectConfirmation}
        />
      )}
    </div>
  )
}


const ModalComponent = ({open, closeModal, data, addMode}) => {
  
  const dispatch = useDispatch()

  useEffect(() => {
    if (!open) return;
    setValues(addMode ? {} : data)
  }, [open])
  

  const createItem = async (values) => {
    const success = await dispatch(createPhysicalGroup(values))
    success && closeModal()
  }
  
  const updateItem = async ({pid, ...values}) => {
    const success = await dispatch(updatePhysicalGroup(pid, values))
    success && closeModal()
  }

  const {
    values,
    errors, 
    handleSubmit,
    handleChange,
    setValues
  } = useFormik({
    initialValues: addMode ? {} : data,
    enableReinitialize: true,
    onSubmit: addMode ? createItem : updateItem
  })
  
  return (
    <Modal
      open={open}
      onClose={closeModal}
      classNames={{modal: styles.modal}}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      showCloseIcon={false}
      animationDuration={2}
      center
    >
      <div className={styles.modalMain}>
        <h2>{addMode ? 'Добавление записи' : 'Редактирование записи'}</h2>
        <Input
          name='title'
          label='Название'
          value={values.title}
          onChange={handleChange}
        />
      </div>
      <div className={styles.modalFooter}>
          <div>
              <div>Создал: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
              <div>Изменил: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
          </div>
          <Button mode='white' type="submit" onClick={handleSubmit}>Сохранить</Button>
          <Button mode='white' onClick={closeModal}>Закрыть</Button>
      </div>
    </Modal>
  )
}


export default PhysicalGroup