import React, { useEffect } from 'react'
import styles from '../styles.module.css'
import Table from '../../../components/Table'
import { useDispatch, useSelector } from 'react-redux'
import { getStudents } from '../../../actions/students/list'

export default function Absences({ active }) {
  const dispatch = useDispatch()

  // ЗАМЕЩЕНИЕ ДАННЫХ
  const { list } = useSelector(state => state.students)
  const mockData = list && list.slice(0, 10)

  useEffect(() => {
    dispatch(getStudents())
  }, [])
  // ЗАМЕЩЕНИЕ ДАННЫХ
  const renderLi = (data) => {
    const { fio, international_passport_series, mid } = data
    return (
      <tr >
        <td>{fio}</td>
        <td>{international_passport_series}</td>
        <td>{mid}</td>
        {/* <td><i className={styles.trash} onClick={deleteItemHandler(data)}><BiTrash /></i></td> */}
      </tr>
    )
  }

  return (
    <div >
      <Table className={styles.tables}>
        <thead>
          <tr>
            <th>Ученица</th>
            <th>Количество дней</th>
            <th>Количество уроков</th>
          </tr>
        </thead>
        <tbody>
          {mockData?.map(renderLi)}
        </tbody>
      </Table>
    </div>
  )
}
