import React, { useEffect } from 'react'
import styles from './index.module.css'
import { Select } from '../../../components/ModalInputs'
import { useDispatch, useSelector } from 'react-redux';
import {getYears, getKurses} from '../../../actions/exam_board'
import Button from '../../../components/Button';

const defaultStreams = [{name: 'Основной', value: 0}, {name: 'Донабор', value: 1}];

const Index = ({filters, setFilters, getExamBoardList}) => {

    const {years, kurses} = useSelector(state => state.exam_board);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getYears());
    }, [])

    useEffect(() => { //выбрать актуальный год в years select
        if (years) {
            const actual = years.find(el => el.actual === true);
            setFilters({...filters, school_year: actual?.xp_key})
        }
    }, [years])

    useEffect(() => { //получать список классов по году обучения
        if (filters.school_year) {
            dispatch(getKurses(filters.school_year));
        }
    }, [filters.school_year])

    const handleChange = (name, value) => { // Обработчик изменения параметров поиска
        setFilters({
            ...filters,
            [name]: value
        });
    }

    const handleGetData = () => {
        dispatch(getExamBoardList(filters));
    }

    return (
        <div>
            <div className={styles.container}>
                <div className={styles.inputsRow}>
                    <div className={styles.container_select}>
                        <Select
                        name='school_year'
                        label='Год набора'
                        value={filters.school_year}
                        onChange={(e) => handleChange(e.target.name, Number(e.target.value))}>
                        {years
                            .map(el => <option value={el.xp_key}>{el.name}</option>)}
                            </Select>
                    </div>
                    
                    <div className={styles.container_select}>
                        <Select
                        name='stream'
                        label='Тип'
                        value={filters.stream}
                        onChange={(e) => handleChange(e.target.name, Number(e.target.value))}>
                        {defaultStreams
                            .map(el => <option value={el.value}>{el.name}</option>)}
                            </Select>
                    </div>
                    
                    <div className={styles.container_select}>
                        <Select
                        name='kurs'
                        label='Класс'
                        value={filters.kurs}
                        onChange={(e) => handleChange(e.target.name, Number(e.target.value))}>
                        {[{kurs: 0, kursname: 'Все'}, ...kurses]
                            .map(el => <option value={el.kurs}>{el.kursname}</option>)}
                            </Select>
                    </div>

                    <Button className={styles.button} onClick={() => handleGetData()}>Обновить</Button>
                </div>
            </div>
        </div>
    )
}

export default Index