import React, { useRef } from 'react'
import Button from '../../components/Button'
import styles from './styles.module.scss'
// import Select from '../../components/Select'
import { useState } from 'react'
import { useFormik } from 'formik'
import Modal from './modal'
import { useNavigate } from 'react-router-dom'
import Table from '../../components/Table'
import { Pagination } from '../../components/Pagination'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getCandidatesList, setPage } from '../../actions/candidates'
import { getCLasses, getParallels, getSchoolYears, getTeachersList } from '../../actions/common'
import useDoubleClick from 'use-double-click'
import classNames from 'classnames'
import { Checkbox, Input } from '../../components/ModalInputs'
import Select from '../../components/Select'
import { getStudentMain } from '../../actions/students/main'
import moment from 'moment'
// import Select from '../../components/Select copy'
import { getEducDirections } from '../../actions/educDirections'
import { getAchievements } from '../../actions/achievements'
import { getEventTypes } from '../../actions/ads'
import { getActTypes } from '../../actions/actTypes'
import { getEventLevels } from '../../actions/event_levels'
import SelectPal from '../../components/Select copy'
import { arrayForSelect } from '../../utils/arrayForSelect'
import { getEventsList } from '../../actions/events_list'

const PARALLELS = [
  { name: "1", value: '1' },
  { name: "2", value: '2' },
  { name: "3", value: '3' },
  { name: "4", value: '4' },
]


export default function EventsList() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { eventsList } = useSelector(state => state.events_list)
  const { data } = useSelector(state => state.students)

  const { list: event_levels } = useSelector(state => state.event_levels)
  const { list: event_type } = useSelector(state => state.event_type)
  const { list: educ_directions } = useSelector(state => state.educ_directions)
  const { list: act_types } = useSelector(state => state.act_types)
  const { list: achievements, } = useSelector(state => state.achievements)

  // ДАННЫЕ ПОДТВЕРДИТЬ !!! 
  const { teachers, classes } = useSelector(state => state.common);
  // ДАННЫЕ ПОДТВЕРДИТЬ !!! 
  const studentData = data && data.main?.[0]

  const [studentId, setStudentId] = useState(null)

  useEffect(() => {
    dispatch(getCandidatesList())
    dispatch(getSchoolYears())

    dispatch(getEducDirections())
    dispatch(getAchievements())
    dispatch(getEventTypes())
    dispatch(getActTypes())
    dispatch(getEventLevels())
    dispatch(getEventsList())

    // ДАННЫЕ ПОДТВЕРДИТЬ !!!  
    dispatch(getTeachersList());
    !classes && dispatch(getCLasses())
    // ДАННЫЕ ПОДТВЕРДИТЬ !!! 
  }, [])

  const openModal = (id) => () => {
    setStudentId(true)
  }

  const closeModal = () => setStudentId(null)


  const goHome = () => navigate('/')

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue
  } = useFormik({
    initialValues: {},
  })
  console.log('values::: ', values);
  return (
    <div className={styles.wrapper}>
      <Modal
        student={studentId}
        isOpen={!!studentId}
        closeModal={closeModal}
        studentData={studentData}
      />

      <h1>Список мероприятий</h1>
      <div className={styles.filters}>
        <div className={styles.filtersBlock}>

          <Input
            error={values.periodfrom === '' ? 'Обязательное поле' : ''}
            type='date'
            label='Период действия с'
            name='periodfrom'
            value={moment(values.periodfrom).subtract(1, 'month').format('YYYY-MM-DD')}
            onChange={e => handleChange(e)}
          />
          <Input
            error={values.periodtill === '' ? 'Обязательное поле' : ''}
            type='date'
            label='Период действия по'
            name='periodtill'
            value={moment(values.periodtill).format('YYYY-MM-DD')}
            onChange={e => handleChange(e)}
          />

          <SelectPal
            title='Параллель'
            valuesName="parallel"
            options={[{ name: "Выбрать", acid: 0 },
            ...PARALLELS]}
            style={{ width: '120px' }}
            setFieldValue={setFieldValue}
          />

          <SelectPal
            title='Класс'
            valuesName="class"
            options={classes && arrayForSelect(classes, 'name', 'gid')}
            style={{ width: '120px' }}
            setFieldValue={setFieldValue}
          />
          <SelectPal
            title='Ответственный'
            valuesName="responsible"
            options={teachers && arrayForSelect(teachers?.teachers, 'calc_fio', 'mid')}
            style={{ width: '120px' }}
            setFieldValue={setFieldValue}
          />
          <SelectPal
            title='Уровень мероприятия'
            valuesName="event_levels"
            options={event_levels && arrayForSelect(event_levels, 'news_level', 'news_level_id')}
            style={{ width: '120px' }}
            setFieldValue={setFieldValue}
          />
          <SelectPal
            title='Форма мероприятия'
            valuesName="event_type"
            options={event_type && arrayForSelect(event_type, 'ev_type', 'ev_type_id')}
            style={{ width: '120px' }}
            setFieldValue={setFieldValue}
          />
          <SelectPal
            title='Тип события'
            valuesName="act_types"
            options={act_types && arrayForSelect(act_types, 'name', 'acid')}
            style={{ width: '120px' }}
            setFieldValue={setFieldValue}
          />
          <SelectPal
            title='Направление воспитательной работы'
            valuesName="educ_directions"
            options={educ_directions && arrayForSelect(educ_directions, 'name', 'edid')}
            style={{ width: '120px' }}
            setFieldValue={setFieldValue}
          />
        </div>
        <div className={styles.filtersBlock}>
          <Input
            error={values.edupart === '' ? 'Обязательное поле' : ''}
            label='Поиск по названию'
            name='search'
            maxLength={25}
            value={values.search}
            onChange={handleChange}
          />
          <div className={styles.checkbox}>
            <Checkbox
              label='Мои мероприятия'
              name='myEvents'
              checked={values.myEvents}
              onChange={handleChange}
            />
          </div>
          <div className={styles.checkbox}>
            <Checkbox
              label='Входит в план ГУК МО РФ'
              name='includedPlan'
              checked={values.includedPlan}
              onChange={e => handleChange(e)}
            />
          </div>
        </div>
      </div>
      <Button mode='white' onClick={openModal({})}>Добавить</Button>
      {/* <Button mode='white' onClick={setIsCreating}></Button> */}
      {eventsList && eventsList.map(event =>
      (<div className={styles.eventList}>
        <div>Название: {event.title}</div>
        <div>Уровень мероприятия: {(event_levels.find(el => el.news_level_id == event.news_level_id))?.news_level}</div>
        <div></div>

      </div>)

      )}
      <footer className={styles.footer}>
        <div></div>
        <Button mode='white' onClick={goHome}>Закрыть</Button>
      </footer>
    </div >
  )
}


const CandidateItem = ({
  data,
  openModal,
  select
}) => {

  const { fio, value, school_class, mid } = data
  // const index = pageNumber * itemsOnPage + pageIndex + 1
  // const selected = selectedIds.includes(xp_key)
  const rowRef = useRef()

  useDoubleClick({
    onSingleClick: openModal(data),
    // onDoubleClick: openModal(data),
    ref: rowRef,
    latency: 200
  });


  return (
    <tr className={classNames({})} ref={rowRef}>
      {/* <td>{index}</td> */}
      <td>{fio}</td>
      <td>{school_class}</td>
      {/* <td>{child_class}</td>
      <td>{ }</td>
      <td>{ }</td>
      <td>{ }</td>
      <td>{ }</td>
      <td>{ }</td>
      <td>{checked()}</td>
      <td>Подтверждение</td> */}
    </tr>
  )
}