import React from 'react'
import { useContext, useEffect } from 'react'
import { EventListContext } from '../../../utils/context'
import { useDispatch, useSelector } from 'react-redux'
import styles from './styles.module.scss'
import Button from '../../../components/Button'



export default function MembersEmployees() {

  const { student, closeModal } = useContext(EventListContext)

  const dispatch = useDispatch()

  return (
    <>
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          {/* <Contraindications />
          <Inoculations /> */}
        </div>
      </div>
      <div className={styles.modalFooter}>
        <div>
          <div>Создал: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
          <div>Изменил: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
        </div>
        <Button mode='white' onClick={closeModal}>Сохранить</Button>
        <Button mode='white' onClick={closeModal}>Закрыть</Button>
      </div>
    </>
  )
}
