import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { IoIosAdd } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/Loader'
import { useState } from 'react'
import Modal from './Modal'
import Table from '../../components/Table'
import { BiTrash } from 'react-icons/bi'
import Confirmation from '../../components/Confirmation/Confirmation'
import { getReportGroups, deleteReportGroup } from '../../actions/reportGroups'
import { getReportsList } from '../../actions/reports'


export default function ReportGroups () {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { list, loading } = useSelector(state => state.report_groups)
  
  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(false);

  useEffect(() => {
    dispatch(getReportGroups())
    dispatch(getReportsList())
  }, [])


  const openModal = (data) => () => {
    setSelectedItem(data || {})
  }

  const closeModal = () => setSelectedItem(null)

  const goHome = () => navigate('/')

  const acceptConfirmation = () => {
    closeModal();
    dispatch(deleteReportGroup(confirmation.id));
    setConfirmation(false);
  };

  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  const renderLi = (data) => {
    const { name, id } = data
    return (
      <tr>
        <td onClick={openModal(data)}>{name}</td>
        <td><i className={styles.trash} onClick={() => setConfirmation({ id, name })}><BiTrash /></i></td>
      </tr>
    )
  }

  return (
    <div className={styles.wrapper}>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
          acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      <Modal
        closeModal={closeModal}
        addMode={!selectedItem?.id}
        data={selectedItem}
      />
      <h1>Группы отчетов</h1>
      <div style={{ margin: '2rem 0', backgroundColor: 'white' }}>
        <Table>
          <thead>
            <tr>
              <th>Название</th>
            </tr>
          </thead>
          <tbody>
            {list?.map(renderLi)}
          </tbody>
        </Table>
      </div>

      <Button
        style={{ padding: '.5rem 1.5rem' }}
        onClick={openModal()}
      >
        <IoIosAdd size={30} />
        Добавить
      </Button>
    </div>
  )
}
