import React, { useEffect } from 'react'
import styles from './styles.module.scss'
import Button from '../../components/Button'
import { useSelector } from 'react-redux'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { TeachersSubjects, CabinetsSubjects, CabinetsTeachers, ComplexityClassesSubjects, TeachersLesson, WorkloadTeachersClass } from './Tabs'
import { useNavigate } from 'react-router-dom'



export default function ScheduleSettings() {

  const navigate = useNavigate()
  const goHome = () => navigate('/')

  const { scheduleSettingsTeachersSubList, scheduleSettingsRoomsSubList, scheduleSettingsRooms } = useSelector(state => state.schedule_settings)

  return (
    !!scheduleSettingsTeachersSubList &&
    <div className={styles.wrapper}>
      <h1>Настройки расписания</h1>
      <Tabs>
        <TabList className={styles.tabsBlock}>
          <Tab className={styles.tab} selectedClassName={styles.activeTab}>Преподаватели-Предметы</Tab>
          <Tab className={styles.tab} selectedClassName={styles.activeTab}>Кабинеты-Предметы</Tab>
          <Tab className={styles.tab} selectedClassName={styles.activeTab}>Кабинеты-Преподаватели</Tab>
          <Tab className={styles.tab} selectedClassName={styles.activeTab}>Сложность Классы-Предметы</Tab>
          <Tab className={styles.tab} selectedClassName={styles.activeTab}>Преподаватели-Уроки</Tab>
          <Tab className={styles.tab} selectedClassName={styles.activeTab}>Нагрузка преподавателей по классам</Tab>
        </TabList>
        <TabPanel>
          <TeachersSubjects />
        </TabPanel>
        <TabPanel>
          <CabinetsSubjects />
        </TabPanel>
        <TabPanel>
          <CabinetsTeachers />
        </TabPanel>
        <TabPanel>
          <ComplexityClassesSubjects />
        </TabPanel>
        <TabPanel>
          <TeachersLesson />
        </TabPanel>
        <TabPanel>
          <WorkloadTeachersClass />
        </TabPanel>
      </Tabs>
      <footer className={styles.footer}>
        <Button mode='white' onClick={() => { }}>Сохранить</Button>
        <Button mode='white' onClick={goHome}>Закрыть</Button>
      </footer>
    </div>

  )
}
