import { education, services, comission, settings, more, reference } from './svg/menuSVG'
import { img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12 } from './images/main'
import { main, adresses, priveleges, parents, admission, medicine, education as educ, documents, candidateInfo, more as moree, qualification, award, punish, experience, lesson, event, role, attestation, history, curator } from './svg/modalSVG'

export const version = '27.03.1'

export const BACKEND_URL_PREFIX = process.env.REACT_APP_URL
export const BACKEND_DOMAIN = process.env.REACT_APP_BACKEND_DOMAIN

export const itemsOnPage = 15

export const menuItems = [
    {
        label: 'Образование',
        link: '/education',
        icon: education,
        id: 1,
        menu: [{
            label: 'Основное образование',
            link: '/education',
            id: 11,
            menu: [{ label: 'Настройки расписания', link: '/schedule_settings' },
            { label: 'Варианты расписания', link: '/schedule_variants' },
            { label: 'Составление расписания', link: '/scheduling' },
            { label: 'Расписание', link: '/schedule' },
            { label: 'Классный журнал', link: '/classbook' },
            { label: 'Домашние задания', link: '/homeworks' },
            { label: 'Тематическое планирование', link: '/thematic_planning' },
            { label: 'Структура учебного предмета', link: '/discipline_structure_ba' },
            { label: 'Электронная зачетка', link: '/digital_exam_book' },
            { label: 'Контроль ведения классного журнала', link: '/journal_control' },
            { label: 'Учебный план', link: '/study_plans' },
            { label: 'Предметы', link: '/courses' },
            { label: 'Предметы по годам обучения', link: '/subjects_by_year_study' },
            { label: 'Классы', link: '/classing' },
            { label: 'Подгруппы', link: '/subgroups' },
            { label: 'Критерии воспитанности', link: '/manners' },
            { label: 'Журнал воспитанности', link: '/journal_manners' },
            {
                label: 'ОГЭ', link: '/', id: 111,
                menu: [
                    { label: 'Информация о ОГЭ', link: '/oge/info', id: 1111 },
                    { label: 'Расписание ОГЭ', link: '/oge/schedule', id: 1112 },
                    { label: 'Результаты ОГЭ', link: '/oge/report', id: 1113 },
                ]
            },
            {
                label: 'ЕГЭ', link: '/', id: 112,
                menu: [
                    { label: 'Информация о ЕГЭ', link: '/ege/info', id: 1121 },
                    { label: 'Расписание ЕГЭ', link: '/ege/schedule', id: 1122 },
                    { label: 'Результаты ЕГЭ', link: '/ege/report', id: 1123 },
                ]
            },
            { label: 'Профили обучения', link: '/track_profile' },
            { label: 'Учебные смены', link: '/study_shifts' },
            { label: 'Образовательные направления', link: '/edu_directions' },
            { label: 'Культура', link: '/culture' },
            { label: 'Компетентность', link: '/competence' },
            {
                label: 'Методическая работа', link: '/', id: 113,
                menu: [
                    { label: 'Учебно-методические комплекты', link: '/', id: 1131 },
                    { label: 'Методические объединения', link: '/', id: 1132 },
                    { label: 'Уровни методических объединений', link: '/', id: 1133 },
                ]
            },
            { label: 'Открытый урок', link: '/' },
            { label: 'Тестовые задания', link: '/' },
            { label: 'Электронная ручка - журналы', link: '/' },
            ]
        },
        {
            label: 'Дополнительное образование',
            link: '/education',
            id: 12,
            menu: [{ label: 'Настройки расписания ДО', link: '/schedule_settings_do' },
            { label: 'Варианты расписания ДО', link: '/do_schedule_variants' },
            { label: 'Составление расписания ДО', link: '/scheduling_do' },
            { label: 'Личное расписание ДО', link: '/personal_schedule_do' },
            { label: 'Журнал ДО', link: '/classbook_do' },
            { label: 'Тематическое планирование ДО', link: '/' },
            { label: 'Структура учебного предмета ДО', link: '/discipline_structure_do' },
            { label: 'Контроль ведения  журнала ДО', link: '/journal_control_do' },
            { label: 'Учебный план ДО', link: '/study_plans_do' },
            { label: 'Предметы ДО', link: '/subjects_extra_edu' },
            { label: 'Группы ДО', link: '/' },
            ]
        },
        {
            label: 'Внеурочная деятельность',
            link: '/education',
            id: 13,
            menu: [{ label: 'Настройки расписания ВД', link: '/schedule_settings_vd' },
            { label: 'Варианты расписания ВД', link: '/vd_schedule_variants' },
            { label: 'Составление расписания ВД', link: '/scheduling_vd' },
            { label: 'Журнал ВД', link: '/classbook_vd' },
            { label: 'Тематическое планирование ВД', link: '/' },
            { label: 'Структура учебного предмета ВД', link: '/discipline_structure_vd' },
            { label: 'Контроль ведения  журнала ВД', link: '/journal_control_vd' },
            { label: 'Учебный план ВД', link: '/study_plans_vd' },
            { label: 'Предметы ВД', link: '/subjects_extracur_edu' },
            { label: 'Группы ВД', link: '/group_vd' },
            ]
        }
        ]
    },
    {
        label: 'Информационные сервисы',
        link: '/education',
        icon: services,
        id: 2,
        menu: [{
            label: 'Мероприятия',
            link: '/education',
            id: 21,
            menu: [{ label: 'Список мероприятий', link: '/events_list' },
            { label: 'Уровни мероприятий', link: '/event_levels' },
            { label: 'Направления воспитательной работы', link: '/educ_directions' },
            { label: 'Формы мероприятий', link: '/act_types' },
            { label: 'Типы событий', link: '/event_types' },
            { label: 'Виды достижений', link: '/achievements' },
            { label: 'Баллы за достижения', link: '/achiev_points' },
            ]
        },
        {
            label: 'Медпункт',
            link: '/education',
            id: 24,
            menu: [{ label: 'Медкарты', link: '/medical_cards' },
            { label: 'Освобождения', link: '/discharge' },
            { label: 'Виды болезней', link: '/types_of_disease' },
            { label: 'Виды прививок', link: '/types_of_vaccinations' },
            { label: 'МКБ-10', link: '/MKB_ten' },
            { label: 'Группы здоровья', link: '/health_group' },
            { label: 'Физкультурные группы', link: '/physical_group' },
            { label: 'Специальные медицинские группы', link: '/special_medical_groups' },
            ]
        },
        {
            label: 'Информационный паспорт',
            link: '/education',
            id: 27,
            menu: [{ label: 'Генеральный план зданий', link: '/' },
            { label: 'Схема ЛВС', link: '/' },
            { label: 'Перечень используемого ПО', link: '/' },
            { label: 'Скорость интернета', link: '/' },
            { label: 'Скорость обработки запросов LMS', link: '/' },
            { label: 'Документы', link: '/' },
            ]
        },
        { label: 'Объявления', link: '/ads' },
        { label: 'Вопрос-Ответ', link: '/faq' },
        { label: 'Публикации, статьи, пособия', link: '/' },
        { label: 'Полезные сайты', link: '/' },
        {
            label: 'Столовая',
            link: '/education',
            id: 22,
            menu: [{ label: 'Меню на день', link: '/menu_day' },
            { label: 'Блюда', link: '/dishes' },
            { label: 'Группы питания', link: '/group_nutrition' },
            { label: 'Периоды питания', link: '/period_nutrition' },
            { label: 'Сотрудники столовой', link: '/canteen_staff' },
            ]
        },
        { label: 'Обмен файлами', link: '/' },
        { label: 'Мониторинг учебной деятельности', link: '/' },
        {
            label: 'Личные дела',
            link: '/education',
            id: 23,
            menu: [{ label: 'Учащиеся', link: '/students' },
            { label: 'Кадровый состав', link: '/personnel' }
            ]
        },
        {
            label: 'Учебно-материальная база',
            link: '/education',
            id: 25,
            menu: [{ label: 'Объекты УМБ', link: '/directoryObjectsUMB' },
            { label: 'Кабинеты', link: '/rooms' },
            { label: 'Типы кабинетов', link: '/room_types' },
            ]
        },
        {
            label: 'Телестудия',
            link: '/education',
            id: 26,
            menu: [{ label: 'Типы программ', link: '/' },
            { label: 'Расписание программ', link: '/' },
            ]
        },
        { label: 'Родительские собрания', link: '/' },
        ]
    },
    {
        label: 'Приемная комиссия',
        link: '/education',
        icon: comission,
        id: 3,
        menu: [
            { label: 'Претенденты', link: '/candidates' },
            { label: 'Экзаменационные группы', link: '/' },
            { label: 'Приемные комиссии', link: '/admissions' },
            { label: 'Вопросы для тестирования', link: '/enroll_questions' },
            { label: 'Предметные комиссии', link: '/exam_board' },
            { label: 'Города сдачи вступительных испытаний', link: '/exam_city' },
            { label: 'Направления для поступления', link: '/trajectories' },
            { label: 'Временные слоты', link: '/timeslot' },
            { label: 'Расписание тестирования', link: '/testing_schedule' },
            { label: 'Варианты экзаменационных заданий', link: '/' },
        ],
    },
    {
        label: 'Настройки',
        link: '/education',
        icon: settings,
        id: 4,
        menu: [
            { label: 'Общие настройки', link: '/settings' },
            { label: 'Локальные настройки', link: '/' },
            { label: 'Учебная структура', link: '/educational_structure' },
            { label: 'Учебные статусы', link: '/educational_statuses' },
            { label: 'Варианты оценок', link: '/grades_options' },
            { label: 'Веса оценок', link: '/grade_weight' },
            { label: 'Типы учебных занятий', link: '/types_study_sessions' },
            { label: 'Учебные года', link: '/school_years' },
            { label: 'Учебные периоды', link: '/terms' },
            { label: 'Недели', link: '/school_weeks' },
            { label: 'Праздничные дни', link: '/holidays' },
            { label: 'Пользовательский словарь', link: '/user_dictionary' },
            { label: 'Формы обучения', link: '/forms_of_education' },
            { label: 'Ступени обучения', link: '/stages_study' },
            { label: 'Показатели физической подготовки', link: '/fitness_indicators' },
            { label: 'Страны', link: '/countries' },
            { label: 'Учебные заведения', link: '/educational_institutions' },
            { label: 'Учебный распорядок', link: '/periods' },
            { label: 'Распорядок дня', link: '/daily_routine' },
            { label: 'Типовые комментарии к оценкам', link: '/typical_comment' },
            { label: 'Закрытие учебного года', link: '/' },
            { label: 'Роли', link: '/roles' },
            { label: 'Настройка прав доступа', link: '/' },
            { label: 'Настройка журнализации', link: '/' },
            { label: 'Журнал изменений', link: '/' },
            { label: 'Журнал безопасности', link: '/' },
            { label: 'Журнал входов', link: '/' },
            { label: 'Описание таблиц', link: '/' },
            { label: 'Резервное копирование', link: '/backup' },
            { label: 'Шаблоны отчетов', link: '/' },
            { label: 'Группы отчетов', link: '/report_groups' },
            { label: 'Сервисные сообщения', link: '/' },
            { label: 'Видеокамеры', link: '/' },
            { label: 'Видеоуроки (архив)', link: '/' },
        ]
    },
    {
        label: 'Дополнительно',
        link: '/education',
        icon: more,
        id: 5,
        menu: [
            { label: 'Структурные подразделения', link: '/' },
            { label: 'Импорт данных', link: '/' },
            { label: 'Импорт фотографий', link: '/' },
            { label: 'Приказы', link: '/' },
            { label: 'Журнал документов', link: '/' },
            { label: 'Виды документов', link: '/' },
            { label: 'Подключенные пользователи', link: '/' },
            { label: 'Выгрузка в "Интеграция-СВО"', link: '/' },
        ]
    },
    {
        label: 'Справка',
        link: '/education',
        icon: reference,
        id: 6,
        menu: [
            { label: 'Содержание', link: '/' },
            { label: 'Новое в версии', link: '/' },
            { label: 'Руководство оператора', link: '/' },
            { label: 'Руководство администратора', link: '/' },
            { label: 'О программе', link: '/', type: 'button' },
        ]
    },
    {
        label: 'Методический кабинет',
        link: '/methodOffice',
        icon: settings,
        id: 7,
        menu: [
            { label: 'Кабинет', link: '/methodOffice' },
            { label: 'Новое в версии', link: '/' },
            { label: 'Руководство оператора', link: '/' },
            { label: 'Руководство администратора', link: '/' },


        ]
    }
]

export const cardItems = [
    { color1: '#6B4EFF', color2: '#757AFF', title: 'Расписание', icon: img1, link: '/schedule' },
    { color1: '#20748C', color2: '#0DBFAF', title: 'Классный журнал', icon: img2, link: '/classbook' },
    { color1: '#41C876', color2: '#48CE7E', title: 'Личные дела', icon: img3, link: '/' },
    { color1: '#61A024', color2: '#A6D429', title: 'Мероприятия', icon: img4, link: '/' },
    { color1: '#9844FF', color2: '#E47DBB', title: 'Вопрос-ответ', icon: img5, link: '/faq' },
    { color1: '#3D52DA', color2: '#5B82E1', title: 'Объявления', icon: img6, link: '/ads' },
    { color1: '#F2821B', color2: '#FBBA57', title: 'Открытый урок', icon: img7, link: '/' },
    { color1: '#1E8572', color2: '#0CBD93', title: 'Методический кабинет', icon: img8, link: '/methodOffice' },
    { color1: '#B6CD28', color2: '#B2CE09', title: 'Тематическое планирование', icon: img9, link: '/thematic_planning' },
    { color1: '#06A98C', color2: '#2ED8AF', title: 'Отчетность', icon: img10, link: '/reports' },
    { color1: '#DF54C0', color2: '#FB7EEE', title: 'Электронные документы', icon: img11, link: '/' },
    { color1: '#1E6085', color2: '#0C9DBD', title: 'Электронное тестирование', icon: img12, link: '/' }
]

export const accessStatuses = ['Полный', 'Чтение', 'Нет']

export const candidatesModalModes = [
    { name: 'Главная', value: 'main', icon: main },
    { name: 'Адреса', value: 'adresses', icon: adresses },
    { name: 'Льготы', value: 'priveleges', icon: priveleges },
    { name: 'Родители', value: 'parents', icon: parents },
    { name: 'Поступление', value: 'admission', icon: admission },
    { name: 'Медицина', value: 'medicine', icon: medicine },
    { name: 'Доп. образование', value: 'education', icon: educ },
    { name: 'Документы', value: 'documents', icon: documents },
    { name: 'Информация о кандидате', value: 'candidateInfo', icon: candidateInfo },
]
export const medCardsModalModes = [
    { name: 'Главная', value: 'main', icon: main },
    { name: 'Состав семьи', value: 'family', icon: adresses },
    { name: 'Обращения', value: 'appeals', icon: priveleges },
    { name: 'Прививки и медотводы', value: 'vaccinations', icon: parents },
    { name: 'Антропометрия', value: 'anthropometry', icon: admission },
    { name: 'Записи специалиста', value: 'specNotes', icon: medicine },

]
export const EventsListModalModes = [
    { name: 'Главная', value: 'main', icon: main },
    { name: 'Участники', value: 'participants', icon: parents },
    { name: 'Ответственные', value: 'responsible', icon: priveleges },
    { name: 'Участники-сотрудники', value: 'members_employees', icon: parents },
    { name: 'Параметры', value: 'parameters', icon: admission },

]

export const personnelModalModes = [
    { name: 'Главная', value: 'main', icon: main },
    { name: 'Дополнительно', value: 'more', icon: moree },
    { name: 'Образование', value: 'education', icon: admission },
    { name: 'Повышение квалификации', value: 'qualification', icon: qualification },
    { name: 'Награды', value: 'award', icon: award },
    { name: 'Взыскания', value: 'punish', icon: punish },
    { name: 'Общий стаж', value: 'experience', icon: experience },
    { name: 'Предметы', value: 'lesson', icon: lesson },
    { name: 'Мероприятия', value: 'event', icon: event },
    { name: 'Роли', value: 'role', icon: role },
    { name: 'Документы', value: 'documents', icon: documents },
    { name: 'Аттестация', value: 'attestation', icon: attestation },
]
//
export const variableStuffModalModes = [
    { name: 'Главная', value: 'main', icon: main },
    { name: 'Поступление', value: 'admission', icon: moree },
    { name: 'Обучение', value: 'education', icon: admission },
    { name: 'ИОТ', value: 'qualification', icon: admission },
    { name: 'Антропометрия', value: 'award', icon: award },
    { name: 'Учебные универсальные действия', value: 'punish', icon: punish },
    { name: 'Физ.подготовка', value: 'experience', icon: experience },
    { name: 'Характеристика', value: 'lesson', icon: lesson },
    { name: 'Достижения', value: 'event', icon: event },
    { name: 'Увлечения', value: 'role', icon: role },
    { name: 'Здоровье', value: 'documents', icon: documents },

]

export const classingModalModes = [
    { name: 'Главная', value: 'main', icon: main },
    { name: 'Состав', value: 'team', icon: parents },
    { name: 'История', value: 'history', icon: history },
    { name: 'Кураторы', value: 'curator', icon: curator }
]

export const coursesModalModes = [
    { name: 'Главная', value: 'main', icon: main },
    { name: 'Файлы', value: 'files', icon: parents },
]

export const defaultModalModes = [
    { name: 'Главная', value: 'main', icon: main },
]

export const planningModalModes = [
    { name: 'Основная часть', value: 'planning', icon: main },
    { name: 'Домашнее задание', value: 'homework', icon: admission },
    { name: 'Файлы', value: 'files', icon: documents },
    { name: 'Пояснительная записка', value: 'about', icon: moree },
    { name: 'Список литературы', value: 'literature', icon: lesson },
]

export const educationalStructureModalModes = [
    { name: 'Главная', value: 'main', icon: main },
    { name: 'Основное образование', value: 'adresses', icon: adresses },
    { name: 'Доп. образование', value: 'priveleges', icon: priveleges },
    { name: 'Внеурочная деятельность', value: 'parents', icon: parents },
    { name: 'Элективы', value: 'admission', icon: admission },
    // {name: 'Медицина', value: 'medicine', icon: medicine},
    // {name: 'Доп. образование', value: 'education', icon: educ},
    // {name: 'Документы', value: 'documents', icon: documents},
    // {name: 'Информация о кандидате', value: 'candidateInfo', icon: candidateInfo},
]

export const studyPlansModes = [
    { name: 'Главная', value: 'main', icon: main },
    { name: 'Состав', value: 'settings', icon: admission },
]

export const armyClasses = [
    { name: 'Мотострелковые войска', value: 1 },
    { name: 'Танковые войска', value: 1 },
    { name: 'Ракетные войска и артиллерия', value: 1 },
    { name: 'Войска противовоздушной обороны', value: 1 },
    { name: 'Разведывательные соединения и воинские части', value: 1 },
    { name: 'Инженерные войска', value: 1 },
    { name: 'Войска связи', value: 1 }
]

export const methodOffice = [
    { name: 'Общие', value: 'main', icon: main },
    { name: 'Допуск', value: 'access', icon: admission },
]

export const armyDistricts = [
    { name: 'Отсутствует' },
    { name: 'Западный военный округ' },
    { name: 'Восточный военный округ' },
    { name: 'Центральный военный округ' },
    { name: 'Южный военный округ' },
    { name: 'Северный флот' }
]

export const statuses = [
    { name: 'Статус1', value: 1 },
    { name: 'Статус2', value: 1 },
    { name: 'Статус3', value: 1 },
    { name: 'Статус4', value: 1 }
]

export const socialstatus = [
    { name: "Из других семей", value: 1 },
    { name: 'Статус2', value: 1 },
    { name: 'Статус3', value: 1 },
    { name: 'Статус4', value: 1 }
]

export const cities = [
    { name: 'Санкт-Петербург', value: 1 },
    { name: 'Москва', value: 1 },
    { name: 'Брянск', value: 1 }
]

export const streams = [
    { name: 'Основной набор', value: 0 },
    { name: 'Донабор', value: 1 }
]

export const sportGrade = [
    { name: '5' },
    { name: '4' },
    { name: '3' },
    { name: 'зачет' }
]

export const sex = [
    { name: 'мужской' },
    { name: 'женский' }
]

export const docTypes = [
    { name: 'Паспорт' },
    { name: 'Св. о рождении' }
]

export const weekForSchedule = [
    { name: 'Понедельник', value: 0 },
    { name: 'Вторник', value: 1 },
    { name: 'Среда', value: 2 },
    { name: 'Четверг', value: 3 },
    { name: 'Пятница', value: 4 },
    { name: 'Суббота', value: 5 },
    { name: 'Воскресенье', value: 6 },
]

export const PARALLELS = [
    { name: "1", value: '1' },
    { name: "2", value: '2' },
    { name: "3", value: '3' },
    { name: "4", value: '4' },
    { name: "5", value: '5' },
    { name: "6", value: '6' },
    { name: "7", value: '7' },
    { name: "8", value: '8' },
    { name: "9", value: '9' },
    { name: "10", value: '10' },
    { name: "11", value: '11' },
    { name: "12", value: '12' },
]

export const statusAccess = [{ value: '0', name: 'Недоступен' }, { value: '1', name: 'Доступен' }];
export const degreeOfCapacity = [{ value: '0', name: '' }, { value: 1, name: 'До 40' }, { value: 2, name: '40-100' }, { value: 3, name: 'Свыше 100' }];

export const userMessageTypes = {
    0: 'Все',
    1: 'Преподаватель - учащийся',
    2: 'Преподаватель - родитель',
    3: 'Сотрудник - Сотрудник'
}

export const recipientTypes = {
    0: 'Учащийся',
    1: 'Родитель',
    2: 'Сотрудник',
    3: 'Роль'
}

export const recipientActionTypes = {
    0: 'STUDENTS',
    1: 'PARENTS',
    2: 'EMPLOYEES',
    3: 'ROLES'
}

// -------------------------------------Кадровый состав-------------------------------------
export const marriageState = [
    { name: 'Никогда несостоял(не состояла) в браке' },
    { name: 'Состоит в зарегистрированом браке' },
    { name: 'Состоит в  незарегистрированом браке' },
    { name: 'Разведен(разведена)' },
    { name: 'Вдовец (вдова)' },
    { name: 'Разошелся (разошлась)' },
]

export const citizenshipOKIN = [
    { name: 'Гражданин Российской Федерации' },
    { name: 'Гражданин Российской Федерации и иностранного государства (двойное гражданство)' },
    { name: 'Иностранный гражданин' },
    { name: 'Лицо без гражданства' },
    { name: '' }
]
