import React, { useEffect } from 'react'
import styles from './styles.module.css'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Table from '../../components/Table'
import { deleteGradeOption } from '../../actions/directory'
import Confirmation from '../../components/Confirmation/Confirmation'
import { getMKBtenBot, getMKBtenMid, getMKBtenTop } from '../../actions/medCenter/mkbTen'
import { GET_MKB_TEN_BOT, GET_MKB_TEN_MID } from '../../actions/types'


export default function MKBten({ setSelectedDiagnosis, modal }) {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { topList, midList, botList } = useSelector(state => state.directoryMedCenter)


  // РЕФАКОРНУТЬ
  const [selectedItem, setSelectedItem] = useState([])
  const [selectedItem2, setSelectedItem2] = useState([])
  const [selectedItem3, setSelectedItem3] = useState([])

  const [confirmation, setConfirmation] = useState(false);

  useEffect(() => {
    dispatch(getMKBtenTop());
  }, [])

  useEffect(() => {
    selectedItem?.id && dispatch(getMKBtenMid(selectedItem.id));
    selectedItem2?.id && dispatch(getMKBtenBot(selectedItem2.id));
  }, [selectedItem, selectedItem2])

  const openModal = (data) => () => {
    if (selectedItem.id === data.id) {
      setSelectedItem([]);
      dispatch({ type: GET_MKB_TEN_MID, payload: [] });

    } else {
      dispatch({ type: GET_MKB_TEN_MID, payload: [] });
      setSelectedItem(data)
    }
  }

  const openModal2 = (data) => () => {
    if (selectedItem2.id === data.id) {
      setSelectedItem2([]);
      dispatch({ type: GET_MKB_TEN_BOT, payload: [] });
    } else {
      dispatch({ type: GET_MKB_TEN_BOT, payload: [] });
      setSelectedItem2(data)
    }

  }
  const openModal3 = (data) => () => {
    modal && setSelectedDiagnosis(data)
    setSelectedItem2([])
  }

  const closeModal = () => {
    setSelectedItem(null)
  }

  const goHome = () => navigate('/')

  const acceptConfirmation = () => {
    closeModal();
    dispatch(deleteGradeOption(confirmation.id));
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  ///TODO переделать умб
  const renderTop = (data) => {
    const { code, id, name } = data
    return (
      <>
        <tr  >
          <td name='code' onClick={openModal(data)}>{code}</td>
          <td name='name' onClick={openModal(data)} >{name}</td>
        </tr>
        {selectedItem?.id == id && midList?.map(renderMid)}
      </>
    )
  }

  const renderMid = (data) => {
    const { code, id, name } = data
    return (
      <>
        <tr style={{ backgroundColor: '#95d7fd' }} >
          <td name='code' onClick={openModal2(data)}>{code}</td>
          <td name='name' onClick={openModal2(data)} >{name}</td>
        </tr>
        {selectedItem2?.id == id && botList?.map(renderBot)}
      </>
    )
  }
  const renderBot = (data) => {
    const { code, name } = data
    return (
      <tr style={{ backgroundColor: '#a7f3b4' }} >
        <td name='code' onClick={openModal3(data)}>{code}</td>
        <td name='name' onClick={openModal3(data)} >{name}</td>
      </tr>
    )
  }
  return (
    <div className={styles.wrapper} style={!modal ? { width: '65vw', margin: '0 auto' } : {}}>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title={`Удалить ${confirmation.id}?`}
          acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
          rejectConfirmation={rejectConfirmation}
        />
      )}

      <h1>МКБ-10</h1>
      <Table className={modal ? styles.tableMKBModal : styles.tableMKB}>
        <thead>
          <tr>
            <th>Код по МКБ10</th>
            <th>Наименование болезни</th>
          </tr>
        </thead>
        <tbody>
          {topList?.map(renderTop)}
        </tbody>
      </Table>

    </div>
  )
}