import React, { useEffect, useState } from 'react'
import Modal from 'react-responsive-modal'
import { useDispatch, useSelector } from 'react-redux'
import styles from './styles.module.css'
import * as Yup from 'yup'
import ModalFooter from '../../components/ModalFooter/ModalFooter'
import Button from '../../components/Button'
import { getSchoolYears, } from '../../actions/common'
import Main from './Main'
import MembersAdmissions from './MembersAdmissions'
import DisciplinesTesting from './DisciplinesTesting'

const validationSchema = Yup.object({
  name: Yup.string().required('обязательное поле').max(30, 'Должно быть не более 30 символов'),

})

export default function ModalComponent({ data, closeModal, height, addMode, classGroup, values, setFieldValue, handleChange, handleSubmit, error }) {

  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState(1);


  const [userModal, setUserModal] = useState()
  const gid = classGroup && classGroup[0].gid

  useEffect(() => {
    dispatch(getSchoolYears())
  }, [])


  const openUserModal = () => {
    setUserModal(true)
  }

  useEffect(() => {
    setActiveTab(1)
  }, [data])


  const handleTab = (tab) => {
    setActiveTab(tab);
  }
  // data={data} ==== из таблицы данные
  //  values={values} === из запроса по ЙД

  const renderTab = (mode) => {
    switch (mode) {
      case 1: return <Main data={data} values={values} setFieldValue={setFieldValue} handleChange={handleChange} addMode={addMode} error={error} />
      case 2: return <MembersAdmissions data={data} values={values} setFieldValue={setFieldValue} handleChange={handleChange} addMode={addMode} error={error} />
      case 3: return <DisciplinesTesting data={data} values={values} setFieldValue={setFieldValue} handleChange={handleChange} addMode={addMode} error={error} />
      // case 'settings': return <Settings values={values}  />
      default: return
    }
  }

  return (
    <Modal
      open={!!data}
      onClose={() => false}
      showCloseIcon={false}
      height={height}
      classNames={{
        // overlay: styles.overlay,
        modal: styles.custom
      }}
      center
    >
      <>
        <div className={!addMode ? styles.modalMain : styles.modalMainEditing}>
          <div className={styles.tabsHeader}>
            <div className={styles.title}>{!addMode
              ? 'Редактирование'
              : 'Добавление'}</div>
            <div className={styles.tabs}>
              {!addMode &&
                <>
                  <Button mode={activeTab === 1 ? '' : 'white'} onClick={() => handleTab(1)}>Главная</Button>
                  <Button mode={activeTab === 2 ? '' : 'white'} onClick={() => handleTab(2)}>Члены приёмной комиссии</Button>
                  <Button mode={activeTab === 3 ? '' : 'white'} onClick={() => handleTab(3)}>Дисциплины для тестирования</Button>
                </>
              }
            </div>
          </div>
        </div>
        <div className={styles.modalLocal} >
          <div className={styles.tabWrapper} >
            {renderTab(activeTab)}
          </div>
        </div>

        <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
      </>

    </Modal >

  )
}
