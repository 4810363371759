import React, { useContext, useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { IoIosAdd } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Modal from './Modal'
import Table from '../../components/Table'
import { BiTrash } from 'react-icons/bi'
import Confirmation from '../../components/Confirmation/Confirmation'
import { getJournalControlPeriods, getJournalControlGroups, getJournalControlData, getJournalControlCourses, deleteJournalControl } from '../../actions/journalControl'
import Select from '../../components/Select'
import { useFormik } from 'formik'
import { getSchoolYearsList } from '../../actions/schoolYears'
import { getCLassesByYear, getCourses } from '../../actions/common'
import { ClassbookContext } from '../../utils/context'


const initialValues = {
  trmid: '',
  gid: ''
}

export default function JournalControl({ classbook }) {

  const { formData } = useContext(ClassbookContext)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { list: yearList } = useSelector(state => state.school_years)
  const { periods, groups, data } = useSelector(state => state.journal_control)
  const { courses, classes } = useSelector(state => state.common)


  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(false);

  const { values, handleChange } = useFormik({ initialValues })

  useEffect(() => {
    dispatch(getSchoolYearsList())
    dispatch(getCourses())
  }, [])

  useEffect(() => {
    if (classbook) return
    const { year } = values
    const params = { year }
    values.year && dispatch(getJournalControlPeriods(params))
  }, [values.year])

  useEffect(() => {
    if (classbook) return

    const { year, trmid } = values
    if (!trmid) return;
    const params = { year, trmid }
    // dispatch(getJournalControlGroups(params))
    dispatch(getJournalControlGroups({ year: 13, trmid: 14 }))/// заглушки

  }, [values.trmid])

  useEffect(() => {
    if (!!formData) dispatch(getJournalControlData({ year: formData.scYear, trmid: formData.studyPeriod, gid: formData.class, })) //TODO ключи!!??
  }, [formData])

  useEffect(() => {
    if (classbook) return

    const { year, trmid, gid } = values
    if (!trmid || !gid) return;
    const params = { year, trmid, gid }
    // dispatch(getJournalControlData(params))
    dispatch(getJournalControlData({ year: 13, trmid: 14, gid: 7 }))/// заглушки
  }, [values.gid])

  const goTo = (url) => () => navigate(url)

  const openModal = (data) => () => setSelectedItem(data || {})

  const closeModal = () => setSelectedItem(null)

  const acceptConfirmation = () => {
    dispatch(deleteJournalControl(confirmation.id));
    setConfirmation(false);
    closeModal();
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  const deleteItemHandler = (data) => (e) => {
    e.stopPropagation()
    setConfirmation({ id: data.jmid, name: data.date })
  }


  const renderLi = (data) => {
    const { date, checktext, xp_f_get_mid_fio, subject, group } = data
    return (
      <tr onClick={openModal(data)}>
        <td>{date}</td>
        <td>{checktext}</td>
        <td>{xp_f_get_mid_fio}</td>
        <td>{subject}</td>
        <td>{group}</td>
        <td><i className={styles.trash} onClick={deleteItemHandler(data)}><BiTrash /></i></td>
      </tr>
    )
  }

  return (
    <div className={styles.wrapper} style={{ width: '1200px', margin: '0 auto' }}>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
          acceptConfirmation={() => acceptConfirmation(confirmation?.id)}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      {selectedItem &&
        <Modal
          closeModal={closeModal}
          data={selectedItem}
          height={'430px'}
          addMode={!selectedItem.jmid}
          valuesFilters={values}
        />
      }
      {!classbook &&
        <>
          <h1>Контроль ведения классного журнала</h1>

          <Select
            title="Учебный год"
            name="year"
            options={[{ name: "Выбрать", xp_key: 0 }, ...yearList]}
            onChange={handleChange}
          />
          { }
          <Select
            title="Учебный период"
            name="trmid"
            value={values.trmid}
            options={[{ name: "Выбрать", value: '' }, ...periods]}
            onChange={handleChange}
          />

          <Select
            title="Класс"
            name="gid"
            value={values.gid}
            options={[{ name: "Выбрать", value: '' }, ...groups]}
            onChange={handleChange}
          />
        </>
      }

      <Table className={styles.tables}>
        <thead>
          <tr>
            <th>Месяц, число</th>
            <th>Замечания и предложения проверяющих</th>
            <th>Отметка о выполнении</th>
            <th>Предмет</th>
            <th>Класс</th>

            <th></th>
          </tr>
        </thead>
        <tbody>
          {data?.map(renderLi)}
        </tbody>
      </Table>

      <div style={{ display: 'flex', flexDirection: "row" }}>
        <Button
          style={{ padding: '.5rem 1.5rem', margin: '12px' }}
          onClick={openModal()}
        >
          <IoIosAdd size={30} />
          Добавить
        </Button>
      </div>
      <div className={styles.footer}>
        <Button mode='white' onClick={goTo('/journal_control')}>Классный журнал</Button>
        <Button onClick={goTo('/')}>На главную</Button>
      </div>
    </div>
  )
}
