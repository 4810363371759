import { api } from "../App";
import { SET_EVENT_LEVELS_LIST, SET_EVENT_LEVELS_LOADING, SET_FITNESS_INDICATORS_LIST, SET_HOLIDAYS_LIST } from "./types";




export const getFitnessIndicators = () => async (dispatch, getState) => {
  try {
    // dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: true });
    const { data } = await api.get('/physical_training');
    dispatch({ type: SET_FITNESS_INDICATORS_LIST, payload: data });
  } catch (ERR) {
  } finally {
    // dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: false });
  }
}

export const createFitnessIndicators = (data) => async (dispatch, getState) => {
  let success
  try {
    dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: true });
    await api.post('/physical_training', data);
    await dispatch(getFitnessIndicators())
    success = true
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: false });
    return success
  }
}

export const updateFitnessIndicators = ({ iid, ...data }) => async (dispatch, getState) => {
  console.log('data::: ', data);
  let success
  try {
    dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: true });
    await api.put(`/physical_training/${iid}`, data);
    await dispatch(getFitnessIndicators())
    success = true
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: false });
    return success
  }
}

export const deleteFitnessIndicators = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: true });
    await api.delete(`/physical_training/${id}`);
    await dispatch(getFitnessIndicators())
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: false });
  }
}