import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { useDispatch, useSelector } from 'react-redux'
import Title from '../../components/Title'
import Select from '../../components/Select'
import Leaf from '../../components/Leaf'
import { getCurrentSchedule, getPersonalTeacherList, getScheduleWeek, getPersonalSchoolYears } from '../../actions/personalScheduleDO'
import { getExcelFromTable } from '../../utils/excel'
import { createNotification } from '../../components/Notifications/Notifications'
import { getRoomsList } from '../../actions/rooms'
import moment from 'moment';
import 'moment/locale/ru';
import Loader from '../../components/Loader'

moment.locale('ru'); // Устанавливаем русский язык

const defaultState = {
    scYear: "19",
    week: "0",
    teacher: '0',
    date: moment().format('YYYY-MM-DD'),
}

const Schedule = () => {

    const dispatch = useDispatch()

    const {
        weeks,
        schedule,
        teachers,
        loading,
        schoolYears
    } = useSelector(state => state.personalScheduleDO);

    const weekNowToPast = [...weeks].reverse()
    const actWeek = weeks.length
        ? weekNowToPast
            ?.[0].week_id
        : '0'

    const [formData,
        setFormData] = useState(defaultState)

    useEffect(() => {
        dispatch(getPersonalSchoolYears());
        dispatch(getRoomsList());
        dispatch(getPersonalTeacherList(formData));
    }, [])

    useEffect(() => {
        if (formData.scYear == '0') {
            setFormData(prev => ({
                ...prev,
                teacher: '0',
                week: '0',
                scYear: '0'
            }))
            dispatch({ type: 'GET_SCHEDULE_WEEKS_LIST', payload: [] })
            dispatch({ type: 'GET_SCHEDULE_TEACHER_LIST', payload: [] })
            dispatch({ type: 'GET_PERSONAL_SCHEDULE_DO_CURRENT', payload: [] })
        }
        if (formData.scYear != '0') {
            dispatch(getScheduleWeek(formData.scYear))
        }

    }, [formData.scYear])

    useEffect(() => {
        formData.week != '0' && formData.teacher != '0' && dispatch(getCurrentSchedule(formData))
    }, [formData.week, formData.teacher])

    useEffect(() => {
        if (formData.scYear != '0') {
            setFormData(prev => ({
                ...prev,
                teacher: '0',
                week: formData.scYear == '0'
                    ? '0'
                    : actWeek
            }))
        }

    }, [weeks])

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleExportToExcel = () => {
        if (schedule) {
            const table = document.getElementById("scheduleTable")
            getExcelFromTable(table, 'Файлик')
        } else {
            createNotification('error', 'Расписание не загружено')
        }
    }

    function groupByLessonDate(data) {
        return Object.values(
            data.reduce((acc, item) => {
                (acc[item.lessondate] = acc[item.lessondate] || []).push(item);
                return acc;
            }, {})
        );
    }

    return (<> <Title title="Личное расписание ДО" />
        <div className={styles.wrapperPanel}>
            <div className={styles.wrapperSelect}>
                    <> <Select
                        title="Учебный год"
                        name={"scYear"}
                        options={[
                            {
                                name: "Выбрать",
                                value: '0'
                            },
                            ...schoolYears
                        ]}
                        value={formData
                            ?.scYear}
                        withoutArrowMode
                        onChange={handleInputChange} />
                        < Leaf
                            title="Неделя"
                            name={
                                "week"
                            }
                            options={
                                weekNowToPast
                            }
                            setFormData={
                                setFormData
                            }
                            formData={
                                formData
                            } />
                        <Select
                            title="Преподаватель"
                            name={"teacher"}
                            options={[
                                {
                                    name: "Все",
                                    value: '0'
                                },
                                ...teachers.map((el) => ({ name: el.employer, value: el.mid }))
                            ]}
                            value={formData
                                ?.teacher}
                            withoutArrowMode
                            onChange={handleInputChange} />
                    </>
            </div>
        </div>

        <div className={styles.wrapperTable}>            
            {loading
                ? <Loader/>
                : schedule.length > 0
                    ? 
                    <table>
                <thead>
                    <tr>
                        <th>День недели</th>
                        <th>Занятие</th>
                        <th>Предмет</th>
                        <th>Класс</th>
                        <th>Кабинет</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        groupByLessonDate(schedule)?.map((element, index) => (
                            <React.Fragment key={index}>
                            {element?.map((lesson, index_) => (
                                <tr key={index_}>
                                    {index_ == 0 && 
                                    <td rowSpan={element.length}>
                                        {moment(lesson.lessondate).format('D MMMM dddd')}
                                    </td>}
                                    <td>{lesson.periodname}</td>
                                    <td>{lesson.coursename}</td>
                                    <td>{lesson.groupname}</td>
                                    <td>{lesson.roomname}</td>
                                </tr>
                            ))}
                            </React.Fragment>
                        ))
                    }
                </tbody>
            </table>
                : <div
                        style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "200px",
                        color: "#1671EF"
                    }}>НЕТ ДАННЫХ</div >
        }
        </div>
    </>)
}

export default Schedule