import React, { useState } from 'react'
import styles from './index.module.css'
import Modal from 'react-responsive-modal'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import Main from './Main'
import Compound from './Compound'
import Button from '../../../components/Button'

const Index = ({ data, closeModal, addMode, values, setFieldValue, handleChange, handleSubmit, image, setImage }) => {

    const [activeTab, setActiveTab] = useState(1);

    const handleTab = (tab) => {
        setActiveTab(tab);
    }

    const renderTab = () => {
        switch (activeTab) {
            // case 'main': return <Main values={values} setValues={setValues}/>
            case 1: return <Main data={data} values={values} setFieldValue={setFieldValue} handleChange={handleChange} addMode={addMode} image={image} setImage={setImage} />
            case 2: return <Compound data={data} values={values} setFieldValue={setFieldValue} handleChange={handleChange} addMode={addMode} />
            default: return
        }
    }

    return (

        <Modal
            classNames={{
                modal: styles.modalMain
            }}
            open={!!data}
            onClose={closeModal}
            closeOnOverlayClick={true}
            closeOnEsc={true}
            showCloseIcon={true}
            animationDuration={1}
            style={{
                modal: {
                    background: 'white',
                    width: '1200px'
                }
            }}
        >
            <>
                <div className={!addMode ? styles.modalMain : styles.modalMainEditing}>
                    <div className={styles.tabsHeader}>
                        <div className={styles.titleTabs}>{!addMode
                            ? 'Редактирование'
                            : 'Добавление'}</div>
                        <div className={styles.tabs}>
                            {!addMode &&
                                <>
                                    <Button mode={activeTab === 1 ? '' : 'white'} onClick={() => handleTab(1)}>Главная</Button>
                                    <Button mode={activeTab === 2 ? '' : 'white'} onClick={() => handleTab(2)}>Состав</Button>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.modalLocal} >
                    <div className={styles.tabWrapper} >
                        {renderTab(activeTab)}
                    </div>
                </div>

                <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
            </>


        </Modal>
    )
}

export default Index