import { api } from "../../App";
import { SET_EXAM_LOADING, SET_EXAM_REPORT } from "../types";




export const getExamReport = (params) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_EXAM_LOADING, payload: true });
    const { data } = await api.get('/exam_report', { params });
    dispatch({ type: SET_EXAM_REPORT, payload: data });
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_EXAM_LOADING, payload: false });
  }
}