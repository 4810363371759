import { BACKEND_URL_PREFIX } from "../assets/const";
import { createNotification } from "../components/Notifications/Notifications";
import { checkAndUpdateToken } from "./auth";
import { buildDefaultHeaders } from "./helper";
import {
  GET_COUNTRIES,
  GET_EDUCATIONAL_STATUSES,
  GET_FORMS_OF_EDUCATION,
  GET_GROUP_NUTRITION_LIST,
  GET_GRADE_WEIGHT,
  GET_GROUP_NUTRITION_PARALLELS,
  GET_NUTRITION_GROUP,
  GET_PERIOD_NUTRITION_LIST,
  GET_NUTRITION_PERIOD,
  GET_HEALTH_GROUP,
  GET_NUTRITION_PERIOD_FILES,
  GET_NUTRITION_PERIOD_FILE,
  GET_CANTEEN_STAFF_LIST,
  GET_CANTEEN_STAFF_FILE,
  GET_CANTEEN_STAFF,
  GET_NUTRITION_PERIOD_CREATOR,
  GET_MEAL,
  GET_DISH,
  GET_MENU_ON_DATE,
  SET_CANTEEN_LOADING,
  GET_DISH_TYPE_LIST,
  GET_DISH_FILE
} from "./types";





// <----  ГРУППЫ ПИТАНИЯ ---->

export const getGroupNutritionList = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_eat_group`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      dispatch({ type: GET_GROUP_NUTRITION_LIST, payload: result });
    }
  }

};
export const getParallelsForGroupNutrition = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_eat_group/parallels`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      dispatch({ type: GET_GROUP_NUTRITION_PARALLELS, payload: result });
    }
  }

};
export const getNutritionGroup = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_eat_group/${id}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());

      dispatch({ type: GET_NUTRITION_GROUP, payload: result });
    }
  }

};

export const updateGroupNutrition = (params) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_eat_group/${params.xp_key}`, {
      method: "PUT",
      body: JSON.stringify(params),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getGroupNutritionList());
    }
  };
}

export const createGroupNutrition = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_eat_group`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getGroupNutritionList());
    } else createNotification('error', "Не удалось создать запись");
  }
};
export const deleteGroupNutrition = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_eat_group/${id}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    const result = await response.json();

    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getGroupNutritionList());
    } else createNotification('error', result.error);
  }
};

// <----  ПЕРИОДЫ ПИТАНИЯ ---->

export const getPeriodNutritionList = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_eat_period `, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      dispatch({ type: GET_PERIOD_NUTRITION_LIST, payload: result });
    }
  }

};

export const getNutritionPeriod = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_eat_period/${id}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());

      dispatch({ type: GET_NUTRITION_PERIOD, payload: result });
    }
  }
};

export const updatePeriodNutrition = (id, params, image) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    // проверка на фото
    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_eat_period/${id}`, {
      method: "PUT",
      body: JSON.stringify(params),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      if (!!image.name) {
        await dispatch(createPeriodNutritionFile(image, id));
      }
      await dispatch(getPeriodNutritionList());
    }
  };
}

export const createPeriodNutrition = (data, image) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_eat_period`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = await response.json()
      if (!!image) {
        await dispatch(createPeriodNutritionFile(image, result.xp_key));
      }
      createNotification('success', 'Успешно');
      await dispatch(getPeriodNutritionList());
    } else createNotification('error', "Не удалось создать запись");
  }
};

export const deletePeriodNutrition = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_eat_period/${id}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    const result = await response.json();

    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getPeriodNutritionList());
    } else createNotification('error', result.error);
  }
};


export const getPeriodNutritionFiles = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_eat_period/files/${id}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = await response.json();
      dispatch({ type: GET_NUTRITION_PERIOD_FILES, payload: result });
    } else dispatch({ type: GET_NUTRITION_PERIOD_FILES, payload: null });
  }
};


export const createPeriodNutritionFile = (data, id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  const fd = new FormData();
  fd.append("file", data);

  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_eat_period/file/${id}`, {
      method: "POST",
      body: fd,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
      },
    });
    if (response.ok) {
      // createNotification('success', 'Успешно');
      await dispatch(getPeriodNutritionFiles(id));
    }
  }
};


export const getPeriodNutritionFile = (file_name, id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_eat_period/file/${id}/${file_name}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = await response.blob()
      const objectURL = URL.createObjectURL(result);
      dispatch({ type: GET_NUTRITION_PERIOD_FILE, payload: objectURL });
    } else dispatch({ type: GET_NUTRITION_PERIOD_FILE, payload: null });
  }
};

// <----  ПЕРСОНАЛ СТОЛОВОЙ ---->

export const getCanteenStaffList = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/cook_team`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      dispatch({ type: GET_CANTEEN_STAFF_LIST, payload: result });
    }
  }

};

export const getCanteenStaff = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/cook_team/${id}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());

      dispatch({ type: GET_CANTEEN_STAFF, payload: result });
    }
  }
};

export const updateCanteenStaff = (id, params, image) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/cook_team/${id}`, {
      method: "PUT",
      body: JSON.stringify(params),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      if (!!image.name) {
        await dispatch(createCanteenStaffFile(image, id));
      }
      await dispatch(getCanteenStaffList());
    }
  };
}

export const createCanteenStaff = (data, image) => async (dispatch, getState) => {
  console.log('data, image::: ', data, image);
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/cook_team `, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = await response.json()
      if (!!image) {
        await dispatch(createCanteenStaffFile(image, result.id));
      }
      createNotification('success', 'Успешно');
      await dispatch(getCanteenStaffList());
    } else createNotification('error', "Не удалось создать запись");
  }
};

export const deleteCanteenStaff = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/cook_team/${id}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    const result = await response.json();

    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getCanteenStaffList());
    } else createNotification('error', result.error);
  }
};


export const createCanteenStaffFile = (data, id) => async (dispatch, getState) => {
  console.log('data, id::: ', data, id);
  await dispatch(checkAndUpdateToken());
  const fd = new FormData();
  fd.append("file", data);

  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/cook_team/images/${id}`, {
      method: "POST",
      body: fd,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
      },
    });
    if (response.ok) {
      // createNotification('success', 'Успешно');
      await dispatch(getCanteenStaffFile(id));
    }
  }
};


export const getCanteenStaffFile = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/cook_team/images/${id}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = await response.blob()
      const objectURL = URL.createObjectURL(result);
      dispatch({ type: GET_CANTEEN_STAFF_FILE, payload: objectURL });
    } else dispatch({ type: GET_CANTEEN_STAFF_FILE, payload: null });
  }
};

// <----  Меню на день ---->
// прием пищи /хедер таблицы
export const getMeal = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_eat_period`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      dispatch({ type: GET_MEAL, payload: result });
    }
  }
};

export const getDish = (data) => async (dispatch, getState) => {
  console.log('data::: ', data);
  const c_dishclass = { c_dishclass: data.period_key }
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_CANTEEN_LOADING, payload: true });

    const urlParams = new URLSearchParams(c_dishclass).toString();
    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_dish?${urlParams} `, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      dispatch({ type: GET_DISH, payload: result });
    }
  }
  dispatch({ type: SET_CANTEEN_LOADING, payload: false });
};

export const getMenuOnDate = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const urlParams = new URLSearchParams(data).toString();
    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_dish_period/menu?${urlParams}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      dispatch({ type: GET_MENU_ON_DATE, payload: result });
    }
  }
};

export const createMenu = (data) => async (dispatch, getState) => {
  console.log('createMenucreateMenucreateMenu createMenudata::: ', data);

  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_dish_period`, {
      method: "POST",
      body: JSON.stringify(data), //TODO
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = await response.json()
      createNotification('success', 'Успешно');
      // await dispatch(getMenuOnDate(date));
    } else createNotification('error', "Не удалось создать запись");
  }
};
export const copyMenu = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_dish_period/copy`, {
      method: "POST",
      body: JSON.stringify(data), //TODO
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = await response.json()
      createNotification('success', 'Успешно');
      await dispatch(getMenuOnDate({ date: data.xp_date }));

    } else createNotification('error', "Не удалось создать запись");
  }
};
export const copyDishPeriod = (data) => async (dispatch, getState) => {
  console.log('datadatadatacopyDishPeriod::: ', data);

  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_dish_period/date`, {
      method: "POST",
      body: JSON.stringify(data), //TODO
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = await response.json()
      console.log('result::: ', result);

      createNotification('success', 'Успешно');
      await dispatch(getMenuOnDate({ date: data.from_date }));
    } else createNotification('error', "Не удалось создать запись");
  }
};

// <----  Блюда  ---->

export const getDishList = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/c_dishclass`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      dispatch({ type: GET_DISH_TYPE_LIST, payload: result });
    }
  }

};

export const getCanteenStaf = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/cook_team/${id}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());

      dispatch({ type: GET_CANTEEN_STAFF, payload: result });
    }
  }
};

export const updateDish = (id, params, image) => async (dispatch, getState) => {
  console.log('id, params, image::: ', id, params, image);
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_dish/${id}`, {
      method: "PUT",
      body: JSON.stringify(params),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      if (!!image.name) {
        await dispatch(createDishFile(image, id));
      }
      await dispatch(getDish());
      await dispatch(getCanteenStaffList());

    }
  };
}

export const createDish = (data, image) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_dish`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = await response.json()
      console.log('result::: ', result);
      if (!!image) {
        await dispatch(createDishFile(image, result.xp_key));
      }
      createNotification('success', 'Успешно');
      await dispatch(getDish());
    } else createNotification('error', "Не удалось создать запись");
  }
};

export const deleteDish = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_dish/${id}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    const result = await response.json();

    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getDish());
    } else createNotification('error', result.error);
  }
};


export const createDishFile = (data, id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  const fd = new FormData();
  fd.append("file", data);

  if (getState().auth.token) {
    // const response = await fetch(`${BACKEND_URL_PREFIX}/cook_team/images/${id}`, {
    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_dish/${id}/file`, {
      method: "POST",
      body: fd,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
      },
    });
    if (response.ok) {
      // createNotification('success', 'Успешно');
      await dispatch(getDishFile(id));
    }
  }
};


export const getDishFile = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_dish/${id}/file`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = await response.blob()
      // const objectURL = URL.createObjectURL(result);
      const objectURL = (result);
      dispatch({ type: GET_DISH_FILE, payload: objectURL });
    } else dispatch({ type: GET_DISH_FILE, payload: null });
  }
};