import * as types from "../actions/types";



const initialState = {
  // list: null,
  loading: false
};


export default function admissions(state = initialState, action) {
  switch (action.type) {
    case types.SET_ADMISSIONS_LIST:
      return {
        ...state,
        admissions_list: action.payload.rows
      };
    case types.SET_ADMISSION_BY_ID:
      return {
        ...state,
        admissionById: action.payload
      };
    case types.SET_ADMISSION_DISCIPLINES:
      return {
        ...state,
        admissionDisciplines: action.payload
      };
    case types.SET_ADMISSION_DISCIPLINES_FOR_SELECT:
      return {
        ...state,
        admissionDisciplinesForSelect: action.payload
      };
    case types.SET_ADMISSIONS_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}