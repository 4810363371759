import React, { useEffect } from 'react'
import styles from './index.module.css'
import Modal from 'react-responsive-modal'
import ModalFooter from '../../../../../components/ModalFooter/ModalFooter'
import { Input, Select } from '../../../../../components/ModalInputs'
import { useDispatch, useSelector } from 'react-redux'
import { getStudyPlanList, getStudyTrackProfile } from '../../../../../actions/studyPlans'

const Index = ({ modalOpen, setModalOpen, closeModal, handleSubmit }) => {
    console.log('modalOpen::: ', modalOpen);

    const dispatch = useDispatch()

    const { fullList = [], track_profile = [] } = useSelector(state => state.studyPlans)

    useEffect(() => {
        dispatch(getStudyPlanList());
        dispatch(getStudyTrackProfile());
    }, [])

    const renderOptions = ({ title, cid, xp_key }) => <option value={cid} key={xp_key}>{title}</option>
    const renderOptions_ = ({ trp_id, name }) => <option value={trp_id} key={trp_id}>{name}</option>

    const handleChange = (e) => {
        setModalOpen({ ...modalOpen, [e.target.name]: (e.target.value) })
    }

    return (
        <Modal
            classNames={{
                modal: styles.modalMain
            }}
            open={modalOpen}
            onClose={closeModal}
            closeOnOverlayClick={true}
            closeOnEsc={true}
            showCloseIcon={true}
            animationDuration={1}
            style={{
                modal: {
                    background: 'white',
                    width: '700px'
                }
            }}
        >

            <div className={styles.modalContent}>
                <h1 className={styles.modalHeader}>{modalOpen?.xp_key ? 'Изменить' : 'Создать'}</h1>
                <div className={styles.modalMenu}>
                    <div className={styles.tabWrapper}>
                        <Input
                            style={{ width: '450px', wordBreak: 'break-all', overflow: 'hidden', textOverflow: 'ellipsis', }}
                            label='Название'
                            error={modalOpen.name === '' ? 'Обязательное поле' : ''}
                            maxLength={120}
                            name='name'
                            value={modalOpen.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className={styles.inputsDate}>
                        <Input
                            label='Начало'
                            name='starttime'
                            type='time'
                            value={modalOpen.starttime}
                            onChange={handleChange}
                        />
                        <Input
                            label='Окончание'
                            name='stoptime'
                            type='time'
                            value={modalOpen.stoptime}
                            onChange={handleChange}
                        />
                    </div>
                    <Input
                        type='text'
                        error={modalOpen.comment === '' ? 'Обязательное поле' : ''}
                        label='Примечение'
                        maxLength={90}
                        // onKeyPress={e => !/[0-9]/.test(e.key) && e.preventDefault()}
                        name='comment'
                        value={modalOpen.comment}
                        onChange={handleChange} />



                </div>
            </div >

            <ModalFooter isShowInfo={true} closeModal={closeModal} handleSubmit={handleSubmit}
            />
        </Modal >
    )
}

export default Index