import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { IoIosAdd, IoIosSave } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Table from '../../components/Table'
import { BiTrash } from 'react-icons/bi'
import Confirmation from '../../components/Confirmation/Confirmation'
import { getBackup, deleteBackup, getBackupTime, createNewBackup, editBackupTime } from '../../actions/backup'
import Input from '../../components/Input'
import ZipDownloader from '../../utils/ZipDownloader'


export default function Backup() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { backupList, backupTime } = useSelector(state => state.backup)

  const [time, setTime] = useState(null)
  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(false);

  useEffect(() => {
    dispatch(getBackup())
    dispatch(getBackupTime())
  }, [])

  useEffect(() => {
    if (!!backupTime) {
      const t = Object.values(backupTime).join(':')
      !!backupTime && setTime(t)
    }

  }, [backupTime])

  const handleSelect = (data) => () => {
    setSelectedItem(data.filename)
  }

  const closeModal = () => setSelectedItem(null)

  const goHome = () => navigate('/')

  const acceptConfirmation = () => {
    dispatch(deleteBackup(confirmation.name));
    setConfirmation(false);
    closeModal();
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  const renderLi = (data) => {
    const { size_mb, filename } = data
    return (
      <tr style={!!selectedItem && selectedItem == filename ? { backgroundColor: 'aliceblue' } : {}} >
        <td onClick={handleSelect(data)}>{filename}</td>
        <td><i className={styles.trash} onClick={() => setConfirmation({ id: size_mb, name: filename })}><BiTrash /></i></td>
      </tr>
    )
  }

  const handleChange = (e) => {
    const t = e.target.value
    setTime(t)
  }

  const saveNewBackup = () => {
    dispatch(createNewBackup())
  }

  const saveNewTime = () => {
    const hhmm = { hour: +time.slice(0, 2), minute: +time.slice(-2) }
    dispatch(editBackupTime(hhmm))
  }


  return (
    <div className={styles.wrapper} style={{ width: '700px', margin: '0 auto' }}>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
          acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
          rejectConfirmation={rejectConfirmation}
        />
      )}

      <h1>Резервное копирование</h1>
      <div className={styles.inputsRow}>

        <Input
          type='time'
          label='Периодическое копирование'
          value={time}
          name='time'
          onChange={handleChange}
        />
        <Button
          style={{ padding: '.5rem 1.5rem', margin: '12px' }}
          onClick={saveNewTime}
        >
          <IoIosSave size={30} />
          Сохранить
        </Button>

      </div>

      <Table className={styles.tableDis}>
        <thead>
          <tr>
            <th>Название</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {backupList?.map(renderLi)}
        </tbody>
      </Table>
      <br />
      <div style={{ display: 'flex', flexDirection: "row" }}>
        <Button
          style={{ padding: '.5rem 1.5rem', margin: '12px' }}
          onClick={saveNewBackup}
        >
          <IoIosAdd size={30} />
          Создать
        </Button>
        {!!selectedItem &&
          <ZipDownloader url={selectedItem} path={'backup_postgres'} />
        }
      </div>
    </div>
  )
}
