import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../../components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Input, Select, Textarea } from '../../../components/ModalInputs'
import { createSchoolWeek, deleteSchoolWeek, updateSchoolWeek } from '../../../actions/schoolWeeks'
import * as Yup from 'yup'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import { getCurrentTermUtils, getCurrentYearUtils } from '../../../utils/classBook'
import { getStudyPeriod } from '../../../actions/classbook'


const validationSchema = Yup.object({
  week_name: Yup.string().required('обязательное поле'),
  wstart_date: Yup.string().required('обязательное поле'),
  wend_date: Yup.string().required('обязательное поле')
})


export default function Main ({closeModal, data, addMode, params }) {

  const dispatch = useDispatch()
  const { loading } = useSelector(state => state.school_weeks)
  const { studyPeriod } = useSelector(state => state.classbook) 
  const { schoolYears } = useSelector(state => state.common) 
 
  const handleCreateWeek = (values) => {
    dispatch(createSchoolWeek(values, params))  
    closeModal()
  }
  
  const handleUpdateWeek = async (values) => {
    const {week_id, ...payload} = values
    await dispatch(updateSchoolWeek(week_id, payload, params))
    closeModal()
  }

  const {
    values, 
    errors,
    touched,
    submitCount,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue
  } = useFormik({
    initialValues: addMode ? {} : data,
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: addMode ? handleCreateWeek : handleUpdateWeek,
    
  })

  const handleDeleteWeek = () => {
    dispatch(deleteSchoolWeek(values.week_id))  
    closeModal()
  }

  const currYear = getCurrentYearUtils(schoolYears, values?.wstart_date)
  const trmid_list = getCurrentTermUtils(studyPeriod, (values?.wstart_date)?.split("-").reverse().join(""))

  useEffect(()=> {
    values?.wstart_date && 
    dispatch(getStudyPeriod(currYear))
    // setFieldValue('currYear', currYear )
    setFieldValue('trmid_list', trmid_list )
  },[values?.wstart_date])

  // useEffect(() => {
  //   const currYear = getCurrentYearUtils(schoolYears)
  //   const trmid_list = getCurrentTermUtils()
  // }, [schoolYears])

  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

  return (
    <>
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          <div className={styles.inputsRow}>
            <Input
              label='Название'
              name='week_name'
              value={values.week_name}
              error={error('week_name')}
              onChange={handleChange}
              onBlur={handleBlur}
              />
          </div>
          <div className={styles.inputsRow}>
            <Input
              label='Период (начало)'
              name='wstart_date'
              type='date'
              value={values.wstart_date}
              error={error('wstart_date')}
              onChange={handleChange}
              onBlur={handleBlur}
              />
            <Input
              label='Период (конец)'
              name='wend_date'
              type='date'
              value={values.wend_date}
              onChange={handleChange}
              error={error('wend_date')}
              onBlur={handleBlur}
            />
          </div>
          <div className={styles.inputsRow}>
            <Textarea
              style={{resize: 'none', width: '750px', height: '100px'}}
              label='Комментарий'
              value={values.week_comment}
              onChange={handleChange}
              name='week_comment'
            />
          </div>
          <Select
          label="Учебный период"
          name={"do_sh_var_id"}
          value = {values.do_sh_var_id}
          withoutArrowMode
          onChange={handleChange}
          >
            <option value='0' >Выбрать</option>
            {studyPeriod && 
              studyPeriod?.map(el => <option key={el.trmid || el.xp_key} value={el.trmid || el.xp_key}>{el.name || el.title}</option>
            )}
          </Select>
        </div>
      </div>
      <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
    </>
  )
}
