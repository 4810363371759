import {BACKEND_URL_PREFIX, itemsOnPage} from "../assets/const";
import {createNotification} from "../components/Notifications/Notifications";
import {checkAndUpdateToken} from "./auth";
import {buildDefaultHeaders} from "./helper";
import {
    SET_ADS,
    SET_ADS_LOADING,
    SET_ADS_BY_ID,
    SET_EVENT_TYPES,
    SET_EVENT_TYPES_LOADING,
    SET_EVENT_TYPE_BY_ID,
    SET_ADS_BY_ID_LOADING
} from "./types";

export const getAds = (from, to) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({type: SET_ADS_LOADING, payload: true});

        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/posts?from_date=${from}&to_date=${to} 23:59:59`, {
                method: "GET",
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                const data = await response.json();
                dispatch({type: SET_ADS, payload: data});
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            dispatch({type: SET_ADS_LOADING, payload: false});
        }
    }
};

export const getAdsById = (id) => async(dispatch, getState) => {
    dispatch({type: SET_ADS_BY_ID_LOADING, payload: true});

    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/posts/${id}`, {
            method: "GET",
            ...buildDefaultHeaders(getState)
        });
        if (response.ok) {
            const data = await response.json();
            dispatch({type: SET_ADS_BY_ID, payload: data});
        }
    }
    dispatch({type: SET_ADS_BY_ID_LOADING, payload: false});
};

export const createAds = (data, from, to) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        //   dispatch({ type: SET_GROUPS_LOADING, payload: true });
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/posts`, {
                method: "POST",
                body: JSON.stringify(data),
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                createNotification('success', 'Успешно');
            } else {
                createNotification('error', 'Ошибка');
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            await dispatch(getAds(from, to));
        }
    }
    // dispatch({ type: SET_GROUPS_LOADING, payload: false });
};

export const editAds = (data, from, to) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        //   dispatch({ type: SET_GROUPS_LOADING, payload: true });
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/posts/${data.postid}`, {
                method: "PUT",
                body: JSON.stringify(data),
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                createNotification('success', 'Успешно');
            } else {
                createNotification('error', 'Ошибка');
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            await dispatch(getAds(from, to));
        }
    }
    // dispatch({ type: SET_GROUPS_LOADING, payload: false });
};

export const deleteAds = (id, from, to) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/posts/${id}`, {
                method: "DELETE",
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                createNotification('success', 'Успешно');
            } else {
                createNotification('error', 'Ошибка');
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            await dispatch(getAds(from, to));
        }
    }
};

export const getEventTypes = () => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({type: SET_EVENT_TYPES_LOADING, payload: true});

        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/event_types`, {
                method: "GET",
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                const data = await response.json();
                dispatch({type: SET_EVENT_TYPES, payload: data});
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            dispatch({type: SET_EVENT_TYPES_LOADING, payload: false});
        }
    }
};

export const deleteEventType = (id) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/event_types/${id}`, {
                method: "DELETE",
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                createNotification('success', 'Успешно');
            } else {
                createNotification('error', 'Ошибка');
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            await dispatch(getEventTypes());
        }
    }
};

export const getEventTypeById = (id) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/event_types/${id}`, {
            method: "GET",
            ...buildDefaultHeaders(getState)
        });
        if (response.ok) {
            const data = await response.json();
            dispatch({type: SET_EVENT_TYPE_BY_ID, payload: data});
        }
    }
};

export const editEventType = (data) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        let success
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/event_types/${data.ev_type_id}`, {
                method: "PUT",
                body: JSON.stringify(data),
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                success = true
                createNotification('success', 'Успешно');
            } else {
                createNotification('error', 'Ошибка');
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            await dispatch(getEventTypes());
            return success
        }
    }
};

export const createEventType = (data) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        let success
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/event_types`, {
                method: "POST",
                body: JSON.stringify(data),
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                success = true
                createNotification('success', 'Успешно');
            } else {
                createNotification('error', 'Ошибка');
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            await dispatch(getEventTypes());
            return success
        }
    }
};