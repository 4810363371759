import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { EventListContext } from '../../../utils/context'
import { useDispatch, useSelector } from 'react-redux'
import styles from './styles.module.scss'
import Button from '../../../components/Button'
import { Input, } from '../../../components/ModalInputs'
import { useFormik } from 'formik'
import { getCurrentYearUtils } from '../../../utils/classBook'
import { getCLassesByYear, getSchoolYears } from '../../../actions/common'
import moment from 'moment'
import Table from '../../../components/Table'
import { getGroupTeam } from '../../../actions/groups'



export default function Participants({ data, closeSubModal, }) {

  const { closeModal, studentData } = useContext(EventListContext)

  const dispatch = useDispatch()
  // const { address: data } = useSelector(state => state.candidates.data)
  const { classes: classByYear, schoolYears } = useSelector(state => state.common)
  const { groupTeam } = useSelector(state => state.groups)

  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(null)
  const [select, setSelect] = useState([]) // масив выбранных классов
  const [tableRigth, setTableRigth] = useState([]) // хз
  const [arrForTable, setArrForTable] = useState([]) // массив для таблички ??? 
  const [undergraundClass, setUndergraundClass] = useState(null) // массив для таблички ??? 

  useEffect(() => {
    dispatch(getSchoolYears())
  }, [])

  useEffect(() => {
    if (!arrForTable?.length) {
      dispatch(getGroupTeam(select[0]?.gid))
    }
    else (arrForTable.find(el => select.find(ele => el.gid === ele.gid))) && dispatch(getGroupTeam(select[select.length - 1]?.gid))
    setArrForTable(arrForTable.filter(el => select.find(ele => el.gid === ele.gid)))

  }, [select])

  useEffect(() => {
    !!selectedItem && !!groupTeam && !arrForTable.find(el => el.gid === selectedItem.gid) && setArrForTable([...arrForTable, { gid: selectedItem.gid, groupTeam }])
  }, [groupTeam])

  useEffect(() => {
    !!arrForTable && setTableRigth(select.map(el => arrForTable.filter(ele => ele.gid === el.gid)))  // работает проверить правильность
  }, [arrForTable])

  useEffect(() => {
    const date = getCurrentYearUtils(schoolYears, moment())
    schoolYears && dispatch(getCLassesByYear(date))  // TODO  КЛАССЫ ЗА ЭТОТ ГОД 
  }, [schoolYears])

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue
  } = useFormik({
    initialValues: studentData || {},
    enableReinitialize: true,
    onSubmit: console.log
  })


  const handleSelect = (data) => {
    if (select.find(item => item.gid === data.gid)) {
      setSelect(select.filter(item => item.gid !== data.gid))
    }
    else {
      setSelect([...select, data])
      setSelectedItem(data)
    }
  }


  const openModal = (data) => () => {
    setSelectedItem(data || {})
  }
  const openUndergraundClass = (data) => () => {
    console.log('data::: ', data);
    if (undergraundClass === data) setUndergraundClass(null)
    else setUndergraundClass(data)
  }
  const renderLi = (data) => {

    return (
      !!data.length && data.map(({ groupTeam, gid }) =>
        <>
          <tr style={{ alignItems: 'center' }} >

            <td onClick={openUndergraundClass(gid)}>{gid}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>

          {undergraundClass === gid && groupTeam.map(fio =>
            <tr style={{ alignItems: 'center' }} >
              <td>{fio.calc_fio}</td>
              <td>< input type='checkbox' /></td>
              <td>< input type='checkbox' /></td>
              <td>< input type='checkbox' /></td>
              <td>< input type='checkbox' /></td>
              <td>< input type='checkbox' /></td>
              <td>< input type='checkbox' /></td>
              <td>< input type='checkbox' /></td>
              <td>< input type='checkbox' /></td>
              <td>< input type='checkbox' /></td>
              <td>< input type='checkbox' /></td>
              <td>< input type='checkbox' /></td>
            </tr>
          )}
        </>
      )
    )
  }

  return (
    <>
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          <div className={styles.checkbox}>
            <div className={styles.inputsRow}>
              <h3>Классы</h3>
              <Button>Выделить всех</Button>
              <Button>Выделить всех План</Button>
              <Button>Выделить всех Факт</Button>

            </div>
          </div>
          <div className={styles.mainClasses}>
            <div className={styles.leftClasses}>
              <Table>
                <thead>
                  <tr>
                    <th>Классы</th>
                  </tr>
                </thead>
                <tbody>
                  {classByYear.map(el =>
                    <tr>
                      <td onClick={() => handleSelect(el)}>{el.name}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <div>
              <Table className={styles.rightClasses}>
                <thead>

                  <tr>
                    <th>Участники</th>
                    <th>План</th>
                    <th>Факт</th>
                    <th>Призер</th>
                    <th>Победитель</th>
                    <th>Место</th>
                    <th>Достижения</th>
                    <th>Результат(примечание)</th>
                    <th>Руководители</th>
                    <th>Очно</th>
                    <th>Заочно</th>
                    <th>Дистанционно</th>
                  </tr>
                </thead>
                <tbody>

                  {tableRigth?.map(renderLi)}

                </tbody>
              </Table>
            </div>
          </div>


          <Input
            label='Поиск по ФИО -'
            name='search'
            maxLength={120}
            value={values.search}
            onChange={handleChange}
            style={{ width: '500px' }}
          />
        </div >
      </div >


      <div className={styles.modalFooter}>
        <div>
          <div>Создал: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
          <div>Изменил: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
        </div>
        <Button mode='white' onClick={closeModal}>Сохранить</Button>
        <Button mode='white' onClick={closeModal}>Закрыть</Button>
      </div>
    </>
  )
}
