import React, { useEffect, useState } from 'react'
import Modal from 'react-responsive-modal'
import {useDispatch, useSelector} from 'react-redux'
import styles from './styles.module.css'
import ModalFooter from '../../components/ModalFooter/ModalFooter'
import { editExamBoardItemInterviewers, getExamBoardItemInterviewersAll } from '../../actions/exam_board'
import Table from '../../components/Table'
import { createNotification } from '../../components/Notifications/Notifications'

export default function ModalComponent({data, closeModal, dataId}) {

    const dispatch = useDispatch();
    const { interviewers_list_all } = useSelector((state) => state.exam_board);

    const [select, setSelect] = useState({});

    useEffect(() => {
      dispatch(getExamBoardItemInterviewersAll(dataId));
    }, [])

    const handleSubmit = () => {
        if (select.mid) {
            dispatch(editExamBoardItemInterviewers(data.id, select.mid, select, dataId));
            closeModal();
        } else {
            createNotification('error', 'Выберите преподавателя');
        }
    }

    return (
        <Modal
            open={!!data}
            onClose={() => closeModal()}
            showCloseIcon={false}
            height={'80vh'}
            center
            closeOnOverlayClick={true}
            closeOnEsc={true}
            classNames={{modal: styles.custom_interviewers}}>
            <> 
                <div className={styles.modalLocal}>
                    <h3>Выбор значения</h3>   
                    <div className={styles.wrapperInterviewers}>
                        <Table className={styles.table}>
                            <tr>
                                <th>ФИО</th>
                                <th>Дисциплины</th>
                                <th>Должность</th>
                                <th>Тип персонала</th>
                            </tr>
                            {   interviewers_list_all.length ?
                                interviewers_list_all.map((el, i) => (
                                    <tr
                                    className={select.mid === el.mid ? styles.active : ''} 
                                    onClick={() => setSelect(el)}
                                    key={`${el.mid}_${i}`}>
                                        <td>{el.disciplines}</td>
                                        <td>{el.fio}</td>
                                        <td>{el.post}</td>
                                        <td>{el.staff_type}</td>
                                    </tr>
                            )) : <></>
                            }
                        </Table>
                        
                    </div>
                </div>

            <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal}/>
            </>
        </Modal>

    )
}
