import React, { useEffect, useState } from 'react'
import Modal from 'react-responsive-modal'
import { useDispatch, useSelector } from 'react-redux'
import styles from './styles.module.css'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ModalFooter from '../../components/ModalFooter/ModalFooter'
import Table from '../../components/Table'
import Confirmation from '../../components/Confirmation/Confirmation'
import Loader from '../../components/Loader'
import { Input, } from '../../components/ModalInputs'
import moment from 'moment'
import { copyDishPeriod } from '../../actions/directoryCanteen'


const validationSchema = Yup.object({
  title: Yup.string().required('обязательное поле').max(30, 'Должно быть не более 30 символов'),

})

export default function ModalComponent({ data, closeModal, height, addMode, setSelectedDish, selectedDay }) {
  console.log('selectedDayselectedDayselectedDayselectedDayselectedDay::: ', selectedDay);
  const dispatch = useDispatch()

  const { loading } = useSelector(state => state.directoryCanteen)

  const [confirmation, setConfirmation] = useState(false);
  const [toDate, setToDate] = useState(null);

  const acceptConfirmation = () => {
    closeModal();
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  }
  const handleChange = (e) => {
    const date = e.target.value
    setToDate(moment(date).format('YYYY-MM-DD'))
  }
  const saveMenuOnDate = () => {
    const forRequest = { from_date: selectedDay.date, to_date: toDate }
    dispatch(copyDishPeriod(forRequest))
    closeModal()
  }
  return (
    <>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
          acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
          rejectConfirmation={rejectConfirmation}
        />
      )}

      <Modal
        open={!!data}
        // onClose={() => false}
        close={closeModal}
        showCloseIcon={false}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        animationDuration={1}
        center
        classNames={{
          // overlay: styles.overlay,
          modal: styles.custom
        }}
      >
        {loading ? <Loader /> :
          <div className={styles.modalDateCopy} >

            <h3>День на который копируется меню</h3>
            <Input
              // label='Дата'
              type='date'
              name='date'
              value={toDate}
              onChange={(e) => handleChange(e)}
            />


          </div>
        }
        <ModalFooter closeModal={closeModal} handleSubmit={saveMenuOnDate} />
      </Modal>
    </>

  )
}
