import React, {  useState } from 'react'
import Modal from 'react-responsive-modal'
import { useDispatch, useSelector } from 'react-redux'
import styles from './styles.module.css'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ModalFooter from '../../components/ModalFooter/ModalFooter'
import Table from '../../components/Table'
import Confirmation from '../../components/Confirmation/Confirmation'
import Loader from '../../components/Loader'

const validationSchema = Yup.object({
  title: Yup.string().required('обязательное поле').max(30, 'Должно быть не более 30 символов'),

})

export default function ModalComponent({ data, closeModal, height, addMode, setSelectedDish, selectedDay }) {

  const { loading } = useSelector(state => state.directoryCanteen)
  
  const [confirmation, setConfirmation] = useState(false);


  const handle = (dish) => {
        setSelectedDish({...dish,
          xp_period: dish.c_dishclass, 
          xp_dish: dish.xp_key,
          xp_date: !!selectedDay && selectedDay.date, 
          exit_period: dish.exit,
          edizm_period: dish.edizm,
        }
        )
        // setSelectedDish(data)
        closeModal()
      }

  const {
    values,
    errors,
    touched,
    submitCount,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: addMode ? {
      title: "",
      teacher_mid: 818,
      school_year: 15,
      period: 0,
      people_specmedgroup: []
    } : data,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: handle
  })

  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]



  const acceptConfirmation = () => {
    closeModal();
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };


  const renderLi = (dish) => {
    const { name, exit, edizm, price, xp_key } = dish
    return (
      <tr
        key={xp_key}
        onClick={() => handle(dish)}
      >
        <td>{name}</td>
        <td>{price}</td>
        <td></td>
        <td>{`${exit} ${edizm}`}</td>
      </tr>
    )
  }


  return (
    <>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
          acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
          rejectConfirmation={rejectConfirmation}
        />
      )}
     
        <Modal
          open={!!data}
          // onClose={() => false}
          close={closeModal}
          showCloseIcon={false}
          closeOnOverlayClick={false}
          closeOnEsc={false}
          animationDuration={1}
          center
          classNames={{
            // overlay: styles.overlay,
            modal: styles.custom
          }}
        >
           {loading ? <Loader /> :
          <div className={styles.modalLocal} >

            <div className={styles.tabWrapper} >
              <Table className={styles.tables} >
                <thead>
                  <tr>
                    <th>Блюдо</th>
                    <th>Цена</th>
                    <th>Тип</th>
                    <th>Выход(по умолчанию)</th>
                  </tr>
                </thead>
                <tbody>

                  {data?.map(renderLi)}

                </tbody>
              </Table>

            </div>
          </div>
        }
          <ModalFooter closeModal={closeModal} />
        </Modal>
    </>

  )
}
