import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getExamInfo, createExamInfo, updateExamInfo, deleteExamInfo } from '../../actions/exams/examInfo'
import Li from '../../components/Li'
import Button from '../../components/Button'
import Modal from 'react-responsive-modal'
import { useFormik } from 'formik'
import { Input, Textarea } from '../../components/ModalInputs'
import Confirmation from '../../components/Confirmation/Confirmation'
import { BiTrash } from 'react-icons/bi'
import * as Yup from 'yup'


const validationSchema = Yup.object().shape({
  name: Yup.string().required('Это поле обязательно для заполнения').max(30, 'имя не может превышать 30 символов'),
  info: Yup.string().required('Это поле обязательно для заполнения').max(256, 'текст не может превышать 256 символов'),
});

const Info = ({ogeMode}) => {
  
  const parallel = ogeMode ? 9 : 11

  const dispatch = useDispatch()
  const { info, loading } = useSelector(state => state.exams)

  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(null)


  useEffect(() => {
    dispatch(getExamInfo(parallel))
  }, [])
  
  const deleteItem = (id) => (e) => {
    e.stopPropagation()
    setConfirmation(id)
  }

  const openModal = (data) => () => setSelectedItem(data || {})

  const closeModal = () => setSelectedItem(null)

  const renderLi = (item) => (
    <Li onClick={openModal(item)}>
      <span>{item.name}</span>
      <BiTrash size={24} color='red' title='Удалить' onClick={deleteItem(item.eiid)}/>
    </Li>
  )
  
  const acceptConfirmation = () => {
    dispatch(deleteExamInfo(confirmation, parallel))
    setConfirmation(null)
  }
  
  const rejectConfirmation = () => {
    setConfirmation(null)
  }

  return (
    <div className={styles.wrapper}>
      <h1>Информация о {ogeMode ? 'ОГЭ' : 'ЕГЭ'}</h1>
      <ul>{info?.map(renderLi)}</ul>
      <Button onClick={openModal()}>Добавить</Button>
      <ModalComponent
        open={!!selectedItem}
        closeModal={closeModal}
        data={selectedItem}
        addMode={!selectedItem?.eiid}
        parallel={parallel}
      />
      {confirmation && (
        <Confirmation
            top="30px"
            left="40px"
            title="Уверены что хотите удалить запись?"
            acceptConfirmation={acceptConfirmation}
            rejectConfirmation={rejectConfirmation}
        />
      )}
    </div>
  )
}


const ModalComponent = ({open, closeModal, data, addMode, parallel}) => {
  
  const dispatch = useDispatch()

  useEffect(() => {
    if (!open) return;
    setValues(addMode ? {parallel} : data)
  }, [open])
  

  const createItem = async (values) => {
    const success = await dispatch(createExamInfo(values, parallel))
    success && closeModal()
  }
  
  const updateItem = async ({eiid, ...values}) => {
    const success = await dispatch(updateExamInfo(eiid, values, parallel))
    success && closeModal()
  }

  const {
    values,
    errors,
    touched,
    submitCount,
    handleBlur,
    handleSubmit,
    handleChange,
    setValues,
  } = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validationSchema,
    onSubmit: addMode ? createItem : updateItem
  })

  const error = (fieldName) => (touched[fieldName] || !!submitCount) && errors[fieldName]

  return (
    <Modal
      open={open}
      onClose={closeModal}
      classNames={{modal: styles.modal}}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      showCloseIcon={false}
      animationDuration={2}
      center
    >
      <div className={styles.modalMain}>
        <h2>{addMode ? 'Добавление записи' : 'Редактирование записи'}</h2>
        <Input
          name='name'
          label='Название'
          value={values.name}
          error={error('name')}
          onChange={handleChange}
          onBlur={handleBlur}
          />
        <Textarea
          name='info'
          label='Текст'
          value={values.info}
          onChange={handleChange}
          onBlur={handleBlur}
          error={error('info')}
        />
      </div>
      <div className={styles.modalFooter}>
          <div>
              <div></div>
              <div></div>
          </div>
          <Button mode='white' type="submit" onClick={handleSubmit}>Сохранить</Button>
          <Button mode='white' onClick={closeModal}>Закрыть</Button>
      </div>
    </Modal>
  )
}


export default Info