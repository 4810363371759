import { BACKEND_URL_PREFIX } from "../assets/const";
import { createNotification } from "../components/Notifications/Notifications";
import { checkAndUpdateToken } from "./auth";
import { buildDefaultHeaders } from "./helper";
import {
  GET_SUBJECTS_EXTRA_CUR_EDU_LIST,
  GET_SUBJECTS_EXTRA_CUR_EDU_ITEM
} from "./types";





// <----  ПРЕДМЕТЫ ДО ---->

export const getSubjectsExtraCurEdu = (status) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const urlParams = status.status !== "Идет" ? '' : new URLSearchParams(status).toString();
    const response = await fetch(`${BACKEND_URL_PREFIX}/out_courses?${urlParams}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      dispatch({ type: GET_SUBJECTS_EXTRA_CUR_EDU_LIST, payload: result });
    }
  }
};

export const getSubjectsExtraCurEduById = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/out_courses/${id} `, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());
      
      dispatch({ type: GET_SUBJECTS_EXTRA_CUR_EDU_ITEM, payload: result });
    }
  }

};

export const updateSubjectsExtraCurEdu = (params, status) => async (dispatch, getState) => {
  console.log('params::: ', params);
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {

    const response = await fetch(`${BACKEND_URL_PREFIX}/out_courses/${params.xp_key}`, {
      method: "PUT",
      body: JSON.stringify(params),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      await dispatch(getSubjectsExtraCurEdu(status));
    }
  };
}

export const createSubjectsExtraCurEdu = (data, status) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/out_courses`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getSubjectsExtraCurEdu(status));
    } else createNotification('error', "Не удалось создать запись");
  }
};

export const deleteSubjectsExtraCurEdu = (id, status) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/out_courses/${id}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    const result = await response.json();

    if (response.ok) {
      createNotification('success', 'Успешно');
      await dispatch(getSubjectsExtraCurEdu(status));
    } else createNotification('error', result.error);
  }
};
