import { BACKEND_URL_PREFIX, itemsOnPage, recipientActionTypes } from "../assets/const";
import { createNotification } from "../components/Notifications/Notifications";
import { checkAndUpdateToken } from "./auth";
import { buildDefaultHeaders } from "./helper";
import { api } from "../App";

import {
    SET_BACKUP_LIST,
    SET_BACKUP_BY_NAME,
    SET_BACKUP_TIME,
} from "./types";


export const getBackup = () => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/backup_postgres`, {
                method: "GET",
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                const data = await response.json();
                dispatch({ type: SET_BACKUP_LIST, payload: data });
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
        }
    }
};


export const getBackupByName = (name) => async (dispatch, getState) => {
    try {
        const { data } = await api.get(`/backup_postgres/${name}`);
        dispatch({ type: SET_BACKUP_BY_NAME, payload: data });
    } catch (ERR) {
    } finally {
    }
}

export const createNewBackup = () => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/backup_postgres/create/now`, {
                method: "POST",
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                createNotification('success', 'Успешно');
            } else {
                const error = await response.json();
                createNotification('error', error.error);
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            await dispatch(getBackup());
        }
    }
};

export const deleteBackup = (name) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/backup_postgres/${name}`, {
            method: "DELETE",
            ...buildDefaultHeaders(getState),
        });
        const result = await response.json();
        if (response.ok) {
            createNotification('success', 'Успешно');
            await dispatch(getBackup());
        } else createNotification('error', result.error);
    }
};

// Время когда делается бэкап
export const getBackupTime = (id) => async (dispatch, getState) => {
    try {
        const { data } = await api.get(`/backup_postgres/time`);
        dispatch({ type: SET_BACKUP_TIME, payload: data });
    } catch (ERR) {
    } finally {
    }
}

//изм этого времени
export const editBackupTime = (data) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/backup_postgres/time`, {
                method: "PUT",
                body: JSON.stringify(data),
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                createNotification('success', 'Успешно');
            } else {
                const error = await response.json();
                createNotification('error', error.error);
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
        }
    }
};

