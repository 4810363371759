import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { createEduDirection, deleteEduDirection, getEduDirections, updateEduDirection } from '../../actions/edu_directions'
import Li from '../../components/Li'
import Button from '../../components/Button'
import Modal from 'react-responsive-modal'
import { useFormik } from 'formik'
import { Input } from '../../components/ModalInputs'
import Confirmation from '../../components/Confirmation/Confirmation'
import { BiTrash } from 'react-icons/bi'
import * as Yup from 'yup'


const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, 'Пожалуйста, введите имя')
    .max(50, 'Имя не может превышать 50 символов')
    .required('Это поле обязательно для заполнения'),
});

const EduDirections = () => {
  
  const dispatch = useDispatch()
  const { list, loading } = useSelector(state => state.edu_directions)

  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(null)


  useEffect(() => {
    dispatch(getEduDirections())
  }, [])
  
  const deleteItem = (id) => (e) => {
    e.stopPropagation()
    setConfirmation(id)
  }

  const openModal = (data) => () => setSelectedItem(data || {})

  const closeModal = () => setSelectedItem(null)

  const renderLi = (item) => (
    <Li onClick={openModal(item)}>
      <span>{item.name}</span>
      <BiTrash size={24} color='red' title='Удалить' onClick={deleteItem(item.id)}/>
    </Li>
  )
  
  const acceptConfirmation = () => {
    dispatch(deleteEduDirection(confirmation))
    setConfirmation(null)
  }
  
  const rejectConfirmation = () => {
    setConfirmation(null)
  }

  return (
    <div className={styles.wrapper}>
      <h1>Образовательные направления</h1>
      <ul>{list?.map(renderLi)}</ul>
      <Button onClick={openModal()}>Добавить</Button>
      <ModalComponent
        open={!!selectedItem}
        closeModal={closeModal}
        data={selectedItem}
        addMode={!selectedItem?.id}
      />
      {confirmation && (
        <Confirmation
            top="30px"
            left="40px"
            title="Уверены что хотите удалить запись?"
            acceptConfirmation={acceptConfirmation}
            rejectConfirmation={rejectConfirmation}
        />
      )}
    </div>
  )
}


const ModalComponent = ({open, closeModal, data, addMode}) => {
  
  const dispatch = useDispatch()

  useEffect(() => {
    if (!open) return;
    setValues(addMode ? {} : data)
  }, [open])
  

  const createItem = async (values) => {
    const success = await dispatch(createEduDirection(values))
    success && closeModal()
  }
  
  const updateItem = async ({id, ...values}) => {
    const success = await dispatch(updateEduDirection(id, values))
    success && closeModal()
  }

  const {
    values,
    errors,
    touched,
    submitCount,
    handleBlur,
    handleSubmit,
    handleChange,
    setValues,
  } = useFormik({
    initialValues: addMode ? {} : data,
    enableReinitialize: true,
    validationSchema,
    onSubmit: addMode ? createItem : updateItem
  })

  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

  return (
    <Modal
      open={open}
      onClose={closeModal}
      classNames={{modal: styles.modal}}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      showCloseIcon={false}
      animationDuration={2}
      center
    >
      <div className={styles.modalMain}>
        <h2>{addMode ? 'Добавление записи' : 'Редактирование записи'}</h2>
        <Input
          name='name'
          label='Название'
          value={values.name}
          error={error('name')}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </div>
      <div className={styles.modalFooter}>
          <div>
              <div></div>
              <div></div>
          </div>
          <Button mode='white' type="submit" onClick={handleSubmit}>Сохранить</Button>
          <Button mode='white' onClick={closeModal}>Закрыть</Button>
      </div>
    </Modal>
  )
}


export default EduDirections