import React from 'react'
import styles from './styles.module.css'
import { Input, Select } from '../../../../../components/ModalInputs';
// import Select from '../../../../../components/Select'

import { statusAccess } from '../../../../../assets/const';
import { useSelector } from 'react-redux';




const Main = ({ tfo_base_obj, tfo_types, teachers, values, handleChange, error }) => {
    console.log('MainMainMain MainMain values::: ', values);

    return (
        <div>
            <div className={styles.inputsRow}>
                <Input
                    label='Название'
                    name='short_title'
                    value={values.short_title}
                    onChange={handleChange}
                    error={error('short_title')}
                />
            </div>
            <div className={styles.inputsRow}>
                <Input
                    label='Полное название'
                    name='full_title'
                    value={values.full_title}
                    onChange={handleChange}
                    error={error('full_title')}
                />
            </div>
            <div className={styles.inputsColumn}>
                <Select
                    label='База объекта'
                    name='baseid'
                    value={values.baseid}
                    onChange={handleChange}
                    error={error('baseid')}
                >
                    <option value={0}>Выбрать</option>
                    {tfo_base_obj?.map(({ tfobid, title }) => <option value={tfobid}>{title}</option>)}
                </Select>
                <Select
                    label='Тип объекта'
                    name='typeid'
                    value={values.tfotid || values.typeid}
                    onChange={handleChange}
                    error={error('typeid')}

                >
                    <option value={0}>Выбрать</option>
                    {tfo_types?.map(({ tfotid, title }) => <option value={tfotid}>{title}</option>)}
                </Select>

                <Select
                    // error={checkError('mid')}
                    name='teacher_mid'
                    label='Ответственный'
                    value={values.teacher_mid}
                    onChange={handleChange}
                    error={error('teacher_mid')}

                >
                    <option value={0}>Выбрать</option>
                    {teachers?.teachers?.map(({ mid, calc_fio }) => <option value={mid}>{calc_fio}</option>)}
                </Select>
                <Select
                    label='Статус'
                    name='isactive'
                    value={values.isactive}
                    onChange={handleChange}
                    error={error('isactive')}

                >
                    <option value={0}>Выбрать</option>
                    {statusAccess?.map(({ value, name }) => <option value={value}>{name}</option>)}
                </Select>


            </div>


        </div >
    )
}

export default Main