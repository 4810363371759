import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getScheduleVariants, deleteScheduleVariant, createScheduleVariant, updateScheduleVariant, setYear } from '../../actions/doScheduleVariants'
import Button from '../../components/Button'
import Modal from 'react-responsive-modal'
import { useFormik } from 'formik'
import { Input, Select, Textarea } from '../../components/ModalInputs'
import Confirmation from '../../components/Confirmation/Confirmation'
import { getSchoolYears } from '../../actions/common'
import { BiTrash } from 'react-icons/bi'
import Table from '../../components/Table'



const ScheduleVariantsDO = () => {

  const dispatch = useDispatch()
  const { list, year } = useSelector(state => state.do_schedule_variants)
  const { schoolYears } = useSelector(state => state.common)

  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(null)

  useEffect(() => {
    dispatch(getSchoolYears())
    return () => dispatch(setYear(''))
  }, [])

  useEffect(() => {
    dispatch(getScheduleVariants())
  }, [year])


  const deleteItem = (id) => (e) => {
    e.stopPropagation()
    setConfirmation(id)
  }

  const openModal = (data) => () => setSelectedItem(data || {})

  const closeModal = () => setSelectedItem(null)

  const renderLi = (item) => {
    const year = schoolYears.find(({ xp_key }) => xp_key === item.s_year_id)?.name
    return (
      <tr onClick={openModal(item)}>
        <td>{year}</td>
        <td>{item.sh_var_name}</td>
        <td>{item.description}</td>
        <td>
          <BiTrash size={24} color='red' title='Удалить' onClick={deleteItem(item.sh_var_id)} />
        </td>
      </tr>
    )
  }

  const acceptConfirmation = () => {
    dispatch(deleteScheduleVariant(confirmation))
    setConfirmation(null)
  }

  const rejectConfirmation = () => {
    setConfirmation(null)
  }

  const renderOptions = ({ name, xp_key }) => <option value={xp_key}>{name}</option>

  return (
    <div className={styles.wrapper}>
      <h1>Варианты расписания ДО</h1>
      <Select label='Учебный год' value={year} onChange={(e) => dispatch(setYear(e.target.value))}>
        <option value=''>Все</option>
        {schoolYears?.map(renderOptions)}
      </Select>
      <Button onClick={openModal()}>Добавить</Button>
      <Table>
        <thead>
          <tr>
            <th>Уч.год</th>
            <th>Вариант</th>
            <th>Комментарий</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {console.log('list::: ', list)}
          {list?.schedule_variants?.map(renderLi)}
        </tbody>
      </Table>
      <ModalComponent
        open={!!selectedItem}
        closeModal={closeModal}
        data={selectedItem}
        addMode={!selectedItem?.sh_var_id}
        schoolYears={schoolYears}
      />
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title="Уверены что хотите удалить запись?"
          acceptConfirmation={acceptConfirmation}
          rejectConfirmation={rejectConfirmation}
        />
      )}
    </div>
  )
}


const ModalComponent = ({ open, closeModal, data, addMode, schoolYears }) => {

  const dispatch = useDispatch()

  useEffect(() => {
    if (!open) return;
    setValues(addMode ? {} : data)
  }, [open])


  const createItem = async (values) => {
    const success = await dispatch(createScheduleVariant(values))
    success && closeModal()
  }

  const updateItem = async ({ sh_var_id, ...values }) => {
    const success = await dispatch(updateScheduleVariant(sh_var_id, values))
    success && closeModal()
  }

  const renderOptions = ({ name, xp_key }) => <option value={xp_key}>{name}</option>

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    setValues
  } = useFormik({
    initialValues: addMode ? {} : data,
    enableReinitialize: true,
    onSubmit: addMode ? createItem : updateItem
  })

  return (
    <Modal
      open={open}
      onClose={closeModal}
      classNames={{ modal: styles.modal }}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      showCloseIcon={false}
      animationDuration={2}
      center
    >
      <div className={styles.modalMain}>
        <h2>{addMode ? 'Добавление записи' : 'Редактирование записи'}</h2>
        <Input
          name='sh_var_name'
          label='Название варианта'
          value={values.sh_var_name}
          onChange={handleChange}
        />
        <Select
          name='s_year_id'
          label='Учебный год'
          value={values.s_year_id}
          onChange={handleChange}
        >
          <option value=''>Все</option>
          {schoolYears?.map(renderOptions)}
        </Select>
        <Textarea
          name='sh_var_comments'
          label='Описание варианта'
          value={values.sh_var_comments}
          onChange={handleChange}
          className={styles.textarea}
        />
      </div>
      <div className={styles.modalFooter}>
        <div>
          <div />
          <div />
        </div>
        <Button mode='white' type="submit" onClick={handleSubmit}>Сохранить</Button>
        <Button mode='white' onClick={closeModal}>Закрыть</Button>
      </div>
    </Modal>
  )
}


export default ScheduleVariantsDO