import React, { useEffect, useRef, useState } from 'react';
import styles from "./styles.module.css"
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import moment from 'moment';



//СЫРОЙ СЕЛЕКТ - НЕОРИГИНАЛ
const SelectPal = ({ options, value, title, onChange, width, setFieldValue, valuesName, disabled, }) => {

    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState([]);
    const [numValue, setNumValue] = useState()
    const select = useRef()
    // 
    useEffect(() => {
        // options && !options.find(el => el.name == selectedValue) && setSelectedValue("Выбрать")
        // options && options.find(el => el.value == numValue && setSelectedValue(el.name))
        // setNumValue(value)
    }, [options])

    useEffect(() => {
        (options && value && typeof (value) === 'string') ?
            setSelectedValue(value) :
            setSelectedValue(!!options && options.find(el => el.value === value)?.name)
        // console.log('typeof (value) ===  ', typeof (value) === 'string');
    }, [value,])

    const handleSelectChange = (e, name) => {
        const nameAtt = e.target.getAttribute('name');
        setSelectedValue(name);
        setIsOpen(false);
        setFieldValue(nameAtt, e.target.value)
    };

    const handleHoverOff = () => {
        setIsOpen(false);
    }
    useOnClickOutside(select, handleHoverOff)

    return (
        <div
            className={styles.customSelect}
            ref={select}
        >
            <p>{title}</p>
            <div
                onClick={() => setIsOpen(true)}
                className={styles.inputSel}
                style={disabled ? { cursor: 'not-allowed', backgroundColor: '#0000000d' } : { width: width }}
            > {!!selectedValue?.length ? selectedValue : "Выбрать"}
            </div>
            {isOpen && (
                <ul className={styles.optionsList}
                >
                    {options.length && options?.map((el) => {
                        return <li key={`${el.value}${valuesName}`} value={el.value} name={valuesName} onClick={(e) => handleSelectChange(e, el.name)}>{el.name}</li>
                    })}
                </ul>
            )
            }

        </div >

    );
};

export default SelectPal;
