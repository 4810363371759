import React, { useEffect, useRef, useState } from 'react'
import styles from './styles.module.css'
import Button from '../../../components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Checkbox, CreatableSelect, Input, MultiSelect, Select } from '../../../components/ModalInputs'
import { createSchoolYear, updateSchoolYear } from '../../../actions/schoolYears'
import * as Yup from 'yup'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import { getTermGradeTypes, getTermTypes } from '../../../actions/common'
import { createGradeOptions, updateGradeOptions } from '../../../actions/directory'
import Creatable from 'react-select/creatable';

const validationSchema = Yup.object({
  value: Yup.string().required('обязательное поле'),

})


export default function Main({ closeModal, data, addMode }) {

  const dispatch = useDispatch()
  const { gradesOptions } = useSelector(state => state.directory)
  console.log('gradesOptions::: ', gradesOptions);
  const [errorForCreatableSelect, setErrorForCreatableSelect] = useState(false)

  const handleCreateGradeOptions = async (values) => {
    const forRequest =
    {
      ...values,
      isgrade: values.isgrade ? -1 : 0,
      isdisease: values.isdisease ? -1 : 0,
    }
    !values.value && setErrorForCreatableSelect(true)
    values.value && await dispatch(createGradeOptions(forRequest))
    closeModal()
  }

  const handleUpdateGradeOptions = async (values) => {
    const forRequest =
    {
      ...values,
      isgrade: values.isgrade ? -1 : 0,
      isdisease: values.isdisease ? -1 : 0,
    }
    dispatch(updateGradeOptions(forRequest))
    closeModal()
  }

  const {
    values,
    errors,
    touched,
    submitCount,
    handleChange,
    setFieldValue,
    handleSubmit
  } = useFormik({
    initialValues: addMode ? { value: '', text: '', isgrade: '', isdisease: '' } : data,
    enableReinitialize: true,
    // validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: addMode ? handleCreateGradeOptions : handleUpdateGradeOptions
  })
  console.log('values::: ', values);
  const createOption = (label, dataId) => ({
    label,
    value: dataId,
  });

  const options = gradesOptions.map(({ value, valid }) => {
    return createOption(value, valid);
  });

  const handleChange2 = (value) => {
    setFieldValue('value', value ? value.label : '')
  }

  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]

  return (
    <>
      <div className={styles.modalMain} >
        <div className={styles.tabWrapper} style={{ display: "flex", justifyContent: 'space-evenly' }}>
          <div className={styles.inputsColumn}>
            <CreatableSelect
              isClearable
              options={options}
              onChange={handleChange2}
              label='Значение'
              name='value'
              placeholder={values.value || "Выбрать"}
              error={error('value')}
            />
          </div>
          <div className={styles.inputsRow}>
            <Input
              label='Примечание'
              name='text'
              value={values.text}
              error={error('text')}
              onChange={handleChange}
              style={{ width: '300px', lineHeight: '25px' }}
            />
          </div>
          <div className={styles.inputsRow}>
            <Checkbox label='Это оценка' checked={!!values.isgrade} value={!!values.isgrade} onChange={handleChange} name='isgrade' />
            <Checkbox label='Отсутстве по болезни' checked={!!values.isdisease} value={!!values.isdisease} onChange={handleChange} name='isdisease' />

          </div>
        </div>
      </div>
      <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
    </>
  )
}
