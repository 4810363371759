import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../../components/Button'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { Input, Select, Textarea } from '../../../components/ModalInputs'
import * as Yup from 'yup'
import { getSchoolYears } from '../../../actions/common'
import { createScheduleVariant, updateScheduleVariant } from '../../../actions/scheduleVariants'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'

const validationSchema = Yup.object({
  sh_var_name: Yup.string().required('обязательное поле').max(30, 'Должно быть не более 30 символов'),
  sh_var_comments: Yup.string().max(256, 'Должно быть не более 256 символов'),
})


export default function Main ({closeModal, data, addMode, yearId}) {
  
  const dispatch = useDispatch()
  const { loading } = useSelector(state => state.terms)
  const { schoolYears } = useSelector(state => state.common)
  
  useEffect(() => {
    !schoolYears && dispatch(getSchoolYears())
  }, [])
  

  const handleCreateVariant = async (values) => {
    await dispatch(createScheduleVariant(values, yearId))  
    closeModal()
  }
  
  const handleUpdateVariant = async (values) => {
    const {sh_var_id, ...payload} = values
    await dispatch(updateScheduleVariant(sh_var_id, payload, yearId))
    closeModal()
  }
  
  const renderOptions = ({name, xp_key}) => <option value={xp_key}>{name}</option>

  const {
    values, 
    errors,
    touched,
    submitCount,
    handleBlur,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: addMode ? { s_year_id: yearId } : data,
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: addMode ? handleCreateVariant : handleUpdateVariant
  })

  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]


  return (
    <>
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          <div className={styles.inputsRow}>
            <Input
              style={{width: '450px'}}
              label='Название'
              name='sh_var_name'
              value={values.sh_var_name}
              error={error('sh_var_name')}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className={styles.inputsRow}>
            <Select
              label='Учебный год'
              name='s_year_id'
              value={values.s_year_id}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              {schoolYears?.map(renderOptions)}
            </Select>
          </div>
          <div className={styles.inputsRow}>
            <Textarea
              style={{resize: 'none', width: '750px', height: '180px'}}
              label='Описание варианта'
              name='sh_var_comments'
              value={values.sh_var_comments}
              onChange={handleChange}
              error={error('sh_var_comments')}
            />
          </div>
        </div>
      </div>
      <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
    </>
  )
}
