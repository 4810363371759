import React from 'react'
import styles from './styles.module.css'
import Button from '../../../components/Button'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createCourse, getCompetence, getCoursesAlias, getCultureList, getCurrentCourse, getEduDirList, updateCourse } from '../../../actions/courses'
import { useFormik } from 'formik'
import { Checkbox, CreatableSelect, Input, Select, Textarea } from '../../../components/ModalInputs'
import cn from 'classnames'
import * as Yup from 'yup'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'

const validationSchema = Yup.object({
  title: Yup.string().required('обязательное поле')
})

const statusList = [
  { name: 'Идет', value: '0' },
  { name: 'Закрыт', value: '2' },
]

export default function Main({ closeModal, courseId, addMode, list, data_, pageNumber, infoCreateChange }) {

  const dispatch = useDispatch()
  const { currentCourse: data, loading,
    coursesAliasList, coursesAliasList_loading,
    eduDirList, eduDirList_loading,
    cultureListList, cultureListList_loading,
    competenceList, competenceList_loading } = useSelector(state => state.courses || [])

  useEffect(() => {
    courseId && !addMode && dispatch(getCurrentCourse(courseId))
  }, [])

  useEffect(() => {
    dispatch(getCoursesAlias())
    dispatch(getEduDirList())
    dispatch(getCultureList())
    dispatch(getCompetence())
  }, [])

  const handleCreateCourse = async (values) => {
    await dispatch(createCourse(values))
    closeModal()
  }

  const handleUpdateCourse = async (values) => {
    const { cid, ...payload } = values
    await dispatch(updateCourse(cid, payload, pageNumber))
    closeModal()
  }

  const {
    values,
    errors,
    touched,
    setFieldValue,
    submitCount,
    handleChange,
    handleBlur,
    handleSubmit
  } = useFormik({
    initialValues: addMode ? {
      title: '',
      xp_split: '',
      xp_paired: 0,
      description: '',
      alias: '',
      cd: '',
      reporthse: 0,
      c_competence: null,
      c_culture: null,
      c_edu_direction: null,
      isindividual: 0,
      isparallelcourse: 0,
      status: 0
    } : list?.find(d => d?.cid === data_),
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: addMode ? handleCreateCourse : handleUpdateCourse
  })

  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]
  console.log('values::: ', values);

  const onCheckCLlick = (e) => setFieldValue(e.target.name, Number(!values[e.target.name]))

  return (
    <>
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          <div className={styles.inputsRow}>
            <Input
              label='Название'
              name='title'
              value={values?.title}
              error={error('title')}
              onChange={handleChange}
            // onBlur={handleBlur}
            />
          </div>
          <div className={styles.inputsRow}>
            <Select
              label='Дисциплины'
              // value={values?.cid}
              value={values?.alias}
              onChange={handleChange}
              name='cid'
              style={{ width: '500px' }}
            >
              {/* {list && [{title: 'Выберите дисциплину', cid: null}, ...list]?.map(({title, cid}) => <option key={cid} value={cid}>{title}</option>)} */}
              {coursesAliasList?.map(el => <option key={el} value={el}>{el}</option>)}

            </Select>
          </div>
          <div className={styles.inputsRow}>
            <Input
              label='Короткое название'
              name='xp_split'
              value={values?.xp_split} // непонятно то ли???
              onChange={handleChange}
            />
            {/* // TODO замена на инпут хз на долго ли
           <Select
              style={{width: '500px', margin: '.3rem 1rem 0 0'}}
              label='Короткое название'
              value={values?.alias}
              onChange={handleChange}
              name='alias'
            >
            <option value=''>Выберите короткое название</option>
            {coursesAliasList?.map(el => <option key={el} value={el}>{el}</option>)}
          </Select> */}
            <Select
              style={{ width: '100px', margin: '.3rem 1rem 0 0' }}
              label='Статус'
              value={values?.status}
              onChange={handleChange}
              name='status'
            >
              {statusList?.map(({ name, value }) => <option key={value} value={value}>{name}</option>)}
            </Select>
          </div>
          <div className={cn([styles.inputsRow, styles.checkboxRow])}
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <Checkbox
              label='Парный урок'
              name='xp_paired'
              checked={values?.xp_paired}
              onClick={onCheckCLlick}
              />
            <Checkbox
              label='Индивидуальный урок'
              name='isindividual'
              checked={values?.isindividual}
              onClick={onCheckCLlick}
              />
            <Checkbox
              label='Для всей параллели'
              name='isparallelcourse'
              checked={values?.isparallelcourse}
              onClick={onCheckCLlick}
              />

            <Checkbox
              label='Без домашнего задания'
              name='needhomework'
              checked={values?.needhomework}
              onClick={onCheckCLlick}
              />
            <Checkbox
              label='Приказ №352'
              name='p352'
              checked={values?.p352}
              onClick={onCheckCLlick}
              />
          </div>
          <div className={cn([styles.inputsRow, styles.checkboxRow])}>
            <Checkbox
              label='Без оценок'
              name='neednt_grades'
              checked={values?.neednt_grades}
              onClick={onCheckCLlick}
              />
            <Checkbox
              style={{ marginLeft: '4rem' }}
              label='Аттестат'
              name='skip_attestat'
              checked={values?.skip_attestat}
              onClick={onCheckCLlick}
              />
            <Checkbox
              style={{ marginLeft: '4rem' }}
              label='ЕГЭ'
              name='ege'
              checked={values?.ege}
              onChange={onCheckCLlick}
              />
            <Checkbox
              style={{ marginLeft: '4rem' }}
              label='ОГЭ'
              name='gia'
              checked={values?.gia}
              onClick={onCheckCLlick}
            />
          </div>
          <div className={styles.inputsRow} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div className={styles.wrapperSelect}>
              <Select
                label='Образовательное направление'
                value={values?.c_edu_direction}
                onChange={handleChange}
                name='c_edu_direction'
                style={{ width: '100%' }}
              >
                {[{ name: 'Не выбрано', id: 0 }, ...eduDirList]?.map(({ name, id }) => <option key={id} value={id}>{name}</option>)}
              </Select>
            </div>
            <div className={styles.wrapperSelect}>
              <Select
                label='Культура'
                value={values?.c_culture}
                onChange={handleChange}
                name='c_culture'
                style={{ width: '100%' }}
              >
                {[{ name: 'Не выбрано', id: 0 }, ...cultureListList]?.map(({ name, id }) => <option key={id} value={id}>{name}</option>)}
              </Select>
            </div>
            <div className={styles.wrapperSelect}>
              <Select
                label='Компетентность'
                value={values?.c_competence}
                onChange={handleChange}
                style={{ width: '100%' }}
                name='c_competence'
              >
                {[{ name: 'Не выбрано', id: 0 }, ...competenceList]?.map(({ name, id }) => <option key={id} value={id}>{name}</option>)}
              </Select>
            </div>
          </div>
          <div className={styles.inputsRow} style={{marginTop: '2rem'}}>
            <Checkbox
              label='Сортировка'
              name='xp_sort'
              checked={values?.xp_sort}
              onChange={onCheckCLlick}
            />
            <Checkbox
              style={{ marginLeft: '4rem' }}
              label='Отчет ВШЭ'
              name='name'
            />
          </div>
        </div>
      </div>
      <ModalFooter closeModal={closeModal} loading={loading} handleSubmit={handleSubmit} infoCreateChange={infoCreateChange} />
    </>
  )
}
