import React, {useEffect} from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import {IoIosAdd} from 'react-icons/io'
import {useDispatch, useSelector} from 'react-redux'
import {useState} from 'react'
import Modal from './Modal'
import Table from '../../components/Table'
import {BiTrash} from 'react-icons/bi'
import Confirmation from '../../components/Confirmation/Confirmation'
import {deleteExamCity, getExamCityList} from '../../actions/exam_city'
import Loader from '../../components/Loader'
import Empty from '../../components/Empty'

export default function Index() {
    const dispatch = useDispatch();
    const {list, loading, item} = useSelector(state => state.exam_city);

    const [selectedItem,
        setSelectedItem] = useState(null);
    const [confirmation,
        setConfirmation] = useState(false);

    useEffect(() => {
        dispatch(getExamCityList())
    }, [])
    
    useEffect(() => {
        if (item.xp_key) {
            setSelectedItem(item)
        }
    }, [item])

    const openModal = (data) => () => {
        setSelectedItem(data)
    }

    const closeModal = () => {
        setSelectedItem(null)
        dispatch({type: 'SET_EXAM_CITY_BY_ID', payload: {}})
    }

    const acceptConfirmation = () => {
        closeModal();
        dispatch(deleteExamCity(confirmation.id));
        setConfirmation(false);
    };
    const rejectConfirmation = () => {
        setConfirmation(false);
    };

    const renderLi = (data) => {
        const {xp_key, name} = data
        return (
            <tr >
                <td onClick={openModal(data)}>{name}</td>
                <td>
                    <i
                        className={styles.trash}
                        onClick={() => setConfirmation({id: xp_key, name: name})}><BiTrash/></i>
                </td>
            </tr>
        )
    }

    return (
        <div
            className={styles.wrapper}
            style={{
            width: '100%',
            margin: '0 auto'
        }}>
            {confirmation && (<Confirmation
                top="30px"
                left="40px"
                title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
                acceptConfirmation={() => acceptConfirmation(confirmation?.id)}
                rejectConfirmation={rejectConfirmation}/>)}

            {selectedItem && <Modal
                setMode={setSelectedItem}
                closeModal={closeModal}
                data={selectedItem}
                height={'430px'}
                addMode={selectedItem == 'add'}/>}

            <h1>Города сдачи вступительных испытаний</h1>

            {loading && <Loader/>}

            {!loading && !list?.length
                    ? <Empty/>
                    : <Table className={styles.tables}>
                        <thead>
                            <tr>
                                <th>Город</th>
                                <th width={'1.5rem'}>Удалить</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list?.map(renderLi)}
                        </tbody>
                    </Table>}

            <div
                style={{
                display: 'flex',
                flexDirection: "row"
            }}>
                <Button
                    style={{
                    padding: '.5rem 1.5rem',
                    margin: '12px'
                }}
                    onClick={openModal('add')}>
                    <IoIosAdd size={30}/>
                    Добавить
                </Button>
            </div>
        </div>
    )
}
