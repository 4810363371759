import { api } from "../../App";
import { SET_STUDENTS_LIST, SET_STUDENTS_LIST_FOR_GENERAL_INFO, SET_STUDENTS_LIST_FOR_GENERAL_INFO_EXPELLED, SET_STUDENTS_LOADING } from "../types";



export const getStudents = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_STUDENTS_LOADING, payload: true });
    const { data } = await api.get('/students')
    dispatch({ type: SET_STUDENTS_LIST, payload: data })
  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_STUDENTS_LOADING, payload: false });
  }
};

// classbook tabs
// НА СТОРОНЕ БЭКА СЛОЖДНО СОВМЕСТИТЬ -ЗАПРОС ДОЛГИЙ
// СТУДЕНТЫ ТЕКУЩИЕ
export const getStudentsForGeneralInfo = ({ year }) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_STUDENTS_LOADING, payload: true });
    const { data } = await api.get('/students?${urlParams}')
    dispatch({ type: SET_STUDENTS_LIST_FOR_GENERAL_INFO, payload: data })
  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_STUDENTS_LOADING, payload: false });
  }
};
// ОТЧИСЛЕННЫЕ 
export const getExpelledStudentsForGeneralInfo = ({ }) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_STUDENTS_LOADING, payload: true });
    const { data } = await api.get('/students?${urlParams}')
    dispatch({ type: SET_STUDENTS_LIST_FOR_GENERAL_INFO_EXPELLED, payload: data })
  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_STUDENTS_LOADING, payload: false });
  }
};