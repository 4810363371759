import React, { useState } from 'react'
import { Main } from './ModalTabs'

import Modal from '../../components/Modal'

export default function ModalComponent({ data, closeModal, width, height, addMode }) {
  console.log('data::: ', data);
  console.log('addMode::: ', addMode);

  const [mode, setMode] = useState('main')

  const renderTab = () => {
    switch (mode) {
      case 'main': return <Main closeModal={closeModal} data={data} width={width} height={height} addMode={addMode} />
      default: return
    }
  }

  return (
    <Modal
      open={!!data}
      menuItem={mode}
      setMenuItem={setMode}
      width={width}
      height={height}
      center
    >
      {renderTab()}
    </Modal>
  )
}
