import { BACKEND_URL_PREFIX, itemsOnPage } from "../assets/const";
import { createNotification } from "../components/Notifications/Notifications";
import { checkAndUpdateToken } from "./auth";
import { buildDefaultHeaders } from "./helper";
import { SET_CURRENT_TERM, SET_TERMS_COUNT, SET_TERMS_LIST, SET_TERMS_LOADING, SET_TERMS_PAGE } from "./types";


export const setPage = (pageNumber) => ({ type: SET_TERMS_PAGE, payload: pageNumber })

export const getTermsList = (year) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    try {
      dispatch({ type: SET_TERMS_LOADING, payload: true });
      const { pageNumber } = getState().terms
      const limit = itemsOnPage
      const offset = itemsOnPage * pageNumber
      const urlParams = new URLSearchParams({ limit, offset }).toString();
      const response = await fetch(`${BACKEND_URL_PREFIX}/terms${year ? `/year/${year}` : ''}`, {
        // ?${urlParams}
        method: "GET",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {

        const { cnt: count, terms } = await response.json();
        const pageCount = Math.ceil(count / itemsOnPage)

        dispatch({ type: SET_TERMS_LIST, payload: terms });
        dispatch({ type: SET_TERMS_COUNT, payload: { pageCount, count } });

      } else {
        dispatch({ type: SET_TERMS_LIST, payload: [] });
        dispatch(getTermsList())
        createNotification('error', 'Информация не найдена');
      }
    } catch (ERR) {

    }
  }
  dispatch({ type: SET_TERMS_LOADING, payload: false });
};

export const getCurrentTerm = (termId) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_TERMS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/terms/${termId}`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_CURRENT_TERM, payload });
    }
  }
  dispatch({ type: SET_TERMS_LOADING, payload: false });
};

export const deleteTerm = (data, search) => async (dispatch, getState) => {
  console.log('search::: ', search);
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_TERMS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/terms/${data.trmid}`, {
      method: "DELETE",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const { list, pageNumber } = getState().terms
      list.length < 2 && dispatch(setPage(pageNumber - 1))
      await dispatch(getTermsList(search));
    }
  }
  dispatch({ type: SET_TERMS_LOADING, payload: false });
};

export const updateTerm = (termId, data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_TERMS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/terms/${termId}`, {
      method: "PUT",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    const result = await response.json();
    if (response.ok) {
      await dispatch(getTermsList(data.year));
      createNotification('success', 'Успешно');
    } else {
      createNotification('error', result.error[0].msg);
    }
  }
  dispatch({ type: SET_TERMS_LOADING, payload: false });
};

export const createTerm = (data) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    dispatch({ type: SET_TERMS_LOADING, payload: true });
    const response = await fetch(`${BACKEND_URL_PREFIX}/terms`, {
      method: "POST",
      body: JSON.stringify(data),
      ...buildDefaultHeaders(getState),
    });
    const result = await response.json();
    if (response.ok) {
      await dispatch(getTermsList(data.year));
      createNotification('success', 'Успешно');
    } else {
      createNotification('error', result.error[0].msg);
    }
  }
  dispatch({ type: SET_TERMS_LOADING, payload: false });
};
