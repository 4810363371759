import React, { useContext, useEffect, useState } from 'react'
import styles from '../styles.module.css'
import ClassbookTable from '../../../components/ClassbookTable'
import { useDispatch, useSelector } from 'react-redux'
import { getSchoolYears } from '../../../actions/common'
import { getLesson, getStudyPeriod, getScClass, getClassbook, getGradeValue, getLessonChart, getClassTopic } from '../../../actions/classbook'
import Loader from '../../../components/Loader'
import { getCurrentPeriodUtils, getCurrentYearUtils } from '../../../utils/classBook'
import TeacherInfo from '../TeacherInfo/TeacherInfo'
import ClassbookChart from '../ClassbookChart/ClassbookChart'
import { ClassbookContext } from '../../../utils/context'


export default function ProgressRegister() {

    const dispatch = useDispatch()
    const { formData } = useContext(ClassbookContext)

    const { schoolYears } = useSelector(state => state.common)
    const { studyPeriod,
        lessons,
        scClass,
        classbook,
        loading,
        class_teacher_fio,
        teacher_fio,
        lesson_chart,
        class_topic
    } = useSelector(state => state.classbook)

    useEffect(() => {
        dispatch(getSchoolYears())
        dispatch(getGradeValue())
    }, [])

    const handleSearch = () => {
        dispatch(getClassbook(formData));
        dispatch(getLessonChart(formData));
    }

    const handleClassTopic = (year, sheid) => {
        dispatch(getClassTopic(year, sheid));
    }

    return (


        loading
            ? <Loader />
            : !classbook
                ? <div className={styles.noData}>Нет данных</div>
                : <>
                    {class_teacher_fio && teacher_fio &&
                        <TeacherInfo
                            class_teacher_fio={class_teacher_fio}
                            teacher_fio={teacher_fio} />}

                    <div className={styles.wrapperClassbook}>
                        <ClassbookTable
                            classbook={classbook}
                            formData={formData}
                            lesson_chart={lesson_chart}
                            class_topic={class_topic}
                            handleClassTopic={handleClassTopic} />

                        <>
                            {lesson_chart?.length > 0 ? <ClassbookChart handleClassTopic={handleClassTopic} data={lesson_chart} formData={formData} />
                                : <div className={styles.noData}>Нет данных</div>}
                        </>
                    </div>
                </>



    )
}
