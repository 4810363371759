import React, { useEffect } from 'react'
import styles from '../styles.module.css'
import Button from '../../../../components/Button'
import { IoIosAdd, IoIosSave } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../../components/Loader'
import { useState } from 'react'
// import Modal from 'react-responsive-modal'
import Modal from '../../../../components/Modal'

import { setPage, getPeriodsList, deletePeriod, copyPeriod } from '../../../../actions/periods'
import Table from '../../../../components/Table'
import { getSchoolYears, getShifts } from '../../../../actions/common'
import { Input, Select } from '../../../../components/ModalInputs'
import Confirmation from '../../../../components/Confirmation/Confirmation'
import { BiTrash } from 'react-icons/bi'
import { getCurrentYearUtils } from '../../../../utils/classBook'
import ModalFooter from '../../../../components/ModalFooter/ModalFooter'
import SelectPal from '../../../../components/Select copy'
import { useFormik } from 'formik'
import { arrayForSelect } from '../../../../utils/arrayForSelect'
import moment from 'moment'



const types = [
    { name: 'По дате', value: 0 },
    { name: 'По дню недели', value: 1 },
    { name: 'Для класса', value: 2 },
]

export default function ModalExceptions({ data, closeModal, search }) {

    const {
        values,
        setFieldValue,
    } = useFormik({
        initialValues: {},
        enableReinitialize: true,
        onSubmit: console.log
    })

    console.log('values::: ', values)
    return (
        <Modal
            open={!!data}
            width={'600px'}
        >

            <div className={styles.modalMain}>
                <div className={styles.tabWrapper}>
                    <div className={styles.inputsDate}>
                        <Input
                            label='Начало'
                            name='starttime'
                            type='time'
                            value={data?.starttime}
                        // onChange={handleChange}
                        />
                        <Input
                            label='Окончание'
                            name='stoptime'
                            type='time'
                            value={data?.stoptime}
                        // onChange={handleChange}
                        />
                    </div>
                    <SelectPal
                        title={'Тип'}
                        valuesName="type"
                        value={values.types}
                        options={types && arrayForSelect(types, 'name', 'value')}
                        style={{ width: '120px' }}
                        setFieldValue={setFieldValue}
                    />
                    <Input
                        label='Дата'
                        type='date'
                        name='date'
                        value={values.date || moment().format('YYYY-MM-DD')}
                        // onChange={handleChange}
                        disabled={values.type === 0 ? false : true}
                    />
                    < SelectPal
                        title={'День недели'}
                        valuesName="date"
                        value={values.types}
                        options={types && arrayForSelect(types, 'name', 'value')}
                        style={{ width: '120px' }}
                        setFieldValue={setFieldValue}
                        disabled={values.type === 1 ? false : true}
                    />
                    <SelectPal
                        title={'Класс'}
                        valuesName="type"
                        value={values.types}
                        options={types && arrayForSelect(types, 'name', 'value')}
                        style={{ width: '120px' }}
                        setFieldValue={setFieldValue}
                        disabled={values.type === 2 ? false : true}
                    />
                </div>
            </div>
            <ModalFooter handleSubmit={() => { }} closeModal={closeModal} />


        </Modal>



    )
}
