import React, { useState } from 'react'
import styles from './index.module.css'
import Table from '../../../../components/Table'
import { BiTrash } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import { createStudyPlan, createStudyPlanContent, deleteStudyPlansContent, editStudyPlan, editStudyPlanContent } from '../../../../actions/studyPlans'
import Confirmation from '../../../../components/Confirmation/Confirmation'
import Button from '../../../../components/Button'
import Modal from './Modal'
import { createNotification } from '../../../../components/Notifications/Notifications'

import moment from 'moment'
import { createDailyRoutineConsists, deleteDailyRoutineConsists, updateDailyRoutineConsists } from '../../../../actions/dailyRoutine'

const Index = ({ data, values, setValues }) => {
    console.log('data::: ', data);
    console.log('values::: ', values);

    const dispatch = useDispatch();

    const [confirmation, setConfirmation] = useState(false);
    const [modalOpen, setModalOpen] = useState(null);

    const acceptConfirmation = () => { // Подтвердить удаление
        dispatch(deleteDailyRoutineConsists(confirmation));
        closeModal();
        setConfirmation(false);
    };
    const rejectConfirmation = () => { // Отменить подтверждение
        setConfirmation(false);
    };

    const openModal = (item) => { // Открыть модалку
        if (item?.xp_key) {
            setModalOpen(item)
        } else {
            setModalOpen({
                comment: '',
                name: '',
                starttime: moment().format('HH:mm'),
                stoptime: moment().format('HH:mm'),
                xp_period: data[0]?.xp_period || 0,  /// НЕ ЯСНО КАК ИСПОЛЬЗОВАТЬ ЕСЛИ ЧЕРЕЗ МОДАЛКУ СОЗДАВАТЬ СТОРОКУ TODO
            })
        }
    }

    const closeModal = () => { // Закрыть модалку
        // setClassId(null)
        setModalOpen(null)
    }

    const handleConfirmation = (e, data) => {
        e.stopPropagation() // Отмена действия по умолчанию (открытие модалки)
        setConfirmation(data)
    }

    const handleSubmit = () => {
        const forCreate = {
            ...modalOpen, xp_key: values.xp_key
        }
        if (!modalOpen.xp_key) {
            dispatch(createDailyRoutineConsists(forCreate))
        } else {
            dispatch(updateDailyRoutineConsists(modalOpen))
        }
        closeModal()
    }

    return (
        <div className={styles.tabWrapper}>
            <Table className={styles.table}>
                <thead>
                    <tr>
                        <th>Время начала</th>
                        <th>Время окончания</th>
                        <th>Мероприятия</th>
                        <th>Примечания</th>
                        <th>Удалить</th>
                    </tr>
                </thead>
                <tbody>

                    {data.map((data, index) => (
                        <tr key={index} onClick={() => openModal(data)}>
                            <td>{data.starttime}</td>
                            <td>{data.stoptime}</td>
                            <td>{data.name}</td>
                            <td>{data.comment}</td>
                            <td onClick={(e) => handleConfirmation(e, data)}>
                                <i className={styles.trash}><BiTrash /></i>
                            </td>
                        </tr>
                    ))
                    }

                </tbody>
            </Table>

            {modalOpen && (
                <div className={styles.modal}>
                    <Modal
                        modalOpen={modalOpen}
                        setModalOpen={setModalOpen}
                        closeModal={closeModal}
                        handleSubmit={() => handleSubmit()}
                    />
                </div>
            )}

            <div className={styles.buttons}>
                <Button
                    style={{
                        backgroundColor: "#3689FF",
                        fontSize: "16px"
                    }}
                    onClick={() => openModal(data)}>Добавить</Button>
            </div>

            {confirmation && (<Confirmation
                top="30px"
                left="40px"
                title="Удалить данную запись?"
                confirmation={confirmation?.name}
                acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
                rejectConfirmation={rejectConfirmation} />)}
        </div>
    )
}

export default Index