import React from 'react'
import styles from './styles.module.css'
import cn from 'classnames'

export default function Input({ label, error, ...props }) {
  console.log('error:  InputInputInput:: ', error);

  const inputClassObject = cn(
    styles.input,
    { [styles.error]: error }
  )

  return (
    <div className={styles.marginTop}>
      <label className={props.name !== "folderName" ? styles.label : styles.labelForMethodOffice}>
        <p>{label}</p>
        <input className={inputClassObject} {...props} />
        {!!error && <p className={styles.errorText}>{error}</p>}
      </label>
    </div>

  )
}
