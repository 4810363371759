import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { IoIosAdd } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Modal from './Modal'
import Table from '../../components/Table'
import { BiTrash } from 'react-icons/bi'
import Confirmation from '../../components/Confirmation/Confirmation'
import { deleteTypicalComment, getTypicalComment } from '../../actions/typicalComment'



export default function TypicalComment() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { typicalCommentList } = useSelector(state => state.typical_comment)

  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(false);

  useEffect(() => {
    dispatch(getTypicalComment())
  }, [selectedItem])

  const openModal = (data) => () => {
    setSelectedItem(data || {})
  }

  const closeModal = () => setSelectedItem(null)

  const goHome = () => navigate('/')

  const acceptConfirmation = () => {
    closeModal();
    dispatch(deleteTypicalComment(confirmation.id));
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  const renderLi = (data) => {
    const { commentid, commenttext } = data
    return (
      <tr >
        <td onClick={openModal(data)}>{commenttext}</td>
        <td><i className={styles.trash} onClick={() => setConfirmation({ id: commentid, name: commenttext })}><BiTrash /></i></td>
      </tr>
    )
  }

  return (
    <div className={styles.wrapper} style={{ width: '700px', margin: '0 auto' }}>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
          acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      {selectedItem &&
        <Modal
          closeModal={closeModal}
          data={selectedItem}
          height={'430px'}
          // addMode={(!selectedItem?.name && selectedItem !== null)}
          addMode={!selectedItem.commenttext}
        />
      }
      <h1>Типовые комментарии к оценкам</h1>
      {!!typicalCommentList?.length ?
        <Table className={styles.tableDis}>
          <thead>
            <tr>
              <th>Название</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {typicalCommentList?.map(renderLi)}
            {/* {console.log('typicalCommentList::: ', typicalCommentList)}
          {typeof (typicalCommentList) !== 'string' ? typicalCommentList?.map(renderLi) : <p>{typicalCommentList}</p>} */}
          </tbody>
        </Table>
        :
        <div className={styles.noData}>Нет данных</div>
      }
      <div style={{ display: 'flex', flexDirection: "row" }}>
        <Button
          style={{ padding: '.5rem 1.5rem', margin: '12px' }}
          onClick={openModal()}
        >
          <IoIosAdd size={30} />
          Добавить
        </Button>
      </div>
    </div>
  )
}
