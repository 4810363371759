import * as types from "../../actions/types";



const initialState = {
  main: []
}


export default function data(state = initialState, action) {
  switch (action.type) {

    case types.SET_STUDENTS_DATA_MAIN:
      return {
        ...state,
        main: action.payload
      }
    case types.SET_STUDENTS_DATA_FAMILY:
      return {
        ...state,
        family: action.payload
      }

    case types.SET_STUDENTS_DATA_OTHER:
      return {
        ...state,
        [action.table || action.entity]: action.payload
      }

    default:
      return state;
  }
}