import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Modal from './Modal'
import Table from '../../components/Table'
import { deleteGradeOptions } from '../../actions/directory'
import Confirmation from '../../components/Confirmation/Confirmation'
import { Select } from '../../components/ModalInputs'
import { getStudy_plans, getSubjectsByYearStudy } from '../../actions/subjectsByYearStudy'
import { createNotification } from '../../components/Notifications/Notifications'

const eduYear = [
  { name: '1 год обучения', id: 1, subject: ['eng', 'span', 'turk'] },
  { name: '2 год обучения', id: 2, subject: ['eng', 'span', 'turk'] },
  { name: '3 год обучения', id: 3, subject: ['eng', 'span', 'turk'] },
  { name: '4 год обучения', id: 4, subject: ['eng', 'span', 'turk'] },
  { name: '5 год обучения', id: 5, subject: ['eng', 'span', 'turk'] },
  { name: '6 год обучения', id: 6, subject: ['eng', 'span', 'turk'] },
  { name: '7 год обучения', id: 7, subject: ['eng', 'span', 'turk'] },
  { name: '8 год обучения', id: 8, subject: ['eng', 'span', 'turk'] },
  { name: '9 год обучения', id: 9, subject: ['eng', 'span', 'turk'] },
  { name: '10 год обучения', id: 10, subject: ['eng', 'span', 'turk'] },
  { name: '11 год обучения', id: 11, subject: ['eng', 'span', 'turk'] },
  { name: '12 год обучения', id: 12, subject: ['eng', 'span', 'turk'] },

]

export default function SubjectsByYearStudy() {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { plans, subjects } = useSelector(state => state.subjectsByYearStudy)
  const [selectedItem, setSelectedItem] = useState(null)
  const [selectedItem2, setSelectedItem2] = useState(null)
  console.log('selectedItem2::: ', selectedItem2);

  const [selectedPlan, setSelectedPlan] = useState(null)
  const [confirmation, setConfirmation] = useState(false);

  const level = subjects && [...subjects]
  const levels = level && level.sort((a, b) => a.level - b.level)

  useEffect(() => {
    dispatch(getStudy_plans())
  }, [])

  useEffect(() => {
    plans && dispatch(getSubjectsByYearStudy(selectedPlan))
    !selectedPlan && plans && setSelectedPlan(plans?.[0].trid)
    setSelectedItem2(null)
  }, [selectedPlan, plans])


  const openList = (data) => () => {
    if (data === selectedItem2 && !selectedItem) setSelectedItem2(null)
    else setSelectedItem2(data || {})
  }
  const openModal = (data) => () => {
    selectedPlan && setSelectedItem(data || {})
    !selectedPlan && createNotification('error', 'Выберите план')
  }

  const closeModal = () => {
    setSelectedItem2(null)
    setSelectedItem(null)
  }
  const goHome = () => navigate('/')

  const acceptConfirmation = () => {
    closeModal();
    dispatch(deleteGradeOptions(confirmation.id));
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  const renderLi = (data) => {
    const { id, name } = data
    return (
      <>
        <tr key={id}>
          <th
            onClick={openList(id)}
            onDoubleClick={openModal(data)}>
            {name}
          </th>
        </tr>
        {levels?.map(el =>
          el.level === id && selectedItem2 == id &&
          <li
            key={el.xp_key}
            onDoubleClick={openModal(data)}
            style={{ textAlign: 'left', padding: '.5rem .5rem .5rem 2rem ' }}
          >{el.course_name}
          </li>


        )}

        {/* <td onClick={openModal(data)}>{text}</td> */}
        {/* <td onClick={()=> setConfirmation({id: valid})}>{thrash()}</td> */}
        {/* <td><i classcourse_name={styles.trash} onClick={() => setConfirmation({ id, course_name })}><BiTrash /></i></td> */}
      </>
    )
  }



  return (
    <div className={styles.wrapper} style={{ width: '700px', margin: '0 auto' }}>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
          // confirmation={confirmation?.name} 
          acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      <Modal
        closeModal={closeModal}
        data={subjects}
        selectedItem={selectedItem}
        height={'80vh'}
        width={'50vw'}
      />
      <h1>Предметы по годам обучения</h1>

      <div className={styles.selectWrapper}>
        <Select
          label='Учебный план'
          value={selectedPlan}
          // onChange={setCurrYear()}
          onChange={({ target: { name, value } }) => setSelectedPlan(value)}
          name='xp_key'
        >
          {/* {role && [{ Название: 'Выберите цикл', pmid: null }, ...role].map(({ Название, pmid }) => <option key={pmid} value={pmid}>{Название}</option>)} */}
          {plans && plans.map(({ name, trid }) => <option key={trid} value={trid}>{name}</option>)}
        </Select>
      </div>

      <Table className={styles.tables}>
        <thead>
          <tr>
            <th>Название</th>
          </tr>
        </thead>
        <tbody>

          {eduYear?.map(renderLi)}

        </tbody>
      </Table>
    </div>
  )
}

// import React, { useEffect } from 'react'
// import styles from './styles.module.css'
// import Button from '../../components/Button'
// import { useNavigate } from 'react-router-dom'
// import { useDispatch, useSelector } from 'react-redux'
// import { useState } from 'react'
// import Modal from './Modal'
// import Table from '../../components/Table'
// import { deleteGradeOptions } from '../../actions/directory'
// import Confirmation from '../../components/Confirmation/Confirmation'
// import { Select } from '../../components/ModalInputs'
// // import Select from "react-select"

// import { getStudy_plans, getSubjectsByYearStudy } from '../../actions/subjectsByYearStudy'
// import { createNotification } from '../../components/Notifications/Notifications'

// const eduYear = [
//   { name: '1 год обучения', id: 1, subject: ['eng', 'span', 'turk'] },
//   { name: '2 год обучения', id: 2, subject: ['eng', 'span', 'turk'] },
//   { name: '3 год обучения', id: 3, subject: ['eng', 'span', 'turk'] },
//   { name: '4 год обучения', id: 4, subject: ['eng', 'span', 'turk'] },
//   { name: '5 год обучения', id: 5, subject: ['eng', 'span', 'turk'] },
//   { name: '6 год обучения', id: 6, subject: ['eng', 'span', 'turk'] },
//   { name: '7 год обучения', id: 7, subject: ['eng', 'span', 'turk'] },
//   { name: '8 год обучения', id: 8, subject: ['eng', 'span', 'turk'] },
//   { name: '9 год обучения', id: 9, subject: ['eng', 'span', 'turk'] },
//   { name: '10 год обучения', id: 10, subject: ['eng', 'span', 'turk'] },
//   { name: '11 год обучения', id: 11, subject: ['eng', 'span', 'turk'] },
//   { name: '12 год обучения', id: 12, subject: ['eng', 'span', 'turk'] },

// ]

// export default function SubjectsByYearStudy() {

//   const dispatch = useDispatch()
//   const navigate = useNavigate()

//   const { plans, subjects } = useSelector(state => state.subjectsByYearStudy)
//   const [selectedItem, setSelectedItem] = useState(null)
//   const [selectedItem2, setSelectedItem2] = useState(null)

//   const [selectedPlan, setSelectedPlan] = useState(null)
//   console.log('selectedPlan::: ', selectedPlan);
//   const [confirmation, setConfirmation] = useState(false);

//   const level = subjects && [...subjects]
//   const levels = level && level.sort((a, b) => a.level - b.level)

//   useEffect(() => {
//     dispatch(getStudy_plans())
//   }, [])

//   useEffect(() => {
//     plans && dispatch(getSubjectsByYearStudy(selectedPlan || plans[0].trid))
//     // setSelectedPlan(plans?.[0].trid)
//   }, [selectedPlan, plans])


//   const openList = (data) => () => {
//     if (data === selectedItem2 && !selectedItem) setSelectedItem2(null)
//     else setSelectedItem2(data || {})
//   }
//   const openModal = (data) => () => {
//     selectedPlan && setSelectedItem(data || {})
//     !selectedPlan && createNotification('error', 'Выберите план')

//   }

//   const closeModal = () => setSelectedItem(null)

//   const goHome = () => navigate('/')

//   const acceptConfirmation = () => {
//     closeModal();
//     dispatch(deleteGradeOptions(confirmation.id));
//     setConfirmation(false);
//   };
//   const rejectConfirmation = () => {
//     setConfirmation(false);
//   };

//   const renderLi = (data) => {
//     const { id, name } = data
//     return (
//       <>
//         <tr key={id}>
//           <th
//             onClick={openList(id)}
//             onDoubleClick={openModal(data)}>
//             {name}
//           </th>
//         </tr>
//         {levels?.map(el =>
//           el.level === id && selectedItem2 == id &&
//           <li
//             key={el.xp_key}
//             onDoubleClick={openModal(data)}
//             style={{ textAlign: 'left', padding: '.5rem .5rem .5rem 2rem ' }}
//           >{el.course_name}
//           </li>


//         )}

//         {/* <td onClick={openModal(data)}>{text}</td> */}
//         {/* <td onClick={()=> setConfirmation({id: valid})}>{thrash()}</td> */}
//         {/* <td><i classcourse_name={styles.trash} onClick={() => setConfirmation({ id, course_name })}><BiTrash /></i></td> */}
//       </>
//     )
//   }
//   const toSelect = plans?.map(el =>
//   ({
//     value: el.trid,
//     label: el.name
//   })
//   )
//   console.log('toSelect::: ', toSelect);


//   return (
//     <div className={styles.wrapper} style={{ width: '700px', margin: '0 auto' }}>
//       {confirmation && (
//         <Confirmation
//           top="30px"
//           left="40px"
//           title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
//           // confirmation={confirmation?.name}
//           acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
//           rejectConfirmation={rejectConfirmation}
//         />
//       )}
//       <Modal
//         closeModal={closeModal}
//         data={subjects}
//         selectedItem={selectedItem}
//         height={'80vh'}
//       />
//       <h1>Предметы по годам обучения</h1>

//       <div className={styles.selectWrapper}>
//         {console.log('plans::: ', plans)}
//         <Select
//           label='Учебный план'
//           value={selectedPlan}
//           // placeholder={toSelect?.find(el => el.value == selectedPlan)?.label}
//           // onChange={setCurrYear()}
//           onChange={({ value }) => setSelectedPlan(value)}
//           name='xp_key'
//           // options={toSelect}
//         >
//           {/* {role && [{ Название: 'Выберите цикл', pmid: null }, ...role].map(({ Название, pmid }) => <option key={pmid} value={pmid}>{Название}</option>)} */}
//           {toSelect && toSelect.map(({ label, value }) => <option key={value} value={value}>{label}</option>)}
//         </Select>

//       </div>

//       <Table className={styles.tables}>
//         <thead>
//           <tr>
//             <th>Название</th>
//           </tr>
//         </thead>
//         <tbody>

//           {eduYear?.map(renderLi)}

//         </tbody>
//       </Table>
//     </div>
//   )
// }
