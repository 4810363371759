import * as types from "../actions/types";



const initialState = {
  list: [],
  loading: false
};


export default function fitnessIndicators(state = initialState, action) {
  switch (action.type) {

    case types.SET_FITNESS_INDICATORS_LIST:
      return {
        ...state,
        fitnessIndicatorsList: action.payload,
      };

    case types.SET_EVENT_LEVELS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
}