import React, { useState } from 'react'
import Modal from 'react-responsive-modal'
import { useDispatch, useSelector } from 'react-redux'
import styles from './styles.module.css'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import ModalFooter from '../../components/ModalFooter/ModalFooter'
import Table from '../../components/Table'
import Confirmation from '../../components/Confirmation/Confirmation'
import Loader from '../../components/Loader'
import Button from '../../components/Button'
import { Checkbox } from '../../components/ModalInputs'
import { copyDishPeriod, copyMenu } from '../../actions/directoryCanteen'

const validationSchema = Yup.object({
  title: Yup.string().required('обязательное поле').max(30, 'Должно быть не более 30 символов'),

})

export default function ModalComponent({ meal, data, closeModal, height, addMode, setSelectedDish, selectedDay, dishForPeriod }) {
  console.log('dishForPeriod::: ', dishForPeriod);

  const dispatch = useDispatch()
  const { loading } = useSelector(state => state.directoryCanteen)

  const [confirmation, setConfirmation] = useState(false);
  const [values, setValues] = useState(false);
  const [select, setSelect] = useState(null);
  const [select2, setSelect2] = useState([]);
  console.log('select::: ', select, select2);



  const acceptConfirmation = () => {
    closeModal();
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };


  const handleChangeCheck = (e) => {
    if (!select) {
      setSelect(+e.target.value)
    } else {
      setSelect(+e.target.value)
    }
  }

  const handleChangeCheck2 = (e) => {
    if (select2?.find(el => +el === +e.target.value)) {
      setSelect2(select2.filter((el, i) => select2.indexOf(+e.target.value) !== i))
    }
    else { setSelect2((prev) => prev ? [...prev, +e.target.value] : +e.target.value) }
  }

  const savePeriod = () => {
    dispatch(copyMenu({ xp_period: dishForPeriod?.period_key, xp_periods: select2, xp_date: selectedDay.date }))
    closeModal()
  }


  return (
    <>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
          acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
          rejectConfirmation={rejectConfirmation}
        />
      )}

      <Modal
        open={!!data}
        // onClose={() => false}
        close={closeModal}
        showCloseIcon={false}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        animationDuration={1}
        center
        classNames={{
          // overlay: styles.overlay,
          modal: styles.custom
        }}
      >
        {loading ? <Loader /> :
          <div className={styles.modalLocal} >

            {/* <div className={styles.inputsColumn}>
            <h4>Какой период</h4>
            {console.log('meal::: ', meal)}

            {meal?.map(par => {
              const che = par.xp_key === select
              return (
                <div className={styles.checkboxRow}>
                  <Checkbox
                    label={par.name}
                    checked={!!che}
                    value={par.xp_key}
                    onChange={(e) => handleChangeCheck(e)}
                    name='period1'
                  // disabled={disabledList2?.find(el => el == par)}
                  />
                </div>
              )
            }
            )}
          </div> */}
            <div className={styles.inputsColumn}>
              <h4>Периоды питания</h4>


              {meal?.map(par => {
                const che = select2?.find(el => +par.xp_key == +el)
                return (
                  <div className={styles.checkboxRow}>
                    <Checkbox
                      label={par.name}
                      checked={!!che}
                      value={par.xp_key}
                      onChange={(e) => handleChangeCheck2(e)}
                      name='period2'
                    // disabled={disabledList2?.find(el => el == par)}
                    />
                  </div>
                )
              }
              )}
            </div>
          </div>
        }
        <ModalFooter closeModal={closeModal} handleSubmit={savePeriod} />
      </Modal>
    </>

  )
}
