import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { IoIosAdd } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Modal from './Modal'
import Table from '../../components/Table'
import { BiTrash } from 'react-icons/bi'
import Confirmation from '../../components/Confirmation/Confirmation'
import { deleteSpecMedGroup, getSpecMedGroups } from '../../actions/medCenter/specMedGroups'
import { getSchoolYears } from '../../actions/common'
import { Select } from '../../components/ModalInputs'


export default function TypesOfDisease() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { specMedGroupsList } = useSelector(state => state.directoryMedCenter)
  const { schoolYears } = useSelector(state => state.common)

  const [selectedItem, setSelectedItem] = useState(null)
  const [selectedYear, setSelectedYear] = useState(null)
  const [confirmation, setConfirmation] = useState(false);

  useEffect(() => {
    dispatch(getSchoolYears())
    selectedYear && dispatch(getSpecMedGroups(selectedYear))
  }, [selectedYear])

  const openModal = (data) => () => {
    setSelectedItem(data || {})
  }

  const closeModal = () => setSelectedItem(null)

  const goHome = () => navigate('/')

  const acceptConfirmation = () => {
    closeModal();
    dispatch(deleteSpecMedGroup(selectedYear, confirmation.id));
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  const renderLi = (data) => {
    const { aid, fio, title, count, term_title, school_year_name } = data
    return (
      <tr >
        <td onClick={openModal(data)}>{title}</td>
        <td onClick={openModal(data)}>{fio}</td>
        <td onClick={openModal(data)}>{school_year_name}</td>
        <td onClick={openModal(data)}>{term_title}</td>
        <td onClick={openModal(data)}>{count}</td>
        <td><i className={styles.trash} onClick={() => setConfirmation({ id: aid, fio })}><BiTrash /></i></td>
      </tr>
    )
  }

  const handleChange = (e) => {
    setSelectedYear(
      { [e.target.name]: e.target.value }
    )
  }

  return (
    <div className={styles.wrapper} style={{ margin: '0 auto' }}>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title={`Вы уверены, что хотите удалить ${confirmation.fio}?`}
          acceptConfirmation={() => acceptConfirmation(confirmation?.fio)}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      {selectedItem &&
        <Modal
          closeModal={closeModal}
          data={selectedItem}
          height={'430px'}
          width={'50vw'}
          // addMode={(!selectedItem?.name && selectedItem !== null)}
          addMode={!selectedItem.aid}
        />
      }
      <h1>Специальные медицинские группы</h1>
      <Select
        label='Учебный год'
        onChange={(e) => handleChange(e)}
        name='school_year'
        value={specMedGroupsList?.[0]?.school_year}
        style={{ width: '150px' }}
      >
        {schoolYears?.length && [{ name: "Выберите год", xp_key: 0 }, ...schoolYears].map(({ name, xp_key }) => <option value={xp_key}>{name}</option>)}
      </Select>
      { }
      <Table className={styles.tables}>
        <thead>
          <tr>
            <th>Название</th>
            <th>Преподаватель</th>
            <th>Учебный год</th>
            <th>Учебный период</th>
            <th>Кол-во учащихся</th>
          </tr>
        </thead>
        <tbody>

          {specMedGroupsList?.map(renderLi)}

        </tbody>
      </Table>

      <div style={{ display: 'flex', flexDirection: "row" }}>
        <Button
          style={{ padding: '.5rem 1.5rem', margin: '12px' }}
          onClick={openModal()}
        >
          <IoIosAdd size={30} />
          Добавить
        </Button>
      </div>
    </div>
  )
}
