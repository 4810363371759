import React from 'react'
import Button from '../../../components/Button'
import styles from '../styles.module.scss'


const Footer = ({addItem, goToMain}) => {
  return (
    <footer>
      <Button mode='white' onClick={addItem} >Добавить</Button>
      <Button mode='white' onClick={goToMain}>Закрыть</Button>
    </footer>
  )
}

export default Footer