import {BACKEND_URL_PREFIX} from "../assets/const";
import {createNotification} from "../components/Notifications/Notifications";
import {checkAndUpdateToken} from "./auth";
import {buildDefaultHeaders} from "./helper";
import {
    GET_EXAM_CITY_LIST, 
    SET_EXAM_CITY_LIST_LOADING,
    SET_EXAM_CITY_BY_ID
} from "./types";

export const getExamCityList = () => async(dispatch, getState) => {
    dispatch({type: SET_EXAM_CITY_LIST_LOADING, payload: true});
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/exam_city`, {
            method: "GET",
            ...buildDefaultHeaders(getState)
        });
        if (response.ok) {
            const result = (await response.json());
            dispatch({type: GET_EXAM_CITY_LIST, payload: result});
        } else {
            dispatch({type: GET_EXAM_CITY_LIST, payload: []});
        }
    }
    dispatch({type: SET_EXAM_CITY_LIST_LOADING, payload: false});
};

export const updateExamCity = (params) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {

        const response = await fetch(`${BACKEND_URL_PREFIX}/exam_city/${params.xp_key}`, {
            method: "PUT",
            body: JSON.stringify(params),
            ...buildDefaultHeaders(getState)
        });
        if (response.ok) {
            await dispatch(getExamCityList());
            createNotification('success', 'Успешно!');
        }
    };
}

export const createExamCity = (data, addMode) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/exam_city`, {
            method: "POST",
            body: JSON.stringify(data),
            ...buildDefaultHeaders(getState)
        });
        if (response.ok) {
            createNotification('success', 'Успешно');
            await dispatch(getExamCityList());
            if (addMode) {
                const newId = (await response.json()).xp_key; 
                await dispatch(getExamCityItem(newId));
            } else {
                
            }
        } else 
            createNotification('error', "Не удалось создать запись");
        }
    };

export const deleteExamCity = (id) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/exam_city/${id}`, {
            method: "DELETE",
            ...buildDefaultHeaders(getState)
        });
        const result = await response.json();

        if (response.ok) {
            createNotification('success', 'Успешно');
            await dispatch(getExamCityList());
        } else 
            createNotification('error', result.error);
        }
    };

    export const updateExamCityAddress = (city_id, params) => async(dispatch, getState) => {
        await dispatch(checkAndUpdateToken());
        if (getState().auth.token) {
    
            const response = await fetch(`${BACKEND_URL_PREFIX}/exam_city/address/${params.id}`, {
                method: "PUT",
                body: JSON.stringify(params),
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                await dispatch(getExamCityItem(city_id));
                createNotification('success', 'Успешно!');
            }
        };
    }
    
    export const createExamCityAddress = (city_id, data) => async(dispatch, getState) => {
        await dispatch(checkAndUpdateToken());
        if (getState().auth.token) {
            const response = await fetch(`${BACKEND_URL_PREFIX}/exam_city/${city_id}/address`, {
                method: "POST",
                body: JSON.stringify(data),
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                createNotification('success', 'Успешно');
                await dispatch(getExamCityItem(city_id));
            } else 
                createNotification('error', "Не удалось создать запись");
            }
        };
    
    export const deleteExamCityAddress = (id, city_id) => async(dispatch, getState) => {
        await dispatch(checkAndUpdateToken());
        if (getState().auth.token) {
            const response = await fetch(`${BACKEND_URL_PREFIX}/exam_city/address/${id}`, {
                method: "DELETE",
                ...buildDefaultHeaders(getState)
            });
            const result = await response.json();
    
            if (response.ok) {
                createNotification('success', 'Успешно');
                await dispatch(getExamCityItem(city_id));
            } else 
                createNotification('error', result.error);
            }
        };

export const getExamCityItem = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/exam_city/${id} `, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());

      dispatch({ type: SET_EXAM_CITY_BY_ID, payload: result });
    }
  }
};