import React, { useState } from 'react'
import { useContext } from 'react'
import { EventListContext } from '../../../utils/context'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import styles from './styles.module.scss'
import Button from '../../../components/Button'
import { Checkbox, Select, Textarea } from '../../../components/ModalInputs'
import { useFormik } from 'formik'
import { BiTrash } from 'react-icons/bi'
import Table from '../../../components/Table'
import { IoIosAdd } from 'react-icons/io'

// import Textarea from '../../../components/ModalInputs/Textarea'
// import { Checkbox, Textarea } from '../../../components/ModalInputs'



const contraindicationsList = [
  { name: '1 четверть', id: 3 },
  { name: '2 четверть', id: 4 },
  { name: '3 четверть', id: 5 },
  { name: '4 четверть', id: 6 },
]

const PARAMETERS = [
  { name: "Инновационная деятельность сотрудников", id: 1 },
  { name: "Внеклассное мероприятие", id: 2 },
  { name: "Представление педагогического опыта", id: 3 },
  { name: "Выступления на конференциях, семинарах, проведение мастер-классов", id: 4 },
  { name: "Участие сотрудников в профессиональных конкурсах, олимпиадах", id: 5 },
  { name: "Общественная активность сотрудников", id: 6 },
  { name: "Участие обучающихся в предметных олимпиадах", id: 7 },
  { name: "Участие обучающихся в конкурсах, соревнованиях", id: 8 },
]


export default function Parameters() {

  const { closeModal, } = useContext(EventListContext) /// 

  const dispatch = useDispatch()
  const { additional_education: data } = useSelector(state => state.candidates.data)

  const [select, setSelect] = useState(1);

  useEffect(() => {
    // dispatch(getCandidateAdditionalEducation(student))
  }, [])
  const selected = (data) => () => {
    // setDiagnosisComplaint(data)
    // closeComplain()
  }
  const openModal = (data) => () => { // Открыть модалку
    console.log('data::: ', data);
    // setModalOpen(data)
    // setSelectedItem(data || {})
    // data?.xp_key && dispatch(getStudyPlanVDCoursesList(data.xp_key))
  }

  const handleConfirmation = (e, data) => {
    // e.stopPropagation() // Отмена действия по умолчанию (открытие модалки)
    // setConfirmation(data)
  }

  const {
    values,
    errors,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: data || {},
    enableReinitialize: true,
    onSubmit: console.log
  })

  const handleSelect = (data) => {
    if (select?.id === data.id) {
      setSelect(null)
    } else {
      setSelect(data)
    }
  }


  const renderOption = (option, index) => {
    const { xp_key, value, trmid, cid, gid, work_status, id, pmid, did, name, title, year, access, diagnosis, disease, iddischarge, discharge, c_vac_type } = option
    const optionValue = xp_key || value || trmid || cid || gid || work_status || id || pmid || did || disease || iddischarge || c_vac_type;
    const optionTitle = name || title || value || year || work_status || access || diagnosis || discharge;
    return <option key={`${optionValue}+${index}`} value={optionValue}>{optionTitle}</option>
  }

  const renderTab = () => {
    switch (select && select.id) {
      case 1: return (
        <>
          <div className={styles.inputsRow}>
            <Select
              label='Форма участия'
              name='term' //TODO mockDAta
              value={values.term}
              onChange={handleChange}
              style={{ width: '400px', marginBottom: '10px' }}
              options={contraindicationsList}
            >
              <option value=''>Выбрать</option>
              {contraindicationsList?.map(renderOption)}
            </Select>
          </div>
          <div className={styles.inputsRow}>
            <Textarea
              style={{ resize: 'none', width: '750px', height: '100px' }}
              label='Содержание деятельности'
            />
          </div>
          <div className={styles.inputsRow}>
            <Textarea
              style={{ resize: 'none', width: '750px', height: '100px' }}
              label='Результаты деятельности'
            />
          </div>
        </>)

      case 2: return (
        <>
          <div className={styles.inputsRow}>
            <Select
              label='Предмет'
              name='term' //TODO mockDAta
              value={values.term}
              onChange={handleChange}
              style={{ width: '400px', marginBottom: '10px' }}
              options={contraindicationsList}
            >
              <option value=''>Выбрать</option>
              {contraindicationsList?.map(renderOption)}
            </Select>
          </div>
        </>
      )
      case 3: return (
        <>
          <div className={styles.inputsRow} >
            <Select
              label='Предмет'
              name='term' //TODO mockDAta
              value={values.term}
              onChange={handleChange}
              style={{ width: '400px', marginBottom: '10px' }}
              options={contraindicationsList}
            >
              <option value=''>Выбрать</option>
              {contraindicationsList?.map(renderOption)}
            </Select>
          </div >
          <div className={styles.inputsRow}>
            <Select
              label='вид'
              name='term' //TODO mockDAta
              value={values.term}
              onChange={handleChange}
              style={{ width: '400px', marginBottom: '10px' }}
              options={contraindicationsList}
            >
              <option value=''>Выбрать</option>
              {contraindicationsList?.map(renderOption)}
            </Select>
          </div>
          <div className={styles.inputsRow}>
            <Textarea
              style={{ resize: 'none', width: '750px', height: '100px' }}
              label='Тема урока'
            />
          </div>
          <div className={styles.inputsRow}>
            <Textarea
              style={{ resize: 'none', width: '750px', height: '100px' }}
              label='Отзывы об уроке'
            />
          </div>
        </>
      )
      case 4: return (<>
        <div className={styles.inputsRow}>
          <Textarea
            style={{ resize: 'none', width: '750px', height: '100px' }}
            label='Тема'
          />
        </div>
        <Checkbox
          name='sert'  // TODO naming
          label='Сертификат участника'
          checked={values.closed}
        // onChange={() => setFieldValue('closed', Number(!values.closed))}
        />
      </>)
      case 5: return (<>
        <div className={styles.inputsRow}>
          <Select
            label='Предмет'
            name='term' //TODO mockDAta
            value={values.term}
            onChange={handleChange}
            style={{ width: '400px', marginBottom: '10px' }}
            options={contraindicationsList}
          >
            <option value=''>Выбрать</option>
            {contraindicationsList?.map(renderOption)}
          </Select>
        </div>

        <div className={styles.inputsRow}>
          <Select
            label='Результат'
            name='term' //TODO mockDAta
            value={values.term}
            onChange={handleChange}
            style={{ width: '400px', marginBottom: '10px' }}
            options={contraindicationsList}
          >
            <option value=''>Выбрать</option>
            {contraindicationsList?.map(renderOption)}
          </Select>
        </div>

        <div className={styles.inputsRow}>
          <Select
            label='Документ награждения'
            name='term' //TODO mockDAta
            value={values.term}
            onChange={handleChange}
            style={{ width: '400px', marginBottom: '10px' }}
            options={contraindicationsList}
          >
            <option value=''>Выбрать</option>
            {contraindicationsList?.map(renderOption)}
          </Select>
        </div>
      </>)
      case 6: return (<>
        <div className={styles.inputsRow}>
          <Select
            label='Предмет'
            name='term' //TODO mockDAta
            value={values.term}
            onChange={handleChange}
            style={{ width: '400px', marginBottom: '10px' }}
            options={contraindicationsList}
          >
            <option value=''>Выбрать</option>
            {contraindicationsList?.map(renderOption)}
          </Select>
        </div>

        <div className={styles.inputsRow}>
          <Select
            label='Направление деятельности'
            name='term' //TODO mockDAta
            value={values.term}
            onChange={handleChange}
            style={{ width: '400px', marginBottom: '10px' }}
            options={contraindicationsList}
          >
            <option value=''>Выбрать</option>
            {contraindicationsList?.map(renderOption)}
          </Select>
        </div>
        <div className={styles.inputsRow}>
          <Textarea
            style={{ resize: 'none', width: '750px', height: '100px' }}
            label='Содержание деятельности'
          />
        </div>
        <div className={styles.inputsRow}>
          <Textarea
            style={{ resize: 'none', width: '750px', height: '100px' }}
            label='Подтверждающие документы'
          />
        </div>
      </>)
      case 7: return (<>
        <div className={styles.inputsRow}>
          <Select
            label='Предмет'
            name='term' //TODO mockDAta
            value={values.term}
            onChange={handleChange}
            style={{ width: '400px', marginBottom: '10px' }}
            options={contraindicationsList}
          >
            <option value=''>Выбрать</option>
            {contraindicationsList?.map(renderOption)}
          </Select>
        </div>

        <div className={styles.inputsRow}>
          <Select
            label='Сертификат'
            name='term' //TODO mockDAta
            value={values.term}
            onChange={handleChange}
            style={{ width: '400px', marginBottom: '10px' }}
            options={contraindicationsList}
          >
            <option value=''>Выбрать</option>
            {contraindicationsList?.map(renderOption)}
          </Select>
        </div>
      </>)
      case 8: return (<>
        <div className={styles.inputsRow}>
          <Select
            label='Предмет'
            name='term' //TODO mockDAta
            value={values.term}
            onChange={handleChange}
            style={{ width: '400px', marginBottom: '10px' }}
            options={contraindicationsList}
          >
            <option value=''>Выбрать</option>
            {contraindicationsList?.map(renderOption)}
          </Select>
        </div>

        <div className={styles.inputsRow}>
          <Select
            label='Сертификат'
            name='term' //TODO mockDAta
            value={values.term}
            onChange={handleChange}
            style={{ width: '400px', marginBottom: '10px' }}
            options={contraindicationsList}
          >
            <option value=''>Выбрать</option>
            {contraindicationsList?.map(renderOption)}
          </Select>
        </div>
        <div className={styles.inputsRow}>
          <Textarea
            style={{ resize: 'none', width: '750px', height: '100px' }}
            label='Название работы'
          />
        </div>
        <Table className={styles.tables}>
          <thead>
            <tr>
              <th>Ученица</th>
              <th>Класс</th>

            </tr>
          </thead>
          <tbody>

            {/* {vacExclusions?.map(renderLi)} */}

          </tbody>
        </Table>
        <Button
          style={{ padding: '.5rem 1.5rem', margin: '12px' }}
          onClick={openModal()}
        >
          <IoIosAdd size={30} />
          Добавить
        </Button>
      </>)
      default: return
    }
  }


  return (
    <>
      <div className={styles.modalMain}>
        <div>
          {PARAMETERS ? PARAMETERS.length !== 0 &&
            <Table className={styles.tables}>
              <thead>
                <tr>
                  <th></th>
                </tr>
              </thead>
              <tbody>

                {
                  PARAMETERS.map((data, index) => (
                    <tr
                      key={index}
                      onClick={() => handleSelect(data)}
                      // onDoubleClick={openModal(data)}
                      style={{ backgroundColor: select?.id === data.id ? '#3689FF' : '' }}
                    >
                      <td style={{ color: select?.id === data.id ? 'white' : '', textAlign: 'left' }}>{data.name}</td>

                    </tr>
                  ))
                }

              </tbody>
            </Table>
            : ''
          }
        </div>
        <br />
        <hr />
        <div className={styles.tabWrapper}>
          {renderTab()}
        </div>
      </div>
      <div className={styles.modalFooter}>
        <div>
          <div>Создал: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
          <div>Изменил: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
        </div>
        <Button mode='white' onClick={handleSubmit}>Сохранить</Button>
        <Button mode='white' onClick={closeModal}>Закрыть</Button>
      </div>
    </>
  )
}
