import * as types from "../actions/types";

const initialState = {
  list: [],
  loading: false
};

export default function trajectories(state = initialState, action) {
  switch (action.type) {

    case types.GET_TRAJECTORIES_LIST:
      return {
        ...state,
        list: action.payload
      };
    case types.SET_TRAJECTORIES_LIST_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}