import { api } from "../App";
import { SET_REPORT_GROUPS_DATA, SET_REPORT_GROUPS_LIST, SET_REPORT_GROUPS_LOADING } from "./types";




export const getReportGroups = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_REPORT_GROUPS_LOADING, payload: true });
    const { data } = await api.get('/report_group');
    dispatch({ type: SET_REPORT_GROUPS_LIST, payload: data });
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_REPORT_GROUPS_LOADING, payload: false });
  }
}

export const getReportGroup = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_REPORT_GROUPS_LOADING, payload: true });
    const { data } = await api.get(`/report_group/${id}`);
    dispatch({ type: SET_REPORT_GROUPS_DATA, payload: data });
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_REPORT_GROUPS_LOADING, payload: false });
  }
}

export const createReportGroup = (payload, reportIds) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_REPORT_GROUPS_LOADING, payload: true });
    const { data } = await api.post('/report_group', payload);
    await dispatch(addReportsToGroup(data.id, reportIds));
    await dispatch(getReportGroups());
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_REPORT_GROUPS_LOADING, payload: false });
  }
}

export const updateReportGroup = (id, data, reportIds) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_REPORT_GROUPS_LOADING, payload: true });
    await api.put(`/report_group/${id}`, data);
    const initialReportIds = getState().report_groups.data?.filter(d => d.used).map(d => d.xp_rpt_id) || []
    const newReportIds = reportIds.filter(id => !initialReportIds.includes(id));
    const deletedReportIds = initialReportIds.filter(id => !reportIds.includes(id));
    await dispatch(deleteReportsFromGroup(id, deletedReportIds));
    await dispatch(addReportsToGroup(id, newReportIds));
    await dispatch(getReportGroups());
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_REPORT_GROUPS_LOADING, payload: false });
  }
}

export const deleteReportGroup = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_REPORT_GROUPS_LOADING, payload: true });
    await api.delete(`/report_group/${id}`);
    await dispatch(getReportGroups());
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_REPORT_GROUPS_LOADING, payload: false });
  }
}

const deleteReportsFromGroup = (groupId, idsList) => async (dispatch, getState) => {
  if (!idsList?.length) return;
  const data = idsList.map(xp_rpt_id => ({xp_rpt_id}));
  await api.delete(`/report_group/${groupId}/reports`, {data});
} 

const addReportsToGroup = (groupId, idsList) => async (dispatch, getState) => {
  if (!idsList?.length) return;
  const data = idsList.map(xp_rpt_id => ({xp_rpt_id}));
  await api.post(`/report_group/${groupId}`, data);
}