import React from 'react'
import styles from './styles.module.scss'
import Button from '../../components/Button'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { TeachersSubjects, CabinetsSubjects } from './Tabs'
import { useNavigate } from 'react-router-dom'


export default function ScheduleSettings() {
  const navigate = useNavigate()
  const goHome = () => navigate('/')

  return (
    <div className={styles.wrapper}>
      <h1>Настройки расписания ВД</h1>
      <Tabs>

        <TabList className={styles.tabsBlock}>
          <Tab className={styles.tab} selectedClassName={styles.activeTab}>Преподаватели-Предметы</Tab>
          <Tab className={styles.tab} selectedClassName={styles.activeTab}>Кабинеты-Предметы</Tab>
        </TabList>

        <TabPanel>
          <TeachersSubjects />
        </TabPanel>

        <TabPanel>
          <CabinetsSubjects />
        </TabPanel>

      </Tabs>
      <footer className={styles.footer}>
        <Button mode='white' onClick={goHome}>Закрыть</Button>
      </footer>
    </div>
  )
}
