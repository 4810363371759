import React, { useState } from 'react'
import Button from '../Button'
import styles from './ModalHistory.module.css'
import { Input, Select } from '../ModalInputs'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { createHistoryGroups, updateHistoryGroups } from '../../actions/groups'
import * as Yup from 'yup'
import { getCurrentYearUtils } from '../../utils/classBook'
import moment from 'moment'


const validationSchema = Yup.object().shape({
  liter: Yup.string().required('обязательное поле').max(50, 'Должно быть не более 50 символов'),
  name: Yup.string().required('обязательное поле').max(50, 'Должно быть не более 50 символов'),
  xp_key: Yup.string().required('обязательное поле'),
})


export default function ModalHistory({
  absolute = true,
  top = "",
  left = "",
  confirmation = "",
  title = "",
  color = '#000',
  closeModal,
  modalHistory,
  setModalHistory,
  groupHistory,
  classId,
  isCreating,
  setIsCreating,
}) {

  const dispatch = useDispatch()

  const { schoolYears, termTypes } = useSelector(state => state.common)
  const handleCreateHistory = () => {
    if (values && isCreating) {
      const dis = {
        school_year: +values.xp_key,
        name: values.name,
        liter: values.liter,
        year: +values.year,
        term_type_id: +values.calc_term_type_name,
        gid: +classId,
      }
      dispatch(createHistoryGroups(classId, dis))
      setModalHistory(false)
      setIsCreating(false)
    } else {
      // createNotification('error', 'Заполните все обязательные поля')
    }
  }
  const handleUpdateHistory = ({ xp_key, ...values }) => {
    dispatch(updateHistoryGroups(classId, xp_key, values))
    setModalHistory(false)
  }
  console.log('getCurrentYearUtils(schoolYears, moment())::: ', getCurrentYearUtils(schoolYears, moment()));
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit
  } = useFormik({
    // initialValues: !isCreating ? {gid:0, ...groupHistory[0]} : 
    initialValues: !isCreating ? modalHistory :
      {
        calc_term_type_name: 1,
        liter: "",
        name: "",
        xp_key: getCurrentYearUtils(schoolYears, moment()),
        year: 0,
        gid: 0,
      },
    validationSchema,
    // enableReinitialize: true,
    onSubmit: isCreating ? handleCreateHistory : handleUpdateHistory
  })

  const error = (fieldName) => touched[fieldName] && errors[fieldName]


  return (
    <div className={absolute ? styles.absolute : null}>
      <div className={styles.confirmation} style={absolute ? null : { position: "absolute", top: top, left: left }}>
        <div className={styles.textBlock} styles={{ color: color }}>

          <Select label='Годы' style={{ width: '200px' }} value={(values.calc_years !== null && schoolYears?.find(el => el.name == values.calc_years)?.xp_key) || schoolYears?.find(el => el.xp_key == values.xp_key)?.xp_key} onChange={handleChange} name='xp_key' error={error('xp_key')}>
            {schoolYears?.map(({ xp_key, name }) => <option value={xp_key}>{name}</option>)}
          </Select>
          <Input label='Название' value={values.name} onChange={handleChange} name='name' error={error('name')} style={{ lineHeight: '18px', margin: '0.3rem' }} />
          <Select label='Годы обучения' style={{ width: '200px' }} value={values.year} onChange={handleChange} name='year'>
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]?.map((el) => <option value={el}>{el}</option>)}
          </Select>
          <Input label='Литера' value={values.liter} onChange={handleChange} name='liter' error={error('liter')} style={{ lineHeight: '18px', margin: '0.3rem' }} />
          <Select label='Период' style={{ width: '200px' }} value={termTypes.find(el => el.term_type_name == values.calc_term_type_name)?.term_type_id} onChange={handleChange} name='calc_term_type_name'>
            {termTypes?.map(({ term_type_id, term_type_name }) => <option value={term_type_id}>{term_type_name}</option>)}
          </Select>

        </div>
        <div className={styles.buttons}>
          <Button style={{ backgroundColor: "#3689FF", fontSize: "16px" }} onClick={handleSubmit}>Сохранить</Button>
          <Button style={{ backgroundColor: "#3689FF", fontSize: "16px" }} onClick={() => setModalHistory(false)}>Закрыть</Button>
        </div>
      </div>
    </div>
  )
}
