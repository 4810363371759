import { BACKEND_URL_PREFIX, itemsOnPage, recipientActionTypes } from "../assets/const";
import { createNotification } from "../components/Notifications/Notifications";
import { checkAndUpdateToken } from "./auth";
import { buildDefaultHeaders } from "./helper";
import { api } from "../App";

import {
    SET_STUDY_PLANS_DO,
    SET_STUDY_PLAN_DO_BY_ID,
    SET_STUDY_PLAN_DO_COURSES_LIST,

    SET_STUDY_PLANS_LOADING,
    SET_STUDY_PLANS_PAGE,
    SET_STUDY_PLAN_LOADING,
    SET_STUDY_PLAN_DO_FILES,

} from "./types";

export const setPage = (pageNumber) => ({ type: SET_STUDY_PLANS_PAGE, payload: pageNumber })


export const getStudyPlansDO = (params) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());

    const { pageNumber } = getState().studyPlans
    const max_results = itemsOnPage
    const paginate_from = itemsOnPage * pageNumber
    let copyParams = {
        ...params,
        limit: max_results,
        offset: paginate_from
    }

    const urlParams = new URLSearchParams(copyParams).toString();

    if (getState().auth.token) {
        dispatch({ type: SET_STUDY_PLANS_LOADING, payload: true });

        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/xp_study_plans`, {
                method: "GET",
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                const data = await response.json();
                console.log('data::: ', data);

                dispatch({ type: SET_STUDY_PLANS_DO, payload: data });
                // dispatch({ type: SET_STUDY_PLANS_COUNT, payload: {pageCount, count} });
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            dispatch({ type: SET_STUDY_PLANS_LOADING, payload: false });
        }
    }
};

export const getStudyPlanDOById = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: SET_STUDY_PLANS_LOADING, payload: true });
        const { data } = await api.get(`/xp_study_plans/${id}`);
        console.log('data::: ', data);
        dispatch({ type: SET_STUDY_PLAN_DO_BY_ID, payload: data });
    } catch (ERR) {
    } finally {
        dispatch({ type: SET_STUDY_PLANS_LOADING, payload: false });
    }
}

export const deleteStudyPlansDO = (id) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({ type: SET_STUDY_PLANS_LOADING, payload: true });
        const response = await fetch(`${BACKEND_URL_PREFIX}/xp_study_plans/${id}`, {
            method: "DELETE",
            ...buildDefaultHeaders(getState),
        });
        const result = await response.json();
        if (response.ok) {
            createNotification('success', 'Успешно');
            // const {list, pageNumber} = getState().plans
            // list.length < 2 && dispatch(setPage(pageNumber - 1))
            await dispatch(getStudyPlansDO());
        } else createNotification('error', result.error);
    }
    dispatch({ type: SET_STUDY_PLANS_LOADING, payload: false });
};



export const editStudyPlanDO = (data) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({ type: SET_STUDY_PLAN_LOADING, payload: true });
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/xp_study_plans/${data.xp_key}`, {
                method: "PUT",
                body: JSON.stringify(data),
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                createNotification('success', 'Успешно');
            } else {
                const error = await response.json();
                createNotification('error', error.error);
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            await dispatch(getStudyPlansDO());
        }
    }
    dispatch({ type: SET_STUDY_PLAN_LOADING, payload: false });
};

export const createStudyPlanDO = (data) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({ type: SET_STUDY_PLAN_LOADING, payload: true });
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/xp_study_plans`, {
                method: "POST",
                body: JSON.stringify(data),
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                createNotification('success', 'Успешно');
            } else {
                const error = await response.json();
                createNotification('error', error.error);
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            await dispatch(getStudyPlansDO());
        }
    }
    dispatch({ type: SET_STUDY_PLAN_LOADING, payload: false });
};

// МО Список предметов
export const getStudyPlanDOCoursesList = (id) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({ type: SET_STUDY_PLAN_LOADING, payload: true });
        const response = await fetch(`${BACKEND_URL_PREFIX}/xp_study_plans/${id}/courses`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            const payload = await response.json();
            dispatch({ type: SET_STUDY_PLAN_DO_COURSES_LIST, payload });
        }
    }
    dispatch({ type: SET_STUDY_PLAN_LOADING, payload: false });
};
export const addStudyPlanDOCourse = (data) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({ type: SET_STUDY_PLAN_LOADING, payload: true });
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/xp_study_plans/courses`, {
                method: "POST",
                body: JSON.stringify(data),
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                createNotification('success', 'Успешно');
            } else {
                const error = await response.json();
                createNotification('error', error.error);
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            await dispatch(getStudyPlanDOCoursesList(data.xp_tracks));
        }
    }
    dispatch({ type: SET_STUDY_PLAN_LOADING, payload: false });
};
export const editStudyPlanDOCourse = ({ xp_tracks, xp_courses, xp_key, cid, name, ...data }) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({ type: SET_STUDY_PLAN_LOADING, payload: true });
        try {
            const response = await fetch(`${BACKEND_URL_PREFIX}/xp_study_plans/${xp_tracks}/courses/${xp_courses}`, {
                method: "PUT",
                body: JSON.stringify(data),
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                createNotification('success', 'Успешно');
            } else {
                const error = await response.json();
                createNotification('error', error.error);
            }
        } catch (error) {
            createNotification('error', 'Ошибка');
        } finally {
            await dispatch(getStudyPlanDOCoursesList(xp_tracks));
        }
    }
    dispatch({ type: SET_STUDY_PLAN_LOADING, payload: false });
};

// xp_study_plans/<int:xp_key>/courses/<int:cid></int:cid>

export const createStudyPlanDOFile = (data, id) => async (dispatch, getState) => {
    console.log('(data, id::: ', (data, id))
    await dispatch(checkAndUpdateToken());
    const fd = new FormData();
    fd.append("file", data);

    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/xp_study_plans/${id}`, {
            method: "POST",
            body: fd,
            headers: {
                "Access-Control-Allow-Origin": "*",
                Authorization: `Basic ${btoa(`nnz:${getState().auth.token}`)}`,
            },
        });
        if (response.ok) {
            createNotification('success', 'Успешно');
            await dispatch(getStudyPlanDOById(id));

        }
    }
};

export const getStudyPlanDOFiles = (id) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {

        const response = await fetch(`${BACKEND_URL_PREFIX}/xp_study_plans/${id}/file`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
        });
        if (response.ok) {
            const result = await response.json();
            console.log('result::: ', result);
            dispatch({ type: SET_STUDY_PLAN_DO_FILES, payload: result });
        }
        else dispatch({ type: SET_STUDY_PLAN_DO_FILES, payload: null });
    }
};

// export const deleteStudyPlansContent = (data) => async (dispatch, getState) => {

//   await dispatch(checkAndUpdateToken());
//   if (getState().auth.token) {
//     dispatch({ type: SET_STUDY_PLANS_LOADING, payload: true });
//     const response = await fetch(`${BACKEND_URL_PREFIX}/study_plans/content/${data.xp_key}`, {
//       method: "DELETE",
//       ...buildDefaultHeaders(getState),
//     });
//     const result = await response.json();
//     if (response.ok) {
//       createNotification('success', 'Успешно');
//       await dispatch(getStudyPlan(data.trid));
//     } else createNotification('error', result.error);
//   }
//   dispatch({ type: SET_STUDY_PLANS_LOADING, payload: false });
// };

// export const getStudyPlanList = () => async (dispatch, getState) => {
//   await dispatch(checkAndUpdateToken());
//   if (getState().auth.token) {
//     dispatch({ type: SET_STUDY_PLAN_LIST_LOADING, payload: true });
//     const response = await fetch(`${BACKEND_URL_PREFIX}/courses`, {
//       method: "GET",
//       ...buildDefaultHeaders(getState),
//     });
//     if (response.ok) {
//       const payload = await response.json();
//       const courses = payload.courses;
//       dispatch({ type: SET_STUDY_PLAN_LIST, payload: courses});
//     }
//   }
//   dispatch({ type: SET_STUDY_PLAN_LIST_LOADING, payload: false });
// };

// export const createStudyPlanContent = (data) => async(dispatch, getState) => {
//   await dispatch(checkAndUpdateToken());
//   if (getState().auth.token) {
//     dispatch({ type: SET_STUDY_PLAN_LIST_LOADING, payload: true });
//       try {
//           const response = await fetch(`${BACKEND_URL_PREFIX}/study_plans/content`, {
//               method: "POST",
//               body: JSON.stringify(data),
//               ...buildDefaultHeaders(getState)
//           });
//           if (response.ok) {
//               createNotification('success', 'Успешно');
//           } else {
//             const error = await response.json();
//               createNotification('error', error.error || 'Ошибка');
//           }
//       } catch (e) {
//         createNotification('error', 'Ошибка')
//       } finally {
//           await dispatch(getStudyPlan(data.trid));
//       }
//   }
//   dispatch({ type: SET_STUDY_PLAN_LIST_LOADING, payload: false });
// };

// export const editStudyPlanContent = (data) => async(dispatch, getState) => {
//   await dispatch(checkAndUpdateToken());
//   if (getState().auth.token) {
//     dispatch({ type: SET_STUDY_PLAN_LIST_LOADING, payload: true });
//       try {
//           const response = await fetch(`${BACKEND_URL_PREFIX}/study_plans/content/${data.xp_key}`, {
//               method: "PUT",
//               body: JSON.stringify(data),
//               ...buildDefaultHeaders(getState)
//           });
//           if (response.ok) {
//               createNotification('success', 'Успешно');
//           } else {
//               createNotification('error', 'Ошибка');
//           }
//       } catch (error) {
//           createNotification('error', 'Ошибка');
//       } finally {
//           await dispatch(getStudyPlan(data.trid));
//       }
//   }
//   dispatch({ type: SET_STUDY_PLAN_LIST_LOADING, payload: false });
// };

// export const getStudyTrackProfile = () => async (dispatch, getState) => {
//   await dispatch(checkAndUpdateToken());
//   if (getState().auth.token) {
//     dispatch({ type: SET_TRACK_PROFILE_LOADING, payload: true });
//     const response = await fetch(`${BACKEND_URL_PREFIX}/track_profile`, {
//       method: "GET",
//       ...buildDefaultHeaders(getState),
//     });
//     if (response.ok) {
//       const payload = await response.json();
//       dispatch({ type: SET_TRACK_PROFILE, payload });
//     }
//   }
//   dispatch({ type: SET_TRACK_PROFILE_LOADING, payload: false });
// };