import * as types from "../actions/types";



const initialState = {

};


export default function typical_comment(state = initialState, action) {
  switch (action.type) {

    case types.SET_TYPICAL_COMMENT_LIST:
      return {
        ...state,
        typicalCommentList: action.payload,
      };

    default:
      return state;
  }
}