import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { getAchievements, deleteAchievement, createAchievement, updateAchievement } from '../../actions/achievements'
import Li from '../../components/Li'
import Button from '../../components/Button'
import Modal from 'react-responsive-modal'
import { useFormik } from 'formik'
import { Input } from '../../components/ModalInputs'
import Confirmation from '../../components/Confirmation/Confirmation'
import { BiTrash } from 'react-icons/bi'
import * as Yup from 'yup'



const INITIAL_VALUES = {
  ball: 1,
  // fixed: null,
  // level: null,
  name: "",
  alias: "",
  type: 1
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('обязательное поле').max(100, 'Должно быть не более 100 символов'),
  alias: Yup.string().max(15, 'Должно быть не более 15 символов'),
})

const Achievements = () => {

  const dispatch = useDispatch()
  const { list, loading } = useSelector(state => state.achievements)

  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(null)


  useEffect(() => {
    dispatch(getAchievements())
  }, [])

  const deleteItem = (id) => (e) => {
    e.stopPropagation()
    setConfirmation(id)
  }

  const openModal = (data) => () => setSelectedItem(data || {})

  const closeModal = () => setSelectedItem(null)

  const renderLi = (item) => (
    <Li onClick={openModal(item)}>
      <span>{item.name}</span>
      <BiTrash size={24} color='red' title='Удалить' onClick={deleteItem(item.id)} />
    </Li>
  )

  const acceptConfirmation = () => {
    dispatch(deleteAchievement(confirmation))
    setConfirmation(null)
  }

  const rejectConfirmation = () => {
    setConfirmation(null)
  }

  return (
    <div className={styles.wrapper}>
      <h1>Виды достижений</h1>
      <ul>{list?.map(renderLi)}</ul>
      <Button onClick={openModal()}>Добавить</Button>
      <ModalComponent
        open={!!selectedItem}
        closeModal={closeModal}
        data={selectedItem}
        addMode={!selectedItem?.id}
      />
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title="Уверены что хотите удалить запись?"
          acceptConfirmation={acceptConfirmation}
          rejectConfirmation={rejectConfirmation}
        />
      )}
    </div>
  )
}


const ModalComponent = ({ open, closeModal, data, addMode }) => {

  const dispatch = useDispatch()

  const [confirmation, setConfirmation] = useState(false)

  useEffect(() => {
    if (!open) return;
    setValues(addMode ? INITIAL_VALUES : data)
  }, [open])


  const createItem = async (values) => {
    const success = await dispatch(createAchievement(values))
    success && closeModal()
  }

  const updateItem = async ({ id, ...values }) => {
    const success = await dispatch(updateAchievement(id, values))
    success && closeModal()
  }

  const {
    values,
    errors,
    touched,
    dirty,
    handleSubmit,
    handleChange,
    handleBlur,
    setValues
  } = useFormik({
    initialValues: addMode ? {} : data,
    enableReinitialize: true,
    validationSchema,
    onSubmit: addMode ? createItem : updateItem
  })

  const handleCloseModal = () => {
    if (dirty) {
      setConfirmation(true)
    } else {
      closeModal()
    }
  }

  const acceptConfirmation = () => {
    closeModal()
    setConfirmation(false)
  }

  const error = (fieldName) => touched[fieldName] && errors[fieldName]

  return (
    <Modal
      open={open}
      onClose={closeModal}
      classNames={{ modal: styles.modal }}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      showCloseIcon={false}
      animationDuration={2}
      center
    >
      <div className={styles.modalMain}>
        <h2>{addMode ? 'Добавление записи' : 'Редактирование записи'}</h2>
        <Input
          name='name'
          label='Наименование'
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={error('name')}
        />
        <Input
          name='alias'
          label='Сокращенное наименование'
          value={values.alias}
          onBlur={handleBlur}
          onChange={handleChange}
          error={error('alias')}
        />
      </div>
      <div className={styles.modalFooter}>
        <div>
          <div />
          <div />
        </div>
        <Button mode='white' type="submit" onClick={handleSubmit}>Сохранить</Button>
        <Button mode='white' onClick={handleCloseModal}>Закрыть</Button>
      </div>
      {confirmation &&
        <Confirmation
          title="Есть несохраненные данные. Уверены что хотите выйти?"
          acceptConfirmation={acceptConfirmation}
          rejectConfirmation={() => setConfirmation(false)}
        />
      }
    </Modal>
  )
}


export default Achievements