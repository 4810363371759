import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import { TableCell } from './tableCell';
import Modal from 'react-responsive-modal';
import Button from '../../components/Button'
import Comment from './Comment.jsx/Comment';
import { getGradeValue, setClassbook, setClassbookHomework } from '../../actions/classbook';
import { useDispatch } from 'react-redux';
import ModalTheme from './ModalTheme/ModalTheme';

const ClassbookTable = ({ classbook, formData, handleClassTopic }) => {

  const getUniqueDateOfPeriod = (options) => {
    return options.reduce(
      (res, cur) =>
        res.find((find) =>
          JSON.stringify(find.lessonunixdate) === JSON.stringify(cur.lessonunixdate)
          && JSON.stringify(find.instance) === JSON.stringify(cur.instance)
          && JSON.stringify(find.sheid) === JSON.stringify(cur.sheid)
          && JSON.stringify(find.lessonid) === JSON.stringify(cur.lessonid)
        )
          ? res
          : [...res, cur],
      []
    ).sort((a, b) => a - b);
  };

  const forListOfDates = (getUniqueDateOfPeriod(classbook))
  // const listOfDates = [...( (forListOfDates.sort((a,b) => a.instance - b.instance)).slice(1)),forListOfDates.find(el => el.lessondate === 'Ср. балл' || el.lessondate === 'Учебный период')]
  const listOfDates1 = forListOfDates.filter(el => el.sheid !== 0)
  const listOfDates2 = forListOfDates.filter(el => el.sheid === 0)
  const listOfDates = [...listOfDates1, ...listOfDates2]


  const uniqStud = (options) => {
    return options.reduce(
      (res, cur) =>
        res.find((find) => JSON.stringify(find.mid) === JSON.stringify(cur.mid))
          ? res
          : [...res, cur],
      []
    )
  };

  const dispatch = useDispatch();

  //TODO 
  const listStudent = (uniqStud(classbook).filter((el) => el.mid !== 99999))
  const avBall = uniqStud(classbook).find((el) => el.mid === 99999) || {}
  const listAll = [...listStudent, avBall]


  const originalStudentMax = ((listAll.map((el, i) => classbook.filter((student) => student?.mid === el?.mid)).sort((a, b) => b.length - a.length)))[0]

  const [modalData, setModalData] = useState(null);
  const [panelOpen, setPanelOpen] = useState({ mid: null, lessonid: null });
  const [commentOpen, setCommentOpen] = useState({ mid: null, lessonid: null });
  const [selected, setSelected] = useState([]);

  const openModal = (data) => setModalData(data);
  const closeModal = () => setModalData(null)

  const handleOpenModal = (data) => {
    handleClassTopic(formData.scYear, data.sheid);
    openModal(data);
  }

  const lessonsType = {
    '': '?',
    'Урок': '?',
    'Контрольная работа': 'КР'
  }

  const romanNum = {
    1: 'I',
    2: 'II',
    3: 'III',
    4: 'IV',
  }

  let count = 0;

  const getMarkType = (day) => lessonsType[day];

  const getActiveSell = (val) => classbook.find((student) => (student.mid) == val?.mid && (student.lessonid == val?.lessonid));

  const postComment = async (value) => {
    await dispatch(setClassbook({ ...(getActiveSell(commentOpen)), gradecomment: value }, formData))
  }

  const handleActive = ({ mid, lessonid }) => {
    if (panelOpen.mid && panelOpen.lessonid) {
      setPanelOpen({ mid: null, lessonid: null });
    } else if (selected.find(el => (el.mid == mid) && (el.lessonid == lessonid))) {
      const arr = selected.filter(el => (el.mid != mid) || (el.lessonid != lessonid));
      setSelected(arr);
    } else {
      setSelected([...selected, { mid: mid, lessonid: lessonid }])
    }
  }

  const checkPeiod = (el) => el === 'Учебный период';

  useEffect(() => {
    dispatch(getGradeValue())
  }, [])

  return (
    <>
      {classbook.length == 0 ?
        <div className={styles.table}>Выберите параметры</div>
        :
        (
          <div className={styles.wrapper}>
            <div className={styles.tableWrapper}>

              {commentOpen.mid ? <Comment data={getActiveSell(commentOpen)?.gradecomment} setCommentOpen={setCommentOpen} postComment={postComment} /> : <></>}

              <table width="100%" border="10" cellspacing="0" cellpadding="5">
                <>
                  <thead>
                    <tr>
                      <td rowSpan={2} style={{ width: "70px", textAlign: "center" }} bgcolor="#85aee7">№ п.п.</td>
                      <td rowSpan={2} style={{ backgroundColor: "#85aee7", textAlign: "center" }}>Фамилия И.О.</td>
                      {listOfDates.length &&
                        listOfDates.map((day, i) => (day.lessonunixdate < 9999999999) ?
                          <>
                            <td onClick={() => handleOpenModal(day)} bgcolor="#85aee7" style={{ textAlign: 'center' }}>{getMarkType(day.lessontype)}</td>
                          </>
                          :
                          ''
                        )
                      }
                      <td bgcolor="#85aee7" style={{ textAlign: 'center' }} colSpan={8}>Итоги</td>
                    </tr>
                    <tr className={styles.classes}>
                      {listOfDates &&
                        listOfDates
                          .filter((day) =>
                            day.lessondate != 'Год' &&
                            !day.lessondate.toLowerCase().includes('экз') &&
                            day.lessondate != 'Итог')
                          .map((day) => {
                            if (checkPeiod(day.lessondate)) { count = count + 1 };
                            return <td bgcolor="#85aee7" style={{ textAlign: 'center' }}>
                              {checkPeiod(day.lessondate) ? romanNum[count] : day.lessondate}
                            </td>
                          })}
                    </tr>


                  </thead>
                  <tbody>
                    {listAll.length &&
                      listAll.map((el, i) => {
                        const allStudentGrades = classbook.filter((student) => student.mid === el.mid)

                        return (
                          <tr>
                            {el?.student !== "Ср. балл" ?
                              <td style={{ borderRight: "1px solid black", width: "70px", textAlign: "center" }}>{i + 1}</td>
                              :
                              <td></td>
                            }
                            <td
                              className={styles.student}
                              style={{
                                borderRight: "1px solid black",
                                textAlign: "left",
                                fontSize: '13px'
                              }}
                            >{el?.student}</td>
                            {listOfDates.map(({ lessondate }, i) => {

                              let grade = allStudentGrades
                                .find((grade) =>
                                  grade.lessondate === lessondate
                                  && grade.lessondate != 'Год'
                                  && !grade.lessondate.toLowerCase().includes('экз')
                                  && grade.lessondate != 'Итог');
                              return grade ?
                                <TableCell
                                  classbook={classbook}
                                  mark={grade.grade}
                                  student={grade}
                                  panelOpen={panelOpen}
                                  setPanelOpen={setPanelOpen}
                                  formData={formData}
                                  commentOpen={commentOpen}
                                  setCommentOpen={setCommentOpen}
                                  setClassbook={setClassbook}
                                  getActiveSell={getActiveSell}
                                  selected={selected}
                                  setSelected={setSelected}
                                  handleActive={handleActive}
                                />

                                : <td>{grade?.grade || ''}</td>
                            })}
                          </tr>
                        )
                      }
                      )

                    }
                  </tbody>
                </>

              </table>

              {<ModalTheme modalData={modalData} setModalData={setModalData} closeModal={closeModal} formData={formData} />}

              <div class={styles.line}></div>

            </div>

          </div>
        )}
    </>

  )
}

export default ClassbookTable;