import * as types from "../actions/types";


const initialState = {
  list: [],
  data: null,
  loading: false
};


export default function report_groups (state = initialState, action) {
  switch (action.type) {
    case types.SET_REPORT_GROUPS_LIST:
      return {
        ...state,
        list: action.payload
      };
  
    case types.SET_REPORT_GROUPS_DATA:
      return {
        ...state,
        data: action.payload
      };
  
    case types.SET_REPORT_GROUPS_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}