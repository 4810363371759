import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { Input, Checkbox, } from '../../../components/ModalInputs'
import { useContext } from 'react'
import { EventListContext, } from '../../../utils/context'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../components/Button'
import { useFormik } from 'formik'
import SelectPal from '../../../components/Select copy'
import moment from 'moment'
import { arrayForSelect } from '../../../utils/arrayForSelect'
import EventLevels from '../../EventLevels'
import Modal from 'react-responsive-modal'
import ActivityTypes from '../../ActivityTypes'
import EducDirections from '../../EducDirections'
import EventTypes from '../../EventTypes'
import { getRoomsList } from '../../../actions/rooms'
import { Textarea } from '../../../components/ModalInputs'

export default function Main() {

  const dispatch = useDispatch()

  const { closeModal, } = useContext(EventListContext)   // TODO КОНТЕКТ ДА СМЕНИТЬ
  const { list: event_levels } = useSelector(state => state.event_levels)
  const { list: event_type } = useSelector(state => state.event_type)
  const { list: educ_directions } = useSelector(state => state.educ_directions)
  const { list: act_types } = useSelector(state => state.act_types)
  const { list: rooms } = useSelector(state => state.rooms)

  const [selectMO, setSelectMO] = useState(null)
  const [modalValue, setModalValue] = useState(null)

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {},
    enableReinitialize: true,
    onSubmit: console.log
  })

  // TODO переделать удаление из инпута
  const handleResetForm = () => {
    setFieldValue('title', "")
  }

  useEffect(() => {
    !values.title && resetForm()
  }, [values.title])

  useEffect(() => {
    dispatch(getRoomsList())
  }, [])

  useEffect(() => {
    const { acid, news_level_id, edid, ev_type_id } = modalValue || {};
    acid && setFieldValue('act_types', acid);
    news_level_id && setFieldValue('event_levels', news_level_id);
    edid && setFieldValue('educ_directions', edid);
    ev_type_id && setFieldValue('event_type', ev_type_id);
  }, [modalValue]);

  const openSubModalComp = (mode) => {
    console.log('mode::: ', mode);
    if (mode === 1) { setSelectMO(<EventLevels closeSubModal={closeSubModal} data='eventList' setModalValue={setModalValue} />) }
    if (mode === 2) { setSelectMO(<EducDirections closeSubModal={closeSubModal} data='eventList' setModalValue={setModalValue} />) }
    if (mode === 3) { setSelectMO(<ActivityTypes closeSubModal={closeSubModal} data='eventList' setModalValue={setModalValue} />) }
    if (mode === 4) { setSelectMO(<EventTypes closeSubModal={closeSubModal} data='eventList' setModalValue={setModalValue} />) }
  }
  const closeSubModal = () => {
    setSelectMO(null)
  }

  return (
    <>
      {!!selectMO &&
        <Modal open={selectMO} onClose={closeSubModal} center={true} className={styles.modalWrapper}>
          {selectMO}
        </Modal>
      }
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          <div className={styles.inputsRow}>
            <Input
              error={values.periodfrom === '' ? 'Обязательное поле' : ''}
              type='date'
              label='Начало'
              name='periodfrom'
              value={moment(values.periodfrom).subtract(1, 'month').format('YYYY-MM-DD')}
              onChange={e => handleChange(e)}
            />
            <div className={styles.checkbox}>
              <Checkbox
                label='Входит в план ГУК МО РФ'
                name='planGUK'
                checked={values.planGUK}
                onChange={handleChange}
              />
            </div>
            <Input
              error={values.periodtill === '' ? 'Обязательное поле' : ''}
              type='date'
              label='Окончание'
              name='periodtill'
              value={moment(values.periodtill).format('YYYY-MM-DD')}
              onChange={e => handleChange(e)}
            />
            <div className={styles.checkbox}>
              <Checkbox
                label='Регулярное'
                name='regular'
                checked={values.regular}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className={styles.checkbox}>
            <Input
              error={values.title === '' ? 'Обязательное поле' : ''}
              label='Название'
              name='title'
              maxLength={120}
              value={values.title}
              onChange={handleChange}
              style={{ width: '500px' }}
            />
          </div>
          <div className={styles.inputsRow}>
            <div className={styles.checkbox}>
              <Checkbox
                label='Внутреннее'
                name='internal'
                checked={values.internal}
                onChange={handleChange}
              />
            </div>
            <SelectPal
              title='Место проведения'  // взял из КАБИНЕТОВ
              valuesName="rid"
              value={values.rid}
              options={rooms && arrayForSelect(rooms, 'name', 'rid')}
              style={{ minWidth: '520px' }}
              setFieldValue={setFieldValue}
            />
          </div>
          <div className={styles.inputsRow}>

            <div className={styles.ModalRow}>
              <div className={styles.select_Modal_Row}>
                <SelectPal
                  title='Уровень мероприятия'
                  valuesName="event_levels"
                  value={values.event_levels}
                  options={event_levels && arrayForSelect(event_levels, 'news_level', 'news_level_id')}
                  style={{ width: '120px' }}
                  setFieldValue={setFieldValue}
                />
                <div className={styles.div_dot} onClick={() => openSubModalComp(1)}> + </div>
              </div>
              <div className={styles.select_Modal_Row}>

                <SelectPal
                  title='Направление воспитательной работы'
                  valuesName="educ_directions"
                  value={values.educ_directions}
                  options={educ_directions && arrayForSelect(educ_directions, 'name', 'edid')}
                  style={{ width: '120px' }}
                  setFieldValue={setFieldValue}
                />
                <div className={styles.div_dot} onClick={() => openSubModalComp(2)}> + </div>
              </div>
            </div>
            <div className={styles.ModalRow}>
              <div className={styles.select_Modal_Row}>
                <SelectPal
                  title='Форма мероприятия'
                  valuesName="event_type"
                  // value={values.event_type}
                  // options={event_type && arrayForSelect(event_type, 'ev_type', 'ev_type_id')}
                  value={values.act_types}
                  options={act_types && arrayForSelect(act_types, 'name', 'acid')}
                  style={{ width: '120px' }}
                  setFieldValue={setFieldValue}
                />
                <div className={styles.div_dot} onClick={() => openSubModalComp(3)}> + </div>
              </div>
              <div className={styles.select_Modal_Row}>
                <SelectPal
                  title='Тип события'
                  valuesName="act_types"
                  // value={values.act_types}
                  // options={act_types && arrayForSelect(act_types, 'name', 'acid')}
                  value={values.event_type}
                  options={event_type && arrayForSelect(event_type, 'ev_type', 'ev_type_id')}
                  style={{ width: '120px' }}
                  setFieldValue={setFieldValue}
                />
                <div className={styles.div_dot} onClick={() => openSubModalComp(4)}> + </div>
              </div>
            </div>
          </div>
          <SelectPal
            title='Вид соревнования'  /// TODO откуда брать?
            valuesName="act_types"
            value={values.act_types}
            options={act_types && arrayForSelect(act_types, 'name', 'acid')}
            style={{ width: '120px' }}
            setFieldValue={setFieldValue}
          />
          <br />
          <Button onClick={handleResetForm}>Очистить формат</Button>
          <Textarea className={styles.textarea} name={"text"} label="Текст" value={values.text} onChange={e => { handleChange(e) }} />
        </div>
      </div>

      <div className={styles.modalFooter}>
        <div>
          <div>Создал: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
          <div>Изменил: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
        </div>
        <Button mode='white' onClick={closeModal}>Сохранить</Button>
        <Button mode='white' onClick={closeModal}>Закрыть</Button>
      </div>
    </>

  )
}
