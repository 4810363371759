import React, { useEffect, useState } from 'react'
import styles from '../styles.module.scss'
import SelectPal from '../../../components/Select copy'

import Leaf from '../../../components/Leaf'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { getCLasses, getCLassesByYear, getParallels, getScheduleVariants, getSchoolYears, getShifts, getStudyForms, getStudySteps } from '../../../actions/common'
import queryString from 'query-string';
import { useFormik } from 'formik'
import { getSchedule } from '../../../actions/scheduling'
import { getStagesStudy } from '../../../actions/stagesStudy'
import { arrayForSelect } from '../../../utils/arrayForSelect'
// import { arrayForSelect } from '../../utils/arrayForSelect'



export default function Form() {

  const dispatch = useDispatch()
  const { schoolYears, shifts, scheduleVariants, studyForms, parallels, classes } = useSelector(state => state.common)
  const { stageStudyList } = useSelector(state => state.stages_study)

  const navigate = useNavigate()
  const location = useLocation()

  // const paramsForm = queryString.parse(location.search)
  const paramsForm = {}
  console.log('paramsForm::: ', paramsForm);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue
  } = useFormik({
    initialValues: paramsForm,
  })

  useEffect(() => {
    !schoolYears.length && dispatch(getSchoolYears())
    !shifts && dispatch(getShifts())
    !studyForms && dispatch(getStudyForms())
    !stageStudyList && dispatch(getStagesStudy())
    !parallels && dispatch(getParallels())
    // !classes && dispatch(getCLasses())
    !classes && dispatch(getCLassesByYear(values.scYear))
  }, [])
  console.log('values::: ', values);
  // const { values, handleChange } = useFormik({
  //   initialValues: paramsForm
  // })


  useEffect(() => {
    const searchParams = queryString.stringify(values)
    console.log('searchParams::: ', searchParams);
    navigate(`/scheduling?${searchParams}`)
    const { var_id, ...params } = values
    if (!var_id) return;
    dispatch(getSchedule(var_id, params))
  }, [values])

  useEffect(() => {
    if (!values.scYear) return;
    dispatch(getScheduleVariants(values.scYear))
  }, [values.scYear])



  console.log('values::: ', values);
  return (
    <div className={styles.wrapperPanel}>

      <div className={styles.inputsRow}>
        <SelectPal
          title="Учебный год"
          valuesName="scYear"
          options={schoolYears && arrayForSelect(schoolYears, 'name', 'xp_key')}
          style={{ width: '120px' }}
          setFieldValue={setFieldValue}
        />
        <SelectPal
          title="Вариант расписания"
          valuesName="var_id"
          options={scheduleVariants && arrayForSelect(scheduleVariants, 'sh_var_name', 'sh_var_id')}
          style={{ width: '120px' }}
          setFieldValue={setFieldValue}
        />
      </div>

      <div className={styles.inputsRow}>
        <SelectPal
          title="Форма обучения"
          valuesName="f_studyform"
          options={studyForms && arrayForSelect(studyForms, 'name', 'f_studyform')}
          style={{ width: '120px' }}
          setFieldValue={setFieldValue}
        />
        <SelectPal
          title="Ступень обучения"
          valuesName="f_studysteps"
          options={stageStudyList && arrayForSelect(stageStudyList, 'name', 'f_studysteps')}
          style={{ width: '120px' }}
          setFieldValue={setFieldValue}
        />
        <SelectPal
          title="Смена"
          valuesName="shift"
          options={shifts && arrayForSelect(shifts, 'name', 'sid')}
          style={{ width: '120px' }}
          setFieldValue={setFieldValue}
        />
      </div>
      <div className={styles.inputsRow}>
        <SelectPal
          title="Параллель"
          valuesName="parallel"
          options={parallels && arrayForSelect(parallels, 'kurs_name', 'year')}
          style={{ width: '120px' }}
          setFieldValue={setFieldValue}
        />
        <SelectPal
          title="Класс"
          valuesName="gid"
          options={classes && arrayForSelect(classes, 'name', 'gid')}
          style={{ width: '120px' }}
          setFieldValue={setFieldValue}
        />
      </div>

    </div>
  )
}
