import React from 'react'
import styles from '../styles.module.css'

export default function Attendance({ active }) {
  return (
    <div >
      Attendance
    </div>
  )
}
