import { api } from "../App";
import { buildTree } from "../utils/disciplineStructure";
import { SET_DISCIPLINE_STRUCTURE_DATA, SET_DISCIPLINE_STRUCTURE_FILTER, SET_DISCIPLINE_STRUCTURE_LOADING } from "./types";



export const getSchoolYears = () => async (dispatch, getState) => {
  try {
    const { data } = await api.get('/course_structure/school_year')
    const payload = { schoolYears: data }
    dispatch({ type: SET_DISCIPLINE_STRUCTURE_FILTER, payload });

  } catch (ERR) {
    console.log(ERR)
  } finally {
  }
};

export const getPeoples = (yearId, params) => async (dispatch, getState) => {
  try {
    const { data } = await api.get(`/course_structure/people/${yearId}`, { params })
    const payload = { peoples: data }
    dispatch({ type: SET_DISCIPLINE_STRUCTURE_FILTER, payload });

  } catch (ERR) {
    console.log(ERR)
  } finally {
  }
};

export const getCourses = (yearId, peopleId, params) => async (dispatch, getState) => {
  try {
    const { data } = await api.get(`/course_structure/courses/${yearId}/${peopleId}`, { params })
    const payload = { courses: data }
    dispatch({ type: SET_DISCIPLINE_STRUCTURE_FILTER, payload });

  } catch (ERR) {
    console.log(ERR)
  } finally {
  }
};

export const getProfiles = () => async (dispatch, getState) => {
  try {
    const { data } = await api.get(`/track_profile`)
    const payload = { profiles: data }
    dispatch({ type: SET_DISCIPLINE_STRUCTURE_FILTER, payload });

  } catch (ERR) {
    console.log(ERR)
  } finally {
  }
};

export const getData = (year, people, course, profile, params) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_DISCIPLINE_STRUCTURE_LOADING, payload: true });
    const { data } = await api.get(`/course_structure/${year}/${people}/${course}/${profile}`, { params })
    dispatch({ type: SET_DISCIPLINE_STRUCTURE_DATA, payload: buildTree(data) });

  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_DISCIPLINE_STRUCTURE_LOADING, payload: false });
  }
};
////// <----------  НОВАЯ СТРУКТУРА ----------> 06/12 159 ////////////////
export const getDataStructure = (params) => async (dispatch, getState) => {
  // const {xp_key, teacher_mid: mid} =params
  try {
    const urlParams = new URLSearchParams(params).toString();

    dispatch({ type: SET_DISCIPLINE_STRUCTURE_LOADING, payload: true });
    const { data } = await api.get(`/course_structure?${urlParams}`)
    // dispatch({ type: SET_DISCIPLINE_STRUCTURE_DATA, payload: buildTree(data) });
    dispatch({ type: SET_DISCIPLINE_STRUCTURE_DATA, payload: (data) });
  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_DISCIPLINE_STRUCTURE_LOADING, payload: false });
  }
};

export const updateData = (year, people, course, profile, params, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_DISCIPLINE_STRUCTURE_LOADING, payload: true });
    const response = await api.put(`/course_structure/${year}/${people}/${course}/${profile}`, data, { params })
    // dispatch({ type: SET_DISCIPLINE_STRUCTURE_DATA, payload: buildTree(data) });

  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_DISCIPLINE_STRUCTURE_LOADING, payload: false });
  }
};