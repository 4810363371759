import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from '../styles.module.scss'
import Table from '../../../components/Table'
import {
  getScheduleSettingsLessons,
  getScheduleSettingsTeachersSubjects,
  updateScheduleSettingsTeachersSubjects,
  getScheduleSettingsCycle
} from '../../../actions/scheduleSettings'
import Loader from '../../../components/Loader'
import { Checkbox, Select } from '../../../components/ModalInputs'


export default function TeachersSubjects() {

  const dispatch = useDispatch()
  const { scheduleSettingsLessons, scheduleSettingsTeachersSubList, loading, cycle } = useSelector(state => state.schedule_settings)
  const [params, setParams] = useState({type: 1, did: '', scheduling: false})
  
  useEffect(() => {
    dispatch(getScheduleSettingsLessons(params.type))
    dispatch(getScheduleSettingsCycle(params.type))
  }, [])

  useEffect(() => {
    dispatch(getScheduleSettingsTeachersSubjects(params))
  }, [params])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    const newValue = name === 'scheduling' ? !params.scheduling : value
    setParams({...params, [name]: newValue})
  }

  const selectedCell = (data) => () => {
    const res = { "mid": data[0], "cid": data[1] }
    dispatch(updateScheduleSettingsTeachersSubjects(res, params))
  }

  const renderLi = (data) => {
    if (data) return (
      <tr key={data[0]}>
        <td className={styles.fio}>{data[1]}</td>
        {data[2].map((el, i) =>
          <td
            key={el[0]}
            onClick={selectedCell([data[0], scheduleSettingsLessons?.[i].cid])}
            style={{ backgroundColor: el[1] === 1 && '#3689FF' }}

          ></td>)}
      </tr >
    )
  }

  return (
    loading ? <Loader /> :
      <div className={styles.inputsWrapper}>
        <div className={styles.selectWrapper}>
          <Select
            label='Цикл'
            value={params.did || 0}
            onChange={(e) => handleInputChange(e)}
            name='did'
            style={{ width: '500px' }}
          >
            {cycle && [{ name: 'Выберите цикл', did: '' }, ...cycle]?.map(({ name, did }) => <option key={did} value={did}>{name}</option>)}
          </Select>

        </div>

        <Checkbox label='Только преподаватели цикла' checked={params.scheduling} onChange={(e) => handleInputChange(e)} name='scheduling' />

        <Table className={styles.tables}>
          <thead className={styles.headSchSet}>
            <tr>
              <th className={styles.fio2}></th>
              {scheduleSettingsLessons?.map(el => <th>{el.title}</th>)}
            </tr>
          </thead>
          <tbody>

            {scheduleSettingsTeachersSubList?.map(renderLi)}

          </tbody>
        </Table>

      </div>
  )
}
