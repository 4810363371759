import React, { useEffect } from 'react'
import styles from '../../styles.module.css'
import Button from '../../../../components/Button'
import { IoIosAdd, IoIosSave } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../../../components/Loader'
import { useState } from 'react'
import Modal from '../../Modal'
import { setPage, getPeriodsList, deletePeriod, copyPeriod } from '../../../../actions/periods'
import Table from '../../../../components/Table'
import { getSchoolYears, getShifts } from '../../../../actions/common'
import { Select } from '../../../../components/ModalInputs'
import Confirmation from '../../../../components/Confirmation/Confirmation'
import { BiTrash } from 'react-icons/bi'
import { getCurrentYearUtils } from '../../../../utils/classBook'
import ModalExceptions from './ModalExcpections'


export default function Exceptions() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { list, loading } = useSelector(state => state.periods)

  const { shifts = [] } = useSelector(state => state.common)

  const [selectedItemExceptions, setSelectedItemExceptions] = useState(null)
  const { schoolYears } = useSelector(state => state.common)

  const [search, setSearch] = useState(null)
  const [periodForDelete, setPeriodForDelete] = useState(null)
  const [forCopyPeriod, setForCopyPeriod] = useState(null)

  useEffect(() => {
    dispatch(getSchoolYears())
    dispatch(getShifts())
  }, [])

  useEffect(() => {
    setSearch({ year: getCurrentYearUtils(schoolYears), shift: '0' })
  }, [schoolYears])

  useEffect(() => () => dispatch(setPage(0)), [])

  const handleSearch = (name, value) => {
    setSearch(prev => ({ ...prev, [name]: value }));
  }

  useEffect(() => {
    if (search !== null) dispatch(getPeriodsList({ year: search.year, shift: search.shift }));
  }, [search]);

  const openModal = (data) => () => {
    setSelectedItemExceptions(data || {})
  }

  const closeModal = () => {
    setSelectedItemExceptions(null)
  }


  const goHome = () => navigate('/')

  const handleAdd = () => {
    console.log('add')
  }

  const renderRow = (data) => {
    const { name, s_year_id, starttime, stoptime, lid, status, dopstatus, outstatus } = data
    return (
      <tr>
        <td onClick={openModal(data)}>{starttime}</td>
        <td onClick={openModal(data)}>{stoptime}</td>
        <td onClick={openModal(data)}>{name}</td>
        <td><i className={styles.trash} onClick={deleteClickHandler({ lid, s_year_id, name, search })}><BiTrash /></i></td>
      </tr>
    )
  }

  const deleteClickHandler = (lid) => (e) => {
    e.stopPropagation()
    setPeriodForDelete(lid)
  }

  const acceptConfirm = () => {
    dispatch(deletePeriod(periodForDelete))
    setPeriodForDelete(null)
  }

  const rejectConfirm = () => {
    setPeriodForDelete(null)
  }

  return (
    <div className={styles.wrapper}>
      {periodForDelete &&
        <Confirmation
          title={`Вы уверены, что хотите удалить занятие ${periodForDelete.name}?`}
          acceptConfirmation={acceptConfirm}
          rejectConfirmation={rejectConfirm}
        />
      }
      {console.log('selectedItemExceptions::: ', selectedItemExceptions)}

      <ModalExceptions
        open={!!selectedItemExceptions}
        closeModal={closeModal}
        data={selectedItemExceptions}
        search={search}
      />


      {loading
        ? <Loader />
        : list &&
        <div className={styles.tablePeriods} >
          <Table style={{ marginTop: '2rem', backgroundColor: 'white' }}>
            <thead>
              <tr className={styles.thead}>
                <th>С</th>
                <th>По</th>
                <th>Исключение</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {list.map(renderRow)}
            </tbody>
          </Table>
        </div>
      }
      <div className={styles.buttons}>
        <Button icon={IoIosAdd} onClick={openModal()}><IoIosAdd size={30} />Добавить запись</Button>
      </div>
    </div>
  )
}


// function ModalExceptions() {
//   return (
//     <div className={styles.modalMain}>
//       <div className={styles.tabWrapper}>
//         <Table>
//           <thead>
//             <tr>
//               <th>Название</th>
//               <th>Уч. период</th>
//               <th>Уч. период ДО</th>
//               <th>Уч. период ВД</th>
//               <th>Время начала</th>
//               <th>Время окончания</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>1</td>
//               <td>2</td>
//               <td>3</td>
//               <td>4</td>
//               <td>5</td>
//               <td>6</td>
//             </tr>
//           </tbody>
//         </Table>
//       </div>
//     </div>



//   )
// }
