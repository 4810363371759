import React, { useEffect, useState } from 'react'
import styles from './index.module.css'
import Modal from 'react-responsive-modal'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import { studyPlansModes } from '../../../assets/const'
import cn from 'classnames'
import Main from './Main'
import Settings from './Settings'
import { useDispatch, useSelector } from 'react-redux'
import { getStudyPlan, editStudyPlan, createStudyPlan } from '../../../actions/studyPlans'
import moment, { weekdays } from 'moment'
import { createNotification } from '../../../components/Notifications/Notifications'
import { getDailyRoutineList, createDailyRoutineConsists, getDays, createDailyRoutine, updateDailyRoutine } from '../../../actions/dailyRoutine'

const Index = ({ modalOpen, closeModal, props }) => {

    const [mode, setMode] = useState('main')
    const [values, setValues] = useState(modalOpen)
    const [days, setDays] = useState(modalOpen.week_days || [])
    const [parallels, setParallels] = useState([...modalOpen.parallels || []])

    const { item } = useSelector(state => state.studyPlans)
    const { dailyRoutinList, daysList } = useSelector(state => state.dailyRoutin)

    const dispath = useDispatch()

    useEffect(() => {
        // setValues(modalOpen)
        !!modalOpen.xp_key && dispath(getDailyRoutineList(modalOpen.xp_key))
        // dispath(getDays(modalOpen.xp_key))
        if (days.length || parallels.length) setValues({ ...values, week_days: days, parallels: parallels })

    }, [days, parallels, modalOpen])

    const handleSubmit = () => {
        dispath(!modalOpen.xp_key ? createDailyRoutine(values) : updateDailyRoutine(values))
        closeModal();
    }

    const renderLi = ({ name, value, icon }) => {
        if (values.xp_key === 'new' && value === 'settings') return
        const selected = value === mode
        return (
            <li
                onClick={() => setMode(value)}
                className={cn(styles.modalMenuItem, {
                    [styles.selectedMenuItem]: selected
                })}
            >
                {icon()}
                {name}
            </li>
        )
    }

    const renderTab = () => {
        switch (mode) {
            case 'main': return <Main values={values} setValues={setValues} days={days} setDays={setDays} parallels={parallels} setParallels={setParallels} />
            case 'settings': return <Settings data={dailyRoutinList} values={values} setValues={setValues} />
            default: return
        }
    }

    return (

        <Modal
            classNames={{
                modal: styles.modalMain
            }}
            open={modalOpen}
            addMode={modalOpen.xp_key}
            onClose={closeModal}
            closeOnOverlayClick={true}
            closeOnEsc={true}
            showCloseIcon={true}
            animationDuration={1}
            style={{
                modal: {
                    background: 'white',
                    width: '600px'
                }
            }}
        >

            <div className={styles.modalContent}>
                <div className={styles.modalMenu}>
                    <ul>{studyPlansModes.map(renderLi)}</ul>
                </div>
                {renderTab()}
            </div>

            <ModalFooter isShowInfo={true} closeModal={closeModal} handleSubmit={handleSubmit}
            />
        </Modal>
    )
}

export default Index