import React, {useEffect} from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import {IoIosAdd} from 'react-icons/io'
import {useDispatch, useSelector} from 'react-redux'
import {useState} from 'react'
import Modal from './Modal'
import Table from '../../components/Table'
import {BiTrash} from 'react-icons/bi'
import Confirmation from '../../components/Confirmation/Confirmation'
import {deleteExamBoard, getExamBoardList} from '../../actions/exam_board'
import Loader from '../../components/Loader'
import Empty from '../../components/Empty'
import Search from './Search'

const defaultFilters = {
    school_year: 0,
    stream: 0,
    kurs: 0
}

export default function Index() {
    const dispatch = useDispatch();
    const {list, loading} = useSelector(state => state.exam_board);

    const [selectedItem,
        setSelectedItem] = useState(null);
    const [confirmation,
        setConfirmation] = useState(false);
    const [filters,
        setFilters] = useState(defaultFilters);

    useEffect(() => {
        // dispatch(getExamBoardList())
        
    }, [])

    const openModal = (data) => () => {
        setSelectedItem(data)
    }

    const closeModal = () => setSelectedItem(null)

    const acceptConfirmation = () => {
        closeModal();
        dispatch(deleteExamBoard(confirmation.id, filters));
        setConfirmation(false);
    };
    const rejectConfirmation = () => {
        setConfirmation(false);
    };

    const renderLi = (data) => {
        const {id, name, kurs, interviewers, total_interview, passed_interview } = data
        return (
            <tr>
                <td onClick={openModal(data)}>{name}</td>
                <td onClick={openModal(data)}>{kurs}</td>
                <td onClick={openModal(data)}>{interviewers}</td>
                <td onClick={openModal(data)}>{total_interview}</td>
                <td onClick={openModal(data)}>{passed_interview}</td>
                <td>
                    <i
                        className={styles.trash}
                        onClick={() => setConfirmation({id, name: name})}><BiTrash/></i>
                </td>
            </tr>
        )
    }

    return (
        <div
            className={styles.wrapper}
            style={{
            width: '100%',
            margin: '0 auto'
        }}>
            {confirmation && (<Confirmation
                top="30px"
                left="40px"
                title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
                acceptConfirmation={() => acceptConfirmation(confirmation?.id)}
                rejectConfirmation={rejectConfirmation}/>)}

            {selectedItem && <Modal
                setFilters={setFilters}
                closeModal={closeModal}
                data={selectedItem}
                height={'430px'}
                addMode={selectedItem == 'add'}/>}

            <h1>Предметные комиссии</h1>

            <Search filters={filters} setFilters={setFilters} getExamBoardList={getExamBoardList}/>

            {loading ? <Loader/> : null}

            {!loading && !list?.length
                    ? <Empty/>
                    : <Table className={styles.tables}>
                        <thead>
                            <tr>
                                <th>Наименование</th>
                                <th>Класс</th>
                                <th>Состав</th>
                                <th>Всего собеседований</th>
                                <th>Проведено собеседований</th>
                                <th>Удалить</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list?.map(renderLi)}
                        </tbody>
                    </Table>}

            <div
                style={{
                display: 'flex',
                flexDirection: "row"
            }}>
                <Button
                    style={{
                    padding: '.5rem 1.5rem',
                    margin: '12px'
                }}
                    onClick={openModal('add')}>
                    <IoIosAdd size={30}/>
                    Добавить
                </Button>
            </div>
        </div>
    )
}
