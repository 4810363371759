import React, { useState } from 'react'
import { useContext } from 'react'
import { EventListContext } from '../../../utils/context'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import styles from './styles.module.scss'
import Button from '../../../components/Button'
import Table from '../../../components/Table'
import { getAppeals, getMedVisites, createMedVisites, updateMedVisites } from '../../../actions/medCenter/medCard'
import { getTypesOfDisease, } from '../../../actions/medCenter/typesOfDisease'
import Modal from 'react-responsive-modal'
import { useFormik } from 'formik'
import { Checkbox, Input, Textarea, Select } from '../../../components/ModalInputs'
import Complaint from './Complaint'
import MKBten from '../../MKBten'
import moment from 'moment'
import Confirmation from '../../../components/Confirmation/Confirmation'
import { BiTrash } from 'react-icons/bi'


const typeOfAppeal = [
  { name: 'Первичное', id: 1 },
  { name: 'Вторичное', id: 2 }
]
const diagnosisComplaintList = [
  { name: 'Перелом', id: 1 },
  { name: 'Температура', id: 2 },
  { name: 'Давление', id: 3 }
]


export default function Responsible() {

  const { student, closeModal } = useContext(EventListContext)
  const dispatch = useDispatch()
  const { appeals, diseaseList, discharge, } = useSelector(state => state.directoryMedCenter)

  const [openComplain, setOpenComplain] = useState(false)
  const [openMKB, setOpenMKB] = useState(false)
  const [selectedDiagnosis, setSelectedDiagnosis] = useState(null)
  const [diagnosisComplaint, setDiagnosisComplaint] = useState(null)
  const [confirmation, setConfirmation] = useState(false);
  const [open, setOpen] = useState(false)

  useEffect(() => {
    selectedDiagnosis && closeMKB()
    selectedDiagnosis && setFieldValue('iddiagnosis', selectedDiagnosis.id)
  }, [selectedDiagnosis])


  const close = () => {
    setDiagnosisComplaint(null)
    setSelectedDiagnosis(null)
    resetForm()
    setOpen(false)
  }
  const closeComplain = () => setOpenComplain(false)
  const closeMKB = () => setOpenMKB(false)

  const handleCreateVisit = () => {
    if (selectedDiagnosis == null) return setSelectedDiagnosis('select');
    dispatch(createMedVisites(values))
    setDiagnosisComplaint(null)
    setSelectedDiagnosis(null)
    resetForm()
    close()
  }
  const handleUpdateVisit = () => {
    console.log('values::: ', values);
    dispatch(updateMedVisites(values))
    setDiagnosisComplaint(null)
    setSelectedDiagnosis(null)
    resetForm()
    close()
  }

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm
  } = useFormik({
    initialValues: !!open.visitdate ? { mid: student?.mid, doctormid: 12, ...open } : { mid: student?.mid, doctormid: 12, visitdate: moment().format("YYYY-MM-DD") },
    enableReinitialize: true,
    onSubmit: !!open.visitdate ? handleUpdateVisit : handleCreateVisit
  })

  const renderOption = (option, index) => {
    const { xp_key, value, trmid, cid, gid, work_status, id, pmid, did, name, title, year, access, diagnosis, disease, iddischarge, discharge } = option
    const optionValue = xp_key || value || trmid || cid || gid || work_status || id || pmid || did || disease || iddischarge;
    const optionTitle = name || title || value || year || work_status || access || diagnosis || discharge;
    return <option key={`${optionValue}+${index}`} value={optionValue}>{optionTitle}</option>
  }

  const handleOpenComplaint = () => {
    setOpenComplain(true)
  }
  const handleOpenMKB = () => {
    setOpenMKB(true)
  }


  const acceptConfirmation = () => {
    closeModal();
    // dispatch(deleteTypesOfDisease(confirmation.id));
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  return (
    <>
      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          {confirmation && (
            <Confirmation
              top="30px"
              left="40px"
              title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
              acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
              rejectConfirmation={rejectConfirmation}
            />
          )}
          <Table>
            <thead>
              <tr>
                <th>Дата</th>
                <th>Диагноз по МКБ</th>
                <th>Диагноз при постеплении (жалоба)</th>
                <th>Освобождение</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {appeals && appeals.map(stud =>
                <tr>
                  <td onClick={() => setOpen(stud)} >{stud.visitdate}</td>
                  <td onClick={() => setOpen(stud)} >{stud.diagnosis}</td>
                  <td onClick={() => setOpen(stud)} >{stud.diagnosiswho}</td>
                  <td onClick={() => setOpen(stud)} >{stud.discharge}</td>
                  <td><i className={styles.trash} onClick={() => setConfirmation({ id: stud.idmedvisit, name: stud.visitdate })}><BiTrash /></i></td>
                </tr>
              )}

            </tbody>
          </Table>
        </div>
        {/* <Button
          onClick={() => setOpen(true)}
          style={{ marginLeft: '50px' }}
        >
          Добавить
        </Button> */}
      </div>
      <div className={styles.modalFooter}>
        <div>
          {/* <div>Создал: Алехина Милана Дмитриевна 21.05.2022 15:34</div>
              <div>Изменил: Алехина Милана Дмитриевна 21.05.2022 15:34</div> */}
        </div>
        <Button mode='white' onClick={closeModal}>Сохранить</Button>
        <Button mode='white' onClick={closeModal}>Закрыть</Button>
      </div>

      {open &&
        <Modal
          open={open}
          closeModal={close}
          onClose={() => false}
          showCloseIcon={false}
          closeOnOverlayClick={false}
          closeOnEsc={false}
          center
          classNames={{
            // overlay: styles.overlay,
            modal: styles.custom
          }}
        >

          <div className={styles.filters}>
            <div className={styles.inputsRow}>
              <div className={styles.inputsColumn}>
                <div>
                  <Input
                    label='Дата'
                    type='date'
                    name='visitdate'
                    // value={values.visitdate}
                    value={values.visitdate}
                    onChange={handleChange}
                  />
                  <Input
                    label='Температура'
                    type='number'
                    name='temperature'
                    value={values.temperature}
                    onChange={handleChange}
                  />
                  <Input
                    label='Пульс'
                    type='number'
                    name='hbr'
                    value={values.hbr}
                    onChange={handleChange}
                  />
                  <Input
                    label='AD'
                    type='number'
                    name='AD'
                    value={values.AD}
                    onChange={handleChange}
                  />

                </div>
              </div>
              <div style={{ marginLeft: '60px' }}>
                <Select
                  label='Вид обращения'
                  name='visitorder'
                  value={values?.visitorder}
                  onChange={handleChange}
                  options={typeOfAppeal}
                >
                  <option value=''>Выбрать</option>
                  {typeOfAppeal?.map(renderOption)}
                </Select>


                <div className={styles.checkboxRow}>
                  <Checkbox
                    label="Изолятор"
                    name='iso'
                    className={styles.checkbox}
                    value={values.iso}
                    onChange={handleChange}
                  />
                  {values.iso &&
                    <Input
                      type='datetime-local'
                      name='date_isolator'
                      value={values.date_isolator}
                      onChange={handleChange}
                    />
                  }
                </div>
                <div className={styles.checkboxRow}>
                  <Checkbox
                    label="Амбулаторное лечение"
                    name='amby'
                    className={styles.checkbox}
                    value={values?.amby}
                    onChange={handleChange}
                  />
                  {values.amby &&
                    <Input
                      type='datetime-local'
                      name='date_ambulatory'
                      value={values.date_ambulatory}
                      onChange={handleChange}
                    />
                  }
                </div>
                <div className={styles.checkboxRow}>
                  <Checkbox
                    label="В класс"
                    name='inClass'
                    className={styles.checkbox}
                    value={values?.inClass}
                    onChange={handleChange}
                  />
                  {values.inClass &&
                    <Input
                      type='datetime-local'
                      name='date_class'
                      value={values.date_class}
                      onChange={handleChange}
                    />
                  }
                </div>
                <div className={styles.inputsColumn}>
                  <div className={styles.checkboxRow}>
                    <Checkbox
                      label="Госпитализация"
                      name="ishospitalisation"
                      className={styles.checkbox}
                      value={values?.ishospitalisation}
                      onChange={handleChange}
                    />
                    {values.ishospitalisation &&
                      <>
                        <Input
                          type='datetime-local'
                          name='datehospitalisation'
                          value={values.datehospitalisation}
                          onChange={handleChange}
                        />
                      </>
                    }
                  </div>
                  {values.ishospitalisation &&
                    <div className={styles.inputsRow}>
                      <Select
                        label='Куда'
                        name='wherehospitalisation'
                        value={values?.wherehospitalisation}
                        onChange={handleChange}
                      // options={diseaseList}
                      >
                        <option value=''>Выбрать</option>
                        {diseaseList?.map(renderOption)}
                      </Select>
                      <Input
                        label='Отделение'
                        type='text'
                        name='parthospital'
                        value={values.parthospital}
                        onChange={handleChange}
                      />
                    </div>
                  }

                </div>
              </div>
            </div>
            <div className={styles.inputsColumn}>
              <Select
                label='Вид болезни'
                name='disease'
                value={values?.disease}
                onChange={handleChange}
                options={diseaseList}
              >
                <option value=''>Выбрать</option>
                {diseaseList?.map(renderOption)}
              </Select>
              <div style={{ display: "flex", flexDirection: 'row', marginTop: '20px' }}>

                <Button
                  onClick={handleOpenComplaint}
                >
                  Диагноз при поступлении (жалоба)
                </Button>
                {!!diagnosisComplaint &&
                  <div className={styles.diagnosis}>
                    <p>{diagnosisComplaint.name}</p>
                  </div>}
              </div>
              <div style={{ display: "flex", flexDirection: 'row', marginTop: '20px' }}>
                <Button
                  onClick={handleOpenMKB}
                >
                  Диагноз по справке (МКБ)
                  {/* selectedDiagnosis ВКАТИТЬ В ВАЛЬЮ ПОДКАКИМ ИМЕНЕМ */}
                </Button>
                {
                  selectedDiagnosis == 'select' ?
                    <div className={styles.diagnosisRed}>
                      <p>Обязательное поле</p>
                    </div>
                    : !!selectedDiagnosis ?
                      <div className={styles.diagnosis}>
                        <p>{`${selectedDiagnosis?.block_code} ${selectedDiagnosis?.name}`}</p>
                      </div> : ''

                }
              </div>
              <br />
              <Select
                label='Освобождение'
                name='iddischarge'
                value={values?.iddischarge}
                onChange={handleChange}
                option={discharge}
              >
                {[{ iddischarge: 0, discharge: 'Нет' }, ...discharge]?.map(renderOption)}
              </Select>
              {!!values.iddischarge && +values.iddischarge !== 0 &&
                <div style={{ display: "flex", flexDirection: 'row', }}>
                  <Input
                    label='C'
                    type='date'
                    name='dischargefrom'
                    value={values.dischargefrom}
                  // onChange={handleChange} 
                  />
                  <div style={{ width: '50px' }}></div>
                  <Input
                    label='По'
                    type='date'
                    name="dischargeto"
                    value={values.dischargeto}
                  // onChange={handleChange} 
                  />
                </div>
              }
              <div className={styles.inputsRow}>

                <Textarea
                  style={{ resize: 'none', width: '550px', height: '140px' }}
                  label='Рекомендации'
                  name='prim'
                  value={values.prim}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div className={styles.buttonBox}>
            <Button
              // onClick={handleCreateVisit}
              onClick={handleSubmit}
              style={{}}
            >
              Сохранить
            </Button>
            <Button
              onClick={close}
              style={{}}
            >
              Закрыть
            </Button>
          </div>

        </Modal>
      }
      {openComplain &&
        <Modal
          open={openComplain}
          closeModal={close}
          onClose={() => false}
          showCloseIcon={false}
          center
          classNames={{
            // overlay: styles.overlay,
            modal: styles.customComplaint
          }}
        >
          <Complaint diagnosisComplaintList={diagnosisComplaintList} setDiagnosisComplaint={setDiagnosisComplaint} closeComplain={closeComplain} />
          <div>

            <Button
              onClick={closeComplain}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              Закрыть
            </Button>
          </div>
        </Modal>
      }
      {openMKB &&
        <Modal
          open={openMKB}
          closeModal={close}
          onClose={() => false}
          showCloseIcon={false}
          center
          classNames={{
            // overlay: styles.overlay,
            modal: styles.customMKB
          }}
        >
          <div className={styles.inputsRow}>


            <Button
              onClick={closeMKB}
              style={{ position: 'sticky', top: '10px', zIndex: '100' }}
            >
              Закрыть
            </Button>

            <MKBten setSelectedDiagnosis={setSelectedDiagnosis} modal />
          </div >

        </Modal>
      }
    </>
  )
}
