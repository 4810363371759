import {BACKEND_URL_PREFIX} from "../assets/const";
import {createNotification} from "../components/Notifications/Notifications";
import {checkAndUpdateToken} from "./auth";
import {buildDefaultHeaders} from "./helper";
import {
    GET_EXAM_BOARD_LIST, 
    SET_EXAM_BOARD_LIST_LOADING,
    SET_EXAM_BOARD_BY_ID,
    GET_EXAM_BOARD_YEARS,
    GET_EXAM_BOARD_KURSES,
    SET_EXAM_BOARD_TRAJECTORIES_BY_ID,
    SET_EXAM_BOARD_INTERVIEWS_BY_ID,
    SET_EXAM_BOARD_INTERVIEWERS_BY_ID,
    SET_EXAM_BOARD_INTERVIEWERS_ALL
} from "./types";

export const getExamBoardList = (filters) => async(dispatch, getState) => {
    dispatch({type: SET_EXAM_BOARD_LIST_LOADING, payload: true});
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/exam_board/${filters.school_year}/${filters.stream}/${filters.kurs}`, {
            method: "GET",
            ...buildDefaultHeaders(getState)
        });
        if (response.ok) {
            const result = (await response.json());
            dispatch({type: GET_EXAM_BOARD_LIST, payload: result});
        } else {
            dispatch({type: GET_EXAM_BOARD_LIST, payload: []});
        }
    }
    dispatch({type: SET_EXAM_BOARD_LIST_LOADING, payload: false});
};

export const updateExamBoard = (data) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {

        const response = await fetch(`${BACKEND_URL_PREFIX}/exam_board/${data.id}`, {
            method: "PUT",
            body: JSON.stringify(data),
            ...buildDefaultHeaders(getState)
        });
        if (response.ok) {
            await dispatch(getExamBoardList({school_year: data.school_year, stream: data.stream, kurs: data.kurs}));
            createNotification('success', 'Успешно!');
        }
    };
}

export const createExamBoard = (data) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/exam_board`, {
            method: "POST",
            body: JSON.stringify(data),
            ...buildDefaultHeaders(getState)
        });
        if (response.ok) {
            createNotification('success', 'Успешно');
            await dispatch(getExamBoardList({school_year: data.school_year, stream: data.stream, kurs: data.kurs}));
        } else 
            createNotification('error', "Не удалось создать запись");
        }
    };

export const deleteExamBoard = (id, filters) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/exam_board/${id}`, {
            method: "DELETE",
            ...buildDefaultHeaders(getState)
        });
        const result = await response.json();

        if (response.ok) {
            createNotification('success', 'Успешно');
            await dispatch(getExamBoardList(filters));
        } else 
            createNotification('error', result.error);
        }
    };

    export const updateExamBoardAddress = (city_id, params) => async(dispatch, getState) => {
        await dispatch(checkAndUpdateToken());
        if (getState().auth.token) {
    
            const response = await fetch(`${BACKEND_URL_PREFIX}/exam_board/address/${params.id}`, {
                method: "PUT",
                body: JSON.stringify(params),
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                await dispatch(getExamBoardItem(city_id));
                createNotification('success', 'Успешно!');
            }
        };
    }
    
    export const createExamBoardAddress = (city_id, data) => async(dispatch, getState) => {
        await dispatch(checkAndUpdateToken());
        if (getState().auth.token) {
            const response = await fetch(`${BACKEND_URL_PREFIX}/exam_board/${city_id}/address`, {
                method: "POST",
                body: JSON.stringify(data),
                ...buildDefaultHeaders(getState)
            });
            if (response.ok) {
                createNotification('success', 'Успешно');
                await dispatch(getExamBoardItem(city_id));
            } else 
                createNotification('error', "Не удалось создать запись");
        }
    };
    
    export const deleteExamBoardAddress = (id, city_id) => async(dispatch, getState) => {
        await dispatch(checkAndUpdateToken());
        if (getState().auth.token) {
            const response = await fetch(`${BACKEND_URL_PREFIX}/exam_board/address/${id}`, {
                method: "DELETE",
                ...buildDefaultHeaders(getState)
            });
            const result = await response.json();
    
            if (response.ok) {
                createNotification('success', 'Успешно');
                await dispatch(getExamBoardItem(city_id));
            } else 
                createNotification('error', result.error);
            }
        };

export const getExamBoardItem = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/exam_board/${id} `, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());

      dispatch({ type: SET_EXAM_BOARD_BY_ID, payload: result });
    }
  }
};

export const getExamBoardItemTrajectories = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/xp_trajectories`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());

      dispatch({ type: SET_EXAM_BOARD_TRAJECTORIES_BY_ID, payload: result });
    }
  }
};

export const getExamBoardItemInterviews = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/exam_board/interviews/${id} `, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());

      dispatch({ type: SET_EXAM_BOARD_INTERVIEWS_BY_ID, payload: result });
    }
  }
};

export const getExamBoardItemInterviewers = (id) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/exam_board/interviewers/${id} `, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const result = (await response.json());

      dispatch({ type: SET_EXAM_BOARD_INTERVIEWERS_BY_ID, payload: result });
    }
  }
};

export const getExamBoardItemInterviewersAll = (id) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
      const response = await fetch(`${BACKEND_URL_PREFIX}/exam_board/add_interviewers/${id}`, {
        method: "GET",
        ...buildDefaultHeaders(getState),
      });
      if (response.ok) {
        const result = (await response.json());
  
        dispatch({ type: SET_EXAM_BOARD_INTERVIEWERS_ALL, payload: result });
      }
    }
  };

export const editExamBoardItemInterviewers = (board_member_id, mid, data, itemId) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/exam_board/interviewers/${board_member_id}/${mid}`, {
            method: "PUT",
            body: JSON.stringify(data),
            ...buildDefaultHeaders(getState)
        });
        if (response.ok) {
            createNotification('success', 'Успешно');
            await dispatch(getExamBoardItemInterviewers(itemId));
        } else 
            createNotification('error', "Не удалось создать запись");
    }
};

export const editExamBoardItemInterviewersRole = (board_member_id, data) => async(dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/exam_board/interviewers/${board_member_id}`, {
            method: "PUT",
            body: JSON.stringify(data),
            ...buildDefaultHeaders(getState)
        });
        if (response.ok) {
            createNotification('success', 'Успешно');
            await dispatch(getExamBoardItemInterviewers(board_member_id));
        } else 
            createNotification('error', "Не удалось создать запись");
    }
};



//FILTERS

export const getYears = () => async(dispatch, getState) => { 
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/school_year`, {
            method: "GET",
            ...buildDefaultHeaders(getState)
        });
        if (response.ok) {
            const result = (await response.json()).school_year;
            dispatch({type: GET_EXAM_BOARD_YEARS, payload: result});
        } else {
            dispatch({type: GET_EXAM_BOARD_YEARS, payload: []});
        }
    }
};

export const getKurses = (kursID) => async(dispatch, getState) => { 
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        const response = await fetch(`${BACKEND_URL_PREFIX}/exam_board/kurs/${kursID}`, {
            method: "GET",
            ...buildDefaultHeaders(getState)
        });
        if (response.ok) {
            const result = (await response.json());
            dispatch({type: GET_EXAM_BOARD_KURSES, payload: result});
        } else {
            dispatch({type: GET_EXAM_BOARD_KURSES, payload: []});
        }
    }
};
//FILTERS