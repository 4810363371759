import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { IoIosAdd, IoIosRefresh } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Modal from './Modal'
import Table from '../../components/Table'
import { BiTrash } from 'react-icons/bi'
import Confirmation from '../../components/Confirmation/Confirmation'
import { createJournalControlElement, createSubgroup, deleteManners, getJournalControl, getJournalControlCourses, getMannersList, getSubgroupById, getSubgroupCoursesList, getSubgroupList, getSubgroupTablesList, updateJournalControlElement, updateSubgroup, } from '../../actions/directoryBasicEducation'
import Select from '../../components/Select copy'
import { getCurrentTerm, getTermsList } from '../../actions/terms'
import { getSchoolYearsList } from '../../actions/schoolYears'
import { getLesson } from '../../actions/classbook'
import { Checkbox } from '../../components/ModalInputs'
import SelectPal from '../../components/Select copy'
import { useFormik } from 'formik'
import { arrayForSelect } from '../../utils/arrayForSelect'
import { getCLasses } from '../../actions/common'


export default function Subgroups() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { tableList, subgroup, subgroupClasses, subgroupCourses } = useSelector(state => state.directoryBasicEducation)

  const { list: yearList } = useSelector(state => state.school_years)
  const { list: termList } = useSelector(state => state.terms)
  const { lessons } = useSelector(state => state.classbook)
  const { classes } = useSelector(state => state.common)

  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(false);
  const [electiveCourse, setElectiveCourse] = useState(false);
  const [statusClose, setStatusClose] = useState(false);
  /// не совпадают содержание и найминг 
  const [data, setData] = useState();
  const [classSel, setClassSel] = useState();
  const [scYear, setScYear] = useState()
  const [termSel, setTermSel] = useState()


  const {
    values,
    setFieldValue,
  } = useFormik({
    initialValues: {},
    enableReinitialize: true,
  })

  console.log('values::: ', values);

  useEffect(() => {
    dispatch(getSchoolYearsList())
  }, [])

  useEffect(() => {
    values.xp_key && !values.cid && dispatch(getSubgroupCoursesList(values.xp_key))
    values.xp_key && values.cid && dispatch(getSubgroupList(values.xp_key, values.cid))
    // values.xp_key && values.cid && values.gid && dispatch(getSubgroupTablesList(values.xp_key, values.cid, values.gid))
  }, [values.xp_key, values.cid])

  const openModal = (data) => () => {
    setSelectedItem(data || {})
    // termSel && classSel && dispatch(getJournalControl({ trmid: termSel, gid: classSel, cid: data?.jmid }))
    dispatch(getSubgroupById(data))
  }

  const closeModal = () => setSelectedItem(null)

  const goHome = () => navigate('/')

  const acceptConfirmation = () => {
    closeModal();
    dispatch(deleteManners(confirmation.id));
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  const renderLi = (data) => {
    const { course_title, group_name, teachers, track_profile_name, shortname, count, gid } = data

    return (
      <tr >
        <td onClick={openModal(data)}>{group_name}</td>
        <td onClick={openModal(data)}>{shortname}</td>
        <td onClick={openModal(data)}>{course_title}</td>
        <td onClick={openModal(data)}>{track_profile_name}</td>
        <td onClick={openModal(data)}>{count}</td>
        <td onClick={openModal(data)}>{teachers}</td>
        <td><i className={styles.trash} onClick={() => setConfirmation({ id: gid, name: course_title })}><BiTrash /></i></td>
      </tr>
    )
  }



  const handleInputChange = (data) => {
    const { name, value, begdate, xp_key, term_end, term_begin, trmid, fio, mid, gid } = data

    if (xp_key) setScYear(xp_key)
    if (trmid) setTermSel(trmid)
    if (gid) setClassSel(gid)
    // const {name, value, xp_key, } = e
    // setData({
    //     ...data,
    //     [name]: value
    // })
  }

  const handleUpdateTable = () => {
    console.log('values::: ', values);
    dispatch(getSubgroupTablesList(values))
    closeModal()
  }


  return (
    <div className={styles.wrapper} style={{ width: '1200px', margin: '0 auto' }}>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
          acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      {selectedItem &&
        <Modal
          closeModal={closeModal}
          data={selectedItem}
          classGroup={data}
          height={'430px'}
          electiveCourse={electiveCourse}
          addMode={!selectedItem.gid}
          filters={values}
        // handleSubmit={handleSubmit}
        // handleCreate={handleCreate}
        // handleUpdate={handleUpdate}
        />
      }
      <h1>Подгруппы</h1>

      <div className={styles.line}>
        <SelectPal
          title="Учебный год"
          valuesName="xp_key"
          value={values.xp_key}
          options={yearList && arrayForSelect(yearList, 'name', 'xp_key')}
          style={{ width: '120px' }}
          setFieldValue={setFieldValue}
        />
        {!electiveCourse &&
          <>
            <SelectPal
              title="Предмет"
              valuesName="cid"
              value={values.cid}
              options={subgroupCourses && arrayForSelect(subgroupCourses, 'title', 'cid')}
              style={{ width: '120px' }}
              setFieldValue={setFieldValue}
            />
            <SelectPal
              title="Класс"
              valuesName="gid"
              value={values.gid}
              options={subgroupClasses && arrayForSelect(subgroupClasses, 'name', 'gid')}
              style={{ width: '120px' }}
              setFieldValue={setFieldValue}
            />
          </>
        }

        <div className={styles.checkbox}>
          <Checkbox onClick={() => setElectiveCourse(!electiveCourse)} label="Элективный курс" />
          <Checkbox onClick={() => setStatusClose()} label="Показать предметы со статусом 'Закрыт'" />
        </div>
        <Button
          style={{ padding: '.5rem 1.5rem', margin: '12px' }}
          onClick={handleUpdateTable}
        >
          <IoIosRefresh size={30} />
          Обновить
        </Button>

      </div>


      {/* {false && */}

      <Table className={styles.tables}>
        <thead>
          <tr>
            <th>Название</th>
            <th>Короткое название</th>
            <th>Предмет</th>
            <th>Профиль обучения</th>
            <th>Количество слушателей</th>
            <th>Преподаватель</th>
            <th>Удалить</th>
          </tr>
        </thead>
        <tbody>

          {console.log('tableList::: ', tableList)}
          {tableList && tableList.map(renderLi)}
        </tbody>
      </Table>
      {/* } */}

      <div style={{ display: 'flex', flexDirection: "row" }}>
        <Button
          style={{ padding: '.5rem 1.5rem', margin: '12px' }}
          onClick={openModal()}
        >
          <IoIosAdd size={30} />
          Добавить
        </Button>
      </div>
    </div >
  )
}
