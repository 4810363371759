import { useState } from 'react'
import {  Main, Family, SpecNotes, Anthropometry, Vaccinations, Appeals } from './ModalTabs'
// import {  Main  } from '../Students/ModalTabs'
import Modal from 'react-responsive-modal'
import { candidatesModalModes, medCardsModalModes } from '../../assets/const'
import styles from './styles.module.scss'
import userPhoto from '../../assets/images/user_photo.png'
import cn from 'classnames'
import { CandidatesContext } from '../../utils/context'

export default function ModalComponent({ student, isOpen, closeModal, studentData }) {

    const [mode, setMode] = useState('main')

    const renderLi = ({ name, value, icon }) => {
        const selected = value === mode
        return (
            <li
                onClick={() => setMode(value)}
                className={cn(styles.modalMenuItem, {
                    [styles.selectedMenuItem]: selected
                })}
            >
                {icon()}
                {name}
            </li>
        )
    }

    const renderTab = () => {
        switch (mode) {
            case 'main': return <Main />
            case 'family': return <Family />
            case 'appeals': return <Appeals />
            case 'vaccinations': return <Vaccinations />
            case 'anthropometry': return <Anthropometry />
            case 'specNotes': return <SpecNotes />
            default: return
        }
    }

    return (
        <CandidatesContext.Provider value={{ student, closeModal, studentData }}>
            <Modal
                open={isOpen}
                classNames={{ modal: styles.modalMain }}
                closeOnOverlayClick={false}
                closeOnEsc={false}
                showCloseIcon={false}
                animationDuration={1}
                center
            >
                <div className={styles.modalContent}>
                    <div className={styles.modalMenu}>
                        <img src={userPhoto} alt='o_O' />
                        <ul>{medCardsModalModes.map(renderLi)}</ul>
                    </div>
                    {renderTab()}
                </div>
            </Modal>
        </CandidatesContext.Provider>
    )
}