import { api } from "../App";
import { SET_ACT_TYPES_LIST, SET_ACT_TYPES_LOADING } from "./types";




export const getActTypes = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_ACT_TYPES_LOADING, payload: true });
    const { data } = await api.get('/acttype');
    dispatch({ type: SET_ACT_TYPES_LIST, payload: data });
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_ACT_TYPES_LOADING, payload: false });
  }
}

export const createActType = (data) => async (dispatch, getState) => {
  let success
  try {
    dispatch({ type: SET_ACT_TYPES_LOADING, payload: true });
    await api.post('/acttype', data);
    await dispatch(getActTypes())
    success = true
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_ACT_TYPES_LOADING, payload: false });
    return success
  }
}

export const updateActType = (id, data) => async (dispatch, getState) => {
  let success
  try {
    dispatch({ type: SET_ACT_TYPES_LOADING, payload: true });
    await api.put(`/acttype/${id}`, data);
    await dispatch(getActTypes())
    success = true
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_ACT_TYPES_LOADING, payload: false });
    return success
  }
}

export const deleteActType = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_ACT_TYPES_LOADING, payload: true });
    await api.delete(`/acttype/${id}`);
    await dispatch(getActTypes())
  } catch (ERR) {
  } finally {
    dispatch({ type: SET_ACT_TYPES_LOADING, payload: false });
  }
}