import React, { useEffect, useState } from 'react'
import styles from './styles.module.css'
import ModalFooter from '../../../components/ModalFooter/ModalFooter'
import Modal from 'react-responsive-modal';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/Button';
import Main from './Tabs/Main';
import Parameters from './Tabs/Parameters';

export default function ModalComponent({ modalOpen, closeModal, handleSubmit, setModalOpen, handleChange, values, error }) {

  const { listUMB, listModal, tfo_base_obj, tfo_types, loading } = useSelector(state => state.directoryObjectsUMB)

  const { list } = useSelector(state => state.roomTypes);
  const { teachers } = useSelector(state => state.common);

  const [activeTab, setActiveTab] = useState(1); // 1 - Main, 2 - Equipment


  useEffect(() => {
    setActiveTab(1)
  }, [modalOpen])


  const handleTab = (tab) => {
    // handleSubmit()
    setActiveTab(tab);
  }

  const getTab = (selectedTab) => {
    switch (selectedTab) {
      case 1: return <Main
        teachers={teachers}
        // roomTypes={list}
        tfo_base_obj={tfo_base_obj}
        tfo_types={tfo_types}
        modalOpen={modalOpen}
        closeModal={closeModal}
        // handleSubmit={handleSubmit}  
        setModalOpen={setModalOpen}
        handleChange={handleChange}
        values={values}
        error={error}
      />
      // case 2: return <Equipment modalOpen={modalOpen}/>
      case 2: return <Parameters
        modalOpen={modalOpen}
        tfo_base_obj={tfo_base_obj}
        tfo_types={tfo_types}
        closeModal={closeModal}
        // handleSubmit={handleSubmit}  
        setModalOpen={setModalOpen}
        handleChange={handleChange}
        values={values}
        error={error} />
    }
  }

  return (
    <Modal
      close={closeModal}
      open={modalOpen}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      showCloseIcon={false}
      animationDuration={1}
      classNames={{
        modal: styles.roomTypesModal
      }}
      style={{
        padding: '0',
        margin: '0'
      }}>

      <div className={styles.modalMain}>
        <div className={styles.tabWrapper}>
          <div className={styles.title}>{modalOpen
            ?.rid
            ? 'Редактирование'
            : 'Добавление'}</div>
          <div className={styles.tabs}>
            <Button mode={activeTab === 1 ? '' : 'white'} onClick={() => handleTab(1)}>Основная</Button>
            <Button mode={activeTab === 2 ? '' : 'white'} onClick={() => handleTab(2)}>Параметры</Button>
          </div>
          {getTab(activeTab)}
        </div>
      </div>
      <div className={styles.modalFooter}><ModalFooter isShowInfo={false} handleSubmit={handleSubmit} closeModal={closeModal} /></div>
    </Modal>
  )
}
