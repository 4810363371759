import { api } from "../App";
import { itemsOnPage } from "../assets/const";
import { SET_EVENTS_LIST } from "./types";




export const getEventsList = () => async (dispatch, getState) => {
  try {
    // dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: true });
    const limit = itemsOnPage
    // const offset = itemsOnPage * 1  // TODO запрос возвращает пустой массив
    const offset = 0
    const urlParams = new URLSearchParams({ limit, offset }).toString();
    const { data } = await api.get(`/events_news?${urlParams}`);
    dispatch({ type: SET_EVENTS_LIST, payload: data });
  } catch (ERR) {
  } finally {
    // dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: false });
  }
}

// export const createEventLevel = (data) => async (dispatch, getState) => {
//   let success
//   try {
//     dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: true });
//     await api.post('/news_levels', data);
//     await dispatch(getEventLevels())
//     success = true
//   } catch (ERR) {
//   } finally {
//     dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: false });
//     return success
//   }
// }

// export const updateEventLevel = (id, data) => async (dispatch, getState) => {
//   let success
//   try {
//     dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: true });
//     await api.put(`/news_levels/${id}`, data);
//     await dispatch(getEventLevels())
//     success = true
//   } catch (ERR) {
//   } finally {
//     dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: false });
//     return success
//   }
// }

// export const deleteEventLevel = (id) => async (dispatch, getState) => {
//   try {
//     dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: true });
//     await api.delete(`/news_levels/${id}`);
//     await dispatch(getEventLevels())
//   } catch (ERR) {
//   } finally {
//     dispatch({ type: SET_EVENT_LEVELS_LOADING, payload: false });
//   }
// }