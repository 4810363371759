import React, { useEffect, useState } from 'react'
import Modal from 'react-responsive-modal'
import { useDispatch, useSelector } from 'react-redux'
import styles from './styles.module.css'
import { Select } from '../../../components/ModalInputs'
import * as Yup from 'yup'
import { getSpecMedGroupsGroups, getSpecMedGroupsStudents, } from '../../../actions/medCenter/specMedGroups'

import Table from '../../../components/Table'
import Button from '../../../components/Button'

const validationSchema = Yup.object({
  name: Yup.string().required('обязательное поле').max(30, 'Должно быть не более 30 символов'),

})

export default function ModalComponent({ data, closeModal, height, handleChangeStudents, selectedStudents }) {

  const dispatch = useDispatch()
  const { specMedGroupsStudentList, specMedGroupsGroupsList } = useSelector(state => state.directoryMedCenter)

  const [selected, setSelected] = useState(null)

  useEffect(() => {
    dispatch(getSpecMedGroupsStudents(selected))
    !selected && dispatch(getSpecMedGroupsGroups())
  }, [selected])

  const renderLi = (data) => {
    const { student, mid } = data
    return (
      <tr>
        <td
          onClick={() => handleChangeStudents(data)}
          style={{ backgroundColor: selectedStudents?.find(el => (el.mid == mid)) ? '#3689FF' : '' }}
        >
          {student}
        </td>
      </tr>
    )
  }


  const handleChange = (e) => {
    setSelected(
      { [e.target.name]: e.target.value }
    )
  }

  const handleSubmit = () => {
    console.log('handleSubmit::: ',);
  }
  return (
    <Modal
      open={!!data}
      onClose={() => false}
      showCloseIcon={false}
      height={height}
      classNames={{
        // overlay: styles.overlay,
        modal: styles.custom
      }}
    >
      <>
        {/* {openEnrollModal &&
          <EnrollModal
            closeModal={closeModal}
            data={selectedItem}
            height={'430px'}
            // addMode={(!selectedItem?.name && selectedItem !== null)}
            addMode={selectedItem == 'add'}
          />
        } */}
        <div className={styles.modalLocal} >
          <div className={styles.tabWrapper} >


            <div className={styles.inputsRow}>
              <Select
                label='Класс'
                value={selected?.gid}
                onChange={(e) => handleChange(e)}
                name='gid'
                style={{ width: '300px' }}
              >
                {specMedGroupsGroupsList && [{ name: 'Выберите класс', gid: 0 }, ...specMedGroupsGroupsList]?.map(({ name, gid }) => <option key={gid} value={gid}>{name}</option>)}
              </Select>
            </div>

            <Table className={styles.table} >
              <thead>
                <tr>
                  <th>Ученица</th>
                </tr>
              </thead>
              <tbody>

                {specMedGroupsStudentList?.map(renderLi)}

              </tbody>
            </Table>



          </div>
        </div>
        <div className={styles.inputsRow}>
          <Button style={{ margin: '0 auto 20px' }} onClick={closeModal}>Сохранить</Button>
          <Button style={{ margin: '0 auto 20px' }} onClick={closeModal}>Отменить</Button>
        </div>

        {/* <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} /> */}
      </>
    </Modal>

  )
}
