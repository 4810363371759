export const AUTH_COMPLETED = 'AUTH_COMPLETED'
export const AUTH_ERROR = 'AUTH_ERROR'
export const SIGNED_OUT = 'SIGNED_OUT'
export const SET_AUTH_USER_INFO = 'SET_AUTH_USER_INFO'
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING'

export const GET_INFO_MAIN = 'GET_INFO_MAIN'

export const GET_LESSON_CHART = 'GET_LESSON_CHART'
export const GET_CLASS_TOPIC = 'GET_CLASS_TOPIC'
export const GET_EVENT_TOOLS = 'GET_EVENT_TOOLS'

export const SET_ROOMS = 'SET_ROOMS'
export const SET_ROOMS_LOAD = 'SET_ROOMS_LOAD'

export const SET_DIRECTORY_OBJECTS_UMB = 'SET_DIRECTORY_OBJECTS_UMB'
export const SET_DIRECTORY_MODAL_LIST = 'SET_DIRECTORY_MODAL_LIST'
export const SET_DIRECTORY_OBJECTS_UMB_LOAD = 'SET_DIRECTORY_OBJECTS_UMB_LOAD'
export const SET_TFO_BASE_OBJ_LIST = 'SET_TFO_BASE_OBJ_LIST'

export const SET_TFO_LIST = 'SET_TFO_LIST'
export const SET_TFO_TYPES_LIST = 'SET_TFO_TYPES_LIST'

export const GET_PLANNERF_LIST = 'GET_PLANNERF_LIST'
export const GET_HOMEWORKF_LIST = 'GET_HOMEWORKF_LIST'

export const GET_PLANNER_LIST = 'GET_PLANNER_LIST'
export const GET_HOMEWORK_LIST = 'GET_HOMEWORK_LIST'

export const SET_PERMISSION_LOADING = 'SET_PERMISSION_LOADING'
export const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS'
export const SET_PERMISSION_GROUPS = 'SET_PERMISSION_GROUPS'
export const SET_PERMISSION_GROUP_USERS = 'SET_PERMISSION_GROUP_USERS'

export const SET_TEACHERS_BY_GROUP_ID = 'SET_TEACHERS_BY_GROUP_ID'
export const SET_TEACHERS_BY_GROUP_ID_LOADING = 'SET_TEACHERS_BY_GROUP_ID_LOADING'

export const SET_CHAPTERS_LIST = 'SET_CHAPTERS_LIST'
export const SET_ACCESS_ENTITY = 'SET_CHAPTER_ENTITY'
export const SET_ACCESS_GROUP_DATA = 'SET_CHAPTER_GROUP_DATA'
export const SET_ACCESS_LOADING = 'SET_CHAPTERS_LOADING'

export const SET_GROUPS_DATA = 'SET_GROUPS_DATA'
export const SET_GROUP_INFO = 'SET_GROUP_INFO'
export const SET_GROUP_HISTORY = 'SET_GROUP_HISTORY'
export const SET_GROUP_TEAM = 'SET_GROUP_TEAM'
export const SET_GROUP_CURATORS = 'SET_GROUP_CURATORS'
export const SET_GROUPS_MODAL_LOADING = 'SET_GROUPS_MODAL_LOADING'
export const SET_GROUPS_LOADING = 'SET_GROUPS_LOADING'
export const SET_CLASSING_PAGE = 'SET_CLASSING_PAGE'
export const SET_CLASSING_COUNT = 'SET_CLASSING_COUNT'

export const SET_SCHOOL_YEARS_LIST = 'SET_SCHOOL_YEARS_LIST'
export const SET_SCHOOL_YEARS_COUNT = 'SET_SCHOOL_YEARS_COUNT'
export const SET_SCHOOL_YEARS_PAGE = 'SET_SCHOOL_YEARS_PAGE'
export const SET_CURRENT_SCHOOL_YEAR = 'SET_CURRENT_SCHOOL_YEAR'
export const SET_SCHOOL_YEARS_LOADING = 'SET_SCHOOL_YEARS_LOADING'

export const SET_SCHOOL_WEEKS_LIST = 'SET_SCHOOL_WEEKS_LIST'
export const SET_SCHOOL_WEEKS_COUNT = 'SET_SCHOOL_WEEKS_COUNT'
export const SET_SCHOOL_WEEKS_PAGE = 'SET_SCHOOL_WEEKS_PAGE'
export const SET_CURRENT_SCHOOL_WEEK = 'SET_CURRENT_SCHOOL_WEEK'
export const SET_SCHOOL_WEEKS_LOADING = 'SET_SCHOOL_WEEKS_LOADING'

export const SET_SCHEDULE_VARIANTS_LIST = 'SET_SCHEDULE_VARIANTS_LIST'
export const SET_SCHEDULE_VARIANTS_COUNT = 'SET_SCHEDULE_VARIANTS_COUNT'
export const SET_SCHEDULE_VARIANTS_PAGE = 'SET_SCHEDULE_VARIANTS_PAGE'
export const SET_CURRENT_SCHEDULE_VARIANT = 'SET_CURRENT_SCHEDULE_VARIANT'
export const SET_SCHEDULE_VARIANTS_LOADING = 'SET_SCHEDULE_VARIANTS_LOADING'


export const GET_SCHEDULE_CURRENT = 'GET_SCHEDULE_CURRENT'
export const GET_SCHEDULE_WEEKS_LIST = 'GET_SCHEDULE_WEEKS_LIST'
export const SET_SCHEDULE_CURRENT_LOADING = 'SET_SCHEDULE_CURRENT_LOADING'
export const GET_SCHEDULE_CLASS_LIST = 'GET_SCHEDULE_CLASS_LIST'
export const GET_SCHEDULE_CURRENT_NEW = 'GET_SCHEDULE_CURRENT_NEW'
export const SET_SCHEDULE_CURRENT_NEW_LOADING = 'SET_SCHEDULE_CURRENT_NEW_LOADING'

export const SET_TERMS_LIST = 'SET_TERMS_LIST'
export const SET_TERMS_COUNT = 'SET_TERMS_COUNT'
export const SET_TERMS_PAGE = 'SET_TERMS_PAGE'
export const SET_CURRENT_TERM = 'SET_CURRENT_TERM'
export const SET_TERMS_LOADING = 'SET_TERMS_LOADING'

export const SET_PERIODS_LIST = 'SET_PERIODS_LIST'
export const SET_PERIODS_COUNT = 'SET_PERIODS_COUNT'
export const SET_PERIODS_PAGE = 'SET_PERIODS_PAGE'
export const SET_CURRENT_PERIOD = 'SET_CURRENT_PERIOD'
export const SET_PERIODS_LOADING = 'SET_PERIODS_LOADING'

export const SET_COURSES_LIST = 'SET_COURSES_LIST'
export const SET_COURSES_COUNT = 'SET_COURSES_COUNT'
export const SET_COURSES_PAGE = 'SET_COURSES_PAGE'
export const SET_CURRENT_COURSE = 'SET_CURRENT_COURSE'
export const SET_COURSES_LOADING = 'SET_COURSES_LOADING'

export const SET_EVENT_LEVELS_LIST = 'SET_EVENT_LEVELS_LIST'
export const SET_EVENT_LEVELS_LOADING = 'SET_EVENT_LEVELS_LOADING'

// export const SET_EVENT_TYPES_LIST = 'SET_EVENT_TYPES_LIST'
// export const SET_EVENT_TYPES_LOADING = 'SET_EVENT_TYPES_LOADING'


export const SET_COMMON_LOADING = 'SET_COMMON_LOADING'
export const SET_COMMON_TEACHERS_LIST = 'SET_TEACHERS_LIST'
export const SET_COMMON_SCHOOL_YEARS = 'SET_SCHOOL_YEARS'
export const SET_COMMON_SPECIALIZATIONS = 'SET_SPECIALIZATIONS'
export const SET_COMMON_LANGUAGES = 'SET_LANGUAGES'
export const SET_COMMON_STUDY_PROFILES = 'SET_STUDY_PROFILES'
export const SET_COMMON_STUDY_MODES = 'SET_STUDY_MODES'
export const SET_COMMON_STUDY_FORMS = 'SET_STUDY_FORMS'
export const SET_COMMON_STUDY_STEPS = 'SET_STUDY_STEPS'
export const SET_COMMON_SHIFTS = 'SET_SHIFTS'
export const SET_COMMON_COUNTRIES = 'SET_COMMON_COUNTRIES'
export const SET_COMMON_SUBJECTS = 'SET_COMMON_SUBJECTS'
export const SET_COMMON_SCHOOLS = 'SET_COMMON_SCHOOLS'
export const SET_COMMON_FAMILY_STATUSES = 'SET_COMMON_FAMILY_STATUSES'
export const SET_COMMON_FAMILY_COMPOSITION = 'SET_COMMON_FAMILY_COMPOSITION'
export const SET_COMMON_TERM_TYPES = 'SET_COMMON_TERM_TYPES'
export const SET_COMMON_TERM_GRADE_TYPES = 'SET_COMMON_TERM_GRADE_TYPES'
export const SET_COMMON_ADMINISTRATION = 'SET_COMMON_ADMINISTRATION'
export const SET_COMMON_PARALLELS = 'SET_COMMON_PARALLELS'
export const SET_COMMON_CLASSES = 'SET_COMMON_CLASSES'
export const SET_COMMON_SCHEDULE_VARIANTS = 'SET_COMMON_SCHEDULE_VARIANTS'
export const SET_COMMON_EXAM_TYPES = 'SET_COMMON_EXAM_TYPES'
export const SET_COMMON_COURSES = 'SET_COMMON_COURSES'

export const SET_CANDIDATES_LIST = 'SET_CANDIDATES_LIST'
export const SET_CANDIDATES_DATA = 'SET_CANDIDATES_DATA'
export const SET_CANDIDATES_ADDRESS = 'SET_CANDIDATES_ADDRESS'
export const SET_CANDIDATES_BENEFITS = 'SET_CANDIDATES_BENEFITS'
export const SET_CANDIDATES_ENTRANCE = 'SET_CANDIDATES_ENTRANCE'
export const SET_CANDIDATES_MEDICINE_INFO = 'SET_CANDIDATES_MEDICINE_INFO'
export const SET_CANDIDATES_ADDITIONAL_EDUCATION = 'SET_CANDIDATES_ADDITIONAL_EDUCATION'
export const SET_CANDIDATES_DOCUMENTS = 'SET_CANDIDATES_DOCUMENTS'
export const SET_CANDIDATES_INFO = 'SET_CANDIDATES_INFO'
export const SET_CANDIDATES_PARENTS = 'SET_CANDIDATES_PARENTS'
export const SET_CANDIDATES_FORM = 'SET_CANDIDATES_FORM'
export const SET_CANDIDATES_EXAM_CITY = 'SET_CANDIDATES_EXAM_CITY'
export const SET_CANDIDATES_LANGUAGES = 'SET_CANDIDATES_LANGUAGES'
export const SET_CANDIDATES_LOADING = 'SET_CANDIDATES_LOADING'

export const SET_REPORTS_LIST = 'SET_REPORTS_LIST'
export const SET_REPORTS_PARAMS = 'SET_REPORTS_PARAMS'
export const SET_REPORTS_LOADING = 'SET_REPORTS_LOADING'

export const SET_REPORT_GROUPS_LIST = 'SET_REPORT_GROUPS_LIST'
export const SET_REPORT_GROUPS_DATA = 'SET_REPORT_GROUPS_DATA'
export const SET_REPORT_GROUPS_LOADING = 'SET_REPORT_GROUPS_LOADING'


export const GET_CLASSBOOK_STUDY_PERIOD = 'GET_CLASSBOOK_STUDY_PERIOD'
export const SET_CLASSBOOK_LOADING = 'SET_CLASSBOOK_LOADING'
export const GET_CLASSBOOK_LESSON = 'GET_CLASSBOOK_LESSON'
export const GET_CLASSBOOK_CLASS = 'GET_CLASSBOOK_CLASS'
export const GET_CLASSBOOK_GRADE_VALUE = 'GET_CLASSBOOK_GRADE_VALUE'
export const GET_CLASSBOOK = 'GET_CLASSBOOK'

export const GET_PERSONNEL_LIST = 'GET_PERSONNEL_LIST'
export const SET_PERSONNEL_LOADING = 'SET_PERSONNEL_LOADING'
export const GET_PERSON = 'GET_PERSON'
export const GET_PERSON_TABLE = 'GET_PERSON_TABLE'

export const GET_METHOD_OFFICE_CYCLE = 'GET_METHOD_OFFICE_CYCLE'
export const GET_METHOD_OFFICE_ROLES = 'GET_METHOD_OFFICE_ROLES'
export const GET_METHOD_OFFICE_ACCESS = 'GET_METHOD_OFFICE_ACCESS'
export const SET_METHOD_OFFICE_LOADING = 'SET_METHOD_OFFICE_LOADING'
export const GET_METHOD_OFFICE_ACCESS_FILTERED_PERSONNEL = 'GET_METHOD_OFFICE_ACCESS_FILTERED_PERSONNEL'
export const GET_METHOD_OFFICE_ACCESS_PERSONNEL = 'GET_METHOD_OFFICE_ACCESS_PERSONNEL'
export const METHOD_OFFICE_TABLE_CLEAN = 'METHOD_OFFICE_TABLE_CLEAN'
export const GET_METHOD_OFFICE_FILES = 'GET_METHOD_OFFICE_FILES'
export const GET_METHOD_OFFICE_FOLDER = 'GET_METHOD_OFFICE_FOLDER'
export const GET_METHOD_OFFICE_SUBFOLDER = 'GET_METHOD_OFFICE_SUBFOLDER'

export const SET_SETTINGS_MAIN_DATA = 'SET_SETTINGS_MAIN_DATA'
export const SET_SETTINGS_ABOUT_DATA = 'SET_SETTINGS_ABOUT_DATA'
export const SET_SETTINGS_CANDIDATES_RATE = 'SET_SETTINGS_CANDIDATES_RATE'
export const SET_SETTINGS_CANDIDATES_PARAMS = 'SET_SETTINGS_CANDIDATES_PARAMS'
export const SET_SETTINGS_CANDIDATES_NOTICES = 'SET_SETTINGS_CANDIDATES_NOTICES'
export const SET_SETTINGS_CANDIDATES_NOTICES_STAT = 'SET_SETTINGS_CANDIDATES_NOTICES_STAT'
export const SET_SETTINGS_CANDIDATES_EXAM_TICKETS = 'SET_SETTINGS_CANDIDATES_EXAM_TICKETS'
export const SET_SETTINGS_RESULTS_DATA = 'SET_SETTINGS_RESULTS_DATA'
export const SET_SETTINGS_ADMINISTRATION_DATA = 'SET_SETTINGS_ADMINISTRATION_DATA'
export const SET_SETTINGS_EXPORT_DATA = 'SET_SETTINGS_EXPORT_DATA'
export const SET_SETTINGS_KIOSK_DATA = 'SET_SETTINGS_KIOSK_DATA'
export const SET_SETTINGS_KIOSK_VIDEO_DATA = 'SET_SETTINGS_KIOSK_VIDEO_DATA'
export const SET_SETTINGS_STRENGTH_DATA = 'SET_SETTINGS_STRENGTH_DATA'
export const SET_SETTINGS_STRENGTH_TABLE_DATA = 'SET_SETTINGS_STRENGTH_TABLE_DATA'
export const SET_SETTINGS_CONTROL_DATA = 'SET_SETTINGS_CONTROL_DATA'
export const SET_SETTINGS_LOADING = 'SET_SETTINGS_LOADING'

export const SET_STUDENTS_LOADING = 'SET_STUDENTS_LOADING'
export const SET_STUDENTS_LIST = 'SET_STUDENTS_LIST'
export const SET_STUDENTS_LIST_FOR_GENERAL_INFO = 'SET_STUDENTS_LIST_FOR_GENERAL_INFO'
export const SET_STUDENTS_LIST_FOR_GENERAL_INFO_EXPELLED = 'SET_STUDENTS_LIST_FOR_GENERAL_INFO_EXPELLED'
export const SET_STUDENTS_DATA_MAIN = 'SET_STUDENTS_DATA_MAIN'
export const SET_STUDENTS_DATA_OTHER = 'SET_STUDENTS_DATA_OTHER'
export const SET_STUDENTS_DATA_FAMILY = 'SET_STUDENTS_DATA_FAMILY'


export const SET_DISCIPLINE_STRUCTURE_FILTER = 'SET_DISCIPLINE_STRUCTURE_FILTER'
export const SET_DISCIPLINE_STRUCTURE_DATA = 'SET_DISCIPLINE_STRUCTURE_DATA'
export const SET_DISCIPLINE_STRUCTURE_LOADING = 'SET_DISCIPLINE_STRUCTURE_LOADING'

export const SET_ROOM_TYPES = 'SET_ROOM_TYPES'
export const SET_ROOM_TYPES_LOAD = 'SET_ROOM_TYPES_LOAD'
export const GET_STUDENT_LIST = 'GET_STUDENT_LIST'
export const GET_STUDENT_LIST_LOADING = 'GET_STUDENT_LIST_LOADING'

export const SET_COURSES_ALIAS_LIST = 'SET_COURSES_ALIAS_LIST'
export const SET_COURSES_ALIAS_LIST_LOADING = 'SET_COURSES_ALIAS_LIST_LOADING'

export const SET_EDU_DIR_LIST_LOADING = 'SET_EDU_DIR_LIST_LOADING'
export const SET_EDU_DIR_LIST = 'SET_EDU_DIR_LIST'
export const SET_CULTURE_LIST_LOADING = 'SET_CULTURE_LIST_LOADING'
export const SET_CULTURE_LIST = 'SET_CULTURE_LIST'
export const SET_COMPETENCE_LIST = 'SET_COMPETENCE_LIST'
export const SET_COMPETENCE_LIST_LOADING = 'SET_COMPETENCE_LIST_LOADING'

export const SET_EQUIPMENTS_LIST = 'SET_EQUIPMENTS_LIST'
export const SET_EQUIPMENTS_BY_ROOM_LIST = 'SET_EQUIPMENTS_BY_ROOM_LIST'

export const GET_THEME_PLANNING = 'GET_THEME_PLANNING'
export const SET_THEME_PLANNING_LOAD = 'SET_THEME_PLANNING_LOAD'

export const SET_ADS = 'SET_ADS'
export const SET_ADS_LOADING = 'SET_ADS_LOADING'
export const SET_ADS_BY_ID = 'SET_ADS_BY_ID'
export const SET_ADS_BY_ID_LOADING = 'SET_ADS_BY_ID_LOADING'

export const SET_EVENT_TYPES = 'SET_EVENT_TYPES'
export const SET_EVENT_TYPES_LOADING = 'SET_EVENT_TYPES_LOADING'
export const SET_EVENT_TYPE_BY_ID = 'SET_EVENT_TYPE_BY_ID'

export const SET_FAQ = 'SET_FAQ'
export const SET_FAQ_LOADING = 'SET_FAQ_LOADING'
export const SET_FAQ_BY_ID = 'SET_FAQ_BY_ID'
export const SET_FAQ_BY_ID_LOADING = 'SET_FAQ_BY_ID_LOADING'
export const SET_FIO = 'SET_FIO'

export const SET_MESSAGE = 'SET_MESSAGE'
export const SET_MESSAGE_LOADING = 'SET_MESSAGE_LOADING'

export const GET_FORMS_OF_EDUCATION = 'GET_FORMS_OF_EDUCATION'

export const SET_STUDENTS = 'SET_STUDENTS'
export const SET_PARENTS = 'SET_PARENTS'
export const SET_EMPLOYEES = 'SET_EMPLOYEES'
export const SET_ROLES = 'SET_ROLES'
export const SET_FAQ_FILES = 'SET_FAQ_FILES'
export const SET_FAQ_PAGE = 'SET_FAQ_PAGE'
export const SET_FAQ_COUNT = 'SET_FAQ_COUNT'
export const GET_CLASS_PARALLEL_LIST = 'GET_CLASS_PARALLEL_LIST'
export const GET_DAILY_ROUTINE_LIST = 'GET_DAILY_ROUTINE_LIST'
export const GET_DAYS_LIST = 'GET_DAYS_LIST'
export const SET_EVENTS_LIST = 'SET_EVENTS_LIST'

/// СПРАВОЧНИКИ
export const GET_GRADE_WEIGHT = 'GET_GRADE_WEIGHT'
export const GET_GRADE_OPTIONS = 'GET_GRADE_OPTIONS'
export const GET_TYPES_STUDY_SESSIONS = 'GET_TYPES_STUDY_SESSIONS'
export const GET_EDUCATIONAL_STATUSES = 'GET_EDUCATIONAL_STATUSES'
export const GET_COUNTRIES = 'GET_COUNTRIES'

export const GET_EDUCATIONAL_STRUCTURE = 'GET_EDUCATIONAL_STRUCTURE'
export const GET_EDUCATIONAL_STRUCTURE_DEPARTMENTS = 'GET_EDUCATIONAL_STRUCTURE_DEPARTMENTS'
export const GET_EDUCATIONAL_STRUCTURE_DEPARTMENTS_INFO = 'GET_EDUCATIONAL_STRUCTURE_DEPARTMENTS_INFO'
export const GET_COURSES_FOR_EDUCATIONAL_STRUCTURE = 'GET_COURSES_FOR_EDUCATIONAL_STRUCTURE'
export const GET_CLASSES_FOR_EDUCATIONAL_STRUCTURE = 'GET_COURSES_FOR_EDUCATIONAL_STRUCTURE'

export const SET_HOLIDAYS_LIST = 'SET_HOLIDAYS_LIST'
export const SET_EDUC_INSTIT_LIST = 'SET_EDUC_INSTIT_LIST'
export const SET_STAGES_STUDY_LIST = 'SET_STAGES_STUDY_LIST'
export const SET_FITNESS_INDICATORS_LIST = 'SET_FITNESS_INDICATORS_LIST'
export const SET_TYPICAL_COMMENT_LIST = 'SET_TYPICAL_COMMENT_LIST'

///   ОСНОВНОЕ ОБРАЗОВАНИЕ  // СПРАВОЧНИКИ /
export const GET_TRACK_PROFILE_LIST = 'GET_TRACK_PROFILE_LIST'
export const GET_TRACK_PROFILE_CURR = 'GET_TRACK_PROFILE_CURR'
export const GET_STUDY_SHIFTS_LIST = 'GET_STUDY_SHIFTS_LIST'
export const GET_STUDY_MANNERS_LIST = 'GET_STUDY_MANNERS_LIST'
export const GET_JOURNAL_MANNERS_LIST = 'GET_JOURNAL_MANNERS_LIST'
export const GET_HOMEWORKS = 'GET_HOMEWORKS'
export const GET_DIGITAL_EXAM_BOOK = 'GET_DIGITAL_EXAM_BOOK'
export const GET_JORNAL_CONTROL = 'GET_JORNAL_CONTROL'
export const GET_JORNAL_CONTROL_COURSES = 'GET_JORNAL_CONTROL_COURSES'
export const GET_HOMEWORK_LIST_BASEEDUC = 'GET_HOMEWORK_LIST_BASEEDUC'

export const SET_JOURNAL_CONTROL_PERIODS = 'SET_JOURNAL_CONTROL_PERIODS'
export const SET_JOURNAL_CONTROL_GROUPS = 'SET_JOURNAL_CONTROL_GROUPS'
export const SET_JOURNAL_CONTROL_COURSES = 'SET_JOURNAL_CONTROL_COURSES'
export const SET_JOURNAL_CONTROL_COURSES_ALL = 'SET_JOURNAL_CONTROL_COURSES_ALL'
export const SET_JOURNAL_CONTROL_DATA = 'SET_JOURNAL_CONTROL_DATA'
export const SET_JOURNAL_CONTROL_LOADING = 'SET_JOURNAL_CONTROL_LOADING'
export const GET_SUBGROUP_LIST_COURSES = 'GET_SUBGROUP_LIST_COURSES'
export const GET_SUBGROUP_TABLES_LIST = 'GET_SUBGROUP_TABLES_LIST'
export const GET_SUBGROUP_BY_ID = 'GET_SUBGROUP_BY_ID'
export const GET_SUBGROUP_CLASS_LIST = 'GET_SUBGROUP_CLASS_LIST'
export const GET_SUBGROUP_GROUPUSER = 'GET_SUBGROUP_GROUPUSER'



export const SET_BASIC_EDUCATION_LOADING = 'SET_BASIC_EDUCATION_LOADING'
///   ОСНОВНОЕ ОБРАЗОВАНИЕ  // СПРАВОЧНИКИ /

///   ДОПОЛНИТЕЛЬНОЕ ОБРАЗОВАНИЕ  // СПРАВОЧНИКИ /
export const GET_SUBJECTS_EXTRA_EDU_LIST = 'GET_SUBJECTS_EXTRA_EDU_LIST'
export const SET_SCHEDULING_DO_LIST = 'SET_SCHEDULING_DO_LIST'
export const SET_SCHEDULING_DO_YEAR_LIST = 'SET_SCHEDULING_DO_YEAR_LIST'
export const SET_STUDY_PLANS_DO = 'SET_STUDY_PLANS_DO'
export const SET_STUDY_PLAN_DO_FILES = 'SET_STUDY_PLAN_DO_FILES'
///   ДОПОЛНИТЕЛЬНОЕ ОБРАЗОВАНИЕ  // СПРАВОЧНИКИ /



///   ВНЕУРОЧНАЯ ДЕЯТЕЛЬНОСТЬ  // СПРАВОЧНИКИ /
export const SET_SCHEDULING_VD_LIST = 'SET_SCHEDULING_VD_LIST'
export const SET_STUDY_PLANS_VD = 'SET_STUDY_PLANS_VD'
export const SET_STUDY_PLAN_VD_BY_ID = 'SET_STUDY_PLAN_VD_BY_ID'
export const SET_STUDY_PLAN_VD_COURSES_LIST = 'SET_STUDY_PLAN_VD_COURSES_LIST'
export const SET_STUDY_PLAN_VD_FILES = 'SET_STUDY_PLAN_VD_FILES'
export const SET_STUDY_PLAN_VD_LOADING = 'SET_STUDY_PLAN_VD_LOADING'
///   ВНЕУРОЧНАЯ ДЕЯТЕЛЬНОСТЬ  // СПРАВОЧНИКИ /

/// ПРИЕМНАЯ КОМИССИИЯ
export const SET_ADMISSIONS_LIST = 'SET_ADMISSIONS_LIST'
export const SET_ADMISSIONS_LOADING = 'SET_ADMISSIONS_LOADING'
export const SET_ADMISSION_BY_ID = 'SET_ADMISSION_BY_ID'
export const SET_ADMISSION_DISCIPLINES = 'SET_ADMISSION_DISCIPLINES'
export const SET_ADMISSION_DISCIPLINES_FOR_SELECT = 'SET_ADMISSION_DISCIPLINES_FOR_SELECT'
export const SET_TESTING_SCHEDULE_LIST = 'SET_TESTING_SCHEDULE_LIST'
/// ПРИЕМНАЯ КОМИССИИЯ


///   НАСТРОЙКИ  // ПОЛЬЗОВАТЕЛЬСКИЙ СЛОВАРЬ /
export const GET_USER_DICT_LIST = 'GET_USER_DICT_LIST'
export const SET_USER_DICT_LIST_LOADING = 'SET_USER_DICT_LIST_LOADING'
export const SET_USER_DICT_BY_ID = 'SET_USER_DICT_BY_ID'
export const SET_BACKUP_BY_NAME = 'SET_BACKUP_BY_NAME'
export const SET_BACKUP_LIST = 'SET_BACKUP_LIST'
export const SET_BACKUP_TIME = 'SET_BACKUP_TIME'
///   НАСТРОЙКИ  // ПОЛЬЗОВАТЕЛЬСКИЙ СЛОВАРЬ /

export const GET_COMPETENCE = 'GET_COMPETENCE'

export const SET_SCHEDULING_LOADING = 'SET_SCHEDULING_LOADING'
export const SET_SCHEDULING_DATA = 'SET_SCHEDULING_DATA'

export const SET_EDU_DIRECTIONS_LIST = 'SET_EDU_DIRECTIONS_LIST'
export const SET_EDU_DIRECTIONS_LOADING = 'SET_EDU_DIRECTIONS_LOADING'

export const SET_EDUC_DIRECTIONS_LIST = 'SET_EDUC_DIRECTIONS_LIST'
export const SET_EDUC_DIRECTIONS_LOADING = 'SET_EDUC_DIRECTIONS_LOADING'

export const SET_ACHIEV_POINTS_LIST = 'SET_ACHIEV_POINTS_LIST'
export const SET_ACHIEV_POINTS_LOADING = 'SET_ACHIEV_POINTS_LOADING'

export const SET_PHYSICAL_GROUP_LIST = 'SET_PHYSICAL_GROUP_LIST'
export const SET_PHYSICAL_GROUP_LOADING = 'SET_PHYSICAL_GROUP_LOADING'

export const SET_ACT_TYPES_LIST = 'SET_ACT_TYPES_LIST'
export const SET_ACT_TYPES_LOADING = 'SET_ACT_TYPES_LOADING'

export const SET_ACHIEVEMENTS_LIST = 'SET_ACHIEVEMENTS_LIST'
export const SET_ACHIEVEMENTS_LOADING = 'SET_ACHIEVEMENTS_LOADING'

export const SET_DO_SCHEDULE_VARIANTS_YEAR = 'SET_DO_SCHEDULE_VARIANTS_YEAR'
export const SET_DO_SCHEDULE_VARIANTS_LIST = 'SET_DO_SCHEDULE_VARIANTS_LIST'
export const SET_DO_SCHEDULE_VARIANTS_LOADING = 'SET_DO_SCHEDULE_VARIANTS_LOADING'


export const SET_VD_SCHEDULE_VARIANTS_YEAR = 'SET_VD_SCHEDULE_VARIANTS_YEAR'
export const SET_VD_SCHEDULE_VARIANTS_LIST = 'SET_VD_SCHEDULE_VARIANTS_LIST'
export const SET_VD_SCHEDULE_VARIANTS_LOADING = 'SET_VD_SCHEDULE_VARIANTS_LOADING'


export const GET_CULTURE = 'GET_CULTURE'

export const GET_SCHEDULE_SETTINGS_TEACHERS_SUBJECTS = 'GET_SCHEDULE_SETTINGS_TEACHERS_SUBJECTS'
export const GET_SCHEDULE_SETTINGS_CYCLE = 'GET_SCHEDULE_SETTINGS_CYCLE'
export const GET_SCHEDULE_SETTINGS_ROOMS_SUBJECTS = 'GET_SCHEDULE_SETTINGS_ROOMS_SUBJECTS'
export const GET_SCHEDULE_SETTINGS_ROOMS_TEACHERS = 'GET_SCHEDULE_SETTINGS_ROOMS_TEACHERS'
export const GET_SCHEDULE_SETTINGS_ROLE = 'GET_SCHEDULE_SETTINGS_ROLE'
export const GET_SCHEDULE_SETTINGS_LESSONS = 'GET_SCHEDULE_SETTINGS_LESSONS'
export const GET_SCHEDULE_SETTINGS_ROOMS = 'GET_SCHEDULE_SETTINGS_ROOMS'
export const GET_SCHEDULE_SETTINGS_COMPLEXITY_GROUPS_SUBJECTS = 'GET_SCHEDULE_SETTINGS_COMPLEXITY_GROUPS_SUBJECTS'
export const GET_SCHEDULE_SETTINGS_TEACHERS_LESSON = 'GET_SCHEDULE_SETTINGS_TEACHERS_LESSON'
export const GET_SCHEDULE_SETTINGS_GROUPS = 'GET_SCHEDULE_SETTINGS_GROUPS'
export const GET_SCHEDULE_SETTINGS_DAY_OF_WEEK = 'GET_SCHEDULE_SETTINGS_DAY_OF_WEEK'
export const GET_SCHEDULE_SETTINGS_WORKLOAD_TEACHERS = 'GET_SCHEDULE_SETTINGS_WORKLOAD_TEACHERS'
export const GET_SCHEDULE_SETTINGS_STUDY_PERIOD = 'GET_SCHEDULE_SETTINGS_STUDY_PERIOD'
export const SET_SCHEDULE_SETTINGS_LOADING = 'SET_SCHEDULE_SETTINGS_LOADING'

export const GET_SUBJECT_BY_YEAR_STUDY = 'GET_SUBJECT_BY_YEAR_STUDY'
export const GET_STUDY_PLANS = 'GET_STUDY_PLANS'

export const SET_STUDY_PLANS = 'SET_STUDY_PLANS'
export const SET_STUDY_PLAN_DO_BY_ID = 'SET_STUDY_PLAN_DO_BY_ID'
export const SET_STUDY_PLANS_LOADING = 'SET_STUDY_PLANS_LOADING'
export const SET_STUDY_PLANS_PAGE = 'SET_STUDY_PLANS_PAGE'
export const SET_STUDY_PLANS_COUNT = 'SET_STUDY_PLANS_COUNT'
export const SET_STUDY_PLAN = 'SET_STUDY_PLAN'
export const SET_STUDY_PLAN_DO_COURSES_LIST = 'SET_STUDY_PLAN_DO_COURSES_LIST'
export const SET_STUDY_PLAN_LOADING = 'SET_STUDY_PLAN_LOADING'

export const SET_STUDY_PLAN_LIST = 'SET_STUDY_PLAN_LIST'
export const SET_STUDY_PLAN_LIST_LOADING = 'SET_STUDY_PLAN_LIST_LOADING'

export const SET_TRACK_PROFILE = 'SET_TRACK_PROFILE'
export const SET_TRACK_PROFILE_LOADING = 'SET_TRACK_PROFILE_LOADING'

// МЕДПУНКТ СПРАВОЧНИКИ
export const GET_TYPES_DISEASE = 'GET_TYPES_DISEASE'
export const GET_MKB_TEN = 'GET_MKB_TEN'
export const GET_HEALTH_GROUP = 'GET_HEALTH_GROUP'
export const GET_DISCHARGE = 'GET_DISCHARGE'
export const GET_VACCINATIONS = 'GET_VACCINATIONS'
export const GET_VACCINATIOS_CURR = 'GET_VACCINATIOS_CURR'
export const GET_TYPES_OF_VACCINATIONS = 'GET_TYPES_OF_VACCINATIONS'
export const GET_MKB_TEN_TOP = 'GET_MKB_TEN_TOP'
export const GET_MKB_TEN_MID = 'GET_MKB_TEN_MID'
export const GET_MKB_TEN_BOT = 'GET_MKB_TEN_BOT'
export const GET_SPEC_MED_GROUPS = 'GET_SPEC_MED_GROUPS'
export const GET_ATTACHED_STUDENTS = 'GET_ATTACHED_STUDENTS'
export const GET_SPEC_MED_GROUPS_TEACHERS = 'GET_SPEC_MED_GROUPS_TEACHERS'
export const GET_SPEC_MED_GROUPS_STUDENTS = 'GET_SPEC_MED_GROUPS_STUDENTS'
export const GET_SPEC_MED_GROUPS_GROUPS = 'GET_SPEC_MED_GROUPS_GROUPS'

export const GET_DIAGNOSES = 'GET_DIAGNOSES'
export const GET_MES_VISITES = 'GET_MES_VISITES'
export const GET_LIST = 'GET_LIST'
export const GET_APPEALS = 'GET_APPEALS'
export const GET_VACCINE_EXCLUSIONS = 'GET_VACCINE_EXCLUSIONS'
export const GET_VACCINATION_MODAL = 'GET_VACCINATION_MODAL'

export const SET_MEDBLOCK_LOADING = 'SET_MEDBLOCK_LOADING'


//СТОЛОВАЯ
export const GET_GROUP_NUTRITION_LIST = 'GET_GROUP_NUTRITION_LIST'
export const GET_GROUP_NUTRITION_PARALLELS = 'GET_GROUP_NUTRITION_PARALLELS'
export const GET_NUTRITION_GROUP = 'GET_NUTRITION_GROUP'
export const GET_PERIOD_NUTRITION_LIST = 'GET_PERIOD_NUTRITION_LIST'
export const GET_NUTRITION_PERIOD = 'GET_NUTRITION_PERIOD'
export const GET_NUTRITION_PERIOD_FILES = 'GET_NUTRITION_PERIOD_FILES'
export const GET_NUTRITION_PERIOD_FILE = 'GET_NUTRITION_PERIOD_FILE'
export const GET_NUTRITION_PERIOD_CREATOR = 'GET_NUTRITION_PERIOD_CREATOR'

export const GET_CANTEEN_STAFF_LIST = 'GET_CANTEEN_STAFF_LIST'
export const GET_CANTEEN_STAFF = 'GET_CANTEEN_STAFF'
export const GET_CANTEEN_STAFF_FILE = 'GET_CANTEEN_STAFF_FILE'

export const GET_DISH_TYPE_LIST = 'GET_DISH_TYPE_LIST'
export const GET_DISH_FILE = 'GET_DISH_FILE'

export const SET_EXAM_INFO = 'SET_EXAM_INFO'
export const SET_EXAM_SCHEDULE = 'SET_EXAM_SCHEDULE'
export const SET_EXAM_REPORT = 'SET_EXAM_REPORT'
export const SET_EXAM_YEAR = 'SET_EXAM_YEAR'
export const SET_EXAM_LOADING = 'SET_EXAM_LOADING'


export const GET_MEAL = 'GET_MEAL'
export const GET_DISH = 'GET_DISH'
export const GET_MENU_ON_DATE = 'GET_MENU_ON_DATE'

export const SET_CANTEEN_LOADING = 'SET_CANTEEN_LOADING'

export const GET_SUBJECTS_EXTRA_CUR_EDU_LIST = 'GET_SUBJECTS_EXTRA_CUR_EDU_LIST'
export const GET_SUBJECTS_EXTRA_CUR_EDU_ITEM = 'GET_SUBJECTS_EXTRA_CUR_EDU_ITEM'

export const SET_PERSONAL_SCHEDULE_DO_CURRENT_LOADING = 'SET_PERSONAL_SCHEDULE_DO_CURRENT_LOADING'
export const GET_PERSONAL_SCHEDULE_DO_CURRENT = 'GET_PERSONAL_SCHEDULE_DO_CURRENT'
export const GET_PERSONAL_SCHEDULE_DO_CLASS_LIST = 'GET_PERSONAL_SCHEDULE_DO_CLASS_LIST'
export const GET_PERSONAL_SCHEDULE_DO_WEEKS_LIST = 'GET_PERSONAL_SCHEDULE_DO_WEEKS_LIST'
export const GET_PERSONAL_SCHEDULE_DO_CURRENT_NEW = 'GET_PERSONAL_SCHEDULE_DO_CURRENT_NEW'
export const SET_PERSONAL_SCHEDULE_DO_CURRENT_NEW_LOADING = 'SET_PERSONAL_SCHEDULE_DO_CURRENT_NEW_LOADING'
export const SET_PERSONAL_SCHOOL_YEARS = 'SET_PERSONAL_SCHOOL_YEARS'
export const GET_PERSONAL_TEACHER_LIST = 'GET_PERSONAL_TEACHER_LIST'


export const GET_TRAJECTORIES_LIST = 'GET_TRAJECTORIES_LIST'
export const SET_TRAJECTORIES_LIST_LOADING = 'SET_TRAJECTORIES_LIST_LOADING'
export const SET_TRAJECTORIES_BY_ID = 'SET_TRAJECTORIES_BY_ID'

export const GET_EXAM_CITY_LIST = 'GET_EXAM_CITY_LIST'
export const SET_EXAM_CITY_LIST_LOADING = 'SET_EXAM_CITY_LIST_LOADING'
export const SET_EXAM_CITY_BY_ID = 'SET_EXAM_CITY_BY_ID'

export const GET_EXAM_BOARD_LIST = 'GET_EXAM_BOARD_LIST'
export const SET_EXAM_BOARD_LIST_LOADING = 'SET_EXAM_BOARD_LIST_LOADING'
export const SET_EXAM_BOARD_BY_ID = 'SET_EXAM_BOARD_BY_ID'
export const GET_EXAM_BOARD_YEARS = 'GET_EXAM_BOARD_YEARS'
export const GET_EXAM_BOARD_KURSES = 'GET_EXAM_BOARD_KURSES'

export const SET_ENROLL_QUESTIONS_LIST = 'SET_ENROLL_QUESTIONS_LIST'
export const SET_ENROLL_QUESTIONS_DATA = 'SET_ENROLL_QUESTIONS_DATA'
export const SET_ENROLL_QUESTIONS_LOADING = 'SET_ENROLL_QUESTIONS_LOADING'
export const SET_ENROLL_QUESTIONS_BLOCKS = 'SET_ENROLL_QUESTIONS_BLOCKS'
export const SET_ENROLL_QUESTIONS_LESSONS = 'SET_ENROLL_QUESTIONS_LESSONS'
export const SET_ENROLL_QUESTIONS_LEVELS = 'SET_ENROLL_QUESTIONS_LEVELS'

export const SET_EXAM_BOARD_TRAJECTORIES_BY_ID = 'SET_EXAM_BOARD_TRAJECTORIES_BY_ID'
export const SET_EXAM_BOARD_INTERVIEWS_BY_ID = 'SET_EXAM_BOARD_INTERVIEWS_BY_ID'
export const SET_EXAM_BOARD_INTERVIEWERS_BY_ID = 'SET_EXAM_BOARD_INTERVIEWERS_BY_ID'
export const SET_EXAM_BOARD_INTERVIEWERS_ALL = 'SET_EXAM_BOARD_INTERVIEWERS_ALL'
