import * as types from "../actions/types";


const initialState = {
  schedule: [],
  weeks: [],
  classSchedule: [],
  loading: false,
  schoolYears: [],
  teachers: []
};


export default function personalScheduleDO (state = initialState, action) {
  switch (action.type) {

    case types.GET_PERSONAL_SCHEDULE_DO_CURRENT:
      return {
        ...state,
        schedule: action.payload
      };
    case types.GET_PERSONAL_SCHEDULE_DO_WEEKS_LIST:
      return {
        ...state,
        weeks: action.payload
      };
    case types.SET_PERSONAL_SCHOOL_YEARS:
      return {
        ...state,
        schoolYears: action.payload
      };
    case types.GET_PERSONAL_TEACHER_LIST:
      return {
        ...state,
        teachers: action.payload
      };

    case types.SET_PERSONAL_SCHEDULE_DO_CURRENT_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}