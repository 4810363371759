import React, { useState } from 'react'
import Modal from 'react-responsive-modal'
import { useDispatch } from 'react-redux'
import styles from './styles.module.css'
import { useFormik } from 'formik'
import { Input } from '../../components/ModalInputs'
import * as Yup from 'yup'
import ModalFooter from '../../components/ModalFooter/ModalFooter'
import { createStudyShift, updateStudyShift, } from '../../actions/directoryBasicEducation'

const validationSchema = Yup.object({
  name: Yup.string().required('обязательное поле').max(30, 'Должно быть не более 30 символов'),

})

export default function ModalComponent({ data, closeModal, height, addMode }) {
  console.log('addMode::: ', addMode);

  const dispatch = useDispatch()

  const handleCreate = (values) => {
    dispatch(createStudyShift(values))
    closeModal()
  }

  const handleUpdate = (values) => {
    dispatch(updateStudyShift(values))
    closeModal()
  }

  const {
    values,
    errors,
    touched,
    submitCount,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues: data,
    enableReinitialize: true,
    validateOnChange: true,
    validationSchema: validationSchema,
    onSubmit: addMode ? handleCreate : handleUpdate
  })

  const error = (fieldName) => (touched[fieldName] || submitCount) && errors[fieldName]


  return (
    <Modal
      open={!!data}
      onClose={() => false}
      showCloseIcon={false}
      height={height}
      classNames={{
        // overlay: styles.overlay,
        modal: styles.custom
      }}
    >
      <>
        <div className={styles.modalLocal} >
          <div className={styles.tabWrapper} >

            <div className={styles.inputsRow}>
              <Input
                label='Название'
                name='name'
                value={values.name}
                error={error('name')}
                onChange={handleChange}
                style={{ width: '600px' }}
              />
            </div>

          </div>
        </div>

        <ModalFooter handleSubmit={handleSubmit} closeModal={closeModal} />
      </>
    </Modal>

  )
}
