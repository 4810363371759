import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { IoIosAdd } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Modal from './Modal'
import ModalPeriod from './ModalPeriod'
import ModalMenuOnDate from './ModalMenuOnDate'
import Table from '../../components/Table'
import Confirmation from '../../components/Confirmation/Confirmation'
import { getMeal, getDish, createMenu, getMenuOnDate, copyDishPeriod, copyMenu } from '../../actions/directoryCanteen'
import { Input, } from '../../components/ModalInputs'
import moment from 'moment'


export default function MenuDay() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { meal, dish, menuOnDate } = useSelector(state => state.directoryCanteen)



  const [selectedItem, setSelectedItem] = useState(null)
  const [selectedDay, setSelectedDay] = useState({ date: moment().format('YYYY-MM-DD') })
  const [confirmation, setConfirmation] = useState(false);

  const [createRow, setCreateRow] = useState(null);
  const [arrRows, setArrRows] = useState([])
  const [selectedDish, setSelectedDish] = useState(null) // выбор блюда на период
  const [dishForPeriod, setDishForPeriod] = useState(null)  // блюдо для копирования в др период
  console.log('dishForPeriod::: ', dishForPeriod);
  const [newRow, setNewRow] = useState(false)
  const [insideRow, setInsideRow] = useState(false)
  const [openPeriod, setOpenPeriod] = useState(false)
  const [openMenuOnDate, setOpenMenuOnDate] = useState(false)
  const [buttonToDisable, setButtonToDisable] = useState(false)

  useEffect(() => {
    dispatch(getMeal())
  }, [])

  useEffect(() => {
    !selectedDish && dispatch(getMeal())
    !createRow && selectedDish && setCreateRow((prev) =>
      prev?.map(el => el.xp_period === selectedDish?.xp_period ?
        // prev?.map(el => el.xp_period === selectedDish?.eat_period[0].eat_period_xp_key ? //ПУТАНИЦА С ОПРЕДЕЛЕНИЕМ ТАРЕЛОК
        { ...el, ...selectedDish } : el))
  }, [selectedDish])

  useEffect(() => {
    insideRow !== null && selectedDish && setArrRows((prev) =>
      prev?.map(ele => ele.map(el =>
        el.period_key === selectedDish?.xp_period && el?.sort === insideRow ? // координаты клетки верт=xp_period гориз=sort
          // el.period_key === selectedDish?.eat_period[0].eat_period_xp_key  && el?.sort === insideRow ?//ПУТАНИЦА С ОПРЕДЕЛЕНИЕМ ТАРЕЛОК
          { ...el, ...selectedDish } : el
      )))
  }, [selectedDish])

  useEffect(() => {
    newRow && selectedDish && createRow && setArrRows((prev) =>
      [...prev, createRow]) // добавление в глобальный массив
  }, [createRow])

  useEffect(() => {
    const arr = arrRows.flat()
    // setArrayForRequest(arr.filter(el => !!el.xp_key && el))
    if (arrRows && selectedDish) {
      setNewRow(false)
      setCreateRow(null)
      setSelectedDish(null)
    }
  }, [arrRows])

  useEffect(() => {
    newRow && meal && setArrRows((prev) => [...prev, meal.map(el => ({ period_key: el.xp_key, sort: arrRows.length }))]) // первичный массив +level для определения
  }, [newRow])

  //создание всего массива при получении запроса
  useEffect(() => {
    if (!menuOnDate) return
    if (menuOnDate) {
      const sortedDishes = menuOnDate?.reduce((acc, item) => {
        if (!acc[item.sort]) {
          acc[item.sort] = [];
        }
        acc[item.sort]?.push(item)
        return acc;
      }, {});
      const sortedDishesArray = Object.values(sortedDishes);
      // добавляю пустые ячейки
      const fullRow = sortedDishesArray?.map((row, i) => meal?.map(meal => row.find(rowEl => rowEl.period_key === meal.xp_key) || { period_key: meal.xp_key, sort: i, }))
      setArrRows(fullRow)
    }
  }, [menuOnDate])

  useEffect(() => {
    dispatch(getMenuOnDate(selectedDay))
  }, [selectedDay])



  const openModal = (data) => () => {
    const selCell = Object.entries(data).length < 3 ? (Object.values(data)).join('') : `${data.period_key}${data.sort}`
    setSelectedItem(selCell)
    dispatch(getDish(data))
    setInsideRow(data?.sort)
  }

  const closeModal = () => {

    setSelectedItem(null)
    setOpenPeriod(null)
    setOpenMenuOnDate(null)
  }

  const goHome = () => navigate('/')

  const acceptConfirmation = () => {
    closeModal();
    // dispatch(deleteTypesOfDisease(confirmation.id));
    setConfirmation(false);
  };

  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  const renderHeader = (data) => {
    const { xp_key, name, } = data
    return (
      <th>{name}</th>
    )
  }

  const selectedDishForPeriod = (data) => {
    setDishForPeriod(data)
  }

  const renderLi = (data) => {
    const { dish_name, dish_period_key, xp_period } = data
    return (
      <td
        style={(dishForPeriod && !!dishForPeriod.dish_period_key && dishForPeriod.dish_period_key === dish_period_key) || (dishForPeriod && !!dishForPeriod.xp_period && dishForPeriod.xp_period === xp_period) ? { backgroundColor: 'aliceblue' } : {}}
        key={dish_period_key}
        onClick={() => selectedDishForPeriod(data)}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            {dish_name || data?.name}
          </div>
          <div onClick={openModal(data)}>
            ...
          </div>
        </div>
      </td>
    )
  }

  const handleChange = (e) => {
    setSelectedDay(
      { [e.target.name]: e.target.value }
    )
  }


  const handleCreateRow = () => {
    setButtonToDisable(true)
    setNewRow(true)
  }

  const handleOpenPeriod = () => {
    setOpenPeriod(true)
  }
  const handleMenuOnDate = () => {
    setOpenMenuOnDate(true)
  }

  return (
    <div className={styles.wrapper} style={{ margin: '0 auto' }}>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
          acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
          rejectConfirmation={rejectConfirmation}
        />
      )}

      {selectedItem &&
        <Modal
          closeModal={closeModal}
          data={dish}
          height={'430px'}
          // addMode={(!selectedItem?.name && selectedItem !== null)}
          addMode={selectedItem === 'add'}
          setSelectedDish={setSelectedDish}
          selectedDay={selectedDay}
        />
      }
      {openPeriod &&
        <ModalPeriod
          closeModal={closeModal}
          data={dishForPeriod}
          dishForPeriod={dishForPeriod}
          height={'430px'}
          // addMode={(!selectedItem?.name && selectedItem !== null)}
          addMode={selectedItem === 'add'}
          setSelectedDish={setSelectedDish}
          selectedDay={selectedDay}
          meal={meal}
        />
      }
      {openMenuOnDate &&
        <ModalMenuOnDate
          closeModal={closeModal}
          data={selectedDay}
          height={'430px'}
          // addMode={(!selectedItem?.name && selectedItem !== null)}
          addMode={selectedItem === 'add'}
          setSelectedDish={setSelectedDish}
          selectedDay={selectedDay}
        />
      }
      <h1>Меню на день</h1>
      <Input
        label='Дата'
        type='date'
        name='date'
        value={selectedDay?.date || moment().format('YYYY-MM-DD')}
        onChange={handleChange}
      />
      <Table className={styles.tables}>
        <thead>
          <tr>
            {meal?.map(renderHeader)}
          </tr>

        </thead>
        <tbody>
          {arrRows && arrRows.map(el => <tr>{el.map(renderLi)}</tr>)}
        </tbody>
      </Table>
      <div style={{ display: 'flex', flexDirection: "row" }}>
        <Button
          style={{ padding: '.5rem 1.5rem', margin: '12px' }}
          onClick={handleCreateRow}
        >
          <IoIosAdd size={30} />
          Добавить
        </Button>
        <Button
          style={{ padding: '.5rem 1.5rem', margin: '12px' }}
          // onClick={() => dispatch(createMenu(arrRows.flat()))}
          onClick={() => dispatch(createMenu((arrRows.flat()).filter(el => el.xp_key)))}
        >
          <IoIosAdd size={30} />
          Сохранить
        </Button>
        {<>
          <Button
            style={{ padding: '.5rem 1.5rem', margin: '12px' }}
            // onClick={() => dispatch(copyMenu((arrRows.flat()).filter(el=> el.dish_key)))} //TODO
            onClick={handleMenuOnDate}
          >
            <IoIosAdd size={30} />
            Сохранить как
          </Button>
          <Button
            style={{ padding: '.5rem 1.5rem', margin: '12px' }}
            // onClick={() => dispatch(copyDishPeriod(arrRows))}
            onClick={handleOpenPeriod}
          >
            <IoIosAdd size={30} />
            Скопировать блюда на другой период
          </Button>
        </>}
      </div>
    </div>
  )
}
