import React, { useEffect } from 'react'
import styles from './styles.module.css'
import Button from '../../components/Button'
import { IoIosAdd } from 'react-icons/io'
import { thrash } from '../../assets/svg/other'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import Modal from './Modal'
import Table from '../../components/Table'
import { getFormOfEducation, deleteFormOfEducation } from '../../actions/directory'
import { BiTrash } from 'react-icons/bi'
import Confirmation from '../../components/Confirmation/Confirmation'
import { createPeriodNutritionFile, deletePeriodNutrition, getGroupNutritionList, getPeriodNutritionList } from '../../actions/directoryCanteen'


export default function PeriodNutrition() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { formOfEducation } = useSelector(state => state.directory)
  const { periodNutritionList, groupNutritionList, forFile, filePNG } = useSelector(state => state.directoryCanteen)
  
  const [selectedItem, setSelectedItem] = useState(null)
  const [confirmation, setConfirmation] = useState(false);

  useEffect(() => {
    dispatch(getFormOfEducation())
    dispatch(getPeriodNutritionList())
    dispatch(getGroupNutritionList())
  }, [])


  const openModal = (data) => () => {
    setSelectedItem(data || {})
  }

  const closeModal = () => setSelectedItem(null)

  const goHome = () => navigate('/')


  const acceptConfirmation = () => {
    closeModal();
    dispatch(deletePeriodNutrition(confirmation.id));
    setConfirmation(false);
  };
  const rejectConfirmation = () => {
    setConfirmation(false);
  };

  const renderLi = (data) => {
    const { name, stop, start, xp_eat_group, xp_key } = data
    return (
      <tr >
        <td onClick={openModal(data)} style={{wordBreak: 'break-all', maxWidth: '150px', overflow: 'hidden',  textOverflow: 'ellipsis' }}>{name}</td>
        <td onClick={openModal(data)}>{groupNutritionList?.find(el => el.xp_key == xp_eat_group)?.name}</td>
        <td onClick={openModal(data)}>{start}</td>
        <td onClick={openModal(data)}>{stop}</td>
        <td><i className={styles.trash} onClick={() => setConfirmation({ id: xp_key, name })}><BiTrash /></i></td>

      </tr>
    )
  }


  return (
    <div className={styles.wrapper} style={{ width: '700px', margin: '0 auto' }}>
      {confirmation && (
        <Confirmation
          top="30px"
          left="40px"
          title={`Вы уверены, что хотите удалить ${confirmation.name}?`}
          acceptConfirmation={() => acceptConfirmation(confirmation?.name)}
          rejectConfirmation={rejectConfirmation}
        />
      )}
      {!!selectedItem &&
        <Modal
          closeModal={closeModal}
          data={selectedItem}
          width={'830px'}
          addMode={!selectedItem?.xp_key}
        />}
      <h1>Периоды питания</h1>

      <Table className={styles.tables}>
        <thead>
          <tr>
            <th>Название</th>
            <th>Группа питания</th>
            <th>Начало</th>
            <th>Окончание</th>
            <th></th>
          </tr>
        </thead>
        <tbody>

          {periodNutritionList?.map(renderLi)}

        </tbody>
      </Table>

      <div style={{ display: 'flex', flexDirection: "row" }}>
        <Button
          style={{ padding: '.5rem 1.5rem', margin: '12px' }}
          onClick={openModal()}
        >
          <IoIosAdd size={30} />
          Добавить
        </Button>
      </div>
    </div>
  )
}
