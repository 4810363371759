import { api } from "../../App";
import { SET_STUDENTS_DATA_FAMILY, SET_STUDENTS_DATA_MAIN, SET_STUDENTS_LOADING } from "../types";



export const getStudentFamily = (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: SET_STUDENTS_LOADING, payload: true });
      // const { data } = await api.get(`/student/${id}`)
      const {data} = (await api.get(`/xp_personal_file/family/user/${id}`))
      // console.log('datas::: ', datas);

      dispatch({ type: SET_STUDENTS_DATA_FAMILY, payload: data })
    } catch (ERR) {
      console.log(ERR)
    } finally {
      dispatch({ type: SET_STUDENTS_LOADING, payload: false });
    }
};

export const updateStudentFamily = (id, data) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_STUDENTS_LOADING, payload: true });
    await api.put(`/xp_personal_file/family/user/${id}`, data)
  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_STUDENTS_LOADING, payload: false });
  }
};
// TODO ??? есть на бэке
export const createStudentFamily = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_STUDENTS_LOADING, payload: true });
  await api.post('/students', data)
  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_STUDENTS_LOADING, payload: false });
  }
};

export const deleteStudentFamily = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: SET_STUDENTS_LOADING, payload: true });
    await api.delete(`/xp_personal_file/family/user/${id}`)
  } catch (ERR) {
    console.log(ERR)
  } finally {
    dispatch({ type: SET_STUDENTS_LOADING, payload: false });
  }
};