import * as types from "../actions/types";

const initialState = {
  list: null,
  loading: false
};


export default function dailyRoutin(state = initialState, action) {
  switch (action.type) {
    case types.GET_CLASS_PARALLEL_LIST:
      return {
        ...state,
        dailyRoutinClassList: action.payload
      };
    case types.GET_DAYS_LIST:
      return {
        ...state,
        daysList: action.payload
      };
    case types.GET_DAILY_ROUTINE_LIST:
      return {
        ...state,
        dailyRoutinList: action.payload
      };
    // case types.SET_ROOM_TYPES:
    //   return {
    //     ...state,
    //     loading: action.payload
    //   };
    default:
      return state;
  }
}