import React from 'react'
import styles from '../styles.module.css'

export default function Recommendations({ active }) {
  return (
    <div >
      Recommendations
    </div>
  )
}
