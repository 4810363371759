import moment from "moment";
import { BACKEND_URL_PREFIX } from "../assets/const";
import { createNotification } from "../components/Notifications/Notifications";
import { checkAndUpdateToken } from "./auth";
import { buildDefaultHeaders } from "./helper";

import {
  SET_PERSONAL_SCHEDULE_DO_CURRENT_LOADING, 
  GET_PERSONAL_SCHEDULE_DO_CURRENT, 
  GET_PERSONAL_SCHEDULE_DO_CLASS_LIST, 
  GET_PERSONAL_SCHEDULE_DO_WEEKS_LIST,
  GET_PERSONAL_SCHEDULE_DO_CURRENT_NEW,
  SET_PERSONAL_SCHEDULE_DO_CURRENT_NEW_LOADING,
  SET_PERSONAL_SCHOOL_YEARS,
  GET_PERSONAL_TEACHER_LIST
} from "./types";

export const getCurrentSchedule = (params) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({ type: SET_PERSONAL_SCHEDULE_DO_CURRENT_LOADING, payload: true });
        try {
          const response = await fetch(`${BACKEND_URL_PREFIX}/personal_schedule_do/${params.teacher}/${params.week}`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
          });
          if (response.ok) {      
            const schedule = (await response.json());
            dispatch({ type: GET_PERSONAL_SCHEDULE_DO_CURRENT, payload: schedule });
          } else {
            dispatch({ type: GET_PERSONAL_SCHEDULE_DO_CURRENT, payload: [] });
          }
        } catch (ERROR) {}
      }
        dispatch({ type: SET_PERSONAL_SCHEDULE_DO_CURRENT_LOADING, payload: false });
}

export const getCurrentScheduleNew = (params) => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
      dispatch({ type: SET_PERSONAL_SCHEDULE_DO_CURRENT_NEW_LOADING, payload: true });
      try {
        const response = await fetch(`${BACKEND_URL_PREFIX}/room_day_schedule/${params.room}${params.date!= moment().format('YYYY-MM-DD') ? `?date=${params.date}` : ''}`, {
          
            method: "GET",
          ...buildDefaultHeaders(getState),
        });
        if (response.ok) {      
          const schedule = (await response.json());
          if (schedule.find(el => el.name1 != null)) {
            dispatch({ type: GET_PERSONAL_SCHEDULE_DO_CURRENT_NEW, payload: schedule });
          } else {
            dispatch({ type: GET_PERSONAL_SCHEDULE_DO_CURRENT_NEW, payload: [] });
          }
        } else {
          dispatch({ type: GET_PERSONAL_SCHEDULE_DO_CURRENT_NEW, payload: [] });
        }
      } catch (ERROR) {}
    }
      dispatch({ type: SET_PERSONAL_SCHEDULE_DO_CURRENT_NEW_LOADING, payload: false });
}

export const getScheduleWeek = (xp_key_year) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());
    if (getState().auth.token) {
        dispatch({ type: SET_PERSONAL_SCHEDULE_DO_CURRENT_LOADING, payload: true });
        const response = await fetch(`${BACKEND_URL_PREFIX}/personal_schedule_do/weeks/${xp_key_year}`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
          });
          if (response.ok) {      
            const weeks =( await response.json());
            dispatch({ type: GET_PERSONAL_SCHEDULE_DO_WEEKS_LIST, payload: weeks });
          }
    };
        dispatch({ type: SET_PERSONAL_SCHEDULE_DO_CURRENT_LOADING, payload: false });
}

export const getPersonalTeacherList = (params) => async (dispatch, getState) => {
    await dispatch(checkAndUpdateToken());

    if (getState().auth.token) {
    
          const response = await fetch(`${BACKEND_URL_PREFIX}/personal_schedule_do/teachers`, {
            method: "GET",
            ...buildDefaultHeaders(getState),
          });
        
         
          if (response.ok) {      
            const teachers = ( await response.json());
            dispatch({ type: GET_PERSONAL_TEACHER_LIST, payload: teachers });
          }         
    };
}

export const getPersonalSchoolYears = () => async (dispatch, getState) => {
  await dispatch(checkAndUpdateToken());
  if (getState().auth.token) {
    const response = await fetch(`${BACKEND_URL_PREFIX}/personal_schedule_do/years`, {
      method: "GET",
      ...buildDefaultHeaders(getState),
    });
    if (response.ok) {
      const payload = await response.json();
      dispatch({ type: SET_PERSONAL_SCHOOL_YEARS, payload: payload });
    }
  }
};