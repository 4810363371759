import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getEnrollQuestionBlocks, getEnrollQuestionLessons, getEnrollQuestionLevels, getEnrollQuestions } from '../../actions/enrollQuestions'
import styles from './styles.module.scss'
import Title from '../../components/Title'
import { Form, Table, Modal, Footer } from './Components'
import Loader from '../../components/Loader'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'


const EnrollQuestions = () => {
  
  const dispatch = useDispatch()
  const { loading, list } = useSelector(state => state.enroll_questions)
  const navigate = useNavigate()
  

  const [selectedItem, setSelectedItem] = useState(null)

  const onSubmit = (values) => {
    console.log(values)
    dispatch(getEnrollQuestions(values))
  }

  const filters = useFormik({ initialValues: {}, onSubmit })

  useEffect(() => {
    dispatch(getEnrollQuestionBlocks())
    dispatch(getEnrollQuestionLevels())
    dispatch(getEnrollQuestionLessons())
    dispatch(getEnrollQuestions())
  }, [])
  
  const selectItemHandler = (data) => () => setSelectedItem(data || null)
  
  const goToMain = () => navigate('/')

  return (
    <div className={styles.wrapper}>

      <Title title='Перечень вопросов для тестирования'/>

      <Form filters={filters}/>
      
      {(loading && !selectedItem)
        ? <Loader/>
        : <Table
            data={list}
            selectItem={selectItemHandler}
            filters={filters.values}
          />
      }

      <Modal
        open={!!selectedItem}
        closeModal={selectItemHandler()}
        addMode={!selectedItem?.id}
        data={selectedItem}
        filters={filters.values}
      />

      <Footer addItem={selectItemHandler({})} goToMain={goToMain}/>

    </div>
  )
}

export default EnrollQuestions